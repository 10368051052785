import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import * as contentfulActions from "../../contentful/actions";

import { Deposit } from "../components/Deposit";

export const DepositPage = (props) => {
  const dispatch = useDispatch();

  const depositReducer = useSelector((state) => state.depositReducer);

  const contentfulReducer = useSelector((state) => state.contentfulReducer);
  const { helpTopicCashDeposit } = contentfulReducer;

  const authReducer = useSelector((state) => state.authReducer);
  const { loginResponseData } = authReducer;


  const handleViewDirectDiposit = () => {
    getBankAccountDetails();
  };


  useEffect(() => {
    dispatch(
      contentfulActions.doGetAndSetContent(
        contentfulActions.contentfulActionTypes.cashDeposit
      )
    );
  }, []);

  const getBankAccountDetails = () => {
    if (!loginResponseData) {
      return;
    }
    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
    };
    return dispatch(actions.doGetAndSetBankAccountDetails({ ...data }));
  };

  const propsTobeForward = {
    ...depositReducer,
    helpTopicCashDeposit,
    handleViewDirectDiposit,

  };

  return <Deposit {...propsTobeForward} />;
};
