export const invalidCreds = "Invalid username or password";
export const unauthorized = "401 - Unauthorized  ";
export const passwordChanged =
  "Password successfully updated";
export const newPasswordCreated =
  "Password successfully updated";
export const commonError = "Internal server error";
export const messageProcessRequestRaised =
  "Message process request has been raised successfully";
export const autoCategorizationRequestRaised =
  "Auto categorization request has been raised successfully";

export const messageProcessStatutesFullForm = `
  { EXF : Extraction failed },
  { RFP : Ready for processing },
  { PRF : process failed },
  { NED : Not enough data },
  { NFI : Financial institution not found },
`;
