import React from 'react'
import { ReactComponent as FilterIcon } from '../../../../assets/icons/filter-icon.svg';
import { ReactComponent as FilterActiveIcon } from '../../../../assets/icons/atm-filter-active.svg';
import SearchField from './SearchField';
import { Box, IconButton, styled } from '@material-ui/core';
import ATMFilterElements from './ATMFilterElements';
import { useAtmFilter } from '../../store/atmhooks';

const SearchAndFilterBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  display: 'flex',
  gap: theme.spacing(0, 0.5),
  alignItems: 'center',
}))
const SearchFieldCover = styled(Box)(({ theme }) => ({
  flex: '1 1 100%',
}))

const FilterBox = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  "& > svg": {
    // Size of input field
    height: '51px'
  },
  "&:hover": {
    backgroundColor: 'transparent'
  }
}))

export default function ATMFilter() {
  const { toggleSelectingFilter, isSelectingFilter } = useAtmFilter()
  return <Box>
    <SearchAndFilterBox data-testid="filter-cover">
      <SearchFieldCover data-testid="search-atm-text-field-cover">
        <SearchField />
      </SearchFieldCover>
      <FilterBox data-testid="filter-icon" disableFocusRipple onClick={toggleSelectingFilter}>
        {isSelectingFilter ? <FilterActiveIcon height={51} /> : <FilterIcon height={51} />}
      </FilterBox>
    </SearchAndFilterBox>
    <ATMFilterElements />
  </Box>
}