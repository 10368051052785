import { API_URL, additionalApiData } from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const transferMoneyPurse = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { fromAccount, toAccount, transfer_type, amount, user_id, card_ting } =
    data;
  return request(
    API_URL + `users/${user_id}/cards/${card_ting}/purse-transfer`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      fromAccount,
      toAccount,
      transfer_type,
      amount,

      channel,
      country_code,
      lang_code,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
