import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  quickLinkIcon: {
    background: theme.palette.other.primary100,
  },
  goalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  goalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  noDataWrapper: {
    textAlign: "center",
  },
  backBtnText: {
    marginTop: "16px"
  },
  backBtnContainer: {
    display: "flex"
  },
  titleContainer: {
    textAlign: "center"
  },
  iframe: {
    borderRadius: "16px",
    width: "100%",
    height: "90%",
    padding: "5px 10px 5px 10px"
  },
  imgBox: {
    height: "142px",
    width: "160px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px",
    display: "inline-block",
    margin: "5px",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    padding: "12px",
  }
}));
