import { makeStyles } from "@material-ui/core";
import { appColors } from "../../../../constants";

export const useStyles = makeStyles((theme) => ({
  goalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "25px 0px 0px 0px"
  },
  goalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  noDataWrapper: {
    textAlign: "center",
  },
  dateChip: {
    alignSelf: "center",
    marginLeft: "14rem",
    backgroundColor: appColors.primary500,
    marginRight: "5px",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
    }
  },
  filterButton: {
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(2),
    }
  },
  backBtnContainer: {
    display: "flex"
  },
  backBtnText: {
    margin: "24px -15px",
    color: appColors.black500
  },
  dateFilterContainer: {
    top: "180px !important"
  },
  toolTipBg: {
    backgroundColor: appColors.black500
  },
  muiDeleteIcon: {
    color: "black"
  },
  muiDeleteIcon: {
    height: "30px !important",
    width: "15px !important",
    color: "black",

  },
  accountInfoContainer: {
    paddingLeft: '1.25rem',
    margin: '1.25rem 0'
  }
}));
