import {
  SET_ALL_GOOGLE_MAP_DATA,
  SET_FREE_ATM_DATA,
  SET_GOOGLE_MAP_KEYS,
  SET_PAID_ATM_DATA,
  CHANGE_CURRENT_LOCATION,
} from "../../../redux/actionTypes";
import {
  GetGoogleMapData,
  GetGoogleMapKeys,
} from "../services";
import { apiCall } from "../../../common/actions/apiCall";

export const setMapKeys = (data) => {
  console.info("data", setMapKeys)
  return {
    type: SET_GOOGLE_MAP_KEYS,
    data,
  };
};

export const setAllGoogleMapData = (data) => {
  return {
    type: SET_ALL_GOOGLE_MAP_DATA,
    data,
  };
};

export const setFreeGoogleMapData = (data) => {
  return {
    type: SET_FREE_ATM_DATA,
    data,
  };
};

export const setCurrentLocation = (data) => {
  return {
    type: CHANGE_CURRENT_LOCATION,
    data,
  };
}

export const setPaidGoogleMapData = (data) => {
  return {
    type: SET_PAID_ATM_DATA,
    data,
  };
};

export const getGoogleMapCredentials = (data, callback) => (dispatch) => {
  apiCall(dispatch, GetGoogleMapKeys(data), {
    showLoader: true,
  }).then(
    (response) => {
      dispatch(setMapKeys(response?.data?.data["google-maps"]?.keys[0]));
      return;
    },
    (error) => { return; }
  );
};

export const resetData = () => (dispatch) => {

}

export const getGoogleMapData = (data, callback) => (dispatch) => {
  dispatch(setPaidGoogleMapData(undefined));
  dispatch(setFreeGoogleMapData(undefined));
  dispatch(setAllGoogleMapData(undefined));

  return apiCall(dispatch, GetGoogleMapData(data), {
    showLoader: true,
  }).then(
    (response) => {
      dispatch(setAllGoogleMapData(response?.data?.data?.locations));
      if (response?.data?.data?.locations) {
        let freeAtm = [];
        let paidAtm = [];
        response?.data?.data?.locations.map((location, key) => {
          if (location.fee_amt > 0) {
            paidAtm.push(location);
          }
          else {
            freeAtm.push(location);
          }
        })

        dispatch(setPaidGoogleMapData(paidAtm));
        dispatch(setFreeGoogleMapData(freeAtm));
      }

      callback && callback();
      return;
    },
    (error) => { return; }
  );
};
