import React, { useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon, handRaisedImg } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";
import { useUnmount } from "ahooks";

export const ActivateCardSuccessDialog = ({ onExit, goTodashBoard }) => {
  const appClasses = useAppStyles();
  const classes = useStyles();

  useUnmount( () => {
    onExit()
  })

  return (
    <Dialog
      open={true}
      onClose={onExit}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={onExit}
          >
            <img src={closeBlackIcon} alt="Close"/>
          </IconButton>
        </div>
      </DialogTitle>
      <Divider></Divider>

      <DialogContent className={appClasses.dialogContent1}>
        <div className={classes.imgWrapper}>
          <img src={handRaisedImg} height={250} width={250}  alt="hand raise"/>
        </div>
        <div className={classes.imgWrapper}>
          <Typography variant="h6" className={appClasses.text3}>
            Your physical card has been activated
          </Typography>
          <Box mt={1} />
          <Typography variant="span" className={appClasses.text1}>
            Hoooray! You can now use it for in-store purchases and withdraw cash
            from nearby ATM's.
          </Typography>
        </div>
        <Box mt={3} />
        <AppButton
          contained
          onClick={goTodashBoard}
          className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
          primary
        >
          Go to Dashboard
        </AppButton>
        <Box mt={2} />
      </DialogContent>
    </Dialog>
  );
};
