import { CHANGE_CURRENT_LOCATION, SET_ALL_GOOGLE_MAP_DATA, SET_FREE_ATM_DATA, SET_GOOGLE_MAP_KEYS, SET_PAID_ATM_DATA } from "../../../redux/actionTypes";

const initState = {
    googleMapKeys: undefined,
    freeAtm: undefined,
    paidAtm: undefined,
    allAtm: undefined,
    currentLocation: undefined,
};

export default function atmLocationReducer(state = initState, action) {
    switch (action.type) {
        case SET_GOOGLE_MAP_KEYS:
            //setLS(TOKEN_LS_KEY, action.data);
            return Object.assign({}, state, {
                googleMapKeys: action.data,
            });
        case SET_ALL_GOOGLE_MAP_DATA:
            //setLS(TOKEN_LS_KEY, action.data);
            return Object.assign({}, state, {
                allAtm: action.data,
            });
        case SET_FREE_ATM_DATA:
            //setLS(TOKEN_LS_KEY, action.data);
            return Object.assign({}, state, {
                freeAtm: action.data,
            });
        case SET_PAID_ATM_DATA:
            //setLS(TOKEN_LS_KEY, action.data);
            return Object.assign({}, state, {
                paidAtm: action.data,
            });
        case CHANGE_CURRENT_LOCATION:
            return {...state, currentLocation: action.data}
        default:
            return state;
    }
}
