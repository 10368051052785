import React from 'react'
import styled from '@emotion/styled'
import { Button, MenuItem, TextField } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from "@hookform/resolvers/yup";
import AddressValidationSchema from './AddressValidationSchema';
import ResidentialAddressTitle from './AddressTitle';
import { getSuggestedAddress } from '../../services';
import useAuth from '../../../../hooks/useAuth';
import SuggestAddressModalBox from './SuggestAddressModalBox';
import { SelectedAddressErrorDialog } from '../SelectedAddressErrorDialog';
import { USA_STATE } from '../../../../constants';
import WarningDialog from '../../../../common/components/Dialogs/WarningDialog';


const Root = styled.div`
  padding: 10px;
`
const FormWrapper = styled.div`
  padding: 10px 0;
`
const AddressFieldWrapper = styled.div`
  margin: 0 0 10px;
`
const StyledTextField = styled(TextField)`
  margin-bottom: 10px;
`

const StyledUpdateButton = styled(Button)`
  background: #1AD6D6;
  border-radius: 4px;
  padding: 16px 24px;
  font-size: 16px;
  color: #262626;
  text-decoration: none;
  text-transform: none;
  &:hover {
    background: #1AD6D6;
  }
`

const AddressTextField = ({ control, name, label, errors }) => {
  const errorMessage = errors?.message
  const isError = !!errorMessage
  return <AddressFieldWrapper>
    <Controller
      control={control}
      render={({ field }) => <StyledTextField fullWidth variant="outlined" label={label} helperText={errorMessage} error={isError} {...field} />}
      name={name}
      defaultValue=""
    />
  </AddressFieldWrapper>
}

const StatesSelectBox = ({ control, name, label, errors }) => {
  const errorMessage = errors?.message
  const isError = !!errorMessage
  return <AddressFieldWrapper>
    <Controller
      render={
        ({ field }) => <StyledTextField
          id="address-select-state"
          data-testid="address-select-state"
          select
          label={label}
          defaultValue=""
          variant='outlined'
          error={isError} helperText={errorMessage}
          fullWidth
          {...field}
        >
          {USA_STATE.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
        </StyledTextField>
      }
      control={control}
      name={name}
      defaultValue={''}
    />
  </AddressFieldWrapper>
}

export default function AddressForm({ isResidentialAddress, address = {}, onSaveAddress }) {
  const defaultAddressValues = { isResidentialAddress, ...address }
  const { control, formState: { errors, isSubmitting, isValid, isValidating, isDirty }, handleSubmit, getValues, setError } = useForm({
    mode: 'onChange',
    values: defaultAddressValues,
    resolver: yupResolver(AddressValidationSchema),
  })

  const [suggestedAddress, setSuggestedAddress] = React.useState(null)
  const [inValidaddress, setInValidAddress] = React.useState(null)
  const { bibson, user_id } = useAuth()

  const addressSuggestion = async (data) => {
    try {
      const suggestedAddressRes = await getSuggestedAddress({ bibson, user_id }, { address: data })
      return suggestedAddressRes
    } catch (e) {
      setInValidAddress(true)
      setError("address_line1", { type: "manual", message: "Invalid Address" }, { shouldFocus: true })
      throw (e)
    }
  }

  const onSubmit = async (data) => {
    try {
      if (!isDirty) return onSaveAddress(data)
      const suggestedAddressRes = await addressSuggestion(data)
      const { suggested } = suggestedAddressRes?.data?.data
      if (suggested) {
        return setSuggestedAddress(suggested)
      }
      return saveAddress(data)
    } catch (e) {
      console.log(e)
    }
  }

  const saveAddress = async (data) => {
    return onSaveAddress(data)
  }

  const onCloseSuggestModalBox = () => {
    setSuggestedAddress(null)
  }

  const closeNotFoundAddressModalBox = () => {
    setInValidAddress(null)
  }

  const isDisabled = isSubmitting || isValidating || !isValid || !isDirty

  return <><Root>
    <div id="form-dialog-title">
      <ResidentialAddressTitle isResidentialAddress={isResidentialAddress} />
    </div>

    <FormWrapper>
      <AddressTextField control={control} name="address_line1" label="Street Address" errors={errors.address_line1} />
      <AddressTextField control={control} name="address_line2" label="Apartment, Suite, Unit (Optional)" errors={errors.address_line2} />
      <AddressTextField control={control} name="city" label="City" errors={errors.city} />
      <StatesSelectBox control={control} name="region" label="State" errors={errors.region} />
      <AddressTextField control={control} name="postal_code" label="Zip Code" errors={errors.postal_code} />

      <StyledUpdateButton
        variant='contained'
        onClick={handleSubmit(onSubmit)}
        fullWidth
        disabled={isDisabled}
        primary>
        Update
      </StyledUpdateButton>
    </FormWrapper>
  </Root>
    {suggestedAddress && <SuggestAddressModalBox
      suggestedAddress={suggestedAddress}
      saveAddress={saveAddress}
      onClose={onCloseSuggestModalBox}
      address={getValues()} />}
    <WarningDialog buttonText="OK" closeHanlder={closeNotFoundAddressModalBox} open={inValidaddress}
                                       content="Double check your address - we couldn&#39;t find the one you entered." />
  </>
}