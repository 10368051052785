import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon, warningIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";

export const SelectedAddressErrorDialog = (props) => {
  const { handleClose } = props;

  const appClasses = useAppStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleClose()}
          >
            <img src={closeBlackIcon} alt="close icon"/>
          </IconButton>
        </div>
      </DialogTitle>
      <Divider></Divider>
      <Box mt={3} />
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <IconButton className={appClasses.dialogCloseBtn}>
          <img src={warningIcon}  alt="close icon"/>
        </IconButton>
        <Box mt={2}></Box>
        <Typography variant="span" className={appClasses.text1}>
          Double check your address - we couldn't find the one you entered.
        </Typography>
        <Box mt={5}></Box>
        <AppButton
          contained
          onClick={() => handleClose()}
          className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
          primary
        >
          OK
        </AppButton>
        <Box mt={5}></Box>
      </DialogContent>
    </Dialog>
  );
};
