import { CONTENTFUL_GQL_API_URL, ContenfulSpace, ContentfulEnvironment, ContentfulToken, ContentfulTags } from "../../../constants";
import { request } from "../../../utilities/request";
import * as contentful from 'contentful'

const CARD_HOLDER_AGREEMENT_ENTRY_ID = '5qtKImepIkjy9hqU4hNFGq'
const PRIVACY_POLICY_ENTRY_ID = '1U5wRqt58lms8WOMCMP5qM'
const PAYFARE_TERMS_ENTRY_ID = '1WmgqQissR2bY7006t7DT8'
const CASH_DEPOSIT_HELP_TOPIC_ENTRY_ID = 'PQxJ6nnMH0hoCiXwBDYIX'

export const contentfulClient = contentful.createClient({
  space: ContenfulSpace,
  accessToken: ContentfulToken
})

const defaultQuery = {
  'metadata.tags.sys.id[in]': ContentfulTags,
}

export const getHelpCategories = async () => {
  const query = { ...defaultQuery, 'content_type': 'helpCategory' }
  const response = await contentfulClient.getEntries(query)
  return response.items
}

export const getHelpCategoriesWithIcon = async () => {
  const query = { ...defaultQuery, 'content_type': 'helpCategory', 'fields.icon': { exists: true } }
  const response = await contentfulClient.getEntries(query)
  const { items } = response

  const helpCategories = items.map((item) => {
    const icon = item.fields
    const { title, file } = icon?.icon?.fields || {}
    return {
      id: item.sys.id,
      ...item.fields,
      icon: {
        title, url: file?.url
      }
    }
  })
  return helpCategories
}

export const getHelpCategoryByName = async (helpCategoryName) => {
  const query = { ...defaultQuery, 'content_type': 'helpCategory', 'fields.title': helpCategoryName }
  const helpCategories = await contentfulClient.getEntries(query)
  return helpCategories.items[0]
}

/* get help topics from contentful. if helpCategoryName is provided, only topics from that category will be returned
* @param {string} helpCategoryName - name of the help category
* @returns {object} - response from contentful
*/
export const getHelpTopics = async (helpCategoryName) => {
  const query = {
    ...defaultQuery,
    'content_type': 'helpTopic',
    select: "fields.title,fields.content,fields.categories,fields.relatedTopics,fields.richTextContent"
  }

  if (helpCategoryName) {
    const helpCategory = await getHelpCategoryByName(helpCategoryName)
    query['fields.categories.sys.id'] = helpCategory.sys.id
  }

  const response = await contentfulClient.getEntries(query)
  return response.items
}

export const contentFulGQRequest = (queryData) => {
  return request(CONTENTFUL_GQL_API_URL, "POST", {}, queryData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getHelpTopicCollectionFromScreenName = (
  collection,
  screenName
) => {
  return (collection || []).find((item) => item.screenName === screenName) || {};
};

export const helpTopicCollection = async () => {
  const query = { ...defaultQuery, 'content_type': 'helpTopic' }
  const response = await contentfulClient.getEntries(query)
  const { total, items } = response
  // Because this function is refacetored from ContentfulGQLRequest, we need to format the response to the previous format
  const helpTopics = items.map((item) => { return { title: item.fields.title, content: item.fields.content } })
  return { helpTopicCollection: { total, items: helpTopics } }
};

export const mobileScreenCollection = async () => {
  const query = { ...defaultQuery, 'content_type': 'mobileScreen' }
  const response = await contentfulClient.getEntries(query)
  const { items, includes } = response
  // Because this function is refacetored from ContentfulGQLRequest, we need to format the response to the previous format
  const indexedEntry = includes.Entry.reduce((acc, entry) => {
    acc[entry.sys.id] = entry
    return acc
  }, {})

  const mobileScreens = items.map((item) => {
    const { screenName, helpTopics } = item.fields
    const helpTopicsCollection = {
      total: helpTopics.length,
      items: helpTopics.map((helpTopic) => {
        const { title, content } = indexedEntry[helpTopic.sys.id].fields
        return { title, content }
      })
    }
    return { screenName, helpTopicsCollection }
  })

  return { mobileScreenCollection: mobileScreens }
};

/*This function to format the response from contentful to the pervious format that uses GraphQL query
* this is to avoid changing the existing code
* @param {object} content - helpTopic content from contentful
*/
const formatContentResponse = (content) => {
  return { helpTopic: { content } }
}

export const helpTopicCashDeposit = async () => {
  const query = { ...defaultQuery, 'content_type': 'helpTopic', 'sys.id': CASH_DEPOSIT_HELP_TOPIC_ENTRY_ID }
  const response = await contentfulClient.getEntries(query)
  if (response.items.length > 0) {
    const content = response.items[0].fields.content
    return formatContentResponse(content)
  }
  return {}
};

const getLegalPdfUrl = async (entryId) => {
  const query = {
    ...defaultQuery,
    'content_type': 'legal',
    'sys.id': entryId,
    select: "fields.contentDocument"
  }
  const response = await contentfulClient.getEntries(query)
  const url = response.items[0] && response.items[0].fields.contentDocument.fields.file.url
  return { legal: { contentDocument: { url } } }
}

export const cardHolderAgreementPDFUrl = async () => {
  return await getLegalPdfUrl(CARD_HOLDER_AGREEMENT_ENTRY_ID)
};

export const registrationTnC = async () => {
  return await getLegalPdfUrl(PAYFARE_TERMS_ENTRY_ID)
};

export const privacyPolicy = async () => {
  return await getLegalPdfUrl(PRIVACY_POLICY_ENTRY_ID)
};

export const getRewardsImageList = async () => {
  const query = { ...defaultQuery, 'content_type': 'imageList', 'sys.id': '4mdbFS8yvtOa3udAWJwMAt' }
  const response = await contentfulClient.getEntries(query)
  const { items, includes } = response
  // This function is refacetored from ContentfulGQLRequest, we need to format the response to the previous format
  const setLinkImageIds = items.reduce((setImagesIds, item) => {
    const images = item.fields?.image || []
    images.forEach((image) => {
      setImagesIds.add(image.sys.id)
    })
    return setImagesIds
  }, new Set())

  const images = includes.Asset.filter((asset) => setLinkImageIds.has(asset.sys.id))
  const imageItems = images.map((image) => { return { url: image.fields.file.url, title: image.fields.title } })
  return { imageList: { imageCollection: { items: imageItems } } }
};

export const helpCategoryCollection = async () => {
  const query = {
    ...defaultQuery, 'content_type': 'helpTopic',
    select: "fields.title,fields.content,fields.categories,fields.relatedTopics,fields.richTextContent",
    'fields.categories[exists]': true
  }

  const response = await contentfulClient.getEntries(query)
  const { items } = response

  // Because this function is refacetored from ContentfulGQLRequest, we need to format the response to the previous format
  const groupByHelpCategory = items.reduce((acc, item) => {
    const { categories, title, content, relatedTopics, richTextContent } = item.fields
    categories.forEach((category) => {
      const helpCategory = category.sys.id
      if (!acc[helpCategory]) {
        acc[helpCategory] = []
      }
      acc[helpCategory].push({
        title,
        content,
        relatedTopics,
        richTextContent
      })
    })
    return acc
  }, {})
  console.info('helpCategoriesWithIcon', groupByHelpCategory)
  const helpCategoriesWithIcon = await getHelpCategoriesWithIcon();

  const data = helpCategoriesWithIcon.map((helpCategory) => {
    const { icon, title } = helpCategory

    const collectionItems = groupByHelpCategory[helpCategory.id] || []

    return {
      id: helpCategory.id,
      icon,
      title,
      linkedFrom: {
        helpTopicCollection: {
          items: collectionItems
        }
      }
    }
  })

  return { helpCategoryCollection: { total: data.length, items: data } }
};
