import { useSize, useSafeState } from "ahooks";
import React, { useCallback, useContext, useEffect } from "react";
import { useAuthData, useAuthedRequest } from "../../hooks/auth_hooks";



const RewardItemWidth = 350
const RewardContext = React.createContext()

const sanitizeRewardResponseData = (resData) => {
  if (!resData) return []
  const { data: { data } } = resData
  // Only get reward which has contentful data.
  return data.filter( (el) => !!el.contentful )
}


export const RewardProvider = ({ children, notifyNoRewards, rewardItemWidth }) => {
  const authData = useAuthData()
  const { data, loading, error } = useAuthedRequest({ method: 'get', endPoint: `/v6/users/${authData.user_id}/rewards` })
  const [rewardGroupSize, setRewardGroupSize] = useSafeState(3)
  const [openedReward, openRewardDetail] = useSafeState()

  const rewards = sanitizeRewardResponseData(data)

  const closeDetailsHandler = useCallback(() => {
    openRewardDetail(null)
  }, [openRewardDetail])

  const isNoRewards = !loading && !error && rewards.length <= 0

  const size = useSize(document.querySelector(`[data-testid='reward-widget-container']`));
  useEffect(() => {
    if (size?.width) setRewardGroupSize(Math.floor(size.width / rewardItemWidth) || 1)
  }, [size?.width, setRewardGroupSize, rewardItemWidth])

  if (notifyNoRewards && isNoRewards) notifyNoRewards()

  const value = { rewards, loading, error, rewardGroupSize, closeDetailsHandler, openedReward, openRewardDetail}

  return <div data-testid='reward-widget-container'>
    <RewardContext.Provider value={value}>
      {children}
    </RewardContext.Provider>
  </div>
}

export const useReward = () => {
  const rewardContext = useContext(RewardContext)
  if (rewardContext === undefined) {
    throw new Error('useReward must be used within a RewardProvider asds')
  }

  return useContext(RewardContext)
}

