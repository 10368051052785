import { TOAST_TYPES } from "../common/components/core";
import { APP_TABLE_SORT_ORDERS } from "../common/components/AppTable";
import { routes } from "./routes";
import {
  carMaintenanceRCIcon,
  diningRCIcon,
  gasRCIcon,
  groceryRCIcon,
  internetCableMobileRCIcon,
  moneyTransferRCIcon,
  otherRCIcon,
  personalCareRCIcon,
  pharmaciesRCIcon,
  shoppingRCIcon,
  SIDEMENU_ICONS,
  utilitiesRCIcon,
} from "./images";
//import { logoImage, locationIcon } from "../constants";
export const ContentfulEnvironment = "master";
export const ContenfulSpace = "n2hgq5nsarz1";
export const ContentfulToken = "CCMa59B3WMjG77PnDuPVEnr-HtzVCAIFZVBELzbkWag";
export const ContentfulTags = "artist_growth_us"
// export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL = "/v6/";
export const GQL_API_URL = `${API_URL}admin/graphql`;
export const CONTENTFUL_GQL_API_URL = `https://graphql.contentful.com/content/v1/spaces/w8usy9myibw4/environments/master?access_token=6eH1ETz-7Ac0e7_HowZE0Gtggqy1AK96mNj5W_XQa00`;

export const THRESHOLD_INCORRECT_PASSWORD_ATTEMPT = 3;

export const TOKEN_LS_KEY = "eyhvjeuhchhtokeneiuyry";
export const USER_ID_LS_KEY = "eyuryuuserfdjgidruuu";
export const USERNAME_LS_KEY = "eyuryuuserfdnameruuu";
export const AUTH_REDUCER_LS_KEY = "eyuryuuserfdnsdfhgsdjameruuu";
export const AUTH_REDUCER_LOGIN_RES_LS_KEY = "eyuryuuserfdnsdsdfasdffhgsdjameruuu";
export const REMEMBER_ME_LS_KEY = "rememberMe";

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const DEVICE_ID = process.env.REACT_APP_DEVICE_ID;
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;

export const getOSInfo = () => {
  const userAgent = navigator.userAgent;
  let os = 'Unknown OS';
  let version = '';

  const regexList = {
    Windows: /Windows NT (\d+\.\d+)/,
    macOS: /Mac OS X (\d+([._]\d+)?)/,
    Linux: /Linux (\d+\.\d+)/,
    iOS: /OS (\d+[._]\d+[._]\d+)/,
    Android: /Android (\d+[._]\d+[._]\d+)/,
  };

  for (const [platform, regex] of Object.entries(regexList)) {
    if (regex.test(userAgent)) {
      os = platform;
      const match = userAgent.match(regex);
      if (match) {
        version = match[1].replace(/_/g, '.');
      }
      break;
    }
  }

  return { os, version };
};

export const browserName = (function() {
  if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Mozilla Firefox";
  } else if (navigator.userAgent.indexOf("Opera") !== -1 || navigator.userAgent.indexOf("OPR") !== -1) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Trident") !== -1) {
    return "Microsoft Internet Explorer";
  } else if (navigator.userAgent.indexOf("Edge") !== -1) {
    return "Microsoft Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Google Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else {
    return "Unknown Browser";
  }
})();

export const platform = (() => {
  const userAgent = navigator.userAgent;
  if (/Windows/i.test(userAgent)) {
    return 'Windows';
  } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
    return 'Macbook';
  } else if (/Linux/i.test(userAgent)) {
    return 'Linux';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS';
  } else if (/Android/i.test(userAgent)) {
    return 'Android';
  } else {
    return 'Unknown';
  }
})();

export const additionalApiData = {
  lang_code: "en-us",
  app_version: "9.9.9",
  // device_id: null,
  app_type: "web",
  timezone: "America/Toronto",
  country_code: "us",
  channel: "paidapp_us",
  currency: "USD",
  deviceType: platform + ' ' + browserName,
  company_id: "artist_growth"
};


export const drawerWidth = 240;
export const debounceTimeOut = 1000;
export const NO_OF_ROWS_PER_PAGE = [5, 10, 25, 50, 100];
export const DEFAULT_NO_OF_ROWS_PER_PAGE = NO_OF_ROWS_PER_PAGE[2];
export const mobileCountryCode = "+91";

export const OTPLength = 6;

export const toastDuration = 3000;
export const toastTypes = {
  ...TOAST_TYPES,
};
export const SORT_ORDERS = APP_TABLE_SORT_ORDERS;

export const KEYWORD_TYPES = {
  GKI: "GKI",
  UKI: "UKI",
};

export const ADMIN_TASK_TYPES = {
  MERCHANT_TEXT_KIM: "MERCHANT_TEXT_KIM",
};

export const TRANSACTION_CATEGORY_TYPES = {
  INFLOW: "INFLOW",
  OUTFLOW: "OUTFLOW",
};

//This is for DEV purpose
//If someone is working so they can skip kyc flow for login
export const HAS_KYC_SKIP = false;
export const SESSION_TIMEOUT_MIN = 15;

export const onlyNumbersRegex = /^[0-9]*$/;
export const usPhoneRegex = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const expirationDateRegex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
export const postalCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
export const passwordRegex = /(?=.*[\d!@#$%^&*()_+~`\-={}[\]:;"'<>,.?/])(?=.*[a-z])(?=.*[A-Z]).+/;
export const exactly4DigitsRegex = /\b\d{4}\b/g;

export const ACCOUNT_LOCK_CODE = "8202";
export const TEN_MIN_LOCK = "8204";
export const NOT_AUTHORIZED = "8205";
export const INVALID_OTP = "8018";
export const UNAUTORIZED = '401'
export const TOKEN_EXPIRED = '8084'
export const TOKEN_NOT_FOUND = '8083'

export const SIDEMENU_ITEMS = [
  {
    key: "acccount",
    display: "Account",
    icon: SIDEMENU_ICONS.walletImg,
    iconGreen: SIDEMENU_ICONS.walletGreenImg,
    route: routes.dashboard.path,
  },
  {
    key: "deposit",
    display: "Deposit",
    icon: SIDEMENU_ICONS.depositImg,
    iconGreen: SIDEMENU_ICONS.depositGreenImg,
    route: routes.deposit.path,
  },
  {
    key: "rewards",
    display: "Rewards",
    icon: SIDEMENU_ICONS.starSidemenuImg,
    iconGreen: SIDEMENU_ICONS.starSidemenuGreenImg,
    route: routes.rewards.path,
  },
  {
    key: "manageCard",
    display: "Manage Card",
    icon: SIDEMENU_ICONS.ccImg,
    iconGreen: SIDEMENU_ICONS.ccGreenImg,
    route: routes.manageCard.path,
  },
  {
    key: "atmFinder",
    display: "ATM Finder",
    icon: SIDEMENU_ICONS.atmSidemenuImg,
    iconGreen: SIDEMENU_ICONS.atmSidemenuGreenImg,
    route: routes.atmfinder.path,
  },
  {
    key: "moveMoney",
    display: "Move Money",
    icon: SIDEMENU_ICONS.moveMoneyImg,
    iconGreen: SIDEMENU_ICONS.moveMoneyGreenImg,
    route: routes.moveMoney.path,
  },
  {
    key: "statements",
    display: "Statements",
    icon: SIDEMENU_ICONS.statementsSidemenuImg,
    iconGreen: SIDEMENU_ICONS.statementsSidemenuGreenImg,
    route: routes.statements.path,
  },
  {
    key: "divider1",
    type: "DIVIDER",
  },
  {
    key: "profile",
    display: "Profile",
    icon: SIDEMENU_ICONS.userImg,
    iconGreen: SIDEMENU_ICONS.userGreenImg,
    route: routes.profile.path,
  },
  {
    key: "settings",
    display: "Settings",
    icon: SIDEMENU_ICONS.settingsImg,
    iconGreen: SIDEMENU_ICONS.settingsGreenImg,
    route: routes.setting.path,
  },
  {
    key: "divider2",
    type: "DIVIDER",
  },
  {
    key: "help",
    display: "Help",
    icon: SIDEMENU_ICONS.helpSidemenuImg,
    iconGreen: SIDEMENU_ICONS.helpSidemenuGreenImg,
    route: routes.help.path,
  },
  // {
  //   key: "legal",
  //   display: "Legal",
  //   icon: SIDEMENU_ICONS.scaleImg,
  //   iconGreen: SIDEMENU_ICONS.scaleGreenImg,
  //   route: null,
  // },
];

export const VERY_LONG_DATE_TIME_FORMAT = "YYYY/MM/DD hh:mm ss.SSS A";
export const LONG_DATE_TIME_FORMAT = "MMMM DD, YYYY | hh:mm A";
export const LONG_DATE_FORMAT = "MMMM DD, YYYY"
export const DATE_TIME_PICKER_FORMAT = "yyyy/MM/DD HH:mm";
export const DATE_PICKER_FORMAT = "yyyy/MM/dd";
export const DATE_FORMAT = "MMM DD, yyyy";
export const DATE_PARSE_FORMAT = "YYYY-MM-DD";

export const ALL_OPTION_VALUE = "ALL_OPTION_SELECTED";

export const MESSAGE_PROCSSING_STATUSES = ["EXF", "RFP", "PRF", "NED", "NFI"];

export const EVENT_STATUSES = ["PENDING", "INPROGRESS", "SUCCESS", "FAILED"];

export const EVENT_CATEGORIES = [
  "RETRY_FAILED_MSG",
  "NEW_DBT_CRD",
  "NEW_FI_SENDER",
  "PROC_UNTRACKED_MSG",
  "RETRY_AUTO_CAT",
  "UKI_UPDATE",
  "GKI_UPDATE",
  "UPDATED_EXTRACTION_RULES",
  "DELETE_USER_DATA",
];

export const CHART_GROUPBY = {
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
};

export const CHART_DURATION = [
  //key~label~groupBy
  `THIS_MONTH~This Month~${CHART_GROUPBY.DAILY}`,
  `LAST_MONTH~Last Month~${CHART_GROUPBY.DAILY}`,
  `LAST_6_MONTHS~Last 6 Months~${CHART_GROUPBY.MONTHLY}`,
  `THIS_YEAR~This Year~${CHART_GROUPBY.MONTHLY}`,
  `LAST_YEAR~Last Year~${CHART_GROUPBY.MONTHLY}`,
  `LAST_5_YEARS~Last 5 Years~${CHART_GROUPBY.YEARLY}`,
];

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const appColors = {
  primary: "#0C2232",
  primary100: "#EDF5F5",
  primary300: "#CCFFFF",
  primary500: "#1AD6D6",
  primary900: "#018787",
  secondary: "#018787",
  grey: "#F2F2F2",
  grey100: "#F2F2F2E5",
  white: "#FFFFFF",
  highlight: "#14B474",
  red: "#e2431e",
  navyBlue700: "#26384B",
  navyBlue300: "#939CAF",
  green900: "#377D31",
  black100: "#E7E7E7",
  black300: "#3B464B",
  black300New: "#9D9D9D",
  black900: "#000000",
  black700: "#262626",
  black500: "#555555",
  orange500: "#F94932",
  black: "#2C2C2C",
  success500: "#058A33",
  success100: "#E6F3EB",
  error100: "#F8E8E6",
  error500: "#B3261E",
};

export const currencySymbol = "$";

export const FORGOT_PASSWORD_STEPS = {
  email: "email",
  otp: "otp",
  questions: "questions",
  success: "success",
  newPassword: "newPassword",
};

export const TRANSFER_MONEY_STEPS = {
  filling: "filling",
  process: "process",
  error: "error",
  success: "success",
};

export const MANAGE_CARD_ITEM = {
  VIEW_VIRTUAL_CARD: "VIEW_VIRTUAL_CARD",
};

export const VIEW_CARD_SET_PIN_STEP = {
  setPin: "setPin",
  viewCard: "viewCard",
};

export const REPLACE_CARD_STEPS = {
  info: "info",
  reason: "reason",
  address: "address",
  confirmAddress: "confirmAddress",
  processConfirmation: "processConfirmation",
  askPassword: "askPassword",
  success: "success",
};

export const REPLACE_CARD_REASONS = [
  {
    reasonValue: "damaged",
    reasonText: "My card is damaged",
  },
  {
    reasonValue: "lost",
    reasonText: "My card is lost",
  },
  {
    reasonValue: "stolen",
    reasonText: "My card was stolen",
  },
];

export const GOOGLE_API_KEY = "AIzaSyBgkVKsbnYwXePO7-_jNqYHIsEKpQNVvlE";

export const ACTION_STATUS = {
  INPROCESS: "INPROCESS",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  NONE: "NONE",
};

export const REWARD_TX_REF_TO_DISPLAY = {
  redemption: "Rewards Redemption",
  "redemption-rewarse": "Redemption Rewarse",
};

export const REWARD_CATEGORY_VALUE_TO_ICON = {
  Shopping: shoppingRCIcon,
  "Car Maintenance": carMaintenanceRCIcon,
  Dining: diningRCIcon,
  Gas: gasRCIcon,
  Grocery: groceryRCIcon,
  "Internet, phone & cable": internetCableMobileRCIcon,
  "Money Transfer": moneyTransferRCIcon,
  Others: otherRCIcon,
  Other: otherRCIcon,
  "Personal Care": personalCareRCIcon,
  Pharmacies: pharmaciesRCIcon,
  Utilities: utilitiesRCIcon,
  "Cash Rewards": shoppingRCIcon,
  "Goal Transfer": moneyTransferRCIcon
};

export const USER_CARD_STATUSES = {
  frozen: "frozen",
  active: "active",
};

export const KYC_STATUS_FAILED = "failed";
export const KYC_STATUS_PASSED = "passed";

export const VISA_SAVING_EDGE_URL = "https://www.visasavingsedge.com/e/members/benefits.php?sid=92XXdKrlo92&xgroupby=category"
export const VISA_SAVING_EDGE_FAQ_URL =
  "https://www.visasavingsedge.com/e/members/article.php?sid=92XXdKrlo92&xid=215000";

export class APPLICATION_STATUS {
  static APPLICATION_CLOSED_BY_USER = "4000"
  static OPEN_CARD_HOLDER_LINK= "4008"
  static KYC_FAILED = "40003"
  static VIEW_VIRTUAL_CARD = "4002"
}

export const USA_STATE = [
  {
    "name": "Alabama",
    "code": "AL"
  },
  {
    "name": "Alaska",
    "code": "AK"
  },
  {
    "name": "Arizona",
    "code": "AZ"
  },
  {
    "name": "Arkansas",
    "code": "AR"
  },
  {
    "name": "California",
    "code": "CA"
  },
  {
    "name": "Colorado",
    "code": "CO"
  },
  {
    "name": "Connecticut",
    "code": "CT"
  },
  {
    "name": "Delaware",
    "code": "DE"
  },
  {
    "name": "Florida",
    "code": "FL"
  },
  {
    "name": "Georgia",
    "code": "GA"
  },
  {
    "name": "Hawaii",
    "code": "HI"
  },
  {
    "name": "Idaho",
    "code": "ID"
  },
  {
    "name": "Illinois",
    "code": "IL"
  },
  {
    "name": "Indiana",
    "code": "IN"
  },
  {
    "name": "Iowa",
    "code": "IA"
  },
  {
    "name": "Kansas",
    "code": "KS"
  },
  {
    "name": "Kentucky",
    "code": "KY"
  },
  {
    "name": "Louisiana",
    "code": "LA"
  },
  {
    "name": "Maine",
    "code": "ME"
  },
  {
    "name": "Maryland",
    "code": "MD"
  },
  {
    "name": "Massachusetts",
    "code": "MA"
  },
  {
    "name": "Michigan",
    "code": "MI"
  },
  {
    "name": "Minnesota",
    "code": "MN"
  },
  {
    "name": "Mississippi",
    "code": "MS"
  },
  {
    "name": "Missouri",
    "code": "MO"
  },
  {
    "name": "Montana",
    "code": "MT"
  },
  {
    "name": "Nebraska",
    "code": "NE"
  },
  {
    "name": "Nevada",
    "code": "NV"
  },
  {
    "name": "New Hampshire",
    "code": "NH"
  },
  {
    "name": "New Jersey",
    "code": "NJ"
  },
  {
    "name": "New Mexico",
    "code": "NM"
  },
  {
    "name": "New York",
    "code": "NY"
  },
  {
    "name": "North Carolina",
    "code": "NC"
  },
  {
    "name": "North Dakota",
    "code": "ND"
  },
  {
    "name": "Ohio",
    "code": "OH"
  },
  {
    "name": "Oklahoma",
    "code": "OK"
  },
  {
    "name": "Oregon",
    "code": "OR"
  },
  {
    "name": "Pennsylvania",
    "code": "PA"
  },
  {
    "name": "Rhode Island",
    "code": "RI"
  },
  {
    "name": "South Carolina",
    "code": "SC"
  },
  {
    "name": "South Dakota",
    "code": "SD"
  },
  {
    "name": "Tennessee",
    "code": "TN"
  },
  {
    "name": "Texas",
    "code": "TX"
  },
  {
    "name": "Utah",
    "code": "UT"
  },
  {
    "name": "Vermont",
    "code": "VT"
  },
  {
    "name": "Virginia",
    "code": "VA"
  },
  {
    "name": "Washington",
    "code": "WA"
  },
  {
    "name": "West Virginia",
    "code": "WV"
  },
  {
    "name": "Wisconsin",
    "code": "WI"
  },
  {
    "name": "Wyoming",
    "code": "WY"
  }
]

export const GOOGLE_MAP_LIBARIES = [
  "places",
  "geometry"
]