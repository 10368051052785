import { SET_PENDING_TRANSACTIONS, SET_TRANSACTIONS } from "../../../redux/actionTypes";

const initState = {
  transactions: [],
  pendingTransactions: []
};

export default function transactionReducer(state = initState, action) {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return Object.assign({}, state, {
        transactions: action.data,
      });
    case SET_PENDING_TRANSACTIONS:
      return Object.assign({}, state, {
        pendingTransactions: action.data,
      });
    default:
      return state;
  }
}
