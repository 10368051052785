import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    width: "8rem",
  },
  buttonLabel: {},
  buttonLink: {
    width: "auto",
    paddingLeft: 0,
    textTransform: "none",
  },
  buttonLinkLabel: {
    textAlign: "left",
  },
}));
