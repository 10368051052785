import { Grid, Typography, Box, styled, useMediaQuery } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { useAppStyles } from '../../../../App.style';
import { useStyles } from './style';
import { VirtualCard } from '../VirtualCard';
import SetPinDialog from '../../../setPin/containers/SetPinDialog';
import ViewCardDialog from '../../../viewCard/containers/ViewCardDialog';

import CredentialsProvider from '../../../../hooks/RequireCredentials/CrendentialProvider';
import Reward from "../Reward/Reward";
import CreateYourPin from '../CreateYourPin';
import AccountCard from '../AccountCard';
import Rewards from '../Rewards';
import QuickServices from '../QuickServices';
import DashboardCardCarousel from '../DashboardCardCarousel';
import { SectionTitle } from '../common';


const StyledRow = styled('div')({
  marginBottom: '1.5rem',
})

const DashboardRow = ({ children, renderIf = true }) => {
  if (renderIf) {
    return <StyledRow>{children}</StyledRow>;
  }
  return null;
}

export const Dashboard = (props) => {

  const appClasses = useAppStyles();
  const classes = useStyles();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [hasHouseOffers, setHasHouseOffers] = useState(true)
  const noRewardsHandler = useCallback(() => setHasHouseOffers(false), [setHasHouseOffers])

  const {
    isGotCardBalances,
    isGotRewardBalance,
    cardBalances,
    isGotDashboardCards,
    dashboardCards,
    handleTransactionsClick,
    handleGoalsClick,
    handleRewardsClick,
    onClickViewVirtualCard,
    handleHelpClick,
    handleAtmClick,
    handleStatementClick,
    directDepositQuickServiceClick,
    pinDialog,
    viewcardDialog,
    handleClose,
    handleViewCardDialogClose,
    tingMatchedCard,
    openTnC,
    handleSetPinPopUp,
  } = props;


  return (
    <>
      <DashboardRow renderIf={Boolean(tingMatchedCard?.is_virtual)}>
        <VirtualCard onClickViewVirtualCard={onClickViewVirtualCard} />
      </DashboardRow>

      <DashboardRow renderIf={Boolean(tingMatchedCard && !tingMatchedCard?.is_virtual && !tingMatchedCard?.is_pin_set)}>
        <CreateYourPin
          tingMatchedCard={tingMatchedCard}
          handleSetPinPopUp={handleSetPinPopUp}
        />
      </DashboardRow>

      <DashboardRow renderIf={isGotCardBalances && isGotRewardBalance}>
        <AccountCard
          handleRewardsClick={handleRewardsClick}
          handleGoalsClick={handleGoalsClick}
          handleTransactionsClick={handleTransactionsClick}
          cardBalances={cardBalances}
        />
      </DashboardRow>
      <DashboardRow>
        <Grid container spacing={4}>
          <Grid xs={12} sm={6} item>
            <SectionTitle>Rewards</SectionTitle>
            {hasHouseOffers ? <Reward notifyNoRewards={noRewardsHandler} /> : <Rewards handleRewardsClick={handleRewardsClick} /> }
          </Grid>
          <Grid xs={12} sm={6} item>
            <Box style={{ marginBottom: '20px' }}>
              <QuickServices handleAtmClick={handleAtmClick}
                directDepositQuickServiceClick={directDepositQuickServiceClick}
                handleStatementClick={handleStatementClick}
                handleHelpClick={handleHelpClick} />
            </Box>

            {lgUp && <DashboardCardCarousel isGotDashboardCards={isGotDashboardCards} dashboardCards={dashboardCards} />}
          </Grid>
          {
            !lgUp && (
              <Grid xs={12} item>
                <DashboardCardCarousel isGotDashboardCards={isGotDashboardCards} dashboardCards={dashboardCards} />
              </Grid>
            )
          }
        </Grid>
      </DashboardRow>

      <DashboardRow>
        <Typography variant="span" className={appClasses.bottomText}>
          The Artists Growth Wallet Card is issued by Stride Bank N.A., member
          FDIC, pursuant to license from Visa U.S.A. Inc. By activating or
          using this card, you agree to be bound by the{' '}
          <a onClick={openTnC} className={`${appClasses.aLink} ${classes.aLinkColor}`}>
            Terms & Conditions
          </a>
          &nbsp; associated with this program.
        </Typography>
      </DashboardRow>

      {
        pinDialog && (
          <CredentialsProvider onClose={handleClose}>
            {' '}
            <SetPinDialog handleClose={handleClose} card={tingMatchedCard} />{' '}
          </CredentialsProvider>
        )
      }

      <ViewCardDialog handleClose={handleViewCardDialogClose} isOpen={viewcardDialog}/>
    </>
  );
};

