import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Home } from '../components/Home';
import * as authActions from '../../auth/actions';
import moment from 'moment';
import {
  initIdleDetector,
  registerIdleEvent,
  removeIdleDetector,
  unRegisterIdleEvent,
} from '../../../common/services/idleDetector';

class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      showChangePasswordDialog: undefined,
      previousActiveTimeStamp: moment(),
      isSessionTimeOut: false,
    };
  }
  componentDidMount() {
    initIdleDetector(this.props.authActions.doAuthCheck)
    registerIdleEvent(this.props.authActions.doLogout);
  }

  componentWillUnmount() {
    unRegisterIdleEvent(this.props.authActions.doLogout);
    removeIdleDetector();
  }

  handleLogout = () => {
    this.props.authActions.doLogout();
  };

  handleChangePassword = () => {
    this.setState({ showChangePasswordDialog: true });
  };

  handleCloseChangePassword = (afterSuccess) => {
    this.setState({ showChangePasswordDialog: false });
  };

  render() {
    const { authData } = this.props;
    const { loginResponseData } = authData;

    //do logout on refresh page
    if (!loginResponseData) {
      this.props.authActions.doLogout();
    }

    const { handleLogout, handleChangePassword, handleCloseChangePassword } =
      this;
    const { showChangePasswordDialog, isSessionTimeOut } = this.state;
    return (
      <Home
        {...{
          authData,
          handleLogout,
          handleChangePassword,
          handleCloseChangePassword,
          showChangePasswordDialog,
          isSessionTimeOut,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomePage)
);
