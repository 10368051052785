import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root : {
    fontSize : "1rem",
    color : theme.palette.other?.black300,
    fontWeight: "400",
    marginBottom: '10px'
  }
}));
