import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "1.5rem",
    alignItems: "center",
    width: "472px",
    height: "121px",
    border: `1px solid ${theme.palette.other.white}`,
    cursor: "pointer",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      border: `1px solid ${theme.palette.other.primary900}`,
    },
  },
  leftContent: {
    display: "flex",
    alignItems: "center",
    "& div": {
      paddingLeft: "1.2rem",
    },
  },
  active: {
    border: `1px solid ${theme.palette.other.primary900}`,
    background: `${theme.palette.other.primary100}`,
  },
}));
