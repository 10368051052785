import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { alarmGreenIcon, closeBlackIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";

export const NotificationEnableDisableConfirm = (props) => {
  const { handleConfirm, isEnableNotiSettings } = props;

  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleConfirm(false)}
          >
            <img src={closeBlackIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider></Divider>
      <Box mt={3} />
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <IconButton className={appClasses.dialogCloseBtn}>
          <img src={alarmGreenIcon} />
        </IconButton>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text3}>
          Would you like to turn on push notifications?
        </Typography>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text1}>
          We'll let you know about your Artist Growth Wallet account updates and
          more.
        </Typography>
        <Box mt={5}></Box>
        <AppButton
          contained
          onClick={() => handleConfirm(true)}
          className={`${appClasses.primaryBtn} ${appClasses.width100p} ${appClasses.br200}`}
          primary
        >
          Yes, Turn {isEnableNotiSettings ? "Off" : "On"}
        </AppButton>
        <Box mt={2}></Box>
        <AppButton
          variant="text"
          className={`${appClasses.textBtn} ${appClasses.width100p}`}
          onClick={() => handleConfirm(false)}
        >
          Maybe Later
        </AppButton>
        <Box mt={5}></Box>
      </DialogContent>
    </Dialog>
  );
};
