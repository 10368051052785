import {
  Grid,
  Typography,
  Box,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import { SidemenuViewContent } from "../../../../common/components/SidemenuViewContent";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import {
  arrowLeftIosIcon,
  arrowRightIosIcon,
  searchBlackIcon,
  trailingIcon,
} from "../../../../constants";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "../../../../common/components/core";
import { AppTextField } from "../../../../common/components/AppTextField";
import { HelpSectionView } from "./HelpSectionView";

export const HelpSection = (props) => {
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();

  const {
    menuDetailViewData,
    openViewIndex,
    isOpenMenu,
    menuData,
    handleMenuViewDetail,

    helpCategoryCollection,

    handleCloseMenu,
    handleViewMenu,

    searchText,
    searchResult,
    handleClearSearchText,

    handleChange,
  } = props;

  const listCardHeight = `${window.innerHeight - 150}px`;
  const listHeight = `${window.innerHeight - 270}px`;

  const helpCategoryList = helpCategoryCollection?.items || [];

  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div className={classes.goalHeader}>
            <Typography variant="h4" className={appClasses.text8}>
              Help
            </Typography>
          </div>
          <Box mt={4}></Box>

          <div
            className={`${appClasses.paperCard} ${classes.leftList}`}
            style={{ height: listCardHeight }}
          >
            <AppTextField
              placeholder="Search"
              variant="filled"
              name={"searchText"}
              value={searchText}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchBlackIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {searchText && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleClearSearchText()}>
                          <img src={trailingIcon} />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />
            <Box mt={1}></Box>
            <List className={classes.list} style={{ height: listHeight }}>
              {!searchText &&
                helpCategoryList.map((item, index) => (
                  <>
                    <ListItem onClick={() => handleViewMenu(item, index)} className={`${classes.listItem} ${openViewIndex == index ? classes.listItemActive : ''}`}>
                      <img src={item.icon?.url} />
                      <ListItemText
                        primary={
                          <Typography variant="h4" className={appClasses.text7}>
                            &nbsp; {item.title}
                          </Typography>
                        }
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleViewMenu(item, index)}
                      >
                        <img src={arrowRightIosIcon} />
                      </IconButton>
                    </ListItem>
                    <Divider component="li" />
                  </>
                ))}

              {searchText &&
                searchResult.map((item, index) => (
                  <>
                    <ListItem className={`${classes.listItem} ${openViewIndex == index ? classes.listItemActive : ''}`}>
                      <ListItemText
                        primary={
                          <Typography variant="h4" className={appClasses.text7}>
                            &nbsp; {item.title}
                          </Typography>
                        }
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleViewMenu(item, index)}
                      >
                        <img src={arrowRightIosIcon} />
                      </IconButton>
                    </ListItem>
                    <Divider component="li" />
                  </>
                ))}
            </List>
          </div>
        </Grid>
      </Grid>

      <SidemenuViewContent
        open={isOpenMenu}
        onClose={handleCloseMenu}
        closeBtnImg={
          menuDetailViewData && !searchText ? arrowLeftIosIcon : undefined
        }
      >
        <HelpSectionView
          data={menuData}
          detail={menuDetailViewData}
          handleViewDetail={handleMenuViewDetail}
        />
      </SidemenuViewContent>
    </div>
  );
};
