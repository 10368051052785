import { combineReducers } from "redux";
import authReducer from "../modules/auth/reducers";
import toastReducer from "../modules/../common/reducers/toastReducer";
import loaderReducer from "../modules/../common/reducers/loader";
import dashboardReducer from "../modules/dashboard/reducers";
import purseReducer from "../modules/goals/reducers";
import transactionReducer from "../modules/transactions/reducers";
import atmLocationReducer from "../modules/atmFinder/reducers";
import depositReducer from "../modules/deposit/reducers";
import rewardsReducer from "../modules/rewards/reducers";
import moveMoneyReducer from "../modules/moveMoney/reducers";
import managecardReducer from "../modules/manageCard/reducers";
import registrationReducer from "../modules/registration/reducers";
import statementReducer from "../modules/statements/reducers";
import profileReducer from "../modules/profile/reducers";
import settingsReducer from "../modules/settings/reducers";
import contentfulReducer from "../modules/contentful/reducers";
import accountStatus from "../common/reducers/accountStatus";
const rootReducer = combineReducers({
  accountStatus,
  authReducer,
  toastReducer,
  loaderReducer,
  dashboardReducer,
  purseReducer,
  transactionReducer,
  atmLocationReducer,
  depositReducer,
  rewardsReducer,
  moveMoneyReducer,
  managecardReducer,
  statementReducer,
  profileReducer,
  settingsReducer,
  contentfulReducer,
  registrationReducer
});

export default (state, action) => {
  return rootReducer(state, action);
};
