import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TRANSFER_MONEY_STEPS } from "../../../constants";
import * as actions from "../actions";
import { TransferMoney } from "../components/TransferMoney";

const initialState = {
  amount: "",
  fromAccount: null,
  toAccount: null,

  errors: {},

  isSubmitted: null,
  isFormValid: false,

  transferMoneyStep: TRANSFER_MONEY_STEPS.filling,
};

class TransferMoneyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const newState = { ...initialState };
    this.setState({ ...newState });
  }

  goToStep = (step) => {
    this.setState({ transferMoneyStep: step }, () => {});
  };

  isValid = () => {
    const { amount, fromAccount, toAccount, isSubmitted } =
      this.state;

    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }

    if (!amount) {
      errors.amount = "Required";
    }
    if (amount) {
      if (isNaN(amount)) {
        errors.amount = "Invalid";
      } else if (amount <= 0) {
        errors.amount = "Must be greater than zero";
      }
    }
    if (!fromAccount) {
      errors.fromAccount = "Require";
    }
    if (!toAccount) {
      errors.toAccount = "Require";
    }

    if (fromAccount && toAccount && fromAccount === toAccount) {
      errors.toAccount = "Accounts cannot be the same";
    }

    this.setState({ errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));

    this.setState({ isFormValid: valid });
    return valid;
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const { data } = this.props;
        const { bibson, user_id, card_ting } = data || {};

        const { fromAccount, toAccount, amount } = this.state;

        this.setState({ transferMoneyStep: TRANSFER_MONEY_STEPS.process });
        this.props.actions.doTransferMoneyPurse(
          {
            fromAccount: fromAccount,
            toAccount: toAccount,
            amount,
            transfer_type: this.getTransferType(fromAccount, toAccount),
            bibson,
            user_id,
            card_ting,
          },
          (result) => {
            if (result) {
              //success
              this.setState({
                transferMoneyStep: TRANSFER_MONEY_STEPS.success,
                successResult: result,
              });
            } else {
              //error
              this.setState({
                transferMoneyStep: TRANSFER_MONEY_STEPS.error,
                successResult: result,
              });
            }
            // this.handleClose(true);
          }
        );
      }
    });
  };

  handleClose = (afterSaveSuccess) => {
    const { handleClose } = this.props;
    handleClose && handleClose(afterSaveSuccess);
  };

  getAccounts = () => {
    return [{
      name: "Main Account",
      ting: this.props.userCardTing,
      transferType: "card",
    },
    {
      name: "Goal Account",
      ting: this.props.purse?.ting,
      transferType: "purse",
    }].filter(el => Boolean(el.ting))
  }

  getTransferType = (fromAccount, toAccount) => {
    const accounts = this.getAccounts()
    const fromAccObj = accounts.find(el => el.ting === fromAccount)
    const toAccObj = accounts.find(el => el.ting === toAccount)
    return fromAccObj.transferType + "-to-" + toAccObj.transferType;
  };

  render() {
    const { noDialog } = this.props;

    const fromAccounts = this.getAccounts();
    const toAccounts = this.getAccounts();
    const { handleChange, handleSubmit, handleClose } = this;
    const propsToForward = {
      ...this.state,
      fromAccounts,
      toAccounts,
      handleChange,
      handleSubmit,
      handleClose,

      noDialog,
    };

    return <TransferMoney {...propsToForward} />;
  }
}

const mapStateToProps = (state) => {
  return {
    purse: state.purseReducer?.purse,
    userCardTing: state.dashboardReducer?.userCardTing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferMoneyDialog);
