export const loadDurationAndDistance = async (origin, atmLocations) => {
  let service = new window.google.maps.DistanceMatrixService();
  const destinations = atmLocations.map((atmLocation) => {
    const { lat, lon: lng } = atmLocation?.location || {}
    return new window.google.maps.LatLng(lat, lng)
  })
  const distances = await service.getDistanceMatrix({
    destinations,
    origins: [origin],
    travelMode: window.google.maps.TravelMode.DRIVING,
    unitSystem: window.google.maps.UnitSystem.METRIC
  })

  const { rows } = distances
  const distanceDurationObj = {}
  rows[0].elements.forEach((element, index) => {
    const { distance, duration } = element
    const { text: distanceText, value: distanceValue } = distance || {}
    const { text: durationText, value: durationValue } = duration || {}
    const locationId = atmLocations[index]?.terminal_id
    distanceDurationObj[locationId] = { distanceText, durationText, durationValue, distanceValue }
  })
  return distanceDurationObj
}

export function atmFullAddress(atmLoctation){
  const { address, city, region } = atmLoctation
  const fullAddress = [address, city, region].filter(Boolean).join(', ')
  return fullAddress
}

export const awaitTimeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
