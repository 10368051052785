import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText } from "../core";
import { useStyles } from "./style";
import {
  SIDEMENU_ITEMS,
  logoWhiteImage,
  logoutWhiteIcon,
  routes,
} from "../../../constants";
import { useLocation } from "react-router";
import history from "../../../history";
import { AppButton } from "../AppButton";
import { Typography } from "@material-ui/core";
import { useAppStyles } from "../../../App.style";
import useLayout from '../../layouts/FullLayout/layoutProvider/useLayout';

// import {} from "../../../assets/images/sidebar/location.png";

export const Sidemenu = (props) => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const theme = useTheme();
  const location = useLocation();
  const { window, mobileOpen, handleDrawerToggle, handleLogout, getTnCUrl, getCardHolderAgreement, getPrivacyPolicy } = props;

  const { toggleSidebar } = useLayout();
  const goTo = (route) => {
    history.push(route);
  };

  const handleListItemClick = ((item) => {
    goTo(item.route);
    toggleSidebar();
  });

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img src={logoWhiteImage} className={classes.logoImg} />
      </div>
      <Divider />
      <List>
        {SIDEMENU_ITEMS.map((item) => {
          if (item.type && item.type == "DIVIDER") {
            return <Divider className={classes.divider} />;
          }
          const selected = item.route === ((location.pathname === routes.transactions.path || location.pathname === routes.goals.path) ? routes.dashboard.path : location.pathname);
          return (
            <ListItem
              button
              key={item.key}
              selected={selected}
              onClick={() => { handleListItemClick(item) }}
              className={`${classes.listItem} ${selected ? classes.listItemActive : ""
                }`}
            >
              <ListItemIcon className={classes.menuIcon}>
                <i>
                  <img src={selected ? item.iconGreen : item.icon} />
                </i>
              </ListItemIcon>
              <ListItemText
                primary={item.display}
                className={`${classes.listItemText} ${selected ? classes.listItemTextActive : ""
                  }`}
              />
            </ListItem>
          );
        })}
      </List>
      <div className={classes.logoutContainer}>
        <AppButton
          contained
          startIcon={<img src={logoutWhiteIcon} />}
          onClick={() => handleLogout()}
          className={classes.logoutBtn}
        >
          Log Out
        </AppButton>
      </div>
      <div className={classes.tncLink}>
        <Typography variant="span" className={appClasses.text13}>
          <a className={classes.agreementLink} onClick={() => { getTnCUrl() }}>Payfare Program Terms</a> · <a className={classes.agreementLink} onClick={() => { getCardHolderAgreement() }}>Cardholder &nbsp; &nbsp; Agreement</a> · <a className={classes.agreementLink} onClick={() => { getPrivacyPolicy() }}>Privacy Policy</a>
        </Typography>
      </div>
    </div>
  );
  return drawer
};
