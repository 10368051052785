import React from "react";
import { useAppTableStyles } from "./style";
import {
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";
import { AppTextField } from "../AppTextField";

export const APP_TABLE_SORT_ORDERS = {
  ASC: "ASC",
  DESC: "DESC",
};

export const TableHeader = (props) => {
  const classes = useAppTableStyles();
  const { columns, sortOrder, sortBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const tSortOrder = sortOrder
    ? sortOrder === APP_TABLE_SORT_ORDERS.ASC
      ? "asc"
      : "desc"
    : "";

  return (
    <TableHead>
      <TableRow>
        {columns.map((col, colIndex) => (
          <TableCell
            key={col.id}
            align={col.numeric ? "right" : "left"}
            padding={col.disablePadding ? "none" : "default"}
            sortDirection={sortBy === col.id ? tSortOrder : false}
            className={`${classes.headerCell} ${
              colIndex == 0 ? classes.paddingLeft : ""
            } ${colIndex == columns.length - 1 ? classes.paddingRight : ""}`}
          >
            <TableSortLabel
              active={sortBy === col.id}
              direction={sortBy === col.id ? tSortOrder : "asc"}
              onClick={createSortHandler(col.id)}
              disabled={!col.canSort}
            >
              {col.label}
              {sortBy === col.id ? (
                <span className={classes.visuallyHidden}>
                  {tSortOrder === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const NoResults = (props) => {
  const { classes } = props;
  return (
    <TableRow tabIndex={-1} key={-9999}>
      <TableCell
        component="th"
        colSpan={999}
        id={-9999}
        align="center"
        className={classes.cell}
      >
        No results found!
      </TableCell>
    </TableRow>
  );
};

export const AppTable = (props) => {
  const classes = useAppTableStyles();
  const {
    loading,
    data,
    columns,
    rowsPerPageOptions,
    totalRows,
    pageSize,
    pageNo,
    handleChange,
    sortOrder,
    sortBy,
    style,
    removePagination,
    tableHeader,
    tableBody,
  } = props;

  const handleRequestSort = (event, property) => {
    const newSortOrder =
      sortOrder === APP_TABLE_SORT_ORDERS.ASC
        ? APP_TABLE_SORT_ORDERS.DESC
        : APP_TABLE_SORT_ORDERS.ASC;
    handleChange("sortData", {
      sortBy: property,
      sortOrder: newSortOrder,
    });
  };

  const handleChangePage = (event, newPage) => {
    handleChange("pageNo", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    handleChange("pageSize", parseInt(event.target.value, 10));
  };

  let totalPages = 0;
  if (totalRows && pageSize) {
    totalPages = parseInt(totalRows / pageSize) + 1;
  }

  let pagesArray = [0];
  if (totalPages) {
    for (let i = 1; i < totalPages; i++) {
      pagesArray.push(i);
    }
  }

  return (
    <>
      <TableContainer style={{ ...style }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          {!tableHeader && (
            <TableHeader
              classes={classes}
              sortOrder={sortOrder}
              sortBy={sortBy}
              onRequestSort={handleRequestSort}
              columns={columns}
            />
          )}

          {tableHeader && tableHeader}

          {!tableBody && (
            <TableBody>
              {data.map((row, rowIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((col, colIndex) => (
                      <TableCell
                        id={col.id}
                        scope="row"
                        className={`${classes.cell} ${
                          colIndex == 0 ? classes.paddingLeft : ""
                        } ${
                          colIndex == columns.length - 1
                            ? classes.paddingRight
                            : ""
                        }`}
                        align={col.numeric ? "right" : "left"}
                      >
                        {col.render && col.render(row, rowIndex)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {/* {!loading && data.length <= 0 && <NoResults classes={classes} />} */}
            </TableBody>
          )}

          {tableBody && tableBody}
        </Table>
      </TableContainer>
      {!removePagination && (
        <Grid container className={classes.paginationWrapper}>
          <Grid item className={classes.pageNoWrapper}>
            <Typography variant={"body2"} className={classes.paginationLabel}>
              Page:{" "}
            </Typography>
            <AppTextField
              select
              name={"pageNo"}
              value={pageNo}
              InputProps={{ disableUnderline: true }}
              onChange={(e) => handleChange("pageNo", e.target.value)}
            >
              {pagesArray.map((page, index) => (
                <MenuItem key={index} value={page}>
                  {page + 1}
                </MenuItem>
              ))}
            </AppTextField>
          </Grid>
          <Grid item>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalRows}
              rowsPerPage={pageSize}
              page={pageNo}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
