import { APP_TABLE_SORT_ORDERS } from "../common/components/AppTable";
import { CHART_GROUPBY, debounceTimeOut, monthNames } from "../constants";
import { appErrors } from "../constants/errors";

export const getLS = (key) => {
  return localStorage.getItem(key);
};
export const setLS = (key, value) => {
  return localStorage.setItem(key, value);
};
export const removeLS = (key) => {
  return localStorage.removeItem(key);
};

export const openContentFulDocOnRes = (res) => {
  window.open(res?.contentDocument?.url);
}

export const debounce = (callback) => {
  clearTimeout(window.searchTimeout);
  window.searchTimeout = setTimeout(() => {
    callback();
  }, debounceTimeOut);
};

export const getUTCString = (date) => {
  return date ? date.toISOString() : "";
};

export const getLabelWithUTCString = (label, date) => {
  return `${label} ${getUTCString(date) ? "(UTC: " + getUTCString(date) + ")" : ""
    }`;
};

export const getCurrentAndOneDayPastDate = () => {
  const currentDt = new Date();
  const onDayPastDate = new Date();
  onDayPastDate.setDate(onDayPastDate.getDate() - 1);

  return {
    currentDt,
    onDayPastDate,
  };
};

export const getDateStrFromDateObj = (dateObj) => {
  return (
    dateObj.getFullYear() +
    "-" +
    (dateObj.getMonth() + 1) +
    "-" +
    dateObj.getDate()
  );
};

export const getRecordDateDisplay = (value, groupBy) => {
  if (value) {
    const now = new Date(value);
    switch (groupBy) {
      //groupby = MONTHLY then value always in "YYYY-MM" format
      case CHART_GROUPBY.MONTHLY:
        return monthNames[now.getMonth()] + "-" + now.getFullYear();
      //groupby = DAILY then value always in "YYYY-MM-DD" format
      case CHART_GROUPBY.DAILY:
        return monthNames[now.getMonth()] + "-" + now.getDate();
    }
  }

  return value;
};

export const getDurationGroupby = (duration) => {
  return duration.split("~")[2];
};
export const getDurationKey = (duration) => {
  return duration.split("~")[0];
};
export const getDurationLabel = (duration) => {
  return duration.split("~")[1];
};

export const getFromAndToDateStrFromChartDuration = (chartDuration) => {
  //taking key from chartDuration
  const chartDurationKey = getDurationKey(chartDuration);
  let fromDate, toDate;
  const nowDt = new Date();

  switch (chartDurationKey) {
    case "THIS_MONTH":
      fromDate = getDateStrFromDateObj(
        new Date(nowDt.getFullYear(), nowDt.getMonth(), 1)
      );
      toDate = getDateStrFromDateObj(
        new Date(nowDt.getFullYear(), nowDt.getMonth() + 1, 0)
      );
      break;
    case "LAST_MONTH":
      fromDate = getDateStrFromDateObj(
        new Date(nowDt.getFullYear(), nowDt.getMonth() - 1, 1)
      );
      toDate = getDateStrFromDateObj(
        new Date(nowDt.getFullYear(), nowDt.getMonth(), 0)
      );
      break;
    case "LAST_6_MONTHS":
      fromDate = getDateStrFromDateObj(
        new Date(nowDt.getFullYear(), nowDt.getMonth() - 6, 1)
      );
      toDate = getDateStrFromDateObj(
        new Date(nowDt.getFullYear(), nowDt.getMonth(), 0)
      );
      break;
    case "THIS_YEAR":
      fromDate = getDateStrFromDateObj(new Date(nowDt.getFullYear(), 0, 1));
      toDate = getDateStrFromDateObj(new Date(nowDt.getFullYear(), 11, 31));
      break;
    case "LAST_YEAR":
      fromDate = getDateStrFromDateObj(new Date(nowDt.getFullYear() - 1, 0, 1));
      toDate = getDateStrFromDateObj(new Date(nowDt.getFullYear() - 1, 11, 31));
      break;
    case "LAST_5_YEARS":
      fromDate = getDateStrFromDateObj(new Date(nowDt.getFullYear() - 4, 0, 1));
      toDate = getDateStrFromDateObj(new Date(nowDt.getFullYear(), 11, 31));
      break;
  }
  return {
    fromDate,
    toDate,
  };
};

export const getSortArrayOfObjectByProp = (
  arrOfObj = [],
  sortBy,
  sortOrder = APP_TABLE_SORT_ORDERS.ASC
) => {
  return arrOfObj.sort((a, b) =>
    a[sortBy] > b[sortBy]
      ? APP_TABLE_SORT_ORDERS.DESC == sortOrder
        ? -1
        : 1
      : b[sortBy] > a[sortBy]
        ? APP_TABLE_SORT_ORDERS.DESC == sortOrder
          ? 1
          : -1
        : 0
  );
};

export const to2Decimal = (amount) => {
  return (Math.round(amount * 100) / 100).toFixed(2);
};

export const toMobileNumber = (mobile, doMasting) => {
  if (mobile) {
    let USNumber = mobile?.replace(/^\+[0-9]/, '')?.match(/(\d{3})(\d{3})(\d{4})/);
    if (!USNumber) { return ""; }

    USNumber =
      "(" +
      USNumber[1] +
      ") " +
      (doMasting ? "***" : USNumber[2]) +
      "-" +
      USNumber[3];
      return USNumber;
  }

  return "";
};

export const twoFaFormatPhone = (mobile, doMasting) => {
  if (mobile) {
    let USNumber = mobile?.replace(/^\+[0-9]/, '')?.match(/(\d{3})(\d{5})(\d{2})/);
    if (!USNumber) { return ""; }

    USNumber = USNumber[1] + (doMasting && "-***-**" + USNumber[3])
    return USNumber;
  }

  return "";
};

export const toMaskEmail = (email = "") => {
  if (!email) return "";
  const [name, domain] = email.split("@");
  const { length: len } = name;
  const maskedName = name[0] + "***" + name[len - 1];
  const maskedEmail = maskedName + "@" + domain;
  return maskedEmail;
};

export const getMessageFromAppErrorDirectory = (code) => {
  return appErrors[code]?.message;
}
