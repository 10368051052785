import React, { useState } from "react";

import {
  Box,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { AppTextField } from "../../../../common/components/AppTextField";
import { AppButton } from "../../../../common/components/AppButton";
import { Amount } from "../../../../common/components/Amount";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";

import {
  arrowDownIosIcon,
  arrowUpIosIcon,
  currencySymbol,
  infoIcon,
  lightBulbBlackIcon,
} from "../../../../constants";
import AddEditRecipientDialog from "../../containers/AddEditRecipientDialog";
import { IconButton } from "../../../../common/components/core";
import SendToRecipientSuccessDialog from "../../containers/SendToRecipientSuccessDialog";
import { AppTooltip } from "../../../../common/components/AppTooltip";

export const SendToRecipient = (props) => {
  const {
    toAccount,
    amount,
    recipient,
    recipientId,
    errors,
    handleChange,
    handleSubmit,
    onAddEditRecipientHandle,
    limits,
    isAddRecipientDialogOpen,
    isTransactionSuccessDialogOpen,
    handleClose,
    confirmation,
    ting,
    handleSuccessDialogClose
  } = props;

  const [isExpandLimits, setIsExpandLimits] = useState(false);

  const appClasses = useAppStyles();
  const classes = useStyles();

  const calculateLimitResult = (limitPeriod) => {
    let text = limitPeriod / 3600;
    if (text > 24) text = text / 24;

    return text > 24 ? text + " Days Limit" : text + " Hours Limit";
  };

  const getRecipientByTing = (ting) => {
    let recipientDetails;
    recipient && recipient.map((account) => {
      if (account.ting == ting) {
        recipientDetails = account;
      }
    })

    return recipient;
  }

  const getPercent = (aval, total) => {
    return ((aval || 0) * 100) / (total || 0);
  };

  return (
    <Grid className={classes.root}>
      <DialogTitle className={classes.header}>
        <Typography variant="h6" className={appClasses.text3}>
          Send Money to Someone Else
        </Typography>
        <Box mt={1} />
        <Typography variant="span" className={appClasses.text1}>
          Your recipient should get their funds within 1-5 business days
          <AppTooltip
            title={
              "Business days are Monday through Friday. This excludes weekends and federal holidays."
            }
          >
            <img src={infoIcon} className={`${appClasses.infoIcon} ${appClasses.vAlignMiddle}`} />
          </AppTooltip>

        </Typography>
      </DialogTitle>

      <Box mt={2} />

      {limits && limits.length > 0 && (
        <Paper className={`${appClasses.paperCard} ${classes.limitWrapper}`}>
          <div className={classes.limitContent}>
            <div className={classes.limitHeader}>
              <img src={lightBulbBlackIcon} /> &nbsp;&nbsp;
              <Typography variant="h6" className={appClasses.text4}>
                Limits Apply
              </Typography>
            </div>
            <IconButton onClick={() => setIsExpandLimits(!isExpandLimits)}>
              <img src={isExpandLimits ? arrowUpIosIcon : arrowDownIosIcon} />
            </IconButton>
          </div>
          {isExpandLimits && (
            <div className={classes.limitProgress}>
              {limits.map((limit) => (
                <>
                  <Box mt={3} />
                  <div>
                    <div className={classes.progressBarHead}>
                      <Typography variant="span" className={appClasses.text7}>
                        <Amount removePlusSign removeSuccessColor>
                          {limit.available}
                        </Amount>{" "}
                        <span className={appClasses.text10}>
                          Remaining of{" "}
                          <Amount removePlusSign removeSuccessColor>
                            {limit.limit_amount}
                          </Amount>
                        </span>
                      </Typography>
                      <Typography variant="span" className={appClasses.text4}>
                        {calculateLimitResult(limit.limit_period)}
                      </Typography>
                    </div>
                    <Box mt={1} />
                    <div className={classes.progressBar}>
                      <div
                        style={{
                          width: `${getPercent(
                            limit.available,
                            limit.limit_amount
                          )}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}
        </Paper>
      )}
      <Box mt={3} />
      <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
        <AppTextField
          name={"recipientId"}
          label="Recipient"
          select
          value={recipientId}
          onChange={handleChange}
          color={"secondary"}
          errorText={errors?.fromAccount}
        >
          <MenuItem className={`${appClasses.dropDownMI} ${classes.textAddRecipient}`} key={"add"} onClick={() => onAddEditRecipientHandle()}>
            {"Add Recipient"}
          </MenuItem>
          {recipient.map((account) => (
            <MenuItem className={appClasses.dropDownMI} key={account.name} value={account.ting}>
              {account.first_name + " " + account.last_name}
            </MenuItem>
          ))}
        </AppTextField>
      </Grid>
      {recipientId && (
        <Typography
          variant="span"
          className={`${appClasses.text9} ${appClasses.colorBlack700}`}
        >
          <a
            onClick={() => {
              onAddEditRecipientHandle();
            }}
            className={appClasses.aLink}
          >
            Edit recipient
          </a>
        </Typography>
      )}
      <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
        <AppTextField
          id={"amount"}
          name={"amount"}
          label="Amount"
          value={amount}
          onChange={handleChange}
          errorText={errors?.amount}
          InputProps={{
            startAdornment: <>{amount ? currencySymbol : ""}</>,
          }}
        />
      </Grid>
      <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
        <AppTextField
          name={"toAccount"}
          label="Optional Message"
          value={toAccount}
          onChange={handleChange}
          errorText={errors?.toAccount}
        ></AppTextField>
      </Grid>

      <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
        <AppButton
          contained
          onClick={handleSubmit}
          className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
          primary
          disabled={(recipientId && amount) ? false : true}
        >
          Send Now
        </AppButton>
      </Grid>

      {/* AddEditRecipient */}
      {isAddRecipientDialogOpen && (
        <AddEditRecipientDialog
          handleClose={handleClose}
          recipientId={recipientId}
        />
      )}
      {
        isTransactionSuccessDialogOpen &&
        <SendToRecipientSuccessDialog
          handleClose={handleSuccessDialogClose}
          amount={amount}
          name={getRecipientByTing(recipientId)}
          confirmation={confirmation}
          ting={ting}
          message={toAccount}
        />
      }
    </Grid>
  );
};
