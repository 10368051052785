import { Marker } from "@react-google-maps/api";
import { lowFeeAtmIcon, paidAtmIcon } from "../../../../constants";

export default function ATMMarker({ atm, onClick }) {
  const onMarkerClick = (e) => {
    onClick && onClick(e, atm)
  }
  const { lat, lon: lng } = atm?.location || {}
  return <Marker
    key={atm.terminal_id}
    icon={atm.fee_amt > 0 ? paidAtmIcon : lowFeeAtmIcon}
    position={{ lat, lng }}
    onClick={onMarkerClick}
  />
}