import { Box, Card, CardContent, CardMedia, Chip, Paper, Typography, styled } from "@material-ui/core";
import { AppButton } from "../../../../common/components/AppButton";
import { rewardCardImg } from "../../../../constants";


const Wrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  flex: '1 1 auto',
}));

const RowCover = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.other.orange500,
  borderRadius: "80px",
  height: "22px",
  width: "73px",
  "& span": {
    fontSize: "11px",
    color: theme.palette.other.white,
  },
}));

const StyledButton = styled(AppButton)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "17px",
  border: `1.5px solid ${theme.palette.other.primary500}`,
  borderRadius: "200px",
  width: "147px",
  height: "40px",
}));
export default function Rewards({ handleRewardsClick }) {
  return <Wrapper>
    <StyledCard>
      <CardMedia src={rewardCardImg}
        component="img"
        title="Reward Card" />
      <CardContent>
        <RowCover data-testid="reward-tag">
          <StyledChip label="Exclusive" />
        </RowCover>
        <RowCover>
          <Typography variant="h6" style={{ fontWeight: "700", fontSize: "18px", lineHeight: "21px" }}>
            Earn exclusive rewards on your purchases
          </Typography>
        </RowCover>
        <RowCover>
          <Typography variant="span" style={{ fontWeight: "400", fontSize: "16px", lineHeight: "22px" }}>
            Use your Artist Growth Wallet Card to earn rewards at stores
            near you. Click below to see the exclusive offers.
          </Typography>
        </RowCover>
        <div>
          <StyledButton link onClick={handleRewardsClick}>
            View Rewards
          </StyledButton>
        </div>
      </CardContent>
    </StyledCard>
  </Wrapper>
}