import axios from "axios";
import {
  API_URL,
  APP_VERSION,
  CLIENT_ID,
  CLIENT_SECRET,
  DEVICE_ID,
  additionalApiData,
  browserName,
  getOSInfo
} from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const clientToken = () => {
  return request(
    API_URL + "auth/client",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET,
      deviceId: DEVICE_ID,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const accessToken = (headers, data) => {
  return request(
    API_URL + "auth/portal",
    "POST",
    {
      ...headers,
    },
    {
      clientId: CLIENT_ID,
      appVersion: APP_VERSION,
      ...data,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const changePassword = (data) => {
  return request(API_URL + "auth/portal/update-password", "POST", {}, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const login = (data) => {
  return request(
    API_URL + "login",
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...additionalApiData,
      ...data,
      multistage: true
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOTPLogin = (data) => {
  const {user_id, bibson, user} = data;
  return request(
    API_URL + `twofac/${user_id}`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "login"
    },
    {
      origin: "login",
      ...additionalApiData,
      phone: user?.phone,
      multistage: true
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const KYC_HASNT_PERFORMED_CODE = 8007;

function isKycHasNotPerformedError(err) {
  const error = err?.response?.data?.errors.find((e) => e.code === KYC_HASNT_PERFORMED_CODE);
  return !!error
}

/*
* This function is used to get the kyc status of the user
* @param {Object} data
* @param {String} data.user_id
* @param {String} data.bibson
* @returns {Promise<Object>} - A promise that resolves to a object containing the kyc status of the user and response data
*/
export const getkYCStatus = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, currency, company_id } =
    additionalApiData;
  return request(
    API_URL + `users/${data.user_id}/kyc/status?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&currency=${currency}&app_version=${app_version}&app_type=${app_type}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
  )
    .then((response) => {
      const kycStatus = response?.data?.data?.kyc_status
      return { kycStatus, kycResponse: response?.data}
    })
    .catch((error) => {
      if (isKycHasNotPerformedError(error)) {
        return { kycStatus: 'NoKYC', kycResponse: error.response?.data}
      }
      throw error;
    });
};

export const getKYCLink = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, currency, company_id } =
    additionalApiData;
  return request(
    API_URL + `users/${data.user_ting}/kyc/form-link?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&currency=${currency}&app_version=${app_version}&app_type=${app_type}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const validateOTPLogin = (data) => {
  const {user_id, bibson, ...rest} = data
  const {os, version} = getOSInfo()
  return request(
    API_URL + `twofac/validate/${user_id}`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "login"
    },
    {
      origin: "login",
      os_version: `${version} (${browserName})`,
      model: os,
      ...additionalApiData,
      ...rest,
      multistage: true
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOTPForgotPassword = (data) => {
  return request(
    API_URL + `twofac/add-password`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-client-2fa-reason": "add-password",
    },
    {
      origin: "forgot_password",
      ...additionalApiData,
      ...data
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
export const sendResendInvite = (data) => {
  return request(
    API_URL + `paidapp/users/resend-invitation`,
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      ...additionalApiData,
      ...data,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

export const validateOTPForgotPassword = (data) => {
  return request(
    API_URL + `twofac/validate/add-password`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-client-2fa-reason": "add-password",
    },
    {
      origin: "forgot_password",
      ...additionalApiData,
      ...data
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSecurityQuestions = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/security-questions?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const validateSecurityQuestions = (data) => {
  return request(
    API_URL + `users/${data.user_id}/security-questions/verify`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      ...additionalApiData,
      ...data,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const createNewPassword = (data) => {
  return request(
    API_URL + `add-password`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      ...additionalApiData,
      ...data,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


export const authCheck  = (data) => {
  const {user_id, bibson} = data
  return axios.get(API_URL + `users/${user_id}/auth-check`, {
    headers: {
      "Content-Type": "application/json",
      'x-bishop': bibson
    },
    params: {
      ...additionalApiData
    }
  })
}