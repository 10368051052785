import { SET_CONTENTFUL_CONTENT } from "../../../redux/actionTypes";

const initState = {};

export default function contentfulReducer(state = initState, action) {
  switch (action.type) {
    case SET_CONTENTFUL_CONTENT:
      return Object.assign({}, state, {
        ...state,
        [action.data.key]: action.data.data,
      });

    default:
      return state;
  }
}
