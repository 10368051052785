import { InputAdornment, TextField } from "@material-ui/core";
import React from "react";
import { eyeIcon, eyeOffIcon } from "../../../constants";
import { IconButton } from "../core";

export const PasswordField = (props) => {
  const {errors, ...rest} = props;
  const [showPwd, setShowPwd] = React.useState();
  const errorMessage = errors?.message
  const isError = !!errorMessage

  const handleClickShowPassword = () => {
    setShowPwd(!showPwd);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      helperText={errorMessage} error={isError}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              <img src={showPwd ? eyeIcon : eyeOffIcon} alt="eye"/>
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
      type={showPwd ? "text" : "password"}
    />
  );
};
