import React from 'react';
import Header from './Header';
import SideNav from './SideNav';
import { Box, styled, Link } from '@material-ui/core';
import { drawerWidth } from '../../../constants';
import LayoutProvider from './layoutProvider/LayoutProvider';
import { Alert, AlertTitle } from '@material-ui/lab';


const MaxMainContentWidth = 1200;
const LayoutCover = styled(Box)(({ theme }) => ({
  maxWidth: `${drawerWidth + theme.spacing(2) + MaxMainContentWidth}px`,
  margin: '0 auto',
}));

const MainWrapper = styled(Box)(({ theme }) => ({
  maxWidth: `${MaxMainContentWidth}px`,
  height: '100vh',
  overflowY: 'auto',
  marginLeft: theme.spacing(0),
  [theme.breakpoints.up('lg')]: {
    marginLeft: `${drawerWidth + theme.spacing(2)}px`,
  },
}));

const StyleAlerTitle = styled(AlertTitle)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '14px',
}));
const StyledWarningAlert = styled(Alert)(({ theme }) => ({
  background: '#F7EED9',
  borderRadius: theme.spacing(2),
  margin: theme.spacing(0, 1),
  '& a': {
    color: '#018787',
    textDecoration: 'underline',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }

}));


function SiteNotOptimizedForMobileAlert() {
  return <StyledWarningAlert severity="warning">
    <StyleAlerTitle>Site not optimized for mobile</StyleAlerTitle>
    Download our  <Link href='https://ag.paid.app/dl'>app</Link>  for the best experience
  </StyledWarningAlert>
}

export default function FullLayout({ children, sideMenuContent }) {
  return (
    <LayoutProvider>
      <LayoutCover>
        <Header />
        <SideNav DrawerContent={sideMenuContent} />
        <MainWrapper>
          <SiteNotOptimizedForMobileAlert />
          {children}
        </MainWrapper>
      </LayoutCover>
    </LayoutProvider>
  );
}
