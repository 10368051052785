import React, { useEffect } from 'react'
import { useStyles } from "./style";


import virtualCardBackground from '../../../../assets/images/virtual-card/background.svg'
import agLogo from '../../../../assets/images/virtual-card/logo.png'
import visaLogo from '../../../../assets/images/virtual-card/visalogo.png'
import { styled } from '@material-ui/core';

const CardContainer = styled('div')(({ theme }) => ({
  background: `url(${virtualCardBackground}) no-repeat center center, url(${agLogo}) no-repeat right 8% top 18%, url(${visaLogo}) no-repeat right 8% bottom 22px`,
  backgroundColor: '#212322',
  backgroundSize: 'cover, 100px auto, 64px auto',
  display: "block",
  position: 'relative',
  width: 420,
  height: 255,
  margin: '0 auto',
  maxWidth: '100%',
  borderRadius: theme.spacing(1),
  "& > #mq-card-pan": {
    position: 'absolute',
    width: 312,
    height: 38,
    top: 100,
    left: 24,
    "& > iframe": {
      width: '100%',
      border: 'none',
      height: '100%',
    }
  }
}))

const ExpiryDateContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  fontSize: 14,
  fontWeight: 400,
  color: '#fff',
  width: 50,
  height: 40,
  top: 200,
  left: 24,
}))

const CVVContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  fontSize: 14,
  fontWeight: 400,
  color: '#fff',
  width: 40,
  height: 40,
  top: 200,
  left: 88,
}))

const ViewCardTemplete = ({ onInit }) => {
  const classes = useStyles();

  useEffect(() => {
    onInit && onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<CardContainer>
    <div id='mq-card-pan'></div>
    <ExpiryDateContainer>
      <div>Expiry</div>
      <div id='mq-card-exp' className={classes.frame2}></div>
    </ExpiryDateContainer>
    <CVVContainer>
      <div>CVV</div>
      <div id='mq-card-cvv' className={classes.frame2}></div>
    </CVVContainer>
  </CardContainer>)
}

export default ViewCardTemplete;