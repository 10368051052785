export const routes = {
  home: {
    path: "",
  },
  employeesList: {
    path: "/employees",
  },
  merchantsList: {
    path: "/merchants",
  },
  transactionCategoriesList: {
    path: "/tx-categories",
  },
  login: {
    path: "/login",
  },
  cardTnC: {
    path: "/cardTnC",
  },
  registration: {
    path: '/registration',
  },
  kyc: {
    path: '/kyc'
  },
  forgotPassword: {
    path: "/forgot-password",
  },
  kim: {
    path: "/kim",
  },
  uncategorisedMerchantTextsList: {
    path: "/uncategorised-merchant-texts",
  },
  retryFailedMessages: {
    path: "/retry-failed-messages",
  },
  retryFiSenderMessages: {
    path: "/retry-fisender-messages",
  },
  retryAutoCategorization: {
    path: "/retry-auto-catgorization",
  },
  viewBackgroundEventsList: {
    path: "/events",
  },
  dashboard: {
    path: "/dashboard",
  },
  goals: {
    path: "/goals",
  },
  transactions: {
    path: "/transactions",
  },
  atmfinder: {
    path: "/atm-finder",
  },
  manageCard: {
    path: "/manage-card",
  },
  deposit: {
    path: "/deposit",
  },
  rewards: {
    path: "/rewards",
  },
  moveMoney: {
    path: "/move-money",
  },
  statements: {
    path: "/statement",
  },
  profile: {
    path: "/profile",
  },
  setting: {
    path: "/settings",
  },
  help: {
    path: "/help",
  },
  offers: {
    path: "/offers",
  },
};
