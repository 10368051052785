import {
  SET_IS_GOT_PURSES,
  SET_IS_GOT_PURSE_TRANSACTIONS,
  SET_PURSE,
  SET_PURSES,
  SET_PURSE_TRANSACTIONS,
  ADD_PURSE_TRANSACTIONS
} from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import { getPurses, getPurseTransactions } from "../services";

export const setPurses = (response) => {
  return {
    type: SET_PURSES,
    data: response,
  };
};

export const setPurse = (response) => {
  return {
    type: SET_PURSE,
    data: response,
  };
};

export const setIsGotPurses = (response) => {
  return {
    type: SET_IS_GOT_PURSES,
    data: response,
  };
};

export const setPurseTransactions = (response) => {
  return {
    type: SET_PURSE_TRANSACTIONS,
    data: response,
  };
};

export const addPurseTransactions = (transaction) => {
  return {
    type: ADD_PURSE_TRANSACTIONS,
    data: transaction,
  };
}

export const setIsGotPurseTransactions = (response) => {
  return {
    type: SET_IS_GOT_PURSE_TRANSACTIONS,
    data: response,
  };
};



export const doGetAndSetPursesAndTransactions = (data) => (dispatch) => {
  dispatch(setIsGotPurses(false));
  apiCall(dispatch, getPurses(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (pursesResponse) => {
      if (pursesResponse?.data?.status == "success") {
        const { purses } = pursesResponse?.data?.data;
        dispatch(setPurses(purses || []));
        if (purses && purses.length > 0) {
          //we taken first purse for now, it might change in future
          data.purse_ting = purses[0].ting;
          dispatch(setPurse(purses[0]));

          dispatch(doGetAndSetPurseTransactions(data));
        }
      }
      dispatch(setIsGotPurses(true));
    },
    (error) => {
      dispatch(setIsGotPurses(true));
    }
  );
};

export const doGetAndSetPurseTransactions = (data) => (dispatch) => {
  dispatch(setIsGotPurseTransactions(false));
  apiCall(dispatch, getPurseTransactions(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (purseTxsResponse) => {
      if (purseTxsResponse?.data?.status == "success") {
        const { transactions } = purseTxsResponse?.data?.data;
        dispatch(setPurseTransactions(transactions || []));
      }
      dispatch(setIsGotPurseTransactions(true));
    },
    (error) => {
      dispatch(setIsGotPurseTransactions(true));
    }
  );
};
