import React from 'react';
import { Amount } from "../../../../common/components/Amount";

const RedeemCashBack = ({ rewardBalance, removePlusSign, removeSuccessColor }) => {
  return (
    <>
      Redeem Cash Back &nbsp;
      {rewardBalance > 0 && (
        <Amount removePlusSign={removePlusSign} removeSuccessColor={removeSuccessColor}>
          {rewardBalance}
        </Amount>
      )}
    </>
  );
};

export default RedeemCashBack;
