import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const StepWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const Title = ({ children }) => {
  return <StyledTitleTypography variant="h2">{children}</StyledTitleTypography>;
}


const StyledSubTitleTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(3),
}));

export const SubTitle = ({ children }) => {
  return <StyledSubTitleTypography variant="subtitle1">{children}</StyledSubTitleTypography>;
}