import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../App.style";
import { IconButton } from "../core";
import { useStyles } from "./style";

export const AppListItemCard = (props) => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const {
    icon,
    title,
    desc,
    active,
    onClick,
    rightIcon,
    onClickRightIcon,
    rightContent,
  } = props;

  return (
    <Paper
      onClick={onClick}
      className={`${appClasses.paperCard} ${classes.root}
      ${active ? classes.active : ""}`}
    >
      <div className={classes.leftContent}>
        <IconButton className={appClasses.bgPrimary100}>
          <img src={icon} alt="icon" />
        </IconButton>
        <div>
          <Typography variant="h6" className={appClasses.text5}>
            {title}
          </Typography>
          <Box mt={1}></Box>
          <Typography variant="span" className={appClasses.text4}>
            {desc}
          </Typography>
        </div>
      </div>
      {rightIcon && <img src={rightIcon} onClick={onClickRightIcon} alt="Right Icon" />}
      {rightContent && rightContent}
    </Paper>
  );
};
