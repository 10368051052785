import { useState } from "react";
import ATMContext from "./ATMContext";
import { useMediaQuery } from "@material-ui/core";

export default function ATMProvider({ children }) {
  const [selectedATM, setSelectedATM] = useState(null)
  const [distanceDurations, setDistanceDurations] = useState({})
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isSelectingFilter, setIsSelectingFilter] = useState(false)
  const [showList, setShowList] = useState(false)
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const displayListAtm  = lgUp || showList

  const value = {
    selectedATM,
    setSelectedATM,
    distanceDurations,
    setDistanceDurations,
    selectedFilters,
    setSelectedFilters,
    isSelectingFilter,
    setIsSelectingFilter,
    showList,
    setShowList,
    displayListAtm,
    lgUp
  }
  return <ATMContext.Provider value={value}>{children}</ATMContext.Provider>
}