import { HIDE_TOAST, SHOW_TOAST } from "../../redux/actionTypes";

export const show = (message, type) => {
  return {
    type: SHOW_TOAST,
    data: {
      message,
      type,
      open: true,
    },
  };
};

export const hide = () => {
  return {
    type: HIDE_TOAST,
  };
};
