import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
    // backgroundColor: theme.palette.other?.grey,
  },
  logoImg: {
    //margin: "auto",
  },
  leftSection: {
    height: "100vh",
    background: theme.palette.other.black700,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  spanCenter: {
    display: "block",
    textAlign: "center",
  },
  getStartedText: {
    width: "100%",
  },
  leftImg: {
    objectFit: "contain",
    width: "100%",
    ...theme.appAnimations?.animationFadeIn,
  },
  rightSectionContainer: {
    height: "100vh",
    overflow: "scroll",
    paddingBottom: theme.spacing(4),
  },
  kycRightSectionContainer: {
    height: "100%",
    overflow: "hidden",
  },
  rightSection: {
    margin: "0 auto",
    padding: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      maxWidth: "400px",
    },
    ...theme.appAnimations?.animationFadeIn,
  },
  kycRightSection: {
    width: "100%",
    margin: "auto",
    height: "100%",
    ...theme.appAnimations?.animationFadeIn,
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: 0,
  },
  pwdWrapper: {
    position: "relative",
    "& button": {
      position: "absolute",
      right: 0,
    },
    "& > div": {
      marginTop: "2rem",
      width: "100%",
    },
  },
  termAndConditionLink:{
    color: '#018787'
  }
}));
