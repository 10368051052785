import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import React from 'react';

import RewardItem from './RewardItem';
import Carousel from 'react-material-ui-carousel'

import { groupElementsByNElement } from '../../../../utilities/array_utils';
import RewardDialogPlaceHolder from '../../../../common/components/Reward/RewardDialogPlaceHolder';
import { RewardProvider, useReward } from '../../../../common/components/Reward/useReward';
import CarouselGroupRewardsItem from '../../../../common/components/Reward/RewardGroupItem';
import { NavigationButton } from '../../../../common/components/Reward/NavigationButton';

const Root = styled(Paper)(() => ({
  padding: '37px 1px 37px 18px',
  borderRadius: '16px'
}));

const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 24,
}));

const RewardItemsContainer = styled(Box)`
  margin-top: 10px;
  margin-left: -40px;
  margin-right: -40px;
`
const StyledCarousel = styled(Carousel)`
  padding-left: 40px;
  padding-right: 60px;
  .CarouselNavigator__buttonWrapper > .navButton__prev, .CarouselNavigator__buttonWrapper > .navButton__next{
    top: 50%;
    transform: translateY(-50%);
  }
  .CarouselNavigator__buttonWrapper:hover > button{
    background-color: #fff;
    opacity: 1;
    filter: unset;
  }
  .navButton__prev{
    left: -10px
  }
  .navButton__next{
    right: 10px
  }
`
const RewardWidget = () => {
  const { rewards, loading, rewardGroupSize, closeDetailsHandler, openedReward } = useReward()

  if (loading) return <div>loading</div>
  const rewardGroups = groupElementsByNElement(rewards, rewardGroupSize)
  const showCarouselIndicators = rewardGroups.length > 1
  return <>
    <Root elevation={1}>
      <Title variant="h4">Wallet+ Offers</Title>
      <RewardItemsContainer>
        <StyledCarousel
          navButtonsAlwaysVisible={false}
          indicators={showCarouselIndicators}
          autoPlay={false}
          NavButton={NavigationButton}
          navButtonsWrapperProps={{className: 'CarouselNavigator__buttonWrapper'}}
           >
          {rewardGroups.map((rewardItems) => (<CarouselGroupRewardsItem rewards={rewardItems} groupSize={rewardGroupSize} rewardItemComp={RewardItem} key={rewardItems[0]?.reward_ting ?? 'last-key'} />)) }
        </StyledCarousel>
      </RewardItemsContainer>
    </Root>
    <RewardDialogPlaceHolder closeDetailsHandler={closeDetailsHandler} reward={openedReward} />
  </>
}

/**
 * @param {Object} props - Reward property
 * @params {?Function} props.notifyNoWrads - Notify to parents that there is no reward
 */
export default function Reward({ notifyNoRewards }) {
  return (
    <RewardProvider notifyNoRewards={notifyNoRewards} rewardItemWidth={250}>
      <RewardWidget />
    </RewardProvider>
  );
}
