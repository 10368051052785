import {
  HIDE_LOADER,
  SHOW_LOADER,
} from "../../redux/actionTypes";

export const show = () => {
  return {
    type: SHOW_LOADER,
  };
};

export const hide = () => {
  return {
    type: HIDE_LOADER,
  };
};
