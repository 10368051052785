import React from 'react';
import {
  TableRow,
  TableCell,
} from "@material-ui/core";

import { AppTooltip } from "../../../../common/components/AppTooltip";
import {
  infoIcon,
} from "../../../../constants";
import { useAppTableStyles } from "../../../../common/components/AppTable/style";
import { useAppStyles } from "../../../../App.style";



const ListTransactions = ({ columns, transactions }) => {
  const appTableClasses = useAppTableStyles();

  return transactions.map((row, rowIndex) => {
    return (
      <TableRow hover tabIndex={-1} key={row.id}>
        {columns.map((col) => (
          <TableCell
            id={col.id}
            scope="row"
            className={appTableClasses.cell}
            padding={col.disablePadding ? "none" : "default"}
          >
            {col.render && col.render(row, rowIndex)}
          </TableCell>
        ))}
      </TableRow>
    );
  })
}
export default function TransactionRow({ status, transactions, columns, title, type }) {
  const appTableClasses = useAppTableStyles();
  const appClasses = useAppStyles();
  const key = status.toLowerCase(status) + 'Row'
  const id = status.toLowerCase(status) + 'Col'

  return (
    <>
      <TableRow hover tabIndex={-1} key={key}>
        <TableCell
          id={id}
          scope="row"
          className={`${appTableClasses.greyCell}`}
          colSpan={3}
        >
          {status} {" "}
          <AppTooltip title={title} placement="bottom">
            <img
              src={infoIcon}
              className={`${appClasses.infoIcon} ${appClasses.vAlignMiddle}`}
              alt="Information Icon"
            />
          </AppTooltip>
        </TableCell>
      </TableRow>
      <ListTransactions columns={columns} transactions={transactions} />
    </>
  );
}


