import { useSelector } from "react-redux";
import CredentialsProvider from "../../../../hooks/RequireCredentials/CrendentialProvider";
import SetPinDialog from "../../../setPin/containers/SetPinDialog";
import ViewCardTemplete from "../../../viewCard/components/ViewCard/viewCardTemplete";
import { useEffect, useRef } from "react";
import { SidemenuViewContent } from "../../../../common/components/SidemenuViewContent";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import { Box, styled } from "@material-ui/core";


// This component is a temporary solution to display the virtual card.
// The Logic:
// When the user open the virtual card, we check if the card is virtual and if the pin is set.
// If the pin is not set, we open the set pin dialog.
// On the Setpin Dialog box, there is a close button and the account after the user sets the pin.
// These 2 actions execute the onClose function which closes the ViewVirtualCardSlide. This cause the ViewVirtualCardSlide to close right after the user sets the pin.
// So that I added a ref and call setPinSuccess to prevent the ViewVirtualCardSlide from closing when the user successfully sets the pin.
// If user click on close before the set pin success, the ViewVirtualCardSlide will close.

const StyledHeaderComponentWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  flex: '1 1 auto',
  justifyContent: 'flex-end'
})

const ViewVirtualCardRighSlide = ({ onInitVirtualCard, onClose, isOpen, mobileScreenCollection }) => {
  return <SidemenuViewContent
    open={isOpen}
    title={"View Virtual Card"}
    onClose={onClose}
    headerComponent={
      <StyledHeaderComponentWrapper>
        <MobileHelpScreen topics={mobileScreenCollection} screenName={'view-virtual-card'} />
      </StyledHeaderComponentWrapper>
    }
  >
    <ViewCardTemplete onInit={onInitVirtualCard} />
  </SidemenuViewContent>
}
export default function ViewVirtualCardSlide({ initVirtualCardView, onClose, isOpen, mobileScreenCollection }) {
  const currentActivatedCard = useSelector((state) => state.managecardReducer?.currentActivatedCard);
  const setPinSuccess = useRef(false)

  // Make sure setPin status is reset when the dialog is closed.
  // We can not use unmount because this component is not unmounted when the dialog is closed.
  useEffect(() => {
    if (!isOpen) { setPinSuccess.current = false }
  }, [isOpen])
  if (!isOpen || !currentActivatedCard) return null

  const isNeedToSetPin = currentActivatedCard.is_virtual && !currentActivatedCard?.is_pin_set;

  // This is called when the user successfully sets the pin
  const onSetPinSuccessHandler = () => {
    setPinSuccess.current = true
  }
  // Only close the Setpin Dialog if the pin is set successfully
  const handleSetPinDialogClose = () => {
    if (!setPinSuccess.current) { onClose(); }
  }

  return <CredentialsProvider onClose={onClose}>
    {!isNeedToSetPin && <ViewVirtualCardRighSlide onInitVirtualCard={initVirtualCardView} onClose={onClose} isOpen={isOpen} mobileScreenCollection={mobileScreenCollection} />}
    {isNeedToSetPin && <SetPinDialog handleClose={handleSetPinDialogClose} gotoViewVirtualCardPage={onSetPinSuccessHandler} />}
  </CredentialsProvider>
}