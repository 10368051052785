import {
  SET_USER_CARD_TING,
  SET_CARD_BALANCES,
  SET_DASHOBOARD_CARDS,
  SET_IS_GOT_CARD_BALANCES,
  SET_IS_GOT_DASHBOARD_CARDS,
  SET_IS_GOT_REWARD_BALANCE,
  SET_TING_MATCHED_CARD,
  CLEAR_CARD_BALANCES,
} from "../../../redux/actionTypes";

const initState = {
  cardBalances: {
    card: 0,
    savings: 0,
    rewards: 0,
  },
  dashboardCards: [],
  userCardTing: undefined,
  tingMatchedCard: undefined,
  isGotDashboardCards: undefined,
  isGotCardBalances: undefined,
  isGotRewardBalance: undefined,
};

export default function dashboardReducer(state = initState, action) {
  switch (action.type) {
    case SET_IS_GOT_CARD_BALANCES:
      return Object.assign({}, state, {
        isGotCardBalances: action.data,
      });
    case SET_TING_MATCHED_CARD:
      return Object.assign({}, state, {
        tingMatchedCard: action.data,
      });
    case SET_USER_CARD_TING:
      return Object.assign({}, state, {
        userCardTing: action.data,
      });
    case SET_IS_GOT_REWARD_BALANCE:
      return Object.assign({}, state, {
        isGotRewardBalance: action.data,
      });
    case SET_IS_GOT_DASHBOARD_CARDS:
      return Object.assign({}, state, {
        isGotDashboardCards: action.data,
      });
    case SET_DASHOBOARD_CARDS:
      return Object.assign({}, state, {
        dashboardCards: action.data,
      });
    case CLEAR_CARD_BALANCES:
      return Object.assign({}, state, {
        cardBalances: initState.cardBalances
      })
    case SET_CARD_BALANCES:
      const { card, savings, rewards } = action.data || {};
      const { cardBalances } = state;
      return Object.assign({}, state, {
        cardBalances: {
          ...cardBalances,
          card: card ?? cardBalances.card,
          savings: savings ?? cardBalances.savings,
          rewards: rewards ?? cardBalances.rewards,
        },
      });

    default:
      return state;
  }
}
