import { Grid, IconButton, Paper, Typography, styled } from "@material-ui/core";
import { atmImage, getHelpImage, manageCardImage, statementsImage } from "../../../../constants";
import { SectionTitle } from "../common";

const QuickLinkCover = styled('div')(({ theme }) => ({
  background: '#FFFFFF',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '16px',
  padding: theme.spacing(2, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
}));

const StyledButtonIcon = styled(IconButton)(({ theme }) => ({
  background: theme.palette.other.primary100,
  marginBottom: theme.spacing(2),
}));

const QuickLinkCardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "11px",
  textAlign: "center",
  padding: theme.spacing(0, 1),
}));

const QuickLinkCard = ({ icon, label }) => {
  return (
    <QuickLinkCover>
      <StyledButtonIcon>
        <img src={icon} alt="label" />
      </StyledButtonIcon>
      <QuickLinkCardTitle variant="h6">
        {label}
      </QuickLinkCardTitle>
    </QuickLinkCover>
  );
};

export default function QuickServices({
  handleAtmClick,
  directDepositQuickServiceClick,
  handleStatementClick,
  handleHelpClick
}) {
  const itemResponsiveProps = {
    xs: 6,
    lg: 3,
  }
  return (<>
    <SectionTitle>
      Quick Services
    </SectionTitle>

    <Grid container spacing={2}>
      <Grid {...itemResponsiveProps} item onClick={handleAtmClick}>
        <QuickLinkCard icon={atmImage} label={'Nearby ATMs'} />
      </Grid>
      <Grid
        {...itemResponsiveProps}
        item
        onClick={directDepositQuickServiceClick}
      >
        <QuickLinkCard icon={manageCardImage} label={'Direct Deposit'} />
      </Grid>
      <Grid
        {...itemResponsiveProps}
        item
        onClick={handleStatementClick}
      >
        <QuickLinkCard icon={statementsImage} label={'View Statements'} />
      </Grid>
      <Grid {...itemResponsiveProps} item onClick={handleHelpClick}>
        <QuickLinkCard icon={getHelpImage} label={'Get Help'} />
      </Grid>
    </Grid>
  </>
  )
}