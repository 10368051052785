import {
  SET_BANK_ACCOUNT_DETAILS,
  SET_IS_GOT_BANK_ACCOUNT_DETAILS,
} from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import { getBankAccountDetails } from "../services";
import { appErrors } from "../../../constants/errors";
import * as toastActions from "../../../common/actions/toast";
import { TOAST_TYPES } from "../../../common/components/core";

export const setBankAccountDetails = (response) => {
  return {
    type: SET_BANK_ACCOUNT_DETAILS,
    data: response,
  };
};

export const setIsGotBankAccountDetails = (response) => {
  return {
    type: SET_IS_GOT_BANK_ACCOUNT_DETAILS,
    data: response,
  };
};

export const doGetAndSetBankAccountDetails = (data) => (dispatch) => {
  dispatch(setIsGotBankAccountDetails(false));
  dispatch(setBankAccountDetails({}));
  return apiCall(dispatch, getBankAccountDetails(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (accountsResponse) => {
      if (accountsResponse?.data?.status == "success") {
        const { bank_account } = accountsResponse?.data?.data;
        dispatch(setBankAccountDetails(bank_account || {}));
      }
      dispatch(setIsGotBankAccountDetails(true));
    },
    (error) => {
      const errors = error?.response?.data?.errors;
      if (errors && errors.length > 0) {
        const errorDetail = errors[0];
        if (errorDetail.code && appErrors[errorDetail.code]) {
          dispatch(
            toastActions.show(
              appErrors[errorDetail.code].message,
              TOAST_TYPES.ERROR
            )
          );
        }
      }
      dispatch(setIsGotBankAccountDetails(true));

      //code will remove
      /*
      const dummySucccessResponse = {
        status: "success",
        data: {
          bank_account: {
            routing_number: "103100195",
            dda_account_number: "25012760357232",
          },
        },
        message: "",
      };

      const { bank_account } = dummySucccessResponse?.data;
      dispatch(setBankAccountDetails(bank_account || {}));
      */
    }
  );
};
