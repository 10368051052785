import { makeStyles } from "@material-ui/core";
import { appColors } from "../../../../constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
    // backgroundColor: theme.palette.other?.grey,
  },
  leftSection: {
    height: "100vh",
    background: theme.palette.other.black700,
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "30vh",
    }
  },
  logoImg: {
    //margin: "auto",
  },
  leftImg: {
    objectFit: "contain",
    width: "100%",
    ...theme.appAnimations?.animationFadeIn,
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "auto",
    }
  },
  rightSection: {
    margin: "auto",
    padding: "0.5rem",
    [theme.breakpoints.up("lg")]: {
      padding: "1.5rem 12rem",
    },
    ...theme.appAnimations?.animationFadeIn,
  },
  rightSectionWd: {
    width: "80%"
  },
  rightSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
  },
  backIconBtn: {
    margin: "1rem",
    background: theme.palette.other.primary100,
  },
  cursor_pointer: {
    cursor: "pointer"
  },
  submitBtn: {
    backgroundColor: appColors.primary500
  }
}));
