import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./style";

export const PageHeader = (props) => {
  const classes = useStyles();
  const { text } = props;
  return (
    <Typography variant="h6" component={"h6"} className={classes.root}>
      {text}
    </Typography>
  );
};
