import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Box,
  DialogTitle, styled,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import {
  closeBlackIcon,
} from "../../../../constants/images";


const SetPinIframeCover = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  maxWidth: 370,
  [theme.breakpoints.down("xs")]: {
    maxWidth: "100%",
  }
}));

export const SetPin = (props) => {
  const {
    handleClose,
    onInitPinWidget,
  } = props;

  useEffect(() => {
    onInitPinWidget && onInitPinWidget('set-pin-frame');
  }, []);

  const appClasses = useAppStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="set-pin-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="set-pin-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleClose()}
          >
            <img src={closeBlackIcon} alt="close button" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent data-testid="dialog-content-set-pin">
        <SetPinIframeCover>
          <iframe frameBorder={0} src="" id="set-pin-frame" height={"370px"} width={"100%"} title="set pin"></iframe>
        </SetPinIframeCover>
      </DialogContent>
    </Dialog>
  );
};
