import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Box,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { AppTextField } from "../../../../common/components/AppTextField";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { checkGreenIcon, closeBlackIcon } from "../../../../constants/images";
import { Amount } from "../../../../common/components/Amount";
import moment from "moment";

export const SendRecipientSuccess = (props) => {
  const {
    successResult,
    handleClose,
    amount,
    name,
    confirmation,
    ting,
    message,
  } = props;

  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleClose()}
          >
            <img src={closeBlackIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <div className={`${appClasses.centerHvWrapper}`}>
        <div className={`${classes.txContainer}`}>
          <IconButton className={appClasses.dialogCloseBtn}>
            <img src={checkGreenIcon} />
          </IconButton>
          <Box mt={2}></Box>
          <Typography variant="span" className={appClasses.text1}>
            You've successfully made a transfer!
          </Typography>
          <Box mt={2}></Box>
          <div className={`${classes.txTable}`}>
            <div className={classes.txDelCol}>
              <label>Amount</label>
              <span>
                <Amount removePlusSign={true}>{amount}</Amount>
              </span>
            </div>
            <div className={classes.txDelCol}>
              <label>Name</label>
              <span>{name[0].first_name + " " + name[0].last_name}</span>
            </div>
            <div className={classes.txDelCol}>
              <label>Date</label>
              <span>{moment().format("LL")}</span>
            </div>
            <div className={classes.txDelCol}>
              <label>Account Number</label>
              <span>
                {"***" +
                  name[0].bank_account.account_number.slice(
                    name[0].bank_account.account_number.length - 3
                  )}
              </span>
            </div>
            <div className={classes.txDelCol}>
              <label>Routing Number</label>
              <span>{"*****" + name[0].bank_account.routing_number.slice(
                    name[0].bank_account.account_number.length - 3
                  )}</span>
            </div>
            <div className={classes.txDelCol}>
              <label>Confirmation</label>
              <span>{confirmation}</span>
            </div>
            {message && <div className={classes.txDelCol}>
              <label>Optional Message</label>
              <span>{message}</span>
            </div>}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
