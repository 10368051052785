import { Box, Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import React from 'react';

import RewardItem from './RewardItem';
import Carousel from 'react-material-ui-carousel'
import { NavigationButton } from '../../../../../common/components/Reward/NavigationButton';
import { groupElementsByNElement } from '../../../../../utilities/array_utils';
import RewardDialogPlaceHolder from '../../../../../common/components/Reward/RewardDialogPlaceHolder';
import CarouselGroupRewardsItem from '../../../../../common/components/Reward/RewardGroupItem';
import { RewardProvider, useReward } from '../../../../../common/components/Reward/useReward';

const Root = styled(Box)(() => ({
  padding: '0',
  margin: '0 0 20px 0'
}));

const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 24,
}));

const RewardItemsContainer = styled(Box)`
  margin-top: 10px;
`

const StyledCarousel = styled(Carousel)`
  padding-left: 40px;
  padding-right: 60px;
  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 30px;
    }
  .CarouselNavigator__buttonWrapper:hover > button{
    background-color: #fff;
    opacity: 1;
    filter: unset;
    top: 50%;
  }
  .navButton__prev{
    left: -10px
  }
  .navButton__next{
    right: 10px
  }
`

const RewardWidget = () => {
  const { rewards, loading, rewardGroupSize, closeDetailsHandler, openedReward } = useReward()
  if (loading) return <div>loading</div>
  if (rewards.length <= 0) return <></>
  const RewardGroups = groupElementsByNElement(rewards, rewardGroupSize)
  const showSarouselIndicators = RewardGroups.length > 1
  return (
    <>
      <Root elevation={1}>
        <Title variant="h4">Wallet+ Offers</Title>
        <RewardItemsContainer>
          <StyledCarousel
            indicators={showSarouselIndicators}
            autoPlay={false}
            NavButton={NavigationButton}
            navButtonsAlwaysVisible={true}
          >
            {RewardGroups.map((rewardItems) => (
              <CarouselGroupRewardsItem
                rewards={rewardItems}
                groupSize={rewardGroupSize}
                rewardItemComp={RewardItem}
                key={rewardItems[0]?.reward_ting ?? "last-key"}
              />
            ))}
          </StyledCarousel>
        </RewardItemsContainer>
      </Root>
      <RewardDialogPlaceHolder
        closeDetailsHandler={closeDetailsHandler}
        reward={openedReward}
      />
    </>
  );
}

/**
 * @param {Object} props - Reward property
 * @params {?Function} props.notifyNoWrads - Notify to parents that there is no reward
 */
export default function Reward({ notifyNoRewards }) {
  return (
    <RewardProvider notifyNoRewards={notifyNoRewards} rewardItemWidth={350}>
      <RewardWidget />
    </RewardProvider>
  );
}
