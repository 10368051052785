import React, { Component, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import { ViewCard } from "../components/ViewCard";
import CredentialsProvider from "../../../hooks/RequireCredentials/CrendentialProvider";
import SetPinDialog from "../../setPin/containers/SetPinDialog";


// To understand more about why using `setPinSuccess` Ref. Please check `src/modules/manageCard/components/ViewVirtualCardSlide/index.js`
export default function ViewCardDialog({ handleClose, isOpen }) {
  const currentActivatedCard = useSelector((state) => state.managecardReducer?.currentActivatedCard);
  const setPinSuccess = useRef(false)

  useEffect(() => {
    if (!isOpen) { setPinSuccess.current = false }
  }, [isOpen])

  if (!isOpen || !currentActivatedCard) return null

  const onSetPinSuccessHandler = () => {
    setPinSuccess.current = true
  }

  const handleSetPinDialogClose = () => {
    if (!setPinSuccess.current) { handleClose(); }
  }

  const isNeedToSetPin = currentActivatedCard.is_virtual && !currentActivatedCard?.is_pin_set;

  return <CredentialsProvider onClose={handleClose}>
    {isNeedToSetPin && <SetPinDialog handleClose={handleSetPinDialogClose} gotoViewVirtualCardPage={onSetPinSuccessHandler} />}
    {!isNeedToSetPin && <ViewCard handleClose={handleClose} card={currentActivatedCard} />}
  </CredentialsProvider>
}
