import logoImagePath from "../assets/images/logo.png";
import logoWhiteImagePath from "../assets/images/logo-white.png";
import loginLeftImgPath from "../assets/images/login-left-img.png";
import virtualCardImgPath from "../assets/images/vertual-card-img.png";
import byPayfareImgPath from "../assets/images/by-payfare.png";
import activateCardPageStaticImgPath from "../assets/images/activate-card-page-static.png";
import handRaisedImgPath from "../assets/images/hand-raised.png";
import logoWithByPayfareImgPath from "../assets/images/logo-with-by-payfare.png";

//icons
import arrowRightIosPath from "../assets/icons/arrow-right-ios.png";
import arrowUpIosPath from "../assets/icons/arrow-up-ios.png";
import arrowDownIosPath from "../assets/icons/arrow-down-ios.png";
import arrowLeftPath from "../assets/icons/arrow-left.png";
import arrowLeftIosPath from "../assets/icons/arrow-left-ios.png";
import arrowLeftIosWhitePath from "../assets/icons/arrow-left-ios-white.png";
import heartPath from "../assets/icons/heart.png";
import starPath from "../assets/icons/star.png";
import closeBlackPath from "../assets/icons/close_black.png";
import checkGreenPath from "../assets/icons/check_green.png";
import loadingBlackPath from "../assets/icons/loading_black.png";
import errorRedPath from "../assets/icons/error_red.png";
import errorRedDarkPath from "../assets/icons/error_red_dark.png";
import helpWhitePath from "../assets/icons/help_white.png";
import logoutWhitePath from "../assets/icons/logout_white.png";
import infoPath from "../assets/icons/info.png";
import filterBlackPath from "../assets/icons/filter_black.png";
import questionBlackPath from "../assets/icons/question-black.png";
import cashbackPath from "../assets/icons/cashback.png";
import arrowLeftRightGreenPath from "../assets/icons/arrow-left-right-green.png";
import landmarkGreenPath from "../assets/icons/landmark-green.png";
import lightBulbBlackPath from "../assets/icons/light-bulb_black.png";
import warningPath from "../assets/icons/warning.png";
import mapPinGreenPath from "../assets/icons/map-pin_green.png";
import resAddressGreenPath from "../assets/icons/res_address_green.png";
import mailGreenPath from "../assets/icons/mail_green.png";
import phoneGreenPath from "../assets/icons/phone_green.png";
import pencilBlackPath from "../assets/icons/pencil_black.png";
import lockGreenPath from "../assets/icons/lock-green.png";
import bailGreenPath from "../assets/icons/bail-green.png";
import alarmGreenPath from "../assets/icons/alarm_green.png";
import searchBlackPath from "../assets/icons/search_black.png";
import trailingIconPath from "../assets/icons/trailing-icon.png";
import eyeIconPath from "../assets/icons/eye.png";
import eyeOffIconPath from "../assets/icons/eye-off.png";

//reward transaction category icons
import shoppingRCPath from "../assets/reward-category-icons/shopping.png";
import carMaintenanceRCPath from "../assets/reward-category-icons/car-maintenance.png";
import diningRCPath from "../assets/reward-category-icons/dining.png";
import gasRCPath from "../assets/reward-category-icons/gas.png";
import groceryRCPath from "../assets/reward-category-icons/grocery.png";
import internetCableMobileRCPath from "../assets/reward-category-icons/internet-cable-mobile.png";
import moneyTransferRCPath from "../assets/reward-category-icons/money-transfer.png";
import otherRCPath from "../assets/reward-category-icons/other.png";
import personalCareRCPath from "../assets/reward-category-icons/personal-care.png";
import pharmaciesRCPath from "../assets/reward-category-icons/pharmacies.png";
import utilitiesRCPath from "../assets/reward-category-icons/utilities.png";

//dashboard quick services icons imports
import atmImagePath from "../assets/images/quick-services/atm.png";
import getHelpImagePath from "../assets/images/quick-services/help.png";
import statementsImagePath from "../assets/images/quick-services/statements.png";
import manageCardImagePath from "../assets/images/quick-services/manage-card.png";

//sidebar white images
import atmSidemenuImgPath from "../assets/images/sidebar/atm.png";
import ccImgPath from "../assets/images/sidebar/credit-card.png";
import depositImgPath from "../assets/images/sidebar/deposit.png";
import helpSidemenuImgPath from "../assets/images/sidebar/help.png";
import moveMoneyImgPath from "../assets/images/sidebar/move-money.png";
import scaleImgPath from "../assets/images/sidebar/scale.png";
import settingsImgPath from "../assets/images/sidebar/settings.png";
import starSidemenuImgPath from "../assets/images/sidebar/star.png";
import statementsSidemenuImgPath from "../assets/images/sidebar/statements.png";
import userImgPath from "../assets/images/sidebar/user.png";
import walletImgPath from "../assets/images/sidebar/wallet.png";

//sidebar green images
import atmSidemenuGreenImgPath from "../assets/images/sidebar/atm_green.png";
import ccGreenImgPath from "../assets/images/sidebar/credit-card_green.png";
import depositGreenImgPath from "../assets/images/sidebar/deposit_green.png";
import helpSidemenuGreenImgPath from "../assets/images/sidebar/help_green.png";
import moveMoneyGreenImgPath from "../assets/images/sidebar/move-money_green.png";
import scaleGreenImgPath from "../assets/images/sidebar/scale_green.png";
import settingsGreenImgPath from "../assets/images/sidebar/settings_green.png";
import starSidemenuGreenImgPath from "../assets/images/sidebar/star_green.png";
import statementsSidemenuGreenImgPath from "../assets/images/sidebar/statements_green.png";
import userGreenImgPath from "../assets/images/sidebar/user_green.png";
import walletGreenImgPath from "../assets/images/sidebar/wallet_green.png";
import rewardCardImgPath from "../assets/images/dashboard/rewards-card-img.png";
import rewardBanner1Path from "../assets/images/dashboard/reward-img-1.png";
import rewardBanner2Path from "../assets/images/dashboard/reward-img-2.png";

//Atm Finder images
import lowFeeAtm from "../assets/AtmLocationIcon/free-marker.png";
import paidAtm from "../assets/AtmLocationIcon/low-fee-marker.png";

//registration images
import stepImagePath from "../assets/images/registration/steps.png";

//other images
import pinGreenImgPath from "../assets/images/other/pin-green.png";
import replaceCardGreenImgPath from "../assets/images/other/replace-card-green.png";
import viewVirtualCardGreenImgPath from "../assets/images/other/view-virtual-card-green.png";
import activePhysicalCardGreenImgPath from "../assets/images/other/active-physical-card-green.png";

//temp images, will remove dummy
import tempVirtualCardImgPath from "../assets/images/temp/temp-virtual-card-mage.png";

export const logoImage = logoImagePath;
export const logoWhiteImage = logoWhiteImagePath;
export const loginLeftImg = loginLeftImgPath;
export const virtualCardImg = virtualCardImgPath;
export const byPayfareImg = byPayfareImgPath;
export const activateCardPageStaticImg = activateCardPageStaticImgPath;
export const handRaisedImg = handRaisedImgPath;
export const logoWithByPayfareImg = logoWithByPayfareImgPath;

//Registration exports
export const stepsImg = stepImagePath;

//Atm Finder images
export const lowFeeAtmIcon = lowFeeAtm;
export const paidAtmIcon = paidAtm;

//dashboard images
export const rewardCardImg = rewardCardImgPath;
export const rewardBanner1 = rewardBanner1Path;
export const rewardBanner2 = rewardBanner2Path;

//icons
export const arrowRightIosIcon = arrowRightIosPath;
export const arrowUpIosIcon = arrowUpIosPath;
export const arrowDownIosIcon = arrowDownIosPath;
export const arrowLeftIcon = arrowLeftPath;
export const arrowLeftIosIcon = arrowLeftIosPath;
export const arrowLeftIosWhiteIcon = arrowLeftIosWhitePath;
export const heartIcon = heartPath;
export const starIcon = starPath;
export const closeBlackIcon = closeBlackPath;
export const checkGreenIcon = checkGreenPath;
export const loadingBlackIcon = loadingBlackPath;
export const errorRedIcon = errorRedPath;
export const errorRedDarkIcon = errorRedDarkPath;
export const helpWhiteIcon = helpWhitePath;
export const logoutWhiteIcon = logoutWhitePath;
export const infoIcon = infoPath;
export const filterBlackIcon = filterBlackPath;
export const questionBlackIcon = questionBlackPath;
export const cashbackIcon = cashbackPath;
export const arrowLeftRightGreenIcon = arrowLeftRightGreenPath;
export const landmarkGreenIcon = landmarkGreenPath;
export const lightBulbBlackIcon = lightBulbBlackPath;
export const warningIcon = warningPath;
export const mapPinGreenIcon = mapPinGreenPath;
export const resAddressGreenIcon = resAddressGreenPath;
export const mailGreenIcon = mailGreenPath;
export const phoneGreenIcon = phoneGreenPath;
export const pencilBlackIcon = pencilBlackPath;
export const lockGreenIcon = lockGreenPath;
export const bailGreenIcon = bailGreenPath;
export const alarmGreenIcon = alarmGreenPath;
export const searchBlackIcon = searchBlackPath;
export const trailingIcon = trailingIconPath;
export const eyeOffIcon = eyeOffIconPath;
export const eyeIcon = eyeIconPath;

//category icons
export const shoppingRCIcon = shoppingRCPath;
export const carMaintenanceRCIcon = carMaintenanceRCPath;
export const diningRCIcon = diningRCPath;
export const gasRCIcon = gasRCPath;
export const groceryRCIcon = groceryRCPath;
export const internetCableMobileRCIcon = internetCableMobileRCPath;
export const moneyTransferRCIcon = moneyTransferRCPath;
export const otherRCIcon = otherRCPath;
export const personalCareRCIcon = personalCareRCPath;
export const pharmaciesRCIcon = pharmaciesRCPath;
export const utilitiesRCIcon = utilitiesRCPath;

//quick services
export const atmImage = atmImagePath;
export const manageCardImage = manageCardImagePath;
export const getHelpImage = getHelpImagePath;
export const statementsImage = statementsImagePath;

//other images
export const pinGreenImg = pinGreenImgPath;
export const replaceCardGreenImg = replaceCardGreenImgPath;
export const viewVirtualCardGreenImg = viewVirtualCardGreenImgPath;
export const activePhysicalCardGreenImg = activePhysicalCardGreenImgPath;

//temp images, will remove
export const tempVirtualCardImg = tempVirtualCardImgPath;

// Sidebar Menu ICON
export const SIDEMENU_ICONS = {
  atmSidemenuImg: atmSidemenuImgPath,
  ccImg: ccImgPath,
  depositImg: depositImgPath,
  helpSidemenuImg: helpSidemenuImgPath,
  moveMoneyImg: moveMoneyImgPath,
  scaleImg: scaleImgPath,
  settingsImg: settingsImgPath,
  starSidemenuImg: starSidemenuImgPath,
  statementsSidemenuImg: statementsSidemenuImgPath,
  userImg: userImgPath,
  walletImg: walletImgPath,

  //green images
  atmSidemenuGreenImg: atmSidemenuGreenImgPath,
  ccGreenImg: ccGreenImgPath,
  depositGreenImg: depositGreenImgPath,
  helpSidemenuGreenImg: helpSidemenuGreenImgPath,
  moveMoneyGreenImg: moveMoneyGreenImgPath,
  scaleGreenImg: scaleGreenImgPath,
  settingsGreenImg: settingsGreenImgPath,
  starSidemenuGreenImg: starSidemenuGreenImgPath,
  statementsSidemenuGreenImg: statementsSidemenuGreenImgPath,
  userGreenImg: userGreenImgPath,
  walletGreenImg: walletGreenImgPath,
};
