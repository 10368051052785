import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


import * as dashboardActions from "../../dashboard/actions";
import * as manageCardActions from "../../manageCard/actions";
import { SetPin } from "../components/SetPin";
import useAuth from "../../../hooks/useAuth";


const SetPinDialog = ({ handleClose , gotoViewVirtualCardPage, card}) => {
  const dispatch = useDispatch();
  const { bibson, user_id } = useAuth()
  const manageCardState = useSelector((state) => state.managecardReducer);
  if (!card){
    const { cards } = manageCardState;
    const activeVirtualCard = cards.find((element) => element.active && element.is_virtual);
    card = activeVirtualCard;
  }

  const cardTing = card?.ting;

  const onSetPinDialogCloseHanlder = useCallback(async () => {
    const authData = {
      bibson,
      user_id
    };
    await dispatch(dashboardActions.doGetAndSetCardBalances({ ...authData }));
    await dispatch(manageCardActions.loadCurrentUserCards(authData));
    gotoViewVirtualCardPage && gotoViewVirtualCardPage();
    handleClose && handleClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bibson, user_id])

  const initPinWidget = (frameName) => {
    let origin = window.location.origin;
    window?.PayfareMarqeta?.setGlobalCredentails({
      authToken: bibson,
      ting: cardTing,
      userId: user_id,
      apiUrl: origin,
    });
    window?.PayfareMarqeta?.renderSetPin(frameName, origin + "/SetPinSuccess.html");
  }

  useEffect(() => {
    if (!cardTing) return;
    const onSetPinMessage = (event) => {
      if (event.data.event_id === 'success') {
        dispatch(dashboardActions.syncSetPinUser({ bibson, userId: user_id, ting: cardTing }, onSetPinDialogCloseHanlder));
      }
    }
    window.addEventListener("message", onSetPinMessage);
    return () => window.removeEventListener("message", onSetPinMessage);

  }, [cardTing, bibson, user_id, handleClose, dispatch, onSetPinDialogCloseHanlder]);

  return <SetPin onInitPinWidget={initPinWidget}  handleClose={handleClose}/>;
}

export default SetPinDialog