import {
  SET_LIMIT,
  SET_RECIPIENTS,
} from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import { getRecipient, getSendLimitDetails, sendMoneyToRecipient } from "../services";
import { appErrors } from "../../../constants/errors";
import * as toastActions from "../../../common/actions/toast";
import { TOAST_TYPES } from "../../../common/components/core";

export const setRecipients = (response) => {
  return {
    type: SET_RECIPIENTS,
    data: response,
  };
};

export const setLimit = (response) => {
  return {
    type: SET_LIMIT,
    data: response,
  };
};


export const doGetAndSetLimit = (data) => (dispatch) => {
  dispatch(setLimit([]))
  apiCall(dispatch, getSendLimitDetails(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(setLimit(response?.data?.data?.limit))
      }
    },
    (error) => {
      const errors = error?.response?.data?.errors;
      if (errors && errors.length > 0) {
        const errorDetail = errors[0];
        if (errorDetail.code && appErrors[errorDetail.code]) {
          dispatch(
            toastActions.show(
              appErrors[errorDetail.code].message,
              TOAST_TYPES.ERROR
            )
          );
        }
      }
    }
  );
};

export const doGetAndSetRecipient = (data) => (dispatch) => {
  dispatch(setRecipients([]))
  apiCall(dispatch, getRecipient(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(setRecipients(response?.data?.data?.recipients))
      }
    },
    (error) => {
      const errors = error?.response?.data?.errors;
      if (errors && errors.length > 0) {
        const errorDetail = errors[0];
        if (errorDetail.code && appErrors[errorDetail.code]) {
          dispatch(
            toastActions.show(
              appErrors[errorDetail.code].message,
              TOAST_TYPES.ERROR
            )
          );
        }
      }
    }
  );
};

export const doSendTransactions = (data, payload, callBackFn) => (dispatch) => {
  apiCall(dispatch, sendMoneyToRecipient(data, payload), {
    showLoader: true,
    displayError: false,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        callBackFn && callBackFn(response?.data?.data);
      }
    },
    (error) => {
      const errors = error?.response?.data?.errors;
      if (errors && errors.length > 0) {
        const errorDetail = errors[0];
        if (errorDetail.code && appErrors[errorDetail.code]) {
          dispatch(
            toastActions.show(
              appErrors[errorDetail.code].message,
              TOAST_TYPES.ERROR
            )
          );
        }
      }
    }
  );
};
