import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-around",
  },
  card: {
    padding: theme.spacing(1),
    position: "relative",
    height: "190px",
    borderRadius: "16px",
    boxShadow: "1px 10px 10px rgba(0, 0, 0, 0.1)",
    background: theme.palette.other.primary900,
    cursor: "pointer",
  },
  cardTitle: {
    color: theme.palette.other.white,
    textAlign: "center",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  cardAmount: {
    color: theme.palette.other.white,
    textAlign: "center",
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: "700",
  },
  whiteCard: {
    "& $card": {
      background: theme.palette.other.white,
    },
    "& $cardTitle": {
      color: theme.palette.other.black700,
      lineHeight: "21px",
    },
    "& $cardAmount": {
      color: theme.palette.other.black700,
      fontSize: "32px",
      lineHeight: "38px",
    },
  },
  iconImg: {
    width: "24px",
    height: "24px",
    verticalAlign: "bottom",
    marginRight: "10px",
  },
}));
