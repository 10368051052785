import { makeStyles } from "@material-ui/core";
import { drawerWidth } from "../../../constants/app.constants";

export const useStyles = makeStyles((theme) => ({
  hideMenu: {
    width: "0px !important",
  },
  drawer: {
    // [theme.breakpoints.up("sm")]: {
    width: drawerWidth,
    flexShrink: 0
    // },
  },
  menuIcon: {
    width: "0%",
  },
  listItem: {
    borderRadius: "5px",
    borderLeftStyle: "solid",
    borderLeftWidth: "12px",
    borderLeftColor: theme.palette.other.black,
  },
  listItemActive: {
    // border-left: 12px white solid;
    borderLeftColor: theme.palette.other?.primary500,
  },
  listItemText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.palette.other.white,
  },
  listItemTextActive: {
    color: theme.palette.other?.primary500,
  },
  listItemSecondaryText: {
    fontSize: "12px",
    textAlign: "left",
    justifyContent: "left",
  },
  // necessary for content to be below app bar
  toolbar: { ...theme.mixins.toolbar, display: "flex" },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.other.black,
    ...theme.appAnimations?.animationFadeIn,
  },
  logoImg: {
    width: "7rem",
    margin: "auto",
    marginLeft: "20px",
    ...theme.appAnimations?.animationFadeIn,
  },
  divider: {
    background: theme.palette.other.white,
    margin: "1.5rem",
    opacity: "0.15",
  },
  logoutContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px 5px 0px",
  },
  logoutBtn: {
    background: "transparent",
    width: "175px !important",
    border: `1.5px solid ${theme.palette.other.primary500}`,
    height: "40px",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "17px",
    borderRadius: "3px",
    color: theme.palette.other.white,
    textTransform: "none",

    "&:hover": {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${theme.palette.other?.primary500}`,
    },
  },
  tncLink: {
    padding: "15px",
    paddingTop : "0px"
  },
  agreementLink: {
    cursor: "pointer"
  }
}));
