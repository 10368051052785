import React from "react";
import { Box } from "@material-ui/core";

import AddressForm from "./AddressForm";
import useAuth from "../../../../hooks/useAuth";
import { doSubmitAddress } from "../../actions";
import { useDispatch, useSelector } from "react-redux";

export const EditResAddress = ({
  isResidentialAddress,
  address,
  password,
  closeHandler
}) => {

  const dispatch = useDispatch();
  const { managecardReducer } = useSelector((state) => state);
  const { currentActivatedCard } = managecardReducer;
  const { bibson, user_id, username } = useAuth()

  const handleSaveAddress = (data) => {
    dispatch(doSubmitAddress({ ...address, ...data, isResidentialAddress, user_id, bibson, card_ting: currentActivatedCard?.ting, identifier: username, password }))
    closeHandler(true)
  }

  return <Box>
    <AddressForm address={address} isResidentialAddress={isResidentialAddress} onSaveAddress={handleSaveAddress} />
  </Box>;
};
