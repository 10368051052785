import { additionalApiData, API_URL } from "../../../constants";
import { request } from "../../../utilities/request";

const removeFormatingForPhoneNumber = (phone) => {
  const removeFormattedCharacters = phone.replace(/[-\(\)\s]/g, "");
  return `+1${removeFormattedCharacters}`;
}

export const verifyPassword = (data) => {
  const {
    app_type,
    app_version,
    channel,
    country_code,
    lang_code,
    company_id,
    currency,
  } = additionalApiData;

  const { identifier, password, user_id, bibson } = data;
  return request(
    API_URL + `users/${user_id}/write-auth`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      identifier,
      password,
      app_version,
      channel,
      lang_code,
      country_code,
      company_id,
      currency,
      app_type,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOtpNewMobile = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { phone, user_id, bibson } = data;
  const phoneWithoutFormatting = removeFormatingForPhoneNumber(phone);
  return request(
    API_URL + `twofac/${user_id}/new-phone`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { phone: phoneWithoutFormatting, channel, lang_code, country_code, company_id}
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOtpCurrentMobile = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { phone, user_id, bibson } = data;
  return request(
    API_URL + `twofac/${user_id}/current-phone`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { phone, channel, lang_code, country_code, company_id }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyOtpNewMobile = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { phone, twofac, user_id, bibson } = data;
  const phoneWithoutFormatting = removeFormatingForPhoneNumber(phone);
  return request(
    API_URL + `twofac/${user_id}/validate/new-phone`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { phone: phoneWithoutFormatting, twofac, channel, lang_code, country_code, company_id}
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


export const verifyOtpCurrentMobile = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { phone, twofac, user_id, bibson } = data;
  return request(
    API_URL + `twofac/${user_id}/validate/current-phone`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { phone, twofac, channel, lang_code, country_code, company_id }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOtpCurrentEmail = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { email, user_id, bibson } = data;

  return request(
    API_URL + `twofac/${user_id}/current-email`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { email, channel, lang_code, country_code, company_id }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendOtpNewEmail = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { email, user_id, bibson } = data;

  return request(
    API_URL + `twofac/${user_id}/new-email`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { email, channel, lang_code, country_code, company_id }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyOtpCurrentEmail = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { email, twofac, user_id, bibson } = data;

  return request(
    API_URL + `twofac/${user_id}/validate/current-email`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { email, twofac, channel, lang_code, country_code, company_id }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyOtpNewEmail = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { email, twofac, user_id, bibson } = data;

  return request(
    API_URL + `twofac/${user_id}/validate/new-email`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
      "x-client-2fa-reason": "edit-profile",
    },
    { email, twofac, channel, lang_code, country_code, company_id }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const changeMobile = (data) => {
  const { channel, country_code, lang_code, app_type, company_id } = additionalApiData;
  const { phone, identifier, password, user_id, bibson } = data;
  const phoneWithoutFormatting = removeFormatingForPhoneNumber(phone);
  return request(
    API_URL + `users/${user_id}/change-phone`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      phone: phoneWithoutFormatting,
      identifier,
      password,
      user_id,
      channel,
      lang_code,
      country_code,
      app_type,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const changeEmail = (data) => {
  const { channel, country_code, lang_code, app_type, company_id } = additionalApiData;
  const { email, identifier, password, user_id, bibson } = data;

  return request(
    API_URL + `users/${user_id}/change-email`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      email,
      identifier,
      password,
      user_id,
      channel,
      lang_code,
      country_code,
      app_type,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const saveAddress = (data) => {
  const { channel, country_code, lang_code, app_type, company_id } = additionalApiData;
  const {
    bibson,
    user_id,

    address_line1,
    address_line2,
    city,
    region,
    postal_code,
    identifier,
    password
  } = data;

  return request(
    API_URL + `users/${user_id}/addresses`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      address_line1,
      address_line2,
      city,
      region,
      postal_code,
      identifier,
      password,
      update_shipping: false,
      skip_validate_address: true,
      channel,
      country_code,
      lang_code,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const saveShippingAddress = (data) => {
  const { channel, country_code, lang_code, app_type, company_id } = additionalApiData;
  const {
    bibson,
    user_id,
    card_ting,

    address_line_1,
    address_line_2,
    city,
    region,
    postal_code,

    first_name,
    last_name,

    identifier,
    password,
  } = data;

  return request(
    API_URL + `users/${user_id}/card/${card_ting}/shipping-address`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      address_line_1,
      address_line_2,
      city,
      region,
      postal_code,

      channel,
      country_code,
      lang_code,
      company_id,

      first_name,
      last_name,

      identifier,
      password,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserProfile = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { user_id, bibson } = data;

  return request(
    API_URL +
      `users/${user_id}?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserAddresses = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { user_id, bibson } = data;

  return request(
    API_URL +
      `users/${user_id}/addresses?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserShippingAddresses = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { user_id, bibson, card_ting } = data;

  return request(
    API_URL +
      `users/${user_id}/card/${card_ting}/shipping-address?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSuggestedAddress = (data, payload) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL + `users/${data.user_id}/suggest-address`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      channel: channel,
      lang_code: lang_code,
      country_code: country_code,
      company_id : company_id,
      app_type,
      app_version,
      ...payload,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSupportEmail = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { user_id, bibson } = data;

  return request(
    API_URL +
      `support/${user_id}?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
