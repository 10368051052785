// create a dialog componet with a password input field
// and a checkbox to show/hide the password

import React from "react";
import { Dialog, DialogContent, Typography, styled, Grid, IconButton } from "@material-ui/core";
import { PasswordField } from "../PasswordField";
import { PrimaryButton, SecondaryButton } from "../core/Button/Buttons";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UNAUTORIZED, closeBlackIcon } from "../../../constants";
import { useDispatch } from "react-redux";

import { useApiErrorHandler } from "../../../modules/auth/hooks/userApiErrorHandler";

const passwordSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
})
const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    padding: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0, 0),
    }
  },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  textAlign: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(3, 1, 1, 1),
    marginBottom: theme.spacing(1),
  }
}));

const PasswordPopup = ({ onClose, onSubmit }) => {
  const { handleErrorsFromServer } = useApiErrorHandler()
  const { handleSubmit, formState, setValue, watch, setError } = useForm({
    mode: "onChange",
    resolver: yupResolver(passwordSchema),
    defaultValues: { password: "" },
  });

  const password = watch('password');

  const { isValidating, isValid, isSubmitting, errors } = formState;

  const handlePasswordChange = (e) => {
    setValue("password", e.target.value, { shouldValidate: true })
  };

  const processErrorMessage = (errorData) => {
    const unauthorized = errorData.some((error) => UNAUTORIZED === error.code.toString());
    if (unauthorized) {
      return "Password you entered does not match our records. Please try again"
    }
    return errorData[0]?.message || "Something went wrong. Please try again later";
  }

  const submitHandler = async (data) => {
    try {
      onSubmit && await onSubmit(data.password);
    } catch (e) {
      if (e.isAxiosError) {
        handlerServerErrors(e)
      } else {
        console.error(e)
      }
    }
  }

  const handlerServerErrors = (error) => {
    const errorData = error.response?.data?.errors || [];
    const isHandled = handleErrorsFromServer(error)
    if (!isHandled) {
      const errorMessage = processErrorMessage(errorData);
      setError('password', { type: "server", message: errorMessage })
    }
  }

  const disabled = isValidating || isSubmitting || !isValid;
  return <StyledDialog open={true} onClose={onClose}>

    <DialogContent>
      <CloseButton onClick={onClose}>
        <img src={closeBlackIcon} alt="close" />
      </CloseButton>
      <TitleText variant="subtitle1" sx={{ px: 3, textAlign: 'center' }} >For your security, please enter your password</TitleText>

      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <PasswordField
            id={"password"}
            name={"password"}
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            errors={errors?.password}
          />
        </Grid>
        <Grid item xs={12}>
          <PrimaryButton
            onClick={handleSubmit(submitHandler)}
            disabled={disabled}
          >
            Continue
          </PrimaryButton>
        </Grid>
        <Grid item xs={12}>
          <SecondaryButton onClick={onClose}>
            Cancel
          </SecondaryButton>
        </Grid>

      </Grid>

    </DialogContent>
  </StyledDialog>;
}

export default PasswordPopup