import { Box, Button, styled, Typography } from "@material-ui/core"
import React from "react"
import { PasswordField } from "../../../../../common/components/PasswordField"
import useAuth from "../../../../../hooks/useAuth";
import useReplaceCard from "../store/useReplaceCard";
import { verifyPassword } from "../../../services";
import { AppButton } from "../../../../../common/components/AppButton";
import { useAppStyles } from "../../../../../App.style";
import { StepWrapper, Title } from "../AtomComponents";
import { useApiErrorHandler } from "../../../../auth/hooks/userApiErrorHandler";

const StyledPasswordField = styled(PasswordField)(({ theme }) => ({
  margin: theme.spacing(4, 0, 2, 0),
}));

export default function AskCurrentPassword() {
  const appClasses = useAppStyles();
  const { setCurrentPassword, onNextStep } = useReplaceCard();
  const { username, bibson, user_id } = useAuth()
  const [pass, setPass] = React.useState("")
  const [error, setError] = React.useState(null)
  const { handleErrorsFromServer } = useApiErrorHandler()

  const onHandlePasswordChange = (e) => {
    if (error) {
      setError(null)
    }
    setPass(e.target.value)
  }

  const continueClickHandler = async () => {
    try {
      await verifyPassword({ identifier: username, bibson, password: pass, user_id })
      setCurrentPassword(pass)
      onNextStep()
    } catch (err) {
      // Do not process more if error is handled
      if (err.isAxiosError && handleErrorsFromServer(err)) return;

      const responseErrors = err.response?.data?.errors || []
      if (responseErrors[0]) {
        return setError(responseErrors[0].message)
      }
      setError(err.message)
    }
  }

  const isDisabled = pass === "" || !pass

  return <StepWrapper>
    <Title>
      Replace Your Card
    </Title>

    <StyledPasswordField
      name={"pass"}
      label="Your password"
      value={pass}
      fullWidth
      onChange={onHandlePasswordChange}
      error={!!error}
      helperText={error}
    />

    <AppButton
      disabled={isDisabled}
      variant="contained"
      fullWidth
      onClick={continueClickHandler}
      className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
      primary
      style={{ width: '100%' }}
    >
      Yes, Continue
    </AppButton>
  </StepWrapper>
}