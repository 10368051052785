import React from "react";
import { TextField } from "../core";
import { useStyles } from "./style";

export const AppTextField = (props) => {
  const classes = useStyles();
  return (
    <TextField
      InputProps={{
        classes: {
          input: classes.textFieldInput,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.textFieldLabel,
        },
      }}
      className={classes.textField}
      { ...props }
      variant="outlined"
    />
  );
};
