import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../App.style";
import { useStyles } from "./style";

export const AlertCardType = {
  ERROR: "ERROR",
};

export const AlertCard = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const { title, detail, icon } = props;
  return (
    <Paper className={`${appClasses.paperCard} ${classes.wrapper}`}>
      <img src={icon} className={classes.img} alt="close" />
      <div className={classes.rightContent}>
        <Typography
          variant="body1"
          className={`${appClasses.text11} ${appClasses.colorBlack700}`}
          style={{fontWeight: 700}}
        >
          {title}
        </Typography>
        <Box mt={1}></Box>
        <Typography
          variant="span"
          className={`${appClasses.text10} ${appClasses.colorBlack700}`}
        >
          {detail}
        </Typography>
      </div>
    </Paper>
  );
};
