import React, { useEffect } from "react";
import KycForm from "./KycForm";

import { useHistory } from "react-router-dom";
import { routes, APPLICATION_STATUS } from "../../../../constants";
import { useDispatch } from "react-redux";
import { doLogout } from "../../../auth/actions";


const checkIsJsonValidstr = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}


const KYC = ({ kycLink, onSuccessKyc }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("message", onKycIframeEvent);
    return () => window.removeEventListener("message", onKycIframeEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycLink]);

  const kycFailed = () => {
    dispatch(doLogout());
  }

  const userClosedKyc = (data) => {
    if (data?.kycStatus === 'passed') {
      onSuccessKyc && onSuccessKyc()
      history.push({ pathname: routes.dashboard.path, });
    }else{
      dispatch(doLogout());
    }
  }

  const redirectToVirtualCard = () => {
    onSuccessKyc && onSuccessKyc()
    history.push({
      pathname: routes.dashboard.path,
      state: { viewVirtualCard: true }
    });
  }

  const KYC_EVENT_ACTION_MAPPER = {
    [APPLICATION_STATUS.APPLICATION_CLOSED_BY_USER]: userClosedKyc,
    [APPLICATION_STATUS.KYC_FAILED]: kycFailed,
    [APPLICATION_STATUS.VIEW_VIRTUAL_CARD]: redirectToVirtualCard
  }

  const onKycIframeEvent = (event) => {
    var checkJsonStr = checkIsJsonValidstr(event?.data);
    const data = checkJsonStr ? JSON.parse(event?.data) : null;
    const eventActionHandler = KYC_EVENT_ACTION_MAPPER[data?.code];
    if (eventActionHandler) {
      eventActionHandler(data);
    }
  }

  return <KycForm kycLink={kycLink} />;
}

export default KYC;