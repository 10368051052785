import { Grid, Typography, Box } from "@material-ui/core";
import React from "react";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { AppListItemCard } from "../../../../common/components/AppListItemCard";
import { SidemenuViewContent } from "../../../../common/components/SidemenuViewContent";
import {
  arrowLeftRightGreenIcon,
  landmarkGreenIcon,
} from "../../../../constants";
import SendToRecipientPage from "../../containers/SendToRecipientPage";
import { TransferBetweenAcPage } from "../../containers/TransferBetweenAcPage";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import { useAsyncEffect } from "ahooks";
import { contentfulActionTypes, doGetAndSetContent } from "../../../contentful/actions";
import { useDispatch, useSelector } from "react-redux";

export const MoveMoneyHome = (props) => {
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();
  const dispatch = useDispatch()
  const mobileScreenCollection = useSelector((state) => state.contentfulReducer.mobileScreenCollection || [])

  useAsyncEffect(async () => {
    // Only fetch if not already fetched
    if (mobileScreenCollection.length > 0) return;
    dispatch(doGetAndSetContent(contentfulActionTypes.mobileScreenCollection));
  }, [])

  const {
    isOpenTransferBetweenAccount,
    isOpenSendToRecipient,

    handleViewTransferBetweenAccount,
    handleViewSendToRecipient,

    handleCloseTransferBetweenAccount,
    handleCloseSendToRecipient,
  } = props;

  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item container direction="column" style={{minHeight: "calc(100vh - 300px)"}}>
          <div>
            <Typography variant="h4" className={appClasses.text8}>
              Move Money
            </Typography>
          </div>
          <Box mt={4}></Box>

          <div style={{flex: "1 1 auto"}}>
            <AppListItemCard
              icon={landmarkGreenIcon}
              title={"Send Money to Someone Else"}
              desc={"Send money via bank transfer"}
              active={isOpenSendToRecipient}
              onClick={handleViewSendToRecipient}
            ></AppListItemCard>
            <Box mt={2}></Box>
            <AppListItemCard
              icon={arrowLeftRightGreenIcon}
              title={"Transfer Between Accounts"}
              desc={"Move money to and from your goal account"}
              active={isOpenTransferBetweenAccount}
              onClick={handleViewTransferBetweenAccount}
            ></AppListItemCard>
            <Box mt={2}></Box>
          </div>
          <div style={{marginTop: '40px'}}>
            <MobileHelpScreen iconName="button" topics={ mobileScreenCollection } screenName={'moveMoneyLanding'} anchor="top"/>
          </div>
        </Grid>
      </Grid>

      <SidemenuViewContent
        open={isOpenSendToRecipient}
        onClose={handleCloseSendToRecipient}
        headerComponent={
          <Box className={classes.viewVirtualCardHeader} style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName="send-money-to-someone-else" />
          </Box>
        }
      >
        <SendToRecipientPage handleCloseSendToRecipient={handleCloseSendToRecipient} />
      </SidemenuViewContent>

      <SidemenuViewContent
        open={isOpenTransferBetweenAccount}
        onClose={handleCloseTransferBetweenAccount}
        headerComponent={
          <Box className={classes.viewVirtualCardHeader} style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName="transfer-money" />
          </Box>
        }
      >
        <TransferBetweenAcPage handleClose={handleCloseTransferBetweenAccount} />
      </SidemenuViewContent>
    </div>
  );
};
