import React from "react";

import Dialog from "@material-ui/core/Dialog";
import { Box, DialogTitle, Divider, Typography } from "@material-ui/core";
import Moment from "react-moment";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import {
  closeBlackIcon,
  shoppingRCIcon,
  cashbackIcon,
} from "../../../../constants/images";
import {
  LONG_DATE_FORMAT,
  REWARD_CATEGORY_VALUE_TO_ICON,
} from "../../../../constants";
import { Amount } from "../../../../common/components/Amount";
import { getTxDetail } from "../../services";

export const ViewTransaction = (props) => {
  const { data, handleClose } = props;
  console.log("data==>", data);
  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleClose()}
          >
            <img src={closeBlackIcon} />
          </IconButton>
          <Typography variant="h1" className={appClasses.dialogText}>
            Transaction Details
          </Typography>
        </div>
      </DialogTitle>
      <Divider></Divider>

      <div className={`${classes.txContainer}`}>
        <Box mt={5}></Box>
        <div className={classes.txDetCol}>
          <div>
            <div className={classes.txDetCol}>
              <img
                src={
                  REWARD_CATEGORY_VALUE_TO_ICON[`${data?.category || "Others"}`]
                }
                className={appClasses.vAlignMiddle}
              />
              <Box ml={1}>
                <Typography variant="h4" className={appClasses.text3}>
                  {getTxDetail(data)}
                </Typography>
              </Box>
            </div>

            <Typography variant="span" className={appClasses.text4}>
              <Moment format={LONG_DATE_FORMAT}>{data?.trans_date}</Moment>
            </Typography>
          </div>
        </div>
        <Box mt={4}></Box>
        <div className={classes.txDetCol}>
          <div>
            <Typography variant="h4" className={appClasses.text5}>
              Transaction total:
            </Typography>
            {data?.category && (
              <Typography variant="span" className={appClasses.text4}>
                Category: {data?.category || ""}
              </Typography>
            )}
          </div>
          <Typography variant="span" className={appClasses.text5}>
            <Amount removePlusSign removeSuccessColor>
              {data?.trans_ref == "redemption" ? data?.reward_amount || 0 : data?.total_amount || 0}
            </Amount>
          </Typography>
        </div>
        {data?.trans_ref != "redemption" && (
          <>
            <Box mt={4}></Box>
            <div className={classes.txDetCol}>
              <div>
                <Typography variant="h4" className={appClasses.text5}>
                  <img src={cashbackIcon} className={appClasses.vAlignMiddle} />{" "}
                  Cash back Earned
                </Typography>
              </div>
              <Typography variant="span" className={appClasses.text7}>
                <Amount removePlusSign>{data?.reward_amount || 0}</Amount>
              </Typography>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
