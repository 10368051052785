import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  txDelCol: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "700",
    "& span": {
      color: theme.palette.other.black700,
    },
    "& label": {
      color: theme.palette.other.black300New,
    },
  },
  txContainer: {
    padding: "0px 48px",
    textAlign: "center",
    minHeight: "400px",
  },
  txTable: {
    padding: "24px 48px",
  },
  cardContainer: {
    width: "300px",
    height: "55vh",
    boxSizing: "border-box",
    border: 0,
    margin: 0,
    padding: 0,
    margin: "10px 25%"
  },
  marqetaCardContainer: {
    position: "absolute",
    boxSizing: "border-box",
    width: "300px",
    height: "350px",
    background: "#000000",
    borderRadius: "16px",
  },
  cardPanWrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    padding: "24px",
  },
  frame1: {
    "& > iframe": {
      width: "60px"
    }
  },
  frame2: {
    "& > iframe": {
      width: "60px",
      height: "30px"
    }
  },
  cvvExpWrapper: {
    height: "96px",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "180px",
    padding: "24px"
  },
  cardExp: {

  },
  fieldCover: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  label: {
    fontWeight: "lighter",
    color: "#fff",
    fontSize: "14px",
  }
}));
