import React from 'react'
import styled from '@emotion/styled';
import { Chip, IconButton } from '@material-ui/core';
import Bolt from '../../icons/bolt';
import CloseIcon from '@material-ui/icons/Close';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const ContenfulOptions = {
  renderText: (text) => {
    const lines = text.split('\n')
    return <>
      {lines.map((line) => <p key={line}>{line}</p>)}
    </>
  },
};

const Wrapper = styled.div`
  background: #fff;
  max-width: 750px;

`
const HeaderWrapper = styled.div`
  height: 180px;
  width: 100%;
  position: relative;
  margin-bottom: 90px;
`

const CloseIconButton = styled(IconButton)`
 position: absolute;
 top: 10px; left: 10px;
 color: #fff;
`
const StyledBrandLogo = styled.div`
  width: 75px;
  height: 75px;
  padding: 10px;
  border: 0.5px solid #E5E5E5;
  border-radius: 50%;
  position: absolute;
  top: 155px;
  left: 24px;
  background: #fff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
`

const Logo = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background: url(${props => props.url}) center center no-repeat;
  background-size: contain;
`

const HeaderImageCover = styled.div`
  height: 180px;
  width: 100%;
  background: url(${props => props.url}) top center no-repeat;
  background-size: cover;
`

const ExpiredCountDownChip = styled(Chip)(() => ({
  position: 'absolute',
  top: '190px',
  left: '114px',
  padding: '11px 22px',
  background: 'rgba(255, 60, 0, 0.1)',
  '& > svg': {
    fontSize: '1.2em'
  },
  '& > .MuiChip-label': {
    fontWeight: 700,
    fontSize: '12px',
    paddingLeft: 5,
    paddingRight: 0,
    color: '#FF3C00'
  }
}))

const Paragraph = styled.div`
  padding: 0 44px 0 24px;
  margin-bottom: 32px;
  & h2.title{
    font-style: normal;
    font-weight: 590;
    font-size: 24px;
    line-height: 29px;
    color: #262626;
  }
  & h3.subtitle{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #262626;
  }

  & .footerText{
    border-top: 1px solid #E5E5E5;
    padding: 20px inherit 30px ;
  }
`
export default function RewardDetails({ onClose, reward }) {
  if (!reward) return <></>

  const { badge_text: badgeText, contentful: { header: headerCoverImgUrl, icon, title, subheading, paragraph, footer_text } } = reward

  return <Wrapper>
    <HeaderWrapper>
      <CloseIconButton aria-label="upload picture" component="span" onClick={onClose}>
        <CloseIcon />
      </CloseIconButton>
      <HeaderImageCover url={headerCoverImgUrl} />
      <StyledBrandLogo>
        <Logo url={icon} />
      </StyledBrandLogo>
      {badgeText && <ExpiredCountDownChip icon={<Bolt css={{ fontSize: '1.2em' }} />} label={badgeText} />}
    </HeaderWrapper>
    <Paragraph>
      <h2 className='title'>{title}</h2>
      {documentToReactComponents(subheading)}
    </Paragraph>
    <Paragraph>
      {documentToReactComponents(paragraph, ContenfulOptions)}
    </Paragraph>

    <Paragraph>
      <div className='footerText'>
      {documentToReactComponents(footer_text, ContenfulOptions)}
      </div>
    </Paragraph>
  </Wrapper>
}