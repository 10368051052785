import {
  SET_STATEMENT_DETAILS,
  SET_STATEMENT_GOT_RESPONSE,
} from "../../../redux/actionTypes";

const initState = {
  statement: [],
  gotResponse: false,
};

export default function statementReducer(state = initState, action) {
  switch (action.type) {
    case SET_STATEMENT_DETAILS:
      return Object.assign({}, state, {
        statement: action.data,
      });
    case SET_STATEMENT_GOT_RESPONSE:
      return Object.assign({}, state, {
        gotResponse: action.data,
      });
    default:
      return state;
  }
}
