import { makeStyles } from "@material-ui/core";
import { appColors } from "../../../../constants";

export const useStyles = makeStyles((theme) => ({
  bgColorNavyBlue700: {
    backgroundColor: `${theme.palette.other.navyBlue700} !important`,
  },
  bgColorNavyBlue300: {
    backgroundColor: `${theme.palette.other.navyBlue300} !important`,
  },
  bigCardWrapper: {
    minWidth: "286px",
  },
  bigCardContainer: {
    flexWrap: "nowrap",
    overflowX: "auto",
  },
  headerText: {
    color: theme.palette.other.black500,
    fontSize: "24px",
    lineHeight: "29px",
  },
  rewardCard: {
    width: "100%",
    borderRadius: "16px",
  },
  rewardCardContent: {
    width: "100%",
    padding: "3.5rem",
    "& > img": { width: "100%", height: "260px", margin: "0 auto" },
    "& > h6": { fontWeight: "700", fontSize: "18px", lineHeight: "21px" },
    "& > span": { fontWeight: "400", fontSize: "16px", lineHeight: "22px" },
    "& button": {
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "17px",
      border: `1.5px solid ${theme.palette.other.primary500}`,
      borderRadius: "200px",
      width: "147px",
      height: "40px",
    },
  },
  chip: {
    background: theme.palette.other.orange500,
    borderRadius: "80px",
    height: "22px",
    width: "73px",
    "& span": {
      fontSize: "11px",
      color: theme.palette.other.white,
    },
  },
  quickLinkContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "134px",
    pedding: "1rem",
  },
  quickLinkIcon: {
    background: theme.palette.other.primary100,
  },
  quickLinkLabel: {
    fontWeight: "700",
    fontSize: "11px",
    lineHeight: "13px",
    letterSpacing: "-0.05em",
  },
  dashboardCardBigContent: {
    width: "100%",
    height: "370px",
    ["& > img"]: {
      width: "100%",
      height: "100%",
      borderRadius: "16px",
    },
  },
  aLinkColor: {
    color: appColors.primary900
  }
}));
