import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    padding: "16px",
    background: '#F8E8E6',
  },
  rightContent: {
    alignSelf: "center",
    margin: "auto",
    marginLeft: "1rem",
  },
  img: {
    height: "24px",
    width: "24px",
  },
}));
