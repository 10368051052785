import { GoogleMap, Marker } from "@react-google-maps/api";
import { useAtmFilter, useAtmLocations, useDistanceDurationStorage, useElementPosition, useSelectedATM } from "../../store/atmhooks";
import ATMMarker from "./ATMMarker";
import ATMInfoWindowContent from "./ATMInfoWindowContent";
import { useRef } from "react";
import { render } from "react-dom";
import { useUpdateEffect } from "ahooks";

const mapContainer = {
  height: "100%",
  width: "100%"
}

const CurrentLocationMarker = ({ currentLocation }) => {
  const icon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: '#00B0FF',
    fillOpacity: 1,
    strokeColor: '#00B0FF',
    scale: 8,
    strokeWeight: 1,
  }
  return <Marker position={currentLocation} icon={icon} />
}

export default function Map() {
  const { currentLocation, changeLocation } = useAtmLocations()
  const { filtedLocations } = useAtmFilter()
  const infoWindowInstance = useRef(null)
  const googleMapContainer = useRef(null)
  const mapInstance = useRef(null)

  const { selectedATM, setSelectedATM } = useSelectedATM()
  const { distanceDurations } = useDistanceDurationStorage()
  const mapPosition = useElementPosition(googleMapContainer)

  const onLoad = (map) => {
    mapInstance.current = map
    infoWindowInstance.current = new window.google.maps.InfoWindow({
      content: '',
      maxWidth: 400,
      ariaLabel: 'ATM Info Window',
      pixelOffset: new window.google.maps.Size(0, -30)
    })
  }

  const updateCurrentLocation = () => {
    const center = mapInstance.current.getCenter()
    console.info("Update current location", center)
    changeLocation({ lat: center.lat(), lng: center.lng() })
  }

  useUpdateEffect(() => {
    if (selectedATM) {
      onMarkerClick(null, selectedATM)
    }
  }, [selectedATM])

  const closeInfoWindow = () => {
    infoWindowInstance.current && infoWindowInstance.current.close();
  }

  const onMarkerClick = (_, atmLocation) => {
    console.info("onMarkerClick", atmLocation)
    const windowContentInfoElement = document.createElement('div');
    const distanceDuration = distanceDurations[atmLocation.terminal_id] || {}
    const onRenderSuccess = () => {
      infoWindowInstance.current.setContent(windowContentInfoElement);
      const { lat, lon: lng } = atmLocation?.location || {}
      infoWindowInstance.current.setPosition({ lat, lng });
      infoWindowInstance.current.open(mapInstance.current);
      setSelectedATM(atmLocation)
    }
    if (windowContentInfoElement) {
      render(<ATMInfoWindowContent onClose={closeInfoWindow} atmLocation={atmLocation} {...distanceDuration} currentLocation={currentLocation}/>, windowContentInfoElement, onRenderSuccess)
    }
  }


  // return <ATMInfoWindowContent />
  return <div ref={googleMapContainer} style={{
    width: '100%',
    height: `calc(100vh - ${mapPosition?.y + 80}px)`
  }}><GoogleMap
    id='map'
    zoom={12}
    center={currentLocation}
    draggable={true}
    drawingMode="Point"
    mapContainerStyle={mapContainer}
    onDragEnd={updateCurrentLocation}
    onLoad={onLoad}
    options={{
      mapTypeControl: false,
      fullscreenControl: false
    }}
  >
      <CurrentLocationMarker currentLocation={currentLocation} />
      {filtedLocations.map((atm) => <ATMMarker atm={atm} key={atm.terminal_id} onClick={onMarkerClick} />)}
    </GoogleMap>
  </div>
}