import { HIDE_TOAST, SHOW_TOAST } from "../../redux/actionTypes";

const initialState = {
  open: undefined,
  message: undefined,
  type: undefined,
};

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST:
      return Object.assign({}, state, {
        ...action.data,
      });
    case HIDE_TOAST:
      return Object.assign({}, state, {
        open: undefined
      });
    default:
      return state;
  }
}
