import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import {
  FORGOT_PASSWORD_STEPS,
  logoImage,
  loginLeftImg,
  arrowLeftIcon,
} from "../../../../constants";
import { useStyles } from "./style";
import { useAppStyles } from "../../../../App.style";
import { PageHeader } from "../../../../common/components/PageHeader";
import { IconButton } from "../../../../common/components/core";
import { ResendOTPPopUp } from "../ResendOTPPopUp";
import EnterEmail from "./EnterEmail";
import EnterOTP from "./EnterOTP";
import EnterSecurityQuestion from "./EnterSecurityQuestion";
import EnterNewPassword from "./EnterNewPassword";

export const ForgotPassword = (props) => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const {
    forgotPasswordEmail,
    handleBack,
    screenSteps,
    newPassword,
    confirmPassword,
    resendOTP,
    filledOtp,
    handleResendOTPDialog,
    openResendOTPDialog,
  } = props;

  let isDisableSubmit = false;
  if (screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.otp] && !filledOtp) {
    isDisableSubmit = true;
  }

  if (
    screenSteps &&
    screenSteps[FORGOT_PASSWORD_STEPS.email] &&
    !forgotPasswordEmail
  ) {
    isDisableSubmit = true;
  }

  if (
    screenSteps &&
    screenSteps[FORGOT_PASSWORD_STEPS.newPassword] &&
    (!newPassword || !confirmPassword)
  ) {
    isDisableSubmit = true;
  }

  return (
    <Grid container={true} className={classes.root}>
      <Grid
        xs={12}
        sm={5}
        md={4}
        xl={4}
        item={true}
        className={classes.leftSection}
      >
        <img src={loginLeftImg} className={classes.leftImg} alt="AG Wallet" />
      </Grid>
      <Grid
        xs={12}
        sm={7}
        md={8}
        xl={8}
        item={true}
        className={classes.rightSectionWrapper}
      >
        <div>
          <IconButton
            color="primary"
            aria-label="Back to login"
            className={classes.backIconBtn}
            onClick={handleBack}
          >
            <img src={arrowLeftIcon} alt="back icon" />
          </IconButton>
        </div>
        <div className={`${classes.rightSection} ${classes.rightSectionWd}`}>
          <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
            <img src={logoImage} className={classes.logoImg} alt="Logo Name" />
          </Grid>
          <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
            <PageHeader text={"Forgot Password"} />
          </Grid>

          {screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.email] && (<EnterEmail {...props} isDisableSubmit={isDisableSubmit} />)}
          {screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.otp] && (<EnterOTP {...props} isDisableSubmit={isDisableSubmit} />)}
          {screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.questions] && (<EnterSecurityQuestion {...props} isDisableSubmit={isDisableSubmit} />)}
          {screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.newPassword] && (<EnterNewPassword {...props} isDisableSubmit={isDisableSubmit} />)}
        </div>
      </Grid>


      {openResendOTPDialog && (
        <ResendOTPPopUp
          redirectViaLogin={false}
          handleConfirm={handleResendOTPDialog}
          resendCode={resendOTP}
          resendOTPCode={props.resendEmailOTP}
        ></ResendOTPPopUp>
      )}
    </Grid>
  );
};
