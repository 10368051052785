import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon, warningIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";

export const AccountLockedPopup = (props) => {
  const { handleConfirm, handleForgotPassowrd } = props;

  const appClasses = useAppStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleConfirm(false)}
          >
            <img src={closeBlackIcon} alt="close"/>
          </IconButton>
        </div>
      </DialogTitle>
      <Box mt={3} />
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <IconButton className={appClasses.dialogCloseBtn}>
          <img src={warningIcon} alt="Warning"/>
        </IconButton>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text3}>
          Account Locked
        </Typography>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text1}>
          For security reasons, your account has been locked. Please reset your password to continue.
        </Typography>
        <Box mt={5}></Box>
        <AppButton
          contained
          onClick={() => handleForgotPassowrd(false)}
          className={`${appClasses.primaryBtn} ${appClasses.primarycapsuleBtn} ${appClasses.width100p}`}
          primary
        >
          Reset Password
        </AppButton>
        <Box mt={5}></Box>
      </DialogContent>
    </Dialog>
  );
};
