import { makeStyles } from "@material-ui/core";

export const useAppTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
    overflowX: "scroll",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  cell: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "12px",
    color: theme.palette.other.black700,
    height: "3rem",
  },
  headerCell: {
    height: "3rem",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.other.black500,
  },
  greyCell: {
    height: "2.5rem",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.other.black500,
    background: theme.palette.other.grey100,
  },
  paginationLabel: {
    marginRight: "1rem",
  },
  pageNoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  paginationWrapper: {
    justifyContent: "flex-end",
  },
  paddingLeft: {
    paddingLeft: "1.1rem !important",
  },
  paddingRight: {
    paddingRight: "1.1rem !important",
  },
}));
