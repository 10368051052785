import { makeStyles, lighten } from "@material-ui/core";
import "./assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf";

export const appAnimations = {
  /*
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(1rem)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(355deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes fadeInX": {
    "0%": {
      opacity: 0,
      transform: "translateX(-1rem)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  animationFadeIn: {
    animation: "$fadeIn .3s ease-in-out",
  },
  animationFadeInX: {
    animation: "$fadeInX .3s ease-in-out",
  },
  animationSpin: {
    animation: "$spin .5s ease-in-out",
  },
  */
  animationFadeIn: {
    animation: "fadeIn .3s ease-in-out",
  },
  animationFadeInX: {
    animation: "fadeInX .3s ease-in-out",
  },
  animationSpin: {
    animation: "spin .5s ease-in-out",
  },
};

export const useAppStyles = makeStyles((theme) => ({
  textFieldWrapper: {
    display: "flex",
    padding: "0.6rem 0rem",
    flexWrap: "wrap",
    ...theme.appAnimations?.animationFadeIn,
  },
  textFieldError: {
    color: theme.palette.other.error500,
    fontWeight: 700,
  },
  card: {
    backgroundColor: theme.palette.other?.white,
    padding: "0.5rem",
    [theme.breakpoints.up("sm")]: {
      padding: "1.5rem 2.5rem",
    },
    ...theme.appAnimations?.animationFadeIn,
  },
  paperCard: {
    width: "100%",
    borderRadius: "16px",
    background: theme.palette.other?.white,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1);",
    ...theme.appAnimations?.animationFadeIn,
  },
  centerHvWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  width100p: {
    width: "100% !important",
  },
  vAlignMiddle: {
    verticalAlign: "middle !important",
  },
  vAlignTxtBtm: {
    verticalAlign: "text-bottom !important",
  },

  /*dialog css*/
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& button": {
      position: "absolute",
      left: 0,
    },
  },
  dialogText: { fontWeight: "700", fontSize: "18px", lineHeight: "21px" },
  dialogCloseBtn: {},
  dialogContent: {
    padding: "18px 48px",
    [theme.breakpoints.down("sm")]: {
      padding: "18px",
    }
  },
  dialogContent1: {
    padding: "18px 80px",
  },
  dialogFooter: {
    padding: "8px 40px",
  },
  dialogFooter1: {
    padding: "8px 15px",
  },
  /**/

  /*btn css*/
  primaryBtn: {
    height: "3rem",
    background: theme.palette.other?.primary500,
    color: theme.palette.other?.black,
    borderRadius: "4px",
    textTransform: "none",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",

    "&:hover": {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${theme.palette.other?.primary500}`,
    },
  },
  primaryOutlineBtn: {
    width: "auto !important",
    height: "3rem",
    border: `${theme.palette.other?.primary500} .1rem solid`,
    color: theme.palette.other?.black,
    borderRadius: "4px",
    textTransform: "none",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",
    padding: "1rem",

    "&:hover": {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${theme.palette.other?.primary500}`,
    },
  },
  cardActiveCapsuleBtn: {
    borderRadius: "24px",
    background: theme.palette.other?.black500,
    width: "320px",
    height: "51px",
    textTransform: "none",
  },
  primarycapsuleBtn: {
    borderRadius: "24px",
    width: "100px",
    textTransform: "none",
  },
  capsuleBtn: {
    borderRadius: "24px",
    background: theme.palette.other?.black500,
    width: "100px",
    textTransform: "none",
  },
  filterBtn: {
    width: "100px",
    height: "2.1rem",
    border: `${theme.palette.other?.primary900} .1rem solid`,
    color: theme.palette.other?.black700,
    background: theme.palette.other?.white,
    borderRadius: "4px",
    textTransform: "none",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",

    "&:hover": {
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${theme.palette.other?.primary500}`,
    },
  },
  filterBtnPos: {
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "1.5rem",
  },
  textBtn: {
    color: theme.palette.other?.black700,
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "none",
  },
  aLink: {
    color: theme.palette.other?.primary500,
    cursor: "pointer",
    textDecoration: "underline",
  },
  aLinkBlack: {
    color: theme.palette.black700,
    cursor: "pointer",
    textDecoration: "underline",
  },
  bottomText: {
    fontWeight: "510",
    fontSize: "8.5px",
    lineHeight: "10px",
    color: theme.palette.other.black300New,
  },
  /**/

  /*Help Footer*/
  openSidemenu: {
    width: "calc(100% - 730px)",
    maxWidth: "477px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  /**/

  /*App Text*/
  text1: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400",
  },
  text2: {
    fontSize: "48px",
    lineHeight: "57px",
    fontWeight: "700",
    color: theme.palette.other.black900,
  },
  text3: {
    color: theme.palette.other.black700,
    fontSize: "24px",
    lineHeight: "29px",
    fontWeight: "700",
  },
  text4: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "22px",
    color: theme.palette.other.black700,
  },
  text5: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "700",
  },
  text6: {
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: "400",
  },
  text7: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
  },
  text8: {
    color: theme.palette.other.black500,
    fontSize: "24px",
    lineHeight: "29px",
    fontWeight: "400",
  },
  text9: {
    color: theme.palette.other.black500,
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "400",
  },
  text10: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17px",
  },
  text11: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "17px",
  },
  text12: {
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "48px",
  },
  text13: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.palette.other.black100,
  },
  /**/

  /*color classes*/
  colorprimary500: {
    color: theme.palette.other?.primary500,
  },
  colorprimary900: {
    color: theme.palette.other?.primary900,
  },
  colorSuccess500: {
    color: theme.palette.other.success500,
  },
  colorBlack500: {
    color: theme.palette.other.black500,
  },
  colorBlack700: {
    color: theme.palette.other.black700,
  },
  colorBlack100: {
    color: theme.palette.other.black100,
  },
  bgGrey100: {
    background: theme.palette.other.grey100,
  },
  bgPrimary100: {
    background: theme.palette.other.primary100,
  },
  bgBlack700: {
    background: theme.palette.other.black700,
  },
  /**/

  /*chip*/
  chipGreen: {
    background: theme.palette.other.success100,
    color: theme.palette.other.success500,
    fontWeight: 700,
  },
  chipRed: {
    background: theme.palette.other.error100,
    color: theme.palette.other.error500,
    fontWeight: 700,
  },

  /*dropdown menu*/
  dropdownMenu: {
    borderRadius: "16px",
    background: theme.palette.other?.white,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1);",
    padding: "16px",
  },

  dropDownMI: {
    "&:hover": {
      backgroundColor: theme.palette.other?.primary300,
    },
  },
  /**/

  dividerWithTextWrapper: {
    width: "10rem",
    margin: "auto",
    marginBottom: "-2rem",
    ...theme.appAnimations?.animationFadeIn,
  },
  labelText: {
    fontSize: "1rem",
  },
  primaryButton: {
    textTransform: "none",
    background: "#1AD6D6",
    color: "#262626",
    fontSize: "16px",
    fontWeight: "700",
    "&:hover": {
      background: "#1AD6D6",
      color: "#262626",
    },
  },
  submitButton: {
    margin: "auto !important",
    minWidth: "8rem",
    width: "100%",
    textTransform: "none",
  },
  registrationButton: {
    padding: "16px 32px",
    borderRadius: "200px",
  },
  highlightText: {
    backgroundColor: theme.palette.other?.highlight,
    color: theme.palette.other?.white,
  },
  box: {
    [theme.breakpoints.up("sm")]: {
      padding: "0rem 2rem",
    },
    padding: "0rem 0.5rem",
    border: `${theme.palette.other?.grey} .2rem solid`,
  },
  overflowY_Hidden: {
    overflowY: "hidden !important",
  },
  iconAfterTextField: {
    height: "3rem",
    paddingBottom: 0,
  },
  infoIcon: {
    width: "18px",
    height: "18px",
    verticalAlign: "bottom",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  br200: {
    borderRadius: "200px !important",
  },
  ttCapital: {
    textTransform: "capitalize",
  },
}));

export const useAppListStyles = makeStyles((theme) => ({
  listContainer: {
    width: "100%",
    overflowX: "auto",
  },
  root: {
    width: "100%",
    margin: "0px",
  },
  paper: {
    width: "100%",
    ...theme.appAnimations?.animationFadeIn,
  },
  tabs: {
    height: "50px",
    width: "100%",

    "& button": {
      textTransform: "none",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "19px",
      color: theme.palette.other.blackNew300,
    },
  },
  tabContent: { width: "100%", height: "75%" },
  divider: {
    background: theme.palette.other.black100,
  },
  cellWithBtn: {
    "& > span": {
      verticalAlign: "text-bottom",
    },
    "& > button": {
      padding: "4px",
    },
  },
}));

export const useAppListToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
