import { Grid, Typography, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { AppListItemCard } from "../../../../common/components/AppListItemCard";
import { SidemenuViewContent } from "../../../../common/components/SidemenuViewContent";
import {
  mailGreenIcon,
  mapPinGreenIcon,
  pencilBlackIcon,
  phoneGreenIcon,
  resAddressGreenIcon,
} from "../../../../constants";
import { ProfileScreenSidemenuTypes } from "../../containers/ProfileHomePage";
import EditMobilePage from "../../containers/EditMobilePage";
import EditEmailPage from "../../containers/EditEmailPage";
import EditResAddressPage from "../../containers/EditResAddressPage";
import { PasswordDialog } from "../../../auth/components/PasswordDialog";
import { toMaskEmail, toMobileNumber } from "../../../../utilities";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import useMobileScreenCollection from "../../../../hooks/userMobileScreenCollection";



const AddressFormat = ({ address }) => {
  if (!address) return null;
  //because shipping address has different keys
  const { address_line1, address_line_1, address_line_2, address_line2, city = '', postal_code, region } = address;
  const addressLine = [address_line1 || address_line_1, address_line2 || address_line_2].filter((a) => a).join(", ");
  const postalCodeAndRegion = [postal_code, region].filter((a) => a).join(", ");
  return <>
    {addressLine} <br />
    {city}, <br />
    {postalCodeAndRegion}
  </>
}
export const ProfileHome = (props) => {
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const mobileScreenCollection = useMobileScreenCollection()


  const {
    userData,
    userAddresses,
    userShippingAddress,

    openSidemenuType,
    handleCloseSidemenu,
    handleOpenSidemenu,

    userAuthData,

    passwordDialodHandleClose,
    passwordDialodHandleSubmit,
  } = props;
  const [editMobileHelpScreen, setEditMobileHelpScreen] = React.useState('SettingsEditPhone');
  const [editEmailHelpScreen, setEditEmailHelpScreen] = React.useState('SettingsEditEmail');


  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div>
            <Typography variant="h4" className={appClasses.text8}>
              Profile
            </Typography>
          </div>
          <Box mt={4}></Box>
          <div>
            <Typography variant="h4" className={appClasses.text3}>
              {`${userData?.first_name || ""} ${userData?.last_name || ""}`}
            </Typography>
          </div>
          <Box mt={1}></Box>
          <div>
            <AppListItemCard
              icon={phoneGreenIcon}
              rightIcon={pencilBlackIcon}
              title={"Mobile Number"}
              desc={toMobileNumber(userData?.phone, true)}
              active={openSidemenuType == ProfileScreenSidemenuTypes.MOBILE}
              onClickRightIcon={() =>
                handleOpenSidemenu(ProfileScreenSidemenuTypes.MOBILE)
              }
            ></AppListItemCard>
            <Box mt={2}></Box>
            <AppListItemCard
              icon={mailGreenIcon}
              rightIcon={pencilBlackIcon}
              title={"Email"}
              desc={toMaskEmail(userData?.email)}
              active={openSidemenuType === ProfileScreenSidemenuTypes.EMAIL}
              onClickRightIcon={() =>
                handleOpenSidemenu(ProfileScreenSidemenuTypes.EMAIL)
              }
            ></AppListItemCard>
            <Box mt={2}></Box>
            <AppListItemCard
              icon={resAddressGreenIcon}
              rightIcon={pencilBlackIcon}
              title={"Residential Address"}
              desc={<AddressFormat address={userAddresses} />}
              active={
                openSidemenuType === ProfileScreenSidemenuTypes.RES_ADDRESS
              }
              onClickRightIcon={() =>
                handleOpenSidemenu(ProfileScreenSidemenuTypes.RES_ADDRESS)
              }
            ></AppListItemCard>
            <Box mt={2}></Box>
            <AppListItemCard
              icon={mapPinGreenIcon}
              rightIcon={pencilBlackIcon}
              title={"Shipping Address"}
              desc={<AddressFormat address={userShippingAddress} />}
              active={
                openSidemenuType === ProfileScreenSidemenuTypes.SHIP_ADDRESS
              }
              onClickRightIcon={() =>
                handleOpenSidemenu(ProfileScreenSidemenuTypes.SHIP_ADDRESS)
              }
            ></AppListItemCard>
            <Box mt={2}></Box>
          </div>
        </Grid>
      </Grid>

      <SidemenuViewContent
        open={openSidemenuType == ProfileScreenSidemenuTypes.MOBILE}
        onClose={handleCloseSidemenu}
        headerComponent={
          <Box  style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName={editMobileHelpScreen} />
          </Box>
        }
      >
        <EditMobilePage data={userAuthData} onClose={handleCloseSidemenu} onChangeHelpContent={setEditMobileHelpScreen}/>
      </SidemenuViewContent>

      <SidemenuViewContent
        open={openSidemenuType === ProfileScreenSidemenuTypes.EMAIL}
        onClose={handleCloseSidemenu}
        headerComponent={
          <Box style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName={editEmailHelpScreen}/>
          </Box>
        }
      >
        <EditEmailPage data={userAuthData} onClose={handleCloseSidemenu} onChangeHelpContent={setEditEmailHelpScreen}/>
      </SidemenuViewContent>

      <SidemenuViewContent
        open={openSidemenuType === ProfileScreenSidemenuTypes.RES_ADDRESS}
        onClose={handleCloseSidemenu}
        headerComponent={
          <Box style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName="SettingsEditResidentialAddress" />
          </Box>
        }
      >
        <EditResAddressPage
          userAddresses={userAddresses}
          userAuthData={userAuthData}
          onClose={handleCloseSidemenu}
          isResidentialAddress
        />
      </SidemenuViewContent>

      <SidemenuViewContent
        open={openSidemenuType === ProfileScreenSidemenuTypes.SHIP_ADDRESS}
        onClose={handleCloseSidemenu}
        headerComponent={
          <Box style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName="SettingsEditShippingAddress" />
          </Box>
        }
      >
        <EditResAddressPage
          userAddresses={userShippingAddress}
          userAuthData={userAuthData}
          onClose={handleCloseSidemenu}
          isShipping
        />
      </SidemenuViewContent>

      {openSidemenuType === ProfileScreenSidemenuTypes.VERIFY_PASSWORD && (
        <PasswordDialog
          handleClose={passwordDialodHandleClose}
          handleSubmit={passwordDialodHandleSubmit}
        ></PasswordDialog>
      )}
    </div>
  );
};
