import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useStyles } from "./style";
import { useAppStyles } from "../../../../App.style";

function DirectDepositDetails({details}){
  const appClasses = useAppStyles();
  const classes = useStyles();
  return <>
     <Typography
              variant="label"
              className={`${appClasses.text6} ${appClasses.colorBlack500}`}
            >
              Routing Number
            </Typography>
            <Typography variant="h6" className={appClasses.text7}>
              {details?.routing_number || "NA"}
            </Typography>

            <Box mt={3} />
            <Typography
              variant="label"
              className={`${appClasses.text6} ${appClasses.colorBlack500}`}
            >
              Account Number
            </Typography>
            <Typography variant="h6" className={appClasses.text7}>
              {details?.dda_number || "NA"}
            </Typography>

            <Box mt={3} />
            <Typography
              variant="label"
              className={`${appClasses.text6} ${appClasses.colorBlack500}`}
            >
              Account Type
            </Typography>
            <Typography variant="h6" className={appClasses.text7}>
              Checking
            </Typography>

            <Box mt={3} />
            <Typography
              variant="label"
              className={`${appClasses.text6} ${appClasses.colorBlack500}`}
            >
              Bank Name
            </Typography>
            <Typography variant="h6" className={appClasses.text7}>Stride</Typography>
          </>
}


export function DirectDeposit({ details, isGotResponse }) {
  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <>
    <Grid xs={12} item className={classes.root}>
      <Box mt={1} />
      <Box mt={1} />
      <div>
       <Typography variant="h6" className={appClasses.text3}>
          Direct Deposit
        </Typography>
        <Typography variant="span" className={appClasses.text1}>
          Use the details below when setting up direct deposit payments for any
          purpose, such as salaries and wages, payment of bills, taxes, and
          other government charges.
        </Typography>
      </div>
      <Box mt={3} />
      <Box mt={3} />
      {isGotResponse && <DirectDepositDetails details={details} />}
      </Grid>
    </>
  );
}

export function DirectDepositWithDescription({ details, isGotResponse }) {

  const classes = useStyles();
  return (
    <>
      <Grid xs={12} item className={classes.root}>
      {isGotResponse && <DirectDepositDetails details={details} />}
      </Grid>
    </>
  );
}
