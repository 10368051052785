import {
  Grid,
  Typography,
  Box,
  Paper,
  styled
} from "@material-ui/core";
import React from "react";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import {
  arrowLeftIcon, routes, infoIcon, VISA_SAVING_EDGE_URL
} from "../../../../constants";
import { IconButton } from "../../../../common/components/core";
import history from "../../../../history";
import { DoshHelpPopup } from "../HelpPopup";

const ListOfferWrapper = styled('div')( () => ({
  minHeight: 'calc(100vh - 300px)'
}))

export const DoshOffers = (props) => {
  const {
    handleHelpPopup,
    isShowHelpPopup,
    items
  } = props;
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();

  const listCardHeight = `${window.innerHeight - 100}px`;

  return (
    <div className={appListClasses.root} data-testid="dosh-offer-container">
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div className={classes.backBtnContainer}>
            <IconButton color="primary" aria-label="Back to login" onClick={() => { history.push(routes.rewards.path) }} className={classes.backIconBtn}>
              <img src={arrowLeftIcon} alt="left arrow" />
            </IconButton>
            <Typography
              variant="h6"
              className={`${appClasses.text10} ${classes.backBtnText}`}
            >
              Back to Rewards
            </Typography>
          </div>
          <div className={classes.titleContainer}>
            <Typography
              variant="h6"
              className={`${appClasses.text12}`}
            >
              Save Money. Save Time.
            </Typography>
            <Typography
              variant="h6"
              className={`${appClasses.text1} ${appClasses.colorBlack500}`}
            >
              Get details on these offers and more by visiting <a onClick={() => { window.open(VISA_SAVING_EDGE_URL) }} className={appClasses.aLink}>Visa SavingsEdge.</a>
            </Typography>
          </div>
          <Box mt={2}></Box>
          <Paper className={`${appClasses.paperCard}`}>
            <ListOfferWrapper
              className={`${appListClasses.listContainer}`}

            >
              <Grid className={classes.iframe}>
                {
                  items && Array.isArray(items) && items.length > 0 && items.map((img, index) => (

                    <Grid className={classes.imgBox}>
                      <img src={img.url} className={classes.img} alt="offers" />
                    </Grid>

                  ))
                }
              </Grid>
              <Typography
                variant="h6"
                className={`${appClasses.text1} ${appClasses.colorBlack500}`}
              >
                <div className={classes.backBtnContainer}>
                  <IconButton color="primary" aria-label="Back to login" onClick={() => { history.push(routes.rewards.path) }} className={classes.backIconBtn}>
                    <img src={infoIcon} />
                  </IconButton>
                  <Typography
                    variant="h6"
                    className={`${appClasses.text10} ${classes.backBtnText}`}
                  >
                    Have questions about Visa SavingsEdge? <a onClick={() => { handleHelpPopup() }} className={appClasses.aLink}>Click Here</a>
                  </Typography>
                </div>
              </Typography>
            </ListOfferWrapper>
          </Paper>
          <div className={classes.goalFooter}>
            <Typography
              variant="h6"
              className={`${appClasses.text9} ${classes.backBtnText}`}
            >
              *Rewards subject to eligibility and offers may change without notice
            </Typography>
          </div>
        </Grid>
      </Grid>

      {
        isShowHelpPopup && <DoshHelpPopup handleConfirm={handleHelpPopup} />
      }
    </div>
  );
};
