import React from "react";

export const ReplaceCardContext = React.createContext();

export default function useReplaceCard() {
  const context = React.useContext(ReplaceCardContext);
  if (context === undefined) {
    throw new Error("useReplaceCard must be used within a ReplaceCardProvider");
  }
  return context;
}
