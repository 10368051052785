import { SET_NOTIFICATION_SETTINGS_DATA } from "../../../redux/actionTypes";

const initState = {
  notificationSettings: null,
};

export default function settingsReducer(state = initState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_SETTINGS_DATA:
      return Object.assign({}, state, {
        notificationSettings: action.data,
      });

    default:
      return state;
  }
}
