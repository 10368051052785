import {
  Grid
} from "@material-ui/core";
import React, { useState } from "react";

import { ActivateCardSuccessDialog } from "./ActivateCardSuccessDialog";
import { useStyles } from "./style";
import { Intro } from "./steps/Intro";
import { CardInfoForm } from "./steps/CardInfoForm";
import { SetPin } from "./steps/SetPin";
import { setActivateCardCongratulationPopup } from "../../actions";
import { useDispatch } from "react-redux";

const ACTIVATE_CARD_STEPS = {
  init: "init",
  filling: "filling",
  initSetPin: "initSetPin",
  setPin: "setPin",
  success: "success",
}

const STEP_COMPONENT_MAPPERS = {
  [ACTIVATE_CARD_STEPS.init]: Intro,
  [ACTIVATE_CARD_STEPS.filling]: CardInfoForm,
  [ACTIVATE_CARD_STEPS.setPin]: SetPin,
}


const STEPS_ORDER = [
  ACTIVATE_CARD_STEPS.init,
  ACTIVATE_CARD_STEPS.filling,
  ACTIVATE_CARD_STEPS.setPin,
]

const HelpScreenByStep = {
  [ACTIVATE_CARD_STEPS.init]: 'ActivateCardFirstScreen',
  [ACTIVATE_CARD_STEPS.filling]: 'ActivateCardEnterManually',
  [ACTIVATE_CARD_STEPS.setPin]: 'ActivateCardCreatePIN',
}

export const ActivateCard = (props) => {
  const classes = useStyles();
  const [step, setStep] = React.useState(STEPS_ORDER[0]);
  const dispatch = useDispatch()
  const {
    handleClose,
    changeHelpScreenName
  } = props;

  const nextStep = () => {
    const currentIndex = STEPS_ORDER.indexOf(step);
    const nextIndex = currentIndex + 1;
    setStep(STEPS_ORDER[nextIndex]);
    changeHelpScreenName(HelpScreenByStep[STEPS_ORDER[nextIndex]]);
  }

  // This function is called after the user has successfully activated the card with the new PIN.
  // It is called from the SetPin component.
  const handleCloseActivateCard = () => {
    props.handleCloseActivateCard(true);
    dispatch(setActivateCardCongratulationPopup(true));
    handleClose();
  }

  const StepComponent = STEP_COMPONENT_MAPPERS[step];

  return (
    <Grid className={classes.root}>
      <StepComponent {...props}
        nextStep={nextStep}
        handleCloseActivateCard={handleCloseActivateCard}
      />
    </Grid>
  );
};
