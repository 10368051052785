import {
  SET_PENDING_TRANSACTIONS,
  SET_TRANSACTIONS,
} from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import { getTransactions } from "../services";

export const setSuccessTransactions = (response) => {
  return {
    type: SET_TRANSACTIONS,
    data: response,
  };
};

export const setPendingTransactions = (response) => {
  return {
    type: SET_PENDING_TRANSACTIONS,
    data: response,
  };
};

export const doGetAndSetTransactionList = (data) => (dispatch) => {
  return apiCall(dispatch, getTransactions(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (dashboardCardsResponse) => {
      if (dashboardCardsResponse?.data?.status == "success") {
        let successTransaction = [];
        let pendingTransaction = [];
        if(dashboardCardsResponse?.data?.data?.meta?.has_transactions){
          Array.isArray(dashboardCardsResponse?.data?.data?.transactions) && dashboardCardsResponse?.data?.data?.transactions.map((transaction) => {
            let modifiedTransactionData = {
              date: transaction.transaction_date,
              desc: transaction.description,
              amount: transaction.amount,
              category: transaction.category,
            }
            if(transaction.status == "Posted"){
              successTransaction.push(modifiedTransactionData);
            }
            else {
              pendingTransaction.push(modifiedTransactionData);
            }
          }) 
        }
        dispatch(setSuccessTransactions(successTransaction));
        dispatch(setPendingTransactions(pendingTransaction));
      }
    },
    (error) => {
      dispatch(setSuccessTransactions([]));
      dispatch(setPendingTransactions([]));
    }
  );
};
