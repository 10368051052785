import React from "react";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, DialogTitle, Grid, Typography } from "@material-ui/core";
import { AppTextField } from "../../../../common/components/AppTextField";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";

import { EditMobilePageSteps } from "../../containers/EditMobilePage";
import { toMobileNumber } from "../../../../utilities";
import { Link } from "react-router-dom";

export const EditMobile = (props) => {
  const {
    mobileNumber,
    mobileOtp,
    emailOtp,
    errors,
    step,

    handleChange,

    handleSubmit,
    handleDontReceiveCodeOnClickHandler,
  } = props;

  const appClasses = useAppStyles();

  const fillMobileStep = step == EditMobilePageSteps.fillMobile;
  const mobileOtpStep = step == EditMobilePageSteps.mobileOtp;
  const emailOtpStep = step == EditMobilePageSteps.emailOtp;

  const displayDontReceiveCode = mobileOtpStep || emailOtpStep;
  const submitBtnText = fillMobileStep ? "Send Code" : "Continue";

  const isDisabledSubmit =
    (fillMobileStep && !mobileNumber) ||
    (mobileOtpStep && !mobileOtp) ||
    (emailOtpStep && !emailOtp);

  const content = () => {
    return (
      <>
        <DialogTitle id="form-dialog-title">
          <div className={appClasses.dialogTitle}>
            <Typography variant="h1" className={appClasses.text3}>
              Edit Mobile Number
            </Typography>
          </div>
          <div className={appClasses.dialogTitle}>
            {fillMobileStep && (
              <Typography variant="span" className={appClasses.text1}>
                Enter your new mobile number and we’ll text you a code to
                verify.
              </Typography>
            )}
            {mobileOtpStep && (
              <Typography variant="span" className={appClasses.text1}>
                We sent a verification code to{" "}
                {toMobileNumber(mobileNumber, true)}. Please enter it below.
              </Typography>
            )}
            {emailOtpStep && (
              <Typography variant="span" className={appClasses.text1}>
                We sent a verification code to the email you use with your
                Artist Growth Wallet account. Please enter it below.
              </Typography>
            )}
          </div>
        </DialogTitle>
        <DialogContent className={appClasses.dialogContent}>
          {fillMobileStep && (
            <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
              <AppTextField
                name={"mobileNumber"}
                label="Mobile Number"
                value={mobileNumber}
                onChange={handleChange}
                errorText={errors?.mobileNumber}
                inputProps={{
                  maxLength: 10,
                }}
                placeholder="000-000-0000"
              ></AppTextField>
            </Grid>
          )}
          {mobileOtpStep && (
            <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
              <AppTextField
                name={"mobileOtp"}
                label="Enter the Code"
                value={mobileOtp}
                onChange={handleChange}
                errorText={errors?.mobileOtp}
              ></AppTextField>
            </Grid>
          )}
          {emailOtpStep && (
            <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
              <AppTextField
                name={"emailOtp"}
                label="Enter the Code"
                value={emailOtp}
                onChange={handleChange}
                errorText={errors?.emailOtp}
              ></AppTextField>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <div className={`${appClasses.dialogFooter} ${appClasses.width100p}`}>
            <AppButton
              contained
              onClick={handleSubmit}
              className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
              disabled={isDisabledSubmit}
              primary
            >
              {submitBtnText}
            </AppButton>
            {displayDontReceiveCode && (
              <>
                <Box mt={3}></Box>
                <Typography
                  variant="h4"
                  className={`${appClasses.text4} ${appClasses.textAlignCenter}`}
                >
                  <Link
                    className={`${appClasses.aLink} ${appClasses.colorBlack500}`}
                    onClick={handleDontReceiveCodeOnClickHandler}
                  >
                    Didn’t receive a code?
                  </Link>
                </Typography>
              </>
            )}
          </div>
        </DialogActions>
      </>
    );
  };

  return <Grid>{content()}</Grid>;
};
