import { Box, Chip, styled } from '@material-ui/core'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import React from 'react'
import { ATMFilterOptions, useAtmFilter } from '../../store/atmhooks';

const Root = styled(Box)(({ theme, isSelecting }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  justifyContent: isSelecting ? 'center' : 'flex-start',
  background: isSelecting ? 'inherit' : '#E7E7E7',
  padding: theme.spacing(1.5, 3),
  borderTop: 'none',
}))

const FilterItem = styled('div')(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: '#262626',
  border: '1px solid #018787',
  borderRadius: '200px',
  fontWeight: 400,
  fontSize: '14px'
}))

const SelectedItem = styled(Chip)(({ theme }) => ({
  backgroundColor: "#1AD6D6",
  color: '#262626',
}))


const FilterOption = ({ filterOption, toggleFilter, toggleSelectingFilter }) => {
  const onClickHandler = () => {
    toggleFilter(filterOption.id)
    toggleSelectingFilter()
  }
  return <FilterItem onClick={onClickHandler}>{filterOption.label}</FilterItem>

}

const SelectedOption = ({ filterOption, deleteFilter }) => {
  const handleDelete = () => {
    deleteFilter(filterOption.id)
  }
  return <SelectedItem label={filterOption.selectedLabel} color='success'
    deleteIcon={<CloseOutlinedIcon fontSize='11px' />} onDelete={handleDelete} />
}

export default function ATMFilterElements() {
  const { isSelectingFilter, selectedFilters, deleteFilter, toggleFilter, toggleSelectingFilter } = useAtmFilter()

  if (!isSelectingFilter && selectedFilters.length <= 0) return null

  return <Root isSelecting={isSelectingFilter}>
    {
      ATMFilterOptions.map((option, index) => {
        const isSelectedFilter = selectedFilters.includes(option.id)
        const FilterComponent = isSelectedFilter ? SelectedOption : FilterOption
        return <FilterComponent selected={isSelectedFilter} deleteFilter={deleteFilter}
          toggleSelectingFilter={toggleSelectingFilter}
          toggleFilter={toggleFilter} filterOption={option} key={option.id} />
      })
    }
  </Root>
}