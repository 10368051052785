import { Box, Card, CardContent, CardHeader, Grid, IconButton, Link, Typography, styled } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { atmFullAddress } from '../../utils';
import allPoint from '../../../../assets/images/allpoint_logo.png';

const WindowConteWrapper = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  position: 'relative',
  padding: theme.spacing(2, 1),
  boxSizing: 'border-box',
  maxHeight: 380,
  border: 'none',
  boxShadow: 'none',
}))

const CloseIconContainer = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(2.5, 2),
  "& .MuiCardHeader-title": {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '29px'
  }
}))

const AddressText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  color: '#555555'
}))

const WindowActions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 1.5, 0),
}))

const StyledDirectionButton = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: 700,
  textTransform: "none",
  backgroundColor: '#1AD6D6',
  color: '#262626',
  textDecoration: 'none',
  display: 'block',
  width: '100%',
  textAlign: 'center',
  "&:hover, &:active": {
    backgroundColor: '#1AD6D6',
  },
}))

const AddressGridRow = styled(Grid)(({ theme }) => ({
  paddingBottom: '8px'
}))

const DurationText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  lineHeight: '13px',
  fontWeight: 700,
  color: '#262626',
  textAlign: 'right'
}))


const DistanceText = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  lineHeight: '13px',
  color: '#262626',
  textAlign: 'right'
}))

const AtmFeeRoot = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}))

const FeeChip = styled('div')(({ theme, free }) => ({
  display: 'inline-block',
  background: free ? '#018787' : '#555555',
  borderRadius: '4px',
  padding: theme.spacing(1),
  color: '#fff',
  fontSize: '11px',
  lineHeight: '11px',
}))

const AtmFee = ({ fee }) => {
  const isFreeAtm = fee <= 0
  const feeText = isFreeAtm ? `Free ATM` : `Low Fee - $${fee} ATM Fee`
  return <AtmFeeRoot>
    <FeeChip free={isFreeAtm}>
      {feeText}
    </FeeChip>
  </AtmFeeRoot>
}

const AllPointIcon = () => {
  return <img src={allPoint} alt="Allpoint" height='100%' maxWidth="100%" style={{ marginLeft: '20px' }} />
}


export default function ATMInfoWindowContent({ atmLocation, onClose, distanceText, durationText, currentLocation }) {
  const { fee_amt, loc_name, postal_code } = atmLocation
  const fullAddress = atmFullAddress(atmLocation)
  const { lat, lon: lng } = atmLocation?.location || {}
  const directionLink = `https://www.google.com/maps/dir/?api=1&origin=${currentLocation.lat},${currentLocation.lng}&destination=${lat},${lng}`
  const closeHandler = () => {
    onClose && onClose()
  }

  // const handlerDirection = () => {
  //   console.info('handlerDirection')
  // }

  return <WindowConteWrapper>
    <CloseIconContainer onClick={closeHandler}>
      <CancelOutlinedIcon />
    </CloseIconContainer>
    <StyledCardHeader title={loc_name} />
    <CardContent>
      <Grid container direction="row" justifyContent='space-between'>
        <AtmFee fee={fee_amt} />
        <AllPointIcon />
      </Grid>

      <AddressGridRow container alignItems='baseline'>
        <Grid item xs={9}>
          <AddressText variant='body1'>{fullAddress}</AddressText>
        </Grid>
        <Grid item xs={3}>
          <DurationText component='div'>{durationText}</DurationText>
        </Grid>
      </AddressGridRow>
      <AddressGridRow container alignItems='baseline'>
        <Grid item xs={9}>
          <AddressText variant='body1'>{postal_code}</AddressText>
        </Grid>
        <Grid item xs={3}>
          <DistanceText>{distanceText}</DistanceText>
        </Grid>
      </AddressGridRow>
      <WindowActions>
        <StyledDirectionButton variant='contained' fullWidth href={directionLink} target='_blank'>Get Directions</StyledDirectionButton>
      </WindowActions>
    </CardContent>
  </WindowConteWrapper>
}
