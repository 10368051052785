import { Grid, Typography, Box } from "@material-ui/core";
import React from "react";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { AppListItemCard } from "../../../../common/components/AppListItemCard";
import { SidemenuViewContent } from "../../../../common/components/SidemenuViewContent";
import { lockGreenIcon } from "../../../../constants";
import { SettingSidemenuTypes } from "../../containers/SettingHomePage";
import { PasswordDialog } from "../../../auth/components/PasswordDialog";
import ChangePasswordPage from "../../containers/ChangePasswordPage";
import { NotificationEnableDisableConfirm } from "../NotificationEnableDisableConfirm";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import useMobileScreenCollection from "../../../../hooks/userMobileScreenCollection";

export const SettingHome = (props) => {
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();
  const mobileScreenCollection = useMobileScreenCollection()
  const {
    isEnableNotiSettings,

    isOpenConfirmEnableDisableNotifications,
    handleConfirmEnableDisableNotifications,

    openSidemenuType,
    handleCloseSidemenu,
    handleOpenSidemenu,

    userAuthData,

    passwordDialodHandleClose,
    passwordDialodHandleSubmit,
  } = props;

  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div>
            <Typography variant="h4" className={appClasses.text8}>
              Settings
            </Typography>
          </div>
          <Box mt={4}></Box>
          <div>
            <AppListItemCard
              icon={lockGreenIcon}
              title={"Change Password"}
              active={openSidemenuType == SettingSidemenuTypes.CHANGE_PASSWORD}
              onClick={() =>
                handleOpenSidemenu(SettingSidemenuTypes.CHANGE_PASSWORD)
              }
            ></AppListItemCard>
          </div>
        </Grid>
      </Grid>

      <SidemenuViewContent
        open={openSidemenuType == SettingSidemenuTypes.CHANGE_PASSWORD}
        onClose={handleCloseSidemenu}
        headerComponent={
          <Box className={classes.viewVirtualCardHeader} style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName="SettingsChangePassword" />
          </Box>
        }
      >
        <ChangePasswordPage data={userAuthData} onClose={handleCloseSidemenu} />
      </SidemenuViewContent>

      {openSidemenuType == SettingSidemenuTypes.VERIFY_PASSWORD && (
        <PasswordDialog
          handleClose={passwordDialodHandleClose}
          handleSubmit={passwordDialodHandleSubmit}
        ></PasswordDialog>
      )}

      {isOpenConfirmEnableDisableNotifications && (
        <NotificationEnableDisableConfirm
          isEnableNotiSettings={isEnableNotiSettings}
          handleConfirm={handleConfirmEnableDisableNotifications}
        ></NotificationEnableDisableConfirm>
      )}
    </div>
  );
};
