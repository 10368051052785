import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";

// TODO: move this component to common
export const ResendOTPPopUp = (props) => {
  const { handleConfirm, redirectViaLogin } = props;

  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleConfirm(false)}
          >
            <img src={closeBlackIcon} alt="back icon"/>
          </IconButton>
          <Typography variant="span" className={appClasses.text5}>
            Didn’t receive a code?
          </Typography>
        </div>
      </DialogTitle>
      {/* <Divider></Divider> */}
      <Box mt={1} />
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <Typography variant="span" className={appClasses.text1}>
          Please wait at least 60 seconds for the code to arrive to your email.
          If you didn't receive the verification code, please check the
          following:
        </Typography>
        <Box mt={1}></Box>
          <ul>
            <li className={classes.li}>
              You must enter the email address associated with your Artist
              Growth Wallet App account. Please go back and make sure the email
              you entered is correct.
            </li>
            <li className={classes.li}>
              Check the spam and junk folders, just in case!
            </li>
            <li className={classes.li}>
              Check your internet connection. You must be connected in order to
              receive emails.
            </li>
          </ul>
        <Box mt={5} mb={5}>
          <div className={classes.resendBtnCtr}>
            <AppButton
              contained
              onClick={props.resendOTPCode}
              className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
              primary
            >
              Resend Code
            </AppButton>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
