import { useSelector } from "react-redux"


const useAuth = () => {
  const authReducer = useSelector((state) => state.authReducer) || {}
  const {inProgressingKyc, loginResponseData, checkingKycStatus} = authReducer
  const { bibson, user_id, user_ting } = loginResponseData || {}
  const username = useSelector((state) => state.authReducer.username)

  return { bibson, user_id, user_ting, username, inProgressingKyc, checkingKycStatus }
}

export default useAuth