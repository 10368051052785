import {
  API_URL,
  additionalApiData,
  REWARD_TX_REF_TO_DISPLAY,
} from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getRewardsTransactions = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  const { fromDate, toDate } = data
  const queryDate = `${fromDate ? '&from_date=' + fromDate : ''}${toDate ? '&to_date=' + toDate : ''}`
  return request(
    API_URL +
      `users/${data.user_id}/rewards/transactions?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}${queryDate}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDoshJWTToken = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
      `users/${data.userTing}/dosh-token?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRewardsSummary = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
      `users/${data.user_id}/rewards/summary?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const redeemRewards = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL + `users/${data.user_id}/rewards/redeem`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      channel,
      country_code,
      company_id,
      lang_code,
      app_type,
      app_version,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTxDetail = (txData) => {
  if (txData?.trans_ref && REWARD_TX_REF_TO_DISPLAY[txData?.trans_ref]) {
    return REWARD_TX_REF_TO_DISPLAY[txData?.trans_ref];
  }
  return txData?.merchant_name;
};
