import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TRANSFER_MONEY_STEPS } from "../../../constants";
import * as actions from "../actions";
import * as addEditRecipientActions from "../actions";
import { AddEditRecipient } from "../components/AddEditRecipient";

const initialState = {

    firstName: {
        value: "",
        isRequired: true
    },
    lastName: {
        value: "",
        isRequired: true
    },
    nickName: {
        value: "",
        isRequired: false
    },
    mobileNumber: {
        value: "",
        isRequired: true
    },
    bankName: {
        value: "",
        isRequired: true
    },
    accountType: {
        value: "",
        isRequired: true
    },
    accountNumber: {
        value: "",
        isRequired: true
    },
    confirmAccountNumber: {
        value: "",
        isRequired: true
    },
    routingNumber: {
        value: "",
        isRequired: true
    },

    errors: {},
    isSubmitted: false,
    isValid: false,
    isButtonEnabled: false,

    transferMoneyStep: TRANSFER_MONEY_STEPS.filling,
};

class AddEditRecipientDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const newState = { ...initialState };

        this.setState({ ...newState });

        const { getRecipientByTing } = this;
        const { bibson, user_id } = this.props.state?.authReducer?.loginResponseData;

        let data = {
            user_id,
            bibson,
            achTing: this.props.recipientId
        }

        this.props.recipientId && getRecipientByTing(data);
    }

    mapRecipientDataToState = (recipient) => {
        this.setState({
            firstName: { value: recipient.first_name, isRequired: initialState.firstName.isRequired },
            lastName: { value: recipient.last_name, isRequired: initialState.lastName.isRequired },
            nickName: { value: recipient.nickname, isRequired: initialState.nickName.isRequired },
            mobileNumber: { value: recipient.phone_number, isRequired: initialState.mobileNumber.isRequired },
            bankName: { value: recipient.bank_account.bank_name, isRequired: initialState.bankName.isRequired },
            accountType: { value: recipient.bank_account.account_type, isRequired: initialState.accountType.isRequired },
            accountNumber: { value: recipient.bank_account.account_number, isRequired: initialState.accountNumber.isRequired },
            confirmAccountNumber: { value: recipient.bank_account.account_number, isRequired: initialState.confirmAccountNumber.isRequired },
            routingNumber: { value: recipient.bank_account.routing_number, isRequired: initialState.routingNumber.isRequired }
        }, () => {
            this.validatedForm();
        })
    }

    componentDidUpdate(nextProps) {
        //Get recipients details on edit
        if (this.props.recipientId !== nextProps.recipientId) {
            const { getRecipientByTing } = this;
            const { bibson, user_id } = this.props.state?.authReducer?.loginResponseData;

            let data = {
                user_id,
                bibson,
                achTing: nextProps.recipientId
            }
            getRecipientByTing(data);
        }
    }

    getRecipientByTing = (data) => {
        const { mapRecipientDataToState } = this;
        this.props.addEditRecipientAction.getRecipientById(data, mapRecipientDataToState)
    }

    goToStep = (step) => {
        //this.setState({ transferMoneyStep: step }, () => { });
    };

    isValid = () => {
        const { firstName, lastName, mobileNumber, accountNumber, confirmAccountNumber, bankName, accountType, routingNumber, nickName, isSubmitted } = this.state;
        const errors = {};
        let valid = true;

        if (!isSubmitted) {
            return valid;
        }

        if (!firstName.value) {
            errors.firstName = "Require";
        }
        if (firstName.value && firstName?.value?.length > 15) {
            errors.firstName = "Value is too long";
        }
        if (!lastName.value) {
            errors.lastName = "Require";
        }
        if (lastName.value && lastName?.value?.length > 15) {
            errors.lastName = "Value is too long";
        }
        if (!mobileNumber.value) {
            errors.mobileNumber = "Require";
        }
        if (mobileNumber.value && mobileNumber?.value?.length != 10) {
            errors.mobileNumber = "Value should be 10 digit long";
        }
        if (mobileNumber.value && isNaN(mobileNumber.value)) {
            errors.mobileNumber = "Value should be number";
        }
        if (!bankName.value) {
            errors.bankName = "Require";
        }
        if (!accountNumber.value) {
            errors.accountNumber = "Require";
        }
        if (accountNumber.value && isNaN(accountNumber.value)) {
            errors.accountNumber = "Value should be number";
        }
        if (accountNumber.value && accountNumber?.value?.length > 16) {
            errors.accountNumber = "Value should not be more than 16 characters";
        }
        if (confirmAccountNumber.value && confirmAccountNumber?.value?.length > 16) {
            errors.confirmAccountNumber = "Value should not be more than 16 characters";
        }
        if (!confirmAccountNumber.value) {
            errors.confirmAccountNumber = "Require";
        }
        if (!accountType.value) {
            errors.accountType = "Require";
        }
        if (!routingNumber.value) {
            errors.routingNumber = "Require";
        }
        if (routingNumber.value && routingNumber?.value?.length != 9) {
            errors.routingNumber = "Value should be 9 digit long";
        }
        if (routingNumber.value && isNaN(routingNumber.value)) {
            errors.routingNumber = "Value should be number";
        }
        if (accountNumber.value && confirmAccountNumber.value && accountNumber.value != confirmAccountNumber.value) {
            errors.confirmAccountNumber = "Mismatch value!";
        }
        if(accountNumber.value && accountNumber.value.length < 4) {
            errors.accountNumber = "Invalid Account Number"
        }
        if(confirmAccountNumber.value && confirmAccountNumber.value.length < 4) {
            errors.confirmAccountNumber = "Invalid Account Number"
        }
        if (nickName.value && nickName.value.length > 15) {
            errors.nickName = "Value should not be more than 15 characters";
        }

        this.setState({ errors, errors });
        Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
        this.setState({ isValid: valid });

        return valid;
    };

    validatedForm = () => {
        let isButtonEnabled = true;

        Object.entries(this.state).map((item) => {

            if (typeof item[1] === 'object' && item[1] !== null) {
                if (isButtonEnabled && item[1]?.isRequired) {
                    isButtonEnabled = item[1]?.value ? true : false
                }
            }
        })
        this.setState({
            isButtonEnabled
        }, () => {
            this.isValid();
        })
    }

    handleChange = ({ target: { name, value } }) => {
        this.setState(
            {
                [name]: { value: value, isRequired: initialState[name].isRequired }
            }, () => {
                this.validatedForm();
            });
    };

    onHandleDelete = () => {

        const { bibson, user_id } = this.props.state?.authReducer?.loginResponseData;
        const { recipientId } = this.props;
        const { handleClose } = this;

        let data = {
            user_id,
            bibson,
            achTing: recipientId
        }
        this.props.addEditRecipientAction.deleteRecipient(data, handleClose);
    }

    handleSubmit = () => {
        this.setState({ isSubmitted: true }, () => {
            if (this.isValid()) {
                //Save Process
                const { handleClose } = this;
                const { bibson, user_id } = this.props.state?.authReducer?.loginResponseData;
                const { recipientId } = this.props;
                const { firstName, lastName, nickName, mobileNumber, accountNumber, bankName, accountType, routingNumber } = this.state;


                let data = {
                    user_id,
                    bibson,
                    achTing: recipientId
                }

                let payload = {
                    "first_name": firstName.value,
                    "last_name": lastName.value,
                    "nickname": nickName.value,
                    "phone_number": mobileNumber.value,
                    "bank_account": {
                        "account_number": accountNumber.value,
                        "account_type": accountType.value,
                        "bank_name": bankName.value,
                        "routing_number": routingNumber.value
                    },
                }

                if (data.achTing)
                    this.props.addEditRecipientAction.updateRecipient(data, payload, handleClose);
                else
                    this.props.addEditRecipientAction.saveRecipient(data, payload, handleClose);
            }
        });
    };

    handleClose = (afterSaveSuccess) => {
        const { handleClose } = this.props;
        handleClose && handleClose(afterSaveSuccess);
    };

    render() {
        const { recipientId } = this.props;
        const { handleChange, handleSubmit, handleClose, onHandleDelete } = this;
        const propsToForward = {
            ...this.state,
            handleChange,
            handleSubmit,
            handleClose,
            recipientId,
            onHandleDelete
        };
        return <AddEditRecipient {...propsToForward} />;
    }
}

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
        addEditRecipientAction: bindActionCreators(addEditRecipientActions, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditRecipientDialog);
