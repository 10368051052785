import React, { useEffect, useMemo } from 'react'
import ATMFinder from '../component';
import { useDispatch } from "react-redux";
import * as ATMFinderActions from '../actions';
import { useAtmLocations, useGoogleMapKey } from '../store/atmhooks';
import useAuth from '../../../hooks/useAuth';
import { useMemoizedFn } from 'ahooks';
import { useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAP_LIBARIES } from '../../../constants';
import ATMProvider from '../store/ATMProvider';
import Loading from '../../../common/components/Loading/Loading';


const DEFAULT_CENTER = {
    lat: 32.9791448,
    lng: -117.2504201
}

const GoogleMapKeyLoader = ({ children }) => {
    const dispatch = useDispatch();
    const { bibson } = useAuth()
    const googleMapKey = useGoogleMapKey()
    console.info(googleMapKey)
    const getGoogleMapKeys = useMemoizedFn(() => {
        let data = { bibson }
        dispatch(ATMFinderActions.getGoogleMapCredentials(data));
    })

    useEffect(() => {
        getGoogleMapKeys()
    }, [getGoogleMapKeys])

    if (!googleMapKey) {
        return <Loading text="Loadding google map configuration" />
    }
    return children
}

const GoogleMapLoader = ({ children }) => {
    const googleMapKey = useGoogleMapKey()
    const { currentLocation } = useAtmLocations()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries: GOOGLE_MAP_LIBARIES,
        googleMapsApiKey: googleMapKey
    })
    if (!isLoaded || !currentLocation) {
        return <Loading text="Map is loading..." />
    }
    return children
}


const ATMFinderPage = () => {
    const { changeLocation } = useAtmLocations()

    const getCurrentLocation = useMemoizedFn(() => {
        if (!navigator.geolocation) {
            return changeLocation(DEFAULT_CENTER)
        }
        navigator.geolocation.getCurrentPosition((pos) => {
            const { latitude: lat, longitude: lng } = pos.coords
            if (lat && lng) {
                changeLocation({ lat, lng })
            } else {
                changeLocation(DEFAULT_CENTER)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    //This is for get google map key when load page
    useEffect(() => {
        getCurrentLocation()
    }, [getCurrentLocation])

    return (
        <ATMProvider>
            <GoogleMapKeyLoader>
                <GoogleMapLoader>
                    <ATMFinder />
                </GoogleMapLoader>
            </GoogleMapKeyLoader>
        </ATMProvider>
    )
}

export default ATMFinderPage;

