import React from 'react'
import { TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

const RegistrationTextField = ({ control, name, label, errors, disabled }) => {
  const errorMessage = errors?.message
  const isError = !!errorMessage
  return <Controller
    control={control}
    render={({ field }) => <TextField fullWidth variant="outlined" className='registration-field'  label={label} helperText={errorMessage} error={isError} {...field} disabled={disabled} />}
    name={name}
    defaultValue=''
  />
}

export default RegistrationTextField