import {
  API_URL,
  additionalApiData,
} from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getSecurityQuestions = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/security-questions?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      // "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserProfile = (data) => {
  const { channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `paidapp/users/token/${data.token}?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      // "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserProfileByToken = (data) => {

 /*  channel: paidapp_us
country_code: us
lang_code: en-us
currency: USD
app_version: 9.9.9
app_type: web
company_id: artist_growth */
  console.log(data)
  const {channel='paidapp_us', country_code='us', lang_code='en-us', company_id= 'artist_growth' } =
    additionalApiData;
  return request(
    API_URL +
    `paidapp/users/token/${data.token}?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      // "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const registration = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users`,
    "POST",
    {
      "Content-Type": "application/json",
    },
    {
      "channel": channel,
      "lang_code": lang_code,
      "country_code": country_code,
      "company_id": company_id,
      app_type,
      app_version,
      ...data
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

