import { Box, Button, Grid } from "@material-ui/core";
import { InputInfo } from "../../../../common/components/InputInfo";
import { AppTextField } from "../../../../common/components/AppTextField";
import { useAppStyles } from "../../../../App.style";
import { AppAutocomplete } from "../../../../common/components/AppAutocomplete";

export default function EnterSecurityQuestion({ handleChange, errors, securityQuestions, selectedSecurityQuestion, securityAnswer, handleSubmit }) {
  const appClasses = useAppStyles();
  return <>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <InputInfo>
        Please answer the security question to verify your identity.
      </InputInfo>
      <Box mt={7}></Box>
      <AppAutocomplete
        options={securityQuestions}
        getOptionLabel={(option) => option.security_question}
        // groupBy={(option) => option.question}
        renderInput={(params) => (
          <AppTextField
            {...params}
            label="Select Question"
            errorText={errors?.selectedSecurityQuestion}
          />
        )}
        value={selectedSecurityQuestion}
        onChange={(event, question) =>
          handleChange({
            target: {
              name: "selectedSecurityQuestion",
              value: question || null,
            },
          })
        }
      />
    </Grid>

    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <AppTextField
        name={"securityAnswer"}
        label={"Answer"}
        inputProps={{
          maxLength: 100,
        }}
        value={securityAnswer}
        onChange={handleChange}
        errorText={errors?.securityAnswer}
      />
    </Grid>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        fullWidth
        className={`${appClasses.primaryBtn}`}
        disabled={
          selectedSecurityQuestion && securityAnswer ? false : true
        }
      >Continue
      </Button>
    </Grid>
  </>
}