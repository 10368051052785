import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  textFieldInput: {
    // fontSize: '2rem !important'
  },
  textFieldLabel: {
    color : theme.palette.other?.black700
  },
}));
