import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MANAGE_CARD_ITEM,
  routes,
  USER_CARD_STATUSES,
} from "../../../constants";
import * as actions from "../actions";
import * as dashboardActions from "../../dashboard/actions";
import { ManageCard } from "../components/ManageCard";
import history from "../../../history";
import { contentfulActionTypes, doGetAndSetContent } from "../../contentful/actions";
import { useAsyncEffect } from "ahooks";
import useAuth from "../../../hooks/useAuth";
import { CreateYourPinDialog } from "../components/ActivateCard/CreateYourPinDialog";
import { ActivateCardSuccessDialog } from "../components/ActivateCard/ActivateCardSuccessDialog";
import LockCard from "../components/LockCard";

const isVirtualCardSetPin = (cards) => {
  const card = cards.find((card) => card.active && card.is_virtual);
  return card?.is_pin_set;
}
export const ManageCardPage = (props) => {
  const dispatch = useDispatch();



  const purseReducer = useSelector((state) => state.purseReducer);

  const authReducer = useSelector((state) => state.authReducer);

  const mobileScreenCollection = useSelector((state) => state.contentfulReducer.mobileScreenCollection || []);

  const manageCardStates = useSelector((state) => state.managecardReducer);
  const { isPhysicalCardActivated, cards, currentActivatedCard, showActivateCardSuccessPopup } = manageCardStates

  const { loginResponseData, username } = authReducer;

  const [pinDialog, setSetPinDialog] = React.useState(undefined);
  const [createYourPinDialog, setCreateYourPinDialog] = React.useState(undefined);

  const [showPwdDialog, setShowPwdDialog] = React.useState(undefined);
  const [showLockCardToolTip, setShowLockCardToolTip] = React.useState(false);

  const [isOpenRightSidemenu, setIsOpenRightSidemenu] = React.useState(false);
  const [isOpenActivateCard, setIsOpenActivateCard] = React.useState(false);
  const [isOpenChangePinCard, setIsOpenChangePinCard] = React.useState(false);
  const [isOpenReplaceCard, setIsOpenReplaceCard] = React.useState(false);
  const [loockCard, setLoockCard] = React.useState(false);


  const isVirtualCard = !isPhysicalCardActivated;

  //card lock status
  const isCardLock = currentActivatedCard?.card_status === USER_CARD_STATUSES.frozen;

  const userCardTing = currentActivatedCard?.card_ting;

  const { user_id, bibson } = useAuth();

  useAsyncEffect(async () => {
    const loadCardTask = dispatch(actions.loadCurrentUserCards({ user_id, bibson }))
    return () => loadCardTask.cancel()
  }, [user_id]);

  useEffect(() => {
    getData();
    dispatch(doGetAndSetContent(contentfulActionTypes.mobileScreenCollection))
  }, []);


  const getData = () => {
    if (!loginResponseData || !userCardTing) {
      return;
    }
    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
      card_ting: userCardTing,
    };
    //dispatch(actions.doGetAndSetPursesAndTransactions({ ...data }));
    dispatch(dashboardActions.doGetAndSetCardBalances({ ...data }));
  };

  const handleConfirmLogoutAttemptPopup = () => {
    history.push(routes.login.path);
  }

  const closeAllSidemenu = () => {
    setIsOpenActivateCard(false);
    setIsOpenRightSidemenu(false);
    setIsOpenChangePinCard(false);
    setIsOpenReplaceCard(false);
  };

  const openViewVirtualCardSlide = () => {
    setIsOpenRightSidemenu(true);
  }

  const handleCloseActivateCard = (force = false) => {
    if (!force && !isOpenReplaceCard && isPhysicalCardActivated && !currentActivatedCard.is_pin_set) {
      setCreateYourPinDialog(true);
      return
    }
    closeAllSidemenu();
    closeCreateYourPinDialog();
  };

  const goTodashBoard = () => {
    history.push(routes.dashboard.path);
  }

  const onActivateCardSuccessPopupClose = () => {
    dispatch(actions.setActivateCardCongratulationPopup(false));
  }

  const closeCreateYourPinDialog = () => {
    setCreateYourPinDialog(false);
  }

  const handleViewActivateCard = () => {
    closeAllSidemenu();
    setIsOpenActivateCard(true);
  };

  const handleViewReplaceCard = () => {
    closeAllSidemenu();
    setIsOpenReplaceCard(true);
  };

  const handleChangePinCard = () => {
    closeAllSidemenu();
    setIsOpenChangePinCard(true);
  };

  const onCardClick = (clickedItem) => {
    //Detect click item and do actions
    if (clickedItem == MANAGE_CARD_ITEM.VIEW_VIRTUAL_CARD) {
      setIsOpenRightSidemenu(true);
    }
  };


  const startDoLockUnlockCard = () => {
    setLoockCard(true);
    closeAllSidemenu();
  };

  const closeLockCard = () => {
    setLoockCard(false);
  }

  const doLockUnlockCard = (password) => {
    if (!loginResponseData || !currentActivatedCard?.ting) {
      return;
    }
    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
      card_ting: currentActivatedCard?.ting,
    };

    const userData = {
      identifier: username,
      password: password,
    };

    const cardPayload = {
      reason: isCardLock
        ? USER_CARD_STATUSES.active
        : USER_CARD_STATUSES.frozen,
    };

    dispatch(
      actions.doActiveDeactiveCard(
        { ...data },
        userData,
        cardPayload,
        (success) => {
          if (success) {
            getData();
          }
        },
      )
    );
  };

  const handleClosePwdDialog = () => {
    setShowPwdDialog(false);
  };

  const handleSubmitPwdDialog = (password) => {
    handleClosePwdDialog();
    doLockUnlockCard(password);
  };

  const handleClose = () => {
    setSetPinDialog(false);
  };

  const propsTobeForward = {
    ...purseReducer,
    pinDialog,
    isOpenRightSidemenu,
    isOpenActivateCard,
    isVirtualCard,
    isOpenChangePinCard,
    isOpenReplaceCard,
    handleCloseActivateCard,
    handleViewActivateCard,
    setIsOpenRightSidemenu,
    openViewVirtualCardSlide,
    onCardClick,
    handleClose,
    handleChangePinCard,
    handleViewReplaceCard,
    closeAllSidemenu,
    mobileScreenCollection,

    //lock / unlock card
    startDoLockUnlockCard,
    isCardLock,
    showLockCardToolTip,
    setShowLockCardToolTip,

    //password dialog
    showPwdDialog,
    handleClosePwdDialog,
    handleSubmitPwdDialog,
    authReducer,
    handleConfirmLogoutAttemptPopup
  };

  return <>
    <ManageCard {...propsTobeForward} />
    {createYourPinDialog && <CreateYourPinDialog onExit={() => handleCloseActivateCard( true )} onSetPin={closeCreateYourPinDialog} />}
    {loockCard && <LockCard getData={getData} onSuccess={closeLockCard} isLocked={isCardLock} cardTing={currentActivatedCard.ting} onClose={closeLockCard}/>}
    {showActivateCardSuccessPopup && (
        <ActivateCardSuccessDialog
          onExit={onActivateCardSuccessPopupClose}
          goTodashBoard={goTodashBoard}
        />
      )}
  </>
};
