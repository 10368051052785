import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon, warningIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";

export const LogoutOnWrongAttemptPopup = (props) => {
  const { handleConfirm } = props;

  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"

      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard, style: {padding: '32px 25px'} }}
    >
      <DialogTitle id="form-dialog-title" >
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleConfirm(false)}
          >
            <img src={closeBlackIcon} alt="close"/>
          </IconButton>
        </div>
      </DialogTitle>
      {/* <Divider></Divider> */}
      <Box mt={3} />
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <IconButton className={appClasses.dialogCloseBtn}>
          <img src={warningIcon} alt="waring"/>
        </IconButton>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text3}>
          You’ve Been Logged Out
        </Typography>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text1}>
          There were too many failed attempts to enter the password. You have
          been logged out for security purposes.
        </Typography>
        <Box mt={5}></Box>
        <AppButton
          contained
          onClick={() => handleConfirm(false)}
          className={`${appClasses.primaryBtn} ${appClasses.width100p} ${appClasses.br200}`}
          primary
        >
          Got it
        </AppButton>
        <Box mt={5}></Box>
      </DialogContent>
    </Dialog>
  );
};
