import { getRecipients, sendMoney, getLimit } from '../services/sendToRecipient';
import { saveRecipients, deleteRecipients, updateRecipients, getRecipientByTing } from '../services/addEditRecipient';

export const getRecipient = getRecipients;
export const sendMoneyToRecipient = sendMoney;
export const getSendLimitDetails = getLimit;

export const saveRecipientDetails = saveRecipients;
export const deleteRecipientDetails = deleteRecipients;
export const updateRecipientDetails = updateRecipients;
export const getRecipientDetailsByTing = getRecipientByTing;
