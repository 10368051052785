import {
  SET_IS_GOT_REWARDS_SUMMARY,
  SET_IS_GOT_REWARDS_TRANSACTIONS,
  SET_REDEEM_REWARDS_STATUS,
  SET_REWARDS_SUMMARY,
  SET_REWARDS_TRANSACTIONS,
} from "../../../redux/actionTypes";
import { getTxDetail } from "../services";

import { apiCall } from "../../../common/actions/apiCall";
import {
  getDoshJWTToken,
  getRewardsSummary,
  getRewardsTransactions,
  redeemRewards,
} from "../services";
import { ACTION_STATUS } from "../../../constants";
import moment from "moment";

export const setRewardsTransactions = (response) => {
  return {
    type: SET_REWARDS_TRANSACTIONS,
    data: response,
  };
};

export const setIsGotRewardsTransactions = (response) => {
  return {
    type: SET_IS_GOT_REWARDS_TRANSACTIONS,
    data: response,
  };
};

export const setRedeemRewardsStatus = (response) => {
  return {
    type: SET_REDEEM_REWARDS_STATUS,
    data: response,
  };
};

export const setRewardsSummary = (response) => {
  return {
    type: SET_REWARDS_SUMMARY,
    data: response,
  };
};

export const setIsGotRewardsSummary = (response) => {
  return {
    type: SET_IS_GOT_REWARDS_SUMMARY,
    data: response,
  };
};

export const doGetAndSetRewardsTransactions = (data) => (dispatch) => {
  dispatch(setIsGotRewardsTransactions(false));
  apiCall(dispatch, getRewardsTransactions(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (rewardsTxsResponse) => {
      if (rewardsTxsResponse?.data?.status == "success") {
        const { transactions } = rewardsTxsResponse?.data?.data;
        transactions.sort(({trans_date: a_date}, {trans_date: b_date}) => moment(b_date || 0).toDate() - moment(a_date || 0).toDate());
        dispatch(setRewardsTransactions(transactions || []));
      }
      dispatch(setIsGotRewardsTransactions(true));
    },
    (error) => {
      dispatch(setIsGotRewardsTransactions(true));
    }
  );
};

export const doGetDoshJWTToken = (data, callBack) => (dispatch) => {
  apiCall(dispatch, getDoshJWTToken(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (res) => {
      if (res?.data?.status == "success") {
        callBack && callBack(res?.data?.data);
      }
    },
    (error) => {
      callBack && callBack();
    }
  );
};

export const doGetAndSetRewardsSummary = (data) => (dispatch) => {
  dispatch(setIsGotRewardsSummary(false));
  apiCall(dispatch, getRewardsSummary(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (rewardsSummaryResponse) => {
      if (rewardsSummaryResponse?.data?.status == "success") {
        const { data } = rewardsSummaryResponse?.data;
        const total = data?.reduce((out, reg) => out + (reg.total || 0), 0)
        dispatch(setRewardsSummary({ total }));
      }
      dispatch(setIsGotRewardsSummary(true));
    },
    (error) => {
      dispatch(setIsGotRewardsSummary(true));
    }
  );
};

export const doRedeemRewards = (data) => (dispatch) => {
  dispatch(setRedeemRewardsStatus(ACTION_STATUS.INPROCESS));

  apiCall(dispatch, redeemRewards(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (redeemResponse) => {
      if (redeemResponse?.data?.status == "success") {
        dispatch(setRedeemRewardsStatus(ACTION_STATUS.SUCCESS));
      } else {
        dispatch(setRedeemRewardsStatus(ACTION_STATUS.FAILED));
      }
    },
    (error) => {
      dispatch(setRedeemRewardsStatus(ACTION_STATUS.FAILED));
    }
  );
  // */
};
