import React, { useEffect } from "react";
import { doResendInvitation, resetInvitationTokenExpiredState } from "../../../registration/actions";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon, warningIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";

  export const ResendInvite = () => {
    const appClasses = useAppStyles();
    const classes = useStyles();
    const dispatch = useDispatch();
    const expiredToken = useSelector((state) => state.registrationReducer?.expiredToken);
    const onCloseHandler = () => {
      dispatch(resetInvitationTokenExpiredState())
    }
  
    const onResendInvitationHandler = () => {
      dispatch(doResendInvitation({invitationCode: expiredToken}))
        .then(() => {
          onCloseHandler()
        })
    }
    

  return (
    <Dialog
      open={true}
      onClose={onCloseHandler}      
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-close-modal">
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={onCloseHandler}
          >
            <img src={closeBlackIcon} />
          </IconButton>
      </DialogTitle>
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <div className={appClasses.dialogTitle}>
          <Typography variant="span" className={appClasses.text5}>
          Oops, something went wrong
          </Typography>
        </div>
        <Typography variant="span" className={appClasses.text1}>
        This invitation has expired. Please click the button below to request a new invite.
        </Typography>
        <Box mt={5} mb={5}>
          <div className={classes.resendBtnCtr}>
            <AppButton
              contained
              onClick={onResendInvitationHandler}
              className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
              primary
            >
              Resend Invite
            </AppButton>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
