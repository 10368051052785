import {
  INVITATION_TOKEN_EXPIRED,
  RESET_INVITATION_TOKEN_EXPIRED,
  SET_SECURITY_QUESTIONS,
  SET_USER_PROFILE,
} from "../../../redux/actionTypes";
import {
  AUTH_REDUCER_LS_KEY,
} from "../../../constants/app.constants";
import { getLS } from "../../../utilities";

const initState = {
  securityQuestions: [],
  userProfile: undefined,
  invitationTokenExpired: false
};

export default function registrationReducer(
  state = JSON.parse(getLS(AUTH_REDUCER_LS_KEY) || JSON.stringify(initState)),
  action
) {
  switch (action.type) {
    case SET_SECURITY_QUESTIONS:
      return Object.assign({}, state, {
        securityQuestions: action.data,
      });
    case SET_USER_PROFILE:
      return Object.assign({}, state, {
        userProfile: action.data,
        invitationTokenExpired: false
      });
    case INVITATION_TOKEN_EXPIRED:
      return Object.assign({}, state, {
        userProfile: {},
        invitationTokenExpired: true,
        expiredToken: action.data
      });
    case RESET_INVITATION_TOKEN_EXPIRED:
      return Object.assign({}, state, {invitationTokenExpired: false, expiredToken: undefined});
    default:
      return state;
  }
}
