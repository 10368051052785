import {
  LOGOUT,
  SET_ACCESS_TOKEN,
  SET_DISPLAY_LOGOUT_WRONG_ATTEMPT_POPUP,
  SET_IS_LOGIN_SUCCESS,
  SET_KYC_FORM_LINK,
  SET_LOGIN_KYC_FAILED,
  SET_LOGIN_RESPONSE_DATA,
  SET_USERNAME,
  SET_USER_ID,
  SET_IN_PROGRESSING_KYC,
  SET_CHECKING_KYC_STATUS
} from "../../../redux/actionTypes";
import {
  AUTH_REDUCER_LOGIN_RES_LS_KEY,
  AUTH_REDUCER_LS_KEY,
  TOKEN_LS_KEY,
  USERNAME_LS_KEY,
  USER_ID_LS_KEY
} from "../../../constants/app.constants";
import { getLS, removeLS, setLS } from "../../../utilities";


const safeParseJson = (str) => {
  try{
    return JSON.parse(str);
  }catch (e) {
    console.log(e);
    return null;
  }
}
const initState = {
  accessToken: undefined,
  userId: undefined,
  username: getLS(USERNAME_LS_KEY) || undefined,
  loginResponseData: safeParseJson(getLS(AUTH_REDUCER_LOGIN_RES_LS_KEY)) || undefined,
  isLoginSuccess: false,
  displayLogoutWrongAttemptPopup: false,
  kycFormLink: undefined,
  loginFailedDueToKYCFailed: false,
  inProgressingKyc: false,
  checkingKycStatus: false
};

export default function authReducer(
  state = initState,
  action
) {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      setLS(TOKEN_LS_KEY, action.data);
      return Object.assign({}, state, {
        accessToken: action.data,
      });
    case SET_IS_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isLoginSuccess: action.data,
      });
    case SET_USER_ID:
      setLS(USER_ID_LS_KEY, action.data);
      return Object.assign({}, state, {
        userId: action.data,
      });
    case SET_USERNAME:
      setLS(USERNAME_LS_KEY, action.data);
      return Object.assign({}, state, {
        username: action.data,
      });
    case SET_LOGIN_RESPONSE_DATA:
      setLS(AUTH_REDUCER_LS_KEY, JSON.stringify(action.data));
      setLS(AUTH_REDUCER_LOGIN_RES_LS_KEY, JSON.stringify(action.data));
      return Object.assign({}, state, {
        loginResponseData: action.data,
      });
    case SET_DISPLAY_LOGOUT_WRONG_ATTEMPT_POPUP:
      return Object.assign({}, state, {
        displayLogoutWrongAttemptPopup: action.data,
      });
    case SET_KYC_FORM_LINK:
      return Object.assign({}, state, {
        kycFormLink: action.data,
      });
    case SET_CHECKING_KYC_STATUS:
      return { ...state, checkingKycStatus: action.data}
    case SET_IN_PROGRESSING_KYC:
      return { ...state, inProgressingKyc: action.data}
    case LOGOUT:
      removeLS(USER_ID_LS_KEY);
      removeLS(USERNAME_LS_KEY);
      removeLS(TOKEN_LS_KEY);
      removeLS(AUTH_REDUCER_LS_KEY);
      removeLS(AUTH_REDUCER_LOGIN_RES_LS_KEY);
      return Object.assign({}, state, {
        ...initState,
      });
    case SET_LOGIN_KYC_FAILED:
      return {...state, loginFailedDueToKYCFailed: action.data}
    default:
      return state;
  }
}
