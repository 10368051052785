import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  styled
} from '@material-ui/core';
import { closeBlackIcon, warningIcon } from '../../../constants';
import { PrimaryButton } from '../core/Button/Buttons';

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperWidthXs{
    border-radius: 16px;
    padding: 100px 60px 70px;
  }
`

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(6, 8),
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(24),
  lineHeight: theme.typography.pxToRem(29),
  textAlign: 'center',
}))

const StyledContent = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(18),
  lineHeight: theme.typography.pxToRem(24),
  textAlign: 'center',
  margin: theme.spacing(1, 0),
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
}));


const MessageTitle = ({ title }) => {
  if (!title) return null;
  return (
    <StyledTitle variant="h3">{title}</StyledTitle>
  )
}

const MessageContent = ({ content }) => {
  return <StyledContent>{content}</StyledContent>
}

const WarningDialog = ({ closeHanlder, title, content, buttonText, open }) => {
  return (
    <StyledDialog
      open={open}
      onClose={closeHanlder}
      aria-labelledby="message-dialog-title"
      maxWidth={'xs'}
      fullWidth
    >
      <StyledDialogContent>
        <CloseButton onClick={closeHanlder}>
          <img src={closeBlackIcon} alt="close" />
        </CloseButton>
        <Grid container justifyContent="center" spacing={2} alignItems='center'>
          <Grid item xs={12} container alignItems='center' justifyContent='center' direction='column'>
            <IconButton>
              <img src={warningIcon} alt="Warning Icon" />
            </IconButton>
            <MessageTitle title={title} data-testid='message-dialog-title' />
            <MessageContent content={content} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ px: 2 }}>
              {buttonText && <PrimaryButton
                fullWidth
                onClick={closeHanlder}>
                {buttonText}
              </PrimaryButton>}
            </Box>
          </Grid>
        </Grid>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default WarningDialog;