import { HIDE_LOADER, SHOW_LOADER } from "../../redux/actionTypes";

const initialState = {
  show: undefined,
  count: 0,
};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return Object.assign({}, state, {
        show: true,
        count: state.count + 1,
      });
    case HIDE_LOADER:
      return Object.assign({}, state, {
        show: state.count > 1 ? true : false,
        count: state.count - 1,
      });
    default:
      return state;
  }
}
