import { ACTION_STATUS } from "../../../constants";
import {
  SET_IS_GOT_REWARDS_SUMMARY,
  SET_IS_GOT_REWARDS_TRANSACTIONS,
  SET_REDEEM_REWARDS_STATUS,
  SET_REWARDS_SUMMARY,
  SET_REWARDS_TRANSACTIONS,
} from "../../../redux/actionTypes";

const initState = {
  rewardsTransactions: [],
  isGotRewardsTransactions: false,
  redeemStatus: ACTION_STATUS.NONE,
  rewardsSummary: null,
  isGotRewardsSummary: false,
};

export default function rewardsReducer(state = initState, action) {
  switch (action.type) {
    case SET_IS_GOT_REWARDS_TRANSACTIONS:
      return Object.assign({}, state, {
        isGotRewardsTransactions: action.data,
      });
    case SET_REWARDS_TRANSACTIONS:
      return Object.assign({}, state, {
        rewardsTransactions: action.data,
      });
    case SET_REDEEM_REWARDS_STATUS:
      return Object.assign({}, state, {
        redeemStatus: action.data,
      });
    case SET_IS_GOT_REWARDS_SUMMARY:
      return Object.assign({}, state, {
        isGotRewardsSummary: action.data,
      });
    case SET_REWARDS_SUMMARY:
      return Object.assign({}, state, {
        rewardsSummary: action.data,
      });

    default:
      return state;
  }
}
