import { additionalApiData, API_URL } from "../../../constants";
import { request } from "../../../utilities/request";

export const changePassword = (data) => {
  const { channel, country_code, lang_code, app_type, currency, company_id } =
    additionalApiData;
  const {
    identifier,
    password,
    current_password,
    new_password,
    user_id,
    bibson,
  } = data;

  return request(
    API_URL + `users/${user_id}/security`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      identifier,
      current_password,
      new_password,
      password,
      channel,
      lang_code,
      country_code,
      app_type,
      currency,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const saveNotificationSettings = (data) => {
  const { channel, country_code, lang_code, app_type, company_id } = additionalApiData;
  const { user_notifications_settings, user_id, bibson } = data;

  return request(
    API_URL + `users/${user_id}/notifications-settings`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      user_notifications_settings,
      channel,
      lang_code,
      country_code,
      app_type,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNotificationSettings = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { user_id, bibson } = data;

  return request(
    API_URL +
      `users/${user_id}/notifications-settings?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
