// TODO: should be moved to common folder in future so that we can share accross the app.
// This file contents the styled components for the registration page.

import { Box, Grid, styled, Typography } from "@material-ui/core";

export const SectionTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))
SectionTitle.defaultProps = { variant: "h2" }

export const SectionSubtitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}))
SectionSubtitle.defaultProps = { variant: "subtitle1" }

export const LeftSection = styled(Box)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
  background: theme.palette.other.black700,
}))

export const RightSectionContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  maxWidth: 400,
  padding: theme.spacing(4),
}))

export const BannerImg = styled("img")(({ theme }) => ({
  width: "100%",
  background: theme.palette.other.black700,
}))