import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MoveMoneyHome } from "../components/MoveMoneyHome";
import * as goalsActions from "../../goals/actions";
import { doGetAndSetCardBalances } from "../../dashboard/actions";
import useAuth from "../../../hooks/useAuth";

export const MoveMoneyPage = (props) => {
  const [isOpenTransferBetweenAccount, setOpenTransferBetweenAccount] =
    React.useState(false);
  const [isOpenSendToRecipient, setOpenSendToRecipient] = React.useState(false);
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const { userCardTing } = dashboardReducer;
  const  {user_id, bibson} = useAuth()
  useEffect(() => {
    dispatch(doGetAndSetCardBalances({user_id, bibson}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, bibson])

  useEffect(() => {
    if (userCardTing){
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCardTing]);

  const getData = () => {
    const data = {
      bibson,
      user_id,
      card_ting: userCardTing,
    };
    dispatch(goalsActions.doGetAndSetPursesAndTransactions({ ...data }));
  };

  const handleViewTransferBetweenAccount = () => {
    closeAllSideMenu();
    setOpenTransferBetweenAccount(true);
  };
  const handleViewSendToRecipient = () => {
    closeAllSideMenu();
    setOpenSendToRecipient(true);
  };

  const handleCloseTransferBetweenAccount = () => {
    closeAllSideMenu();
  };

  const handleCloseSendToRecipient = () => {
    closeAllSideMenu();
  };

  const closeAllSideMenu = () => {
    setOpenSendToRecipient(false);
    setOpenTransferBetweenAccount(false);
  };

  const propsTobeForward = {
    isOpenTransferBetweenAccount,
    isOpenSendToRecipient,

    handleViewTransferBetweenAccount,
    handleViewSendToRecipient,

    handleCloseTransferBetweenAccount,
    handleCloseSendToRecipient,
  };

  return <MoveMoneyHome {...propsTobeForward} />;
};
