import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from "react";

export const MD_X_DesktopDatePicker = (props) => {
  const propsToForward = {
    ...props,
  };

  if (!props.id && props.name) {
    propsToForward.id = props.name;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker {...propsToForward} />
    </LocalizationProvider>
  );
};
