import { API_URL, additionalApiData } from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getRecipients = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
      `users/${data.user_id}/ach-payments/recipients?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const sendMoney = (data, payload) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
      `users/${data.user_id}/ach-payments/recipients/${data.achTing}/send`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
        ...payload,
        channel,
        lang_code,
        country_code,
        company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


export const getLimit = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
      `users/${data.user_id}/ach-payments/info?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
