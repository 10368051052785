import { useDispatch } from "react-redux";
import { NOT_AUTHORIZED } from "../../../constants";
import { doLogout, setDisplayWrongAttemptPopup } from "../actions";

export function useApiErrorHandler(){
  const dispatch = useDispatch();


  const handleErrorsFromServer = (error) => {
    const errorData = error.response?.data?.errors || [];
    const toManyAttempts = errorData.some((error) => NOT_AUTHORIZED === error.code.toString());
    let alreadyHandler = false
    if (toManyAttempts) {
      dispatch(doLogout())
      dispatch(setDisplayWrongAttemptPopup(true))
      alreadyHandler = true
    }
    return alreadyHandler
  }

  return { handleErrorsFromServer }
}