import React from 'react';
import CardTnC from '../components/Registration/CardTnC';

const CardTnCPage = () => {
    return (
        <>
            <CardTnC />
        </>
    )
}

export default CardTnCPage;