import { makeStyles } from "@material-ui/core";
import { appColors } from "../../../constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: theme.palette.other?.grey,
  },
  atmListingContainerHeaderText: {
    color: appColors.black300New,
    fontFamily: "SF Pro",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
  },
  atmListingContainerHeaderBox: {
    height: "45px",
    borderBottom: "1px solid #d1cbcb",
    padding: "10px",
    backgroundColor: appColors.white,
  },
  infoBox: {
    height: "100px",
    width: "100px",
    backgroundColor: appColors.white,
    border: "1px solid black"
  },
  modalItemBox: {
    padding: "40px 35px",
  },
  mapListContainer: {
    padding: 0,
  },
  itemBox: {
    width: "100%"
  },
  bankNamebox: {
    display: "flex",
    flexDirection: "row",
  },
  bankNameboxModal: {
    display: "flex",
    flexDirection: "row",
    padding: "15px 0px"
  },
  btnDisplayAtmFee:  {
    height: "30px",
    background: appColors.black500,
    color: appColors.white,
    padding: "5px",
    borderRadius: "4px",
    width: "55%"
  },
  btnDisplayFreeAtm: {
    height: "30px",
    background: appColors.primary900,
    color: appColors.white,
    padding: "5px",
    borderRadius: "4px",
    width: "55%"
  },
  btnGetDirection: {
    width: "100%",
    background: appColors.primary500,
    "& > a": {
      textDecoration: "none",
      color: "#262626",
    }
  },
  bankName: {
    color: appColors.black700,
    width: "50%",
    textAlign: "left",
    fontFamily: 'SF Pro',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
  },
  otherText: {
    color: appColors.black500,
    width: "50%",
    textAlign: "left",
    fontFamily: 'SF Pro',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "11px",
    lineHeight: "20px",
  },
  durationText: {
    color: appColors.black700,
    width: "50%",
    textAlign: "right",
    fontFamily: 'SF Pro',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "11px",
    lineHeight: "13px",
    textAlign: "right",
    letterSpacing: "-0.02em",
  },
  durationTextModal: {
    color: appColors.black700,
    width: "50%",
    textAlign: "right",
    fontFamily: 'SF Pro',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "11px",
    lineHeight: "13px",
    textAlign: "right",
    letterSpacing: "-0.02em",
    "& > span": {
      padding: "3px 0px",
      display: "block"
    }
  },
  mapListContainerItem: {
    height: "100px",
    borderBottom: "1px solid #e5e2e2",
    padding: "0px 15px 0px 15px",
    cursor: "pointer"
  },
  mapListContainerItemWithMarked: {
    height: "100px",
    borderBottom: "1px solid #e5e2e2",
    padding: "0px 15px 0px 15px",
    cursor: "pointer",
    background: appColors.primary100
  },
  infoCard: {
    maxHeight: "457px",
    height: "457px",
    overflowY: "scroll"
  },
  freeAtmText: {
    color: appColors.primary900
  },
  atmFilter: {
    position: "absolute",
    zIndex: 999,
    left: "1rem",
    top: "1rem",
    background: appColors.white,
    border: "1px solid rgba(231, 231, 231, 0.9)",
    boxShadow: "0px 0px 8px rgb(0 0 0 / 10%)",
    borderRadius: "4px",
    padding: "0px 10px",
  },
  mapContainer: {
    position: "relative"
  },
  mapFilterRadio: {
    fontSize: "10px",
    "& > span": {
      paddingRight: "3px"
    }
  },
  mapSearchContainer: {
    position: "absolute",
    right: "5px",
    top: "9px",
  },
  InfoWindowContainer: {
    width: "350px",
    height: "235px",
    background: appColors.white,
    boxShadow: "1px 10px 32px rgba(0, 0, 0, 0.15)",
    borderRadius: "16px",
  },
  mapSearchTxt: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
  }
}));

