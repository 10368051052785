import {
  Grid,
  Typography,
  Box,
  Paper,
  Tabs,
  Tab,
  Divider,
  Menu,
  Chip,
  Hidden,
} from "@material-ui/core";
import React, { useState } from "react";
import Moment from "react-moment";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import moment from "moment";
import { DateFilter } from "../../../transactions/components/DateFilter";
import CloseIcon from '@material-ui/icons/Close';
import {
  ACTION_STATUS,
  arrowRightIosIcon,
  DATE_FORMAT,
  infoIcon,
  filterBlackIcon,
} from "../../../../constants";
import { AppButton } from "../../../../common/components/AppButton";
import { Amount } from "../../../../common/components/Amount";
import { WayToEarn } from "../WayToEarn";
import { AppTable } from "../../../../common/components/AppTable";
import { IconButton } from "../../../../common/components/core";
import { ViewTransaction } from "../ViewTransaction";
import { getTxDetail } from "../../services";
import { AppTooltip } from "../../../../common/components/AppTooltip";
import RedeemCashBack from './RedeemCashBack';
import LoadingFragment from "./LoadingFragment";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import useMobileScreenCollection from "../../../../hooks/userMobileScreenCollection";


export const Rewards = (props) => {
  const {
    cardBalances,
    rewardsTransactions,
    isGotRewardsTransactions,
    rewardsSummary,
    redeemStatus,
    selectedTransaction,
    isOpenViewTransaction,
    handleCloseViewTransaction,
    handleViewTransaction,
    handleRedeem,
    getDoshJWTToken,
  } = props;

  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();
  const wayToEarnIndex = 0
  const HistoryTabIndex = 1

  const HelpScreenAndTabMapper = {
    [wayToEarnIndex]: 'rewards-dashboard',
    [HistoryTabIndex]: 'showRewardsTransaction',
    default: 'rewards-dashboard'
  }
  
  const mobileScreenCollection = useMobileScreenCollection()

  const onHandleDateSubmit = () => {
    props.onClickApply(startDate, endDate);
    handleCloseShowTxFilter();
  };

  const handleClickShowTxFilter = (event) => {
    setShowTxFilter(event.currentTarget);
  };

  const handleCloseShowTxFilter = () => {
    setShowTxFilter(null);
  };

  const onHandleStartDateChange = (value, ctx) => {
    setStartDate(ctx || !value ? undefined : moment(value).format());
  };

  const onHandleEndDateChange = (value, ctx) => {
    setEndDate(ctx || !value ? undefined : moment(value).format());
  };

  const onClearDate = () => {
    setStartDate(null);
    setEndDate(null);
    props.removeDateChip();
  }
  const [tabValue, setTabValue] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTxFilter, setShowTxFilter] = useState(null);
  const helpScreenName = HelpScreenAndTabMapper[tabValue] || HelpScreenAndTabMapper['default']
  const columns = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      canSort: false,
      render: (row) => <Moment format={DATE_FORMAT}>{row.trans_date}</Moment>,
    },
    {
      id: "desc",
      numeric: false,
      disablePadding: false,
      label: "Details",
      render: (row) => getTxDetail(row),
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      render: (row) => (
        <span className={appListClasses.cellWithBtn}>

          <Amount>{row.trans_ref == "redemption" ? row.reward_amount : row.reward_amount}</Amount>
          <IconButton onClick={() => handleViewTransaction(row)}>
            <img src={arrowRightIosIcon} />
          </IconButton>
        </span>
      ),
    },
  ];

  const listCardHeight = `${window.innerHeight - 285}px`;
  const rewardBalance = cardBalances?.rewards || 0;
  const RedeemButtonAndAllTimeEarning = (props)=> {
    return(<Typography
      variant="span"
      className={`${classes.filterBtnPos} ${appClasses.text4}`}
    >
      All-time cash back earnings{" "}
      <span className={`${appClasses.text7}`}>
        <Amount removePlusSign removeSuccessColor>
          {rewardsSummary?.total || 0}
        </Amount>
      </span>
    </Typography>)
   }

   const minDate = endDate ? moment(endDate).subtract(1, 'month').toDate() : undefined
   const maxDate = startDate ? moment(startDate).add(1, 'month').toDate() : undefined
 
   const dateFilter = () => {
     return tabValue === HistoryTabIndex ? (
       <>
         <AppButton
           startIcon={<img src={filterBlackIcon} />}
           contained
           className={`${appClasses.filterBtn} ${appClasses.filterBtnPos}`}
           primary
           onClick={handleClickShowTxFilter}
         >
           Filter
         </AppButton>
         <Menu
           id="user-menu"
           anchorEl={showTxFilter}
           keepMounted
           open={Boolean(showTxFilter)}
           onClose={handleCloseShowTxFilter}
           PaperProps={{
             className: `${appClasses.dropdownMenu} ${classes.dateFilterContainer}`,
           }}
         >
           <DateFilter
             startDate={startDate}
             endDate={endDate}
             onHandleStartDateChange={onHandleStartDateChange}
             onHandleEndDateChange={onHandleEndDateChange}
             minDate={minDate}
             maxDate={maxDate}
             disableFuture
             onClickApply={() => {
               onHandleDateSubmit();
             }}
           />
         </Menu>
       </>
     ) : null;
   };

  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div className={classes.goalHeader}>
            <div>
              <Typography variant="h4" className={appClasses.text8}>
                Rewards
              </Typography>
              <Box mt={2}></Box>
              <Typography variant="h1" className={appClasses.text2}>
                <Amount removePlusSign removeSuccessColor>
                  {rewardBalance}
                </Amount>
              </Typography>
              <Box mt={1}></Box>
              <Typography variant="h6" className={appClasses.text1}>
                Account Balance{" "}
                <AppTooltip
                  title={
                    "It may take up to 10 days for rewards to display in your cash back balance and transaction history."
                  }
                >
                  <img
                    src={infoIcon}
                    className={`${appClasses.infoIcon} ${appClasses.vAlignMiddle}`}
                  />
                </AppTooltip>
              </Typography>
            </div>
            <div className={classes.buttonNav}>
              <AppButton
                contained
                style={{ width: "246px" }}
                onClick={handleRedeem}
                className={`${appClasses.primaryBtn} ${classes.redeemBtn}`}
                disabled={rewardBalance <= 0}
                primary
              >
                {redeemStatus !== ACTION_STATUS.INPROCESS && (
                  <RedeemCashBack
                    rewardBalance={rewardBalance}
                    removePlusSign
                    removeSuccessColor
                  />
                )}
                {redeemStatus == ACTION_STATUS.INPROCESS && (
                  <LoadingFragment/>
                )}
              </AppButton>

              {tabValue == HistoryTabIndex && (
                <RedeemButtonAndAllTimeEarning/>
              )}
            </div>
          </div>
          <Box mt={2}></Box>
          <Paper className={`${appClasses.paperCard}`}>
            <div
              className={`${appListClasses.listContainer}`}
              style={{ height: listCardHeight }}
            >
              <Tabs
                value={tabValue}
                onChange={(event, number) => setTabValue(number)}
                aria-label="rewards tabs"
                className={appListClasses.tabs}
              >
                <Tab label="Ways to Earn" />
                <Tab label="History" />
                {props.dateChipLabel && <Chip
                  deleteIcon={<CloseIcon className={classes.muiDeleteIcon} />}
                  onDelete={() => { onClearDate() }}
                  label={props.dateChipLabel}
                  className={classes.dateChip}
                />}
                <Hidden mdDown>
                  {dateFilter()}
                </Hidden>
              </Tabs>

              <Divider className={appListClasses.divider} />

              <Hidden lgUp>
                <Box sx={{ my: 1, display: 'flex', justifyContent: 'space-between' }}>
                  {dateFilter()}
                </Box>
              </Hidden>

              {tabValue == wayToEarnIndex && (
                <div>
                  <WayToEarn getDoshJWTToken={getDoshJWTToken}></WayToEarn>
                </div>
              )}

              {tabValue == HistoryTabIndex && (
                <div style={{ height: "100%" }}>
                  {isGotRewardsTransactions &&
                    rewardsTransactions &&
                    rewardsTransactions.length == 0 && (
                      <div
                        className={`${appListClasses.listContainer} ${appClasses.centerHvWrapper}`}
                        style={{ height: "100%" }}
                      >
                        <div className={`${classes.noDataWrapper}`}>
                          <Typography variant="h4" className={appClasses.text3}>
                            No transaction to display
                          </Typography>
                          <Box mt={2}></Box>
                          <Typography
                            variant="span"
                            className={appClasses.text4}
                          >
                            You can earn cash back when you shop at hundreds{" "}
                            <br /> of brands with your Artist Growth Wallet Card
                          </Typography>
                        </div>
                      </div>
                    )}

                  {isGotRewardsTransactions &&
                    rewardsTransactions &&
                    rewardsTransactions.length > 0 && (
                      <div className={`${appListClasses.listContainer}`}>
                        <AppTable
                          columns={columns}
                          removePagination
                          data={rewardsTransactions}
                        ></AppTable>
                      </div>
                    )}
                </div>
              )}
            </div>
          </Paper>
          <Box mt={2}></Box>
          <div className={classes.goalFooter}>
            <Typography variant="span" className={appClasses.bottomText}>
              *Rewards subject to eligibility and offers may change without
              notice
            </Typography>
            <MobileHelpScreen iconName="button" topics={ mobileScreenCollection } screenName={helpScreenName} anchor="top"/>
          </div>
        </Grid>
      </Grid>

      {/*View transaction*/}
      {isOpenViewTransaction && (
        <ViewTransaction
          data={selectedTransaction}
          handleClose={handleCloseViewTransaction}
        ></ViewTransaction>
      )}
    </div>
  );
};
