import { makeStyles } from "@material-ui/core";
import { appColors } from "../../../../constants";

export const useStyles = makeStyles((theme) => ({
  quickLinkIcon: {
    background: theme.palette.other.primary100,
  },
  goalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  buttonNav: {
    display: 'flex',
    alignSelf:'flex-end',
    flexDirection: 'column'
  },
  filterBtnPos: {
    alignSelf: "center",
    marginLeft: "auto",
    marginRight: "-0.5rem",
  },
  goalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noDataWrapper: {
    textAlign: "center",
  },
  redeemBtn: {
    width: "auto",
    alignSelf: "end",
  },
  muiDeleteIcon: {
    height: "30px !important",
    width: "15px !important",
    color: "black",
  },
  dateChip: {
    alignSelf: "center",
    marginLeft: "14rem",
    backgroundColor: appColors.primary500,
    marginRight: "5px"
  },
}));
