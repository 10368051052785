import * as yup from "yup";

const PhoneNumbeRegex = /^(\+1[\s]?)[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im


const RegistrationDataSchema = yup.object({
  first_name: yup.string().required("is required"),
  last_name: yup.string().required("is required"),
  email: yup.string().email().required("is required"),
  phone: yup.string().required("is required").matches(PhoneNumbeRegex, "Invalid Phone Number"),
  password: yup.string().trim()
    .min(8)
    .required("is required")
    .test('password-include-special-character', 'Password must include at least one special character or a number', (value) => { return /[0-9!@#$%^&*(),.?":{}|<>]/.test(value); })
    .test('password-include-uppercase', 'Password must include at least one uppercase letter', (value) => { return /[A-Z]/.test(value); })
    .test('password-include-lowercase', 'Password must include at least one lowercase letter', (value) => { return /[a-z]/.test(value); }),
  password_confirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  security_questions: yup.array().of(
    yup.object({
      security_question: yup.string().required("is required"),
      security_answer: yup.string().required("is required"),
    }).test('security-question-answer', 'Security question and answer must not be the same', (value, context) => {
      const sameSelectedQuestion = context.parent.filter((item) => item.security_question === value.security_question)
      if (sameSelectedQuestion.length > 1) {
        return new yup.ValidationError('Question must be unique', value, `${context.path}.security_question`)
      }
      return true
    })
  )
    .length(3, "is required"),
  agreeTNC: yup.boolean().oneOf([true], "To continue with the application, you must consent to the term and conditions above."),
})


export default RegistrationDataSchema