import { makeStyles } from "@material-ui/core";
import { appColors } from "../../../../constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "626px",
  },
  limitHeader: {
    display: "flex",
    width: "100%",
    height: "64px",
    alignItems: "center",
    padding: "0rem 1rem",
  },
  limitContent: {
    display: "flex",
  },
  limitWrapper: {
    borderRadius: "8px",
    borderLeft: `8px solid ${theme.palette.other?.orange500}`,
  },
  limitProgress: {
    padding: "0rem 3rem 2rem 3rem",
  },
  progressBar: {
    border: `1px solid ${theme.palette.other?.black300New}`,
    borderRadius: "200px",
    height: "12px",
    padding: "1.5px",
    "& div ": {
      background: theme.palette.other?.primary500,
      width: "80%",
      borderRadius: "200px",
      height: "7.5px",
    },
  },
  progressBarHead: {
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    textAlign: "center",
  },
  txHeader: {
    padding: "0px 0px",
    marginBottom: "20px",
    marginLeft: "20%",
  },
  txContainer: {
    display: "flex",
    textAlign: "center",
    padding: "5px 0px",
  },
  itemLabel: {
    width: "50%",
  },
  itemValue: {
    width: "50%",
  },
  txDelCol: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "700",
    margin : "20px 0px",
    "& span": {
      color: theme.palette.other.black700,
    },
    "& label": {
      color: theme.palette.other.black300New,
    },
  },
  txContainer: {
    padding: "0px 48px",
    textAlign: "center",
    minHeight: "400px",
  },
  txTable: {
    padding: "24px 0px",
  },
  textAddRecipient: {
    color: appColors.black700
  }
}));
