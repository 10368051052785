import { useReducer } from 'react';
import { LayoutContext } from './LayoutContext';

const ACTION_TYPES = {
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
};
const initState = {
  sidebarOpen: false,
};
function layoutReducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.TOGGLE_SIDEBAR:
      return { ...state, sidebarOpen: !state.sidebarOpen };
    default:
      return state;
  }
}

export default function LayoutProvider({ children }) {
  const [state, dispatch] = useReducer(layoutReducer, initState);
  const toggleSidebar = () => dispatch({ type: ACTION_TYPES.TOGGLE_SIDEBAR });

  const { sidebarOpen } = state;
  const value = { sidebarOpen, toggleSidebar };
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}
