import { Link, styled } from '@material-ui/core';
import { AlertCard } from '../../../../common/components/AlertCard';
import { errorRedDarkIcon } from '../../../../constants';

const CreateYouPinLink = styled(Link)(({ theme }) => ({
  color: theme.palette.other?.primary500,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

export default function CreateYourPin({
  handleSetPinPopUp,
}) {
  return (
    <AlertCard
      icon={errorRedDarkIcon}
      title='Create Your PIN'
      detail={
        <>
          You must set a PIN in order to make ATM withdrawals and card
          transactions. Go to a{' '}
          <CreateYouPinLink onClick={handleSetPinPopUp}>
            Create Your PIN
          </CreateYouPinLink>
        </>
      }
    ></AlertCard>
  );
}
