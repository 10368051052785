import { ACCOUNT_TEMPORARY_LOCKED, DISMISS_ACCOUNT_TEMPORARY_LOCKED } from "../../redux/actionTypes";

const initialState = {
  isTemporaryLocked: false,
  lockedCountDown: 10,
  lockType: 'login',
};

export default function accountStatus(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_TEMPORARY_LOCKED:
      const { lockType, lockedCountDown } = action.data;
      return {...state, isTemporaryLocked: true, lockType, lockedCountDown };
    case DISMISS_ACCOUNT_TEMPORARY_LOCKED:
      return {...state, isTemporaryLocked: false, lockedCountDown: 10};
    default:
      return state;
  }
}
