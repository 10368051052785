import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
  styled,
  Link
} from "@material-ui/core";

import { useAppStyles } from "../../../App.style";
import { IconButton } from "../core";
import { warningIcon, closeBlackIcon } from "../../../constants";


const LOCK_TYPE_CONTENT_MAPPER = {
  default: {
    title: (remainingTime) => `Account Locked for ${remainingTime} minutes`,
    message: (remainingTime) => `There were too many failed attempts to enter the verification code. You account has been locked for ${remainingTime} minutes. Please try again later.`,
    needHelpLink: true
  },
  "login": {
    title: (remainingTime) => `Account Locked for ${remainingTime} minutes`,
    message: (remainingTime) => `There were too many failed attempts to enter the verification code. Your account has been locked for ${remainingTime} minutes. Please try again later.`,
    needHelpLink: false
  },
  "edit-profile": {
    title: (remainingTime) => `Editing locked for ${remainingTime} minutes`,
    message: (remainingTime) => `There were too many failed attempts to enter the verification code. You cannot attempt any edits for ${remainingTime} minutes. Please try again later.`,
    needHelpLink: false
  }
}
const StyleDialogTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  display: 'block',
}));
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0, 12),

}));

const StyleContentWrapper = styled(Box)(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 0, 6),
  textAlign: 'center',
}));

const StyledGotItButton = styled(Button)`
  padding: 16px 24px;
  background: #1AD6D6;
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #262626;
  &:hover {
    background: #1AD6D6;
  }
`

const StyledStillNeedHelpLink = styled(Link)`
  font-size: 16px;
  line-height: 22px;
  color: #262626;
`

const StillNeedHelp = ({ open }) => {
  if (!open) {
    return null;
  }

  return <Box style={{ display: 'inline-block', textAlign: 'center' }}>
    <StyledStillNeedHelpLink href="mailto:agwallet-support@payfare.com" target="_blank" underline="none">Still need help? contact us.</StyledStillNeedHelpLink>
  </Box>
}


export const TemporaryAccountLockedPopup = ({ handleConfirm, lockoutTime, lockType }) => {
  const [remainingTime, setRemainingTime] = React.useState(lockoutTime || 10);

  const lockTypeContent = LOCK_TYPE_CONTENT_MAPPER[lockType] || LOCK_TYPE_CONTENT_MAPPER.default;
  const title = lockTypeContent.title(remainingTime);

  // Function to count down the remaining time
  const countDown = () => {
    setRemainingTime((prevTime) => prevTime - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      countDown();
    }, 60 * 1000);

    if (remainingTime <= 0) {
      handleConfirm(false);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  const appClasses = useAppStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleConfirm(false)}
          >
            <img src={closeBlackIcon} alt="close back icon" />
          </IconButton>
        </div>
      </DialogTitle>
      <StyledDialogContent>
        <StyleContentWrapper>
          <IconButton className={appClasses.dialogCloseBtn}>
            <img src={warningIcon} alt="warning icon" />
          </IconButton>

          <StyleDialogTitle variant="span" className={appClasses.text3}>
            {title}
          </StyleDialogTitle>

          <Typography variant="span" className={appClasses.text1}>
            {lockTypeContent.message(remainingTime)}
          </Typography>
        </StyleContentWrapper>
        <Box my={5} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <StyledGotItButton
            variant="contained"
            color="secondary"
            onClick={() => handleConfirm(false)}
            fullWidth
            primary>
            Got It
          </StyledGotItButton>

          <StillNeedHelp open={lockTypeContent.needHelpLink} />
        </Box>
      </StyledDialogContent>
    </Dialog>
  );
};

TemporaryAccountLockedPopup.propTypes = {
  handleConfirm: PropTypes.func.isRequired
}