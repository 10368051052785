import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "626px",
  },
  header: {
    textAlign: "center",
  },
  imgWrapper: {
    textAlign: "center",
  },
  mb5: {
    marginBottom: "-5px",
  },
  dialogContent: {
    margin: "10px 10%"
  }
}));
