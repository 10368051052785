import { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react"
import ATMContext from "./ATMContext"
import { useDispatch, useSelector } from "react-redux";
import { getGoogleMapData, setCurrentLocation } from "../actions";
import useAuth from "../../../hooks/useAuth";

export const useATMFinderContext = () => {
  const context = useContext(ATMContext)
  if (context === undefined) {
    throw new Error("useGoogleMapKey must be used within a ATMProvider");
  }
  return context
}
export const useGoogleMapKey = () => {
  const { googleMapKeys: googleMapKey } = useSelector(state => state.atmLocationReducer)
  return googleMapKey
}

export const useSelectedATM = () => {
  const context = useATMFinderContext()
  const { selectedATM, setSelectedATM } = context
  return {
    selectedATM,
    setSelectedATM
  }
}

export const useDistanceDurationStorage = () => {
  const context = useATMFinderContext()
  const { distanceDurations, setDistanceDurations } = context
  return {
    distanceDurations,
    setDistanceDurations
  }
}

export const useOnSelectedATMChange = (changeHandlerFn) => {
  const context = useATMFinderContext()
  const { selectedATM } = context
  useEffect(() => {
    changeHandlerFn(selectedATM)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedATM])
  return selectedATM
}

export const useAtmLocations = () => {
  const atmLocationReducer = useSelector(state => state.atmLocationReducer || {})
  const { allAtm: atmLocations, currentLocation } = atmLocationReducer
  const { bibson, user_id } = useAuth()
  const dispatch = useDispatch()

  const loadNearbyATMs = (location) => {
    if (!location) {
      console.error("Current location is not set")
      return false
    }
    const { lat, lng } = location
    dispatch(getGoogleMapData({ lat, lng, bibson, user_id }))
  }

  const changeLocation = (location) => {
    const placeId = location?.place_id
    dispatch(setCurrentLocation({ ...location, placeId }))
  }

  return {
    atmLocations: atmLocations || [],
    currentLocation,
    changeLocation,
    loadNearbyATMs
  }
}


export const useElementPosition = (elementRef) => {
  const [position, setPosition] = useState(null)

  useLayoutEffect(() => {
    const { current: element } = elementRef
    if (!element) return null
    const { x, y } = element.getBoundingClientRect()
    setPosition({ x, y })
  }, [elementRef])
  return position
}


export const ATMFilterOptions = [
  {
    id: 'atm-free',
    label: 'Find Free ATMs',
    selectedLabel: 'Free ATMs',
    filterFn: (atm) => atm.fee_amt <= 0
  }
]

export const useAtmFilter = () => {
  const context = useATMFinderContext()
  const { selectedFilters, setSelectedFilters, isSelectingFilter,
    setIsSelectingFilter } = context

  const atmLocationReducer = useSelector(state => state.atmLocationReducer || {})
  const { allAtm: atmLocations } = atmLocationReducer

  const toggleFilter = (filterId) => {
    const filterIndex = selectedFilters.indexOf(filterId)
    if (filterIndex === -1) {
      setSelectedFilters([...selectedFilters, filterId])
    } else {
      setSelectedFilters(selectedFilters.filter((id) => id !== filterId))
    }
  }

  const deleteFilter = (filterId) => {
    setSelectedFilters(selectedFilters.filter((id) => id !== filterId))
  }

  const toggleSelectingFilter = () => {
    setIsSelectingFilter(!isSelectingFilter)
  }

  const filtedLocations = useMemo(() => {
    if (selectedFilters.length === 0) return atmLocations || []
    return (atmLocations || []).filter((atm) => {
      return selectedFilters.every((filterId) => {
        const filter = ATMFilterOptions.find((option) => option.id === filterId)
        return filter.filterFn(atm)
      })
    })
  }, [atmLocations, selectedFilters])

  return {
    selectedFilters,
    setSelectedFilters,
    toggleFilter,
    deleteFilter,
    isSelectingFilter,
    setIsSelectingFilter,
    toggleSelectingFilter,
    filtedLocations
  }
}