import { Button, styled } from "@material-ui/core";


export const PrimaryButton = styled((props) => <Button variant="contained" fullWidth {...props}/>)(({ theme }) => ({
  backgroundColor: theme.palette.other.primaryButtonBg,
  color: theme.palette.common.black,
  padding: theme.spacing(2, 3),
  borderRadius: theme.spacing(0.5),
  fontWeight: 700,
  textTransform: "none",
  "&.Mui-disabled": {
    backgroundColor: theme.palette.other.disableButtonBg,
    color: theme.palette.common.white,
  },
  "&:hover, &:active": {
    backgroundColor: theme.palette.other.primaryButtonBg,
  },

}));

export const SecondaryButton = styled((props) => <Button variant="text" fullWidth {...props}/>)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: theme.palette.common.white,
  outline: "none",
  color: theme.palette.common.black,
  padding: theme.spacing(2, 3),
  fontWeight: 700,
}))

