import {
  API_URL,
  APP_VERSION,
  CLIENT_ID,
  CLIENT_SECRET,
  DEVICE_ID,
  additionalApiData,
} from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getTransactions = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  const { user_id } = data;

  return request(
    API_URL +
    `users/${user_id}/cards/${data.userCardTing}/transactions?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&device_type=${app_type}&from_date=${data.fromDate}&to_date=${data.toDate}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
