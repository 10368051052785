import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    "li": {
        textAlign: "left",
        padding: "5px 0px"
    },
    resendBtnCtr : {
        padding: "0rem 2rem"
    }
}));
