import {
  SET_LIMIT,
  SET_RECIPIENTS,
} from "../../../redux/actionTypes";

const initState = {
  recipients: [],
  limitData: []
};

export default function moveMoneyReducer(state = initState, action) {
  switch (action.type) {
    case SET_RECIPIENTS:
      return Object.assign({}, state, {
        recipients: action.data,
      });
    case SET_LIMIT:
      return Object.assign({}, state, {
        limitData: action.data,
      });
    default:
      return state;
  }
}
