import styled from '@emotion/styled';
import { Button, IconButton } from "@material-ui/core"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const StyledNavButton = styled(IconButton)`
  background-color: #fff;
  color: #555555;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  &:hover{
    background-color: #fff !important;
    color: #555555;
    opacity: 1 !important;
  }
`

export const NavigationButton = ({ onClick, className, style, next, prev }) => {
  const label = prev ? "prev" : 'next'
  return (
    <StyledNavButton aria-label={label} onClick={onClick} className={`${className} navButton__${label}`} style={style}>
      {next && <ArrowForwardIcon />}
      {prev && <ArrowBackIcon />}
    </StyledNavButton>
  )
}