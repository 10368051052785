import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
    Box,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { AppTextField } from "../../../../common/components/AppTextField";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import {
    closeBlackIcon,
} from "../../../../constants/images";

export const AddEditRecipient = (props) => {
    const {
        onHandleDelete,
        successResult,
        errors,
        handleChange,
        handleClose,
        handleSubmit,
        firstName,
        lastName,
        nickName,
        mobileNumber,
        bankName,
        accountType,
        accountNumber,
        confirmAccountNumber,
        routingNumber,
        recipientId,
        isSubmitted,
        isValid,
        isButtonEnabled
    } = props;

    const appClasses = useAppStyles();
    const classes = useStyles();
    return (
        <Dialog
            open={true}
            onClose={() => handleClose()}
            aria-labelledby="form-dialog-title"
            maxWidth={"sm"}
            fullWidth
            PaperProps={{ className: appClasses.paperCard }}
        >

            <DialogTitle id="form-dialog-title">
                <div className={appClasses.dialogTitle}>
                    <IconButton
                        className={appClasses.dialogCloseBtn}
                        onClick={() => handleClose()}
                    >
                        <img src={closeBlackIcon} />
                    </IconButton>
                    <Typography variant="h1" className={appClasses.dialogText}>
                        {recipientId ? "Edit Recipient" : "Add Recipient"}
                    </Typography>
                </div>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent className={appClasses.dialogContent}>
                <Typography variant="h1" className={`${appClasses.dialogText} ${classes.mb10}`}>
                    1. Recipient Details
                </Typography>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"firstName"}
                        name={"firstName"}
                        label="First Name"
                        value={firstName.value}
                        onChange={handleChange}
                        errorText={errors?.firstName}
                    />
                </Grid>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"lastName"}
                        name={"lastName"}
                        label="Last Name"
                        value={lastName.value}
                        onChange={handleChange}
                        errorText={errors?.lastName}
                    />
                </Grid>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"nickName"}
                        name={"nickName"}
                        label="Nick Name (Optional)"
                        value={nickName.value}
                        onChange={handleChange}
                        errorText={errors?.nickName}
                    />
                </Grid>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"mobileNumber"}
                        name={"mobileNumber"}
                        label="Mobile Number"
                        value={mobileNumber.value}
                        onChange={handleChange}
                        errorText={errors?.mobileNumber}
                    />
                </Grid>
                <Typography variant="h1" className={`${appClasses.dialogText} ${classes.mtb10}`}>
                    2. Transfer Info
                </Typography>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"bankName"}
                        name={"bankName"}
                        label="Bank Name"
                        value={bankName.value}
                        onChange={handleChange}
                        errorText={errors?.bankName}
                    />
                </Grid>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"accountType"}
                        name={"accountType"}
                        label="Account Type"
                        select
                        value={accountType.value}
                        onChange={handleChange}
                        errorText={errors?.accountType}
                    >
                        <MenuItem key={"saving"} value={"saving"}>
                            {"Savings"}
                        </MenuItem>
                        <MenuItem key={"checking"} value={"checking"}>
                            {"Checking"}
                        </MenuItem>
                    </AppTextField>
                </Grid>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"accountNumber"}
                        name={"accountNumber"}
                        label="Account Number"
                        value={accountNumber.value}
                        onChange={handleChange}
                        errorText={errors?.accountNumber}
                        inputProps={{
                            maxLength: 16,
                        }}
                    />
                </Grid>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"confirmAccountNumber"}
                        name={"confirmAccountNumber"}
                        label="Confirm Account Number"
                        value={confirmAccountNumber.value}
                        onChange={handleChange}
                        errorText={errors?.confirmAccountNumber}
                        inputProps={{
                            maxLength: 16,
                        }}
                    />
                </Grid>
                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                    <AppTextField
                        id={"routingNumber"}
                        name={"routingNumber"}
                        label="Routing Number"
                        value={routingNumber.value}
                        onChange={handleChange}
                        errorText={errors?.routingNumber}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid className={appClasses.dialogFooter} xs={12} item={true}>
                    <AppButton
                        disabled={isButtonEnabled ? false : true}
                        contained
                        onClick={handleSubmit}
                        className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
                        primary
                    >
                        Save
                    </AppButton>
                
                    {recipientId && <Grid className={classes.btnDelete}><Typography
                        variant="span"
                        className={`${appClasses.text7} ${appClasses.colorBlack700}`}
                    >

                        <a onClick={() => { onHandleDelete() }}>Delete</a>
                    </Typography></Grid>}
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
