import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routes, VIEW_CARD_SET_PIN_STEP } from "../../../constants";
import { bindActionCreators } from "redux";
import history from "../../../history";
import * as actions from "../actions";
//import { PayfareMarqeta } from 'https://qa2-marqeta.payfare.com/paidapp/marqeta-widget.js'
import * as contentfulActions from "../../contentful/actions";

import { Dashboard } from "../components/Dashboard";
import { openContentFulDocOnRes } from "../../../utilities";
import { useLocation } from "react-router-dom";

export const DashboardPage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state: locationState } = location;

  const contentfulAction = bindActionCreators(contentfulActions, dispatch);
  const reducer = useSelector((state) => state.dashboardReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const { loginResponseData } = authReducer;

  const [pinDialog, setPinDialog] =
    React.useState(undefined);

  const [viewcardDialog, setViewCardViewCardDialog] =
    React.useState(undefined);

  const [currentStepOfSetPinOrViewCard, setCurrentStepOfSetPinOrViewCard] =
    React.useState(undefined);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (locationState?.viewVirtualCard) {
      onClickViewVirtualCard();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickViewVirtualCard = () => {
    setCurrentStepOfSetPinOrViewCard(VIEW_CARD_SET_PIN_STEP.viewCard);
    setViewCardViewCardDialog(true);
  }

  const handleSetPinPopUp = () => {
    setPinDialog(true);
    setCurrentStepOfSetPinOrViewCard(VIEW_CARD_SET_PIN_STEP.setPin);
  }

  const handleClose = () => {
    //getData();
    setPinDialog(false);
  }

  const handleViewCardDialogClose = () => {
    setViewCardViewCardDialog(false);
  }

  const getData = () => {
    if (!loginResponseData) {
      return;
    }
    const authData = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
    };
    dispatch(actions.clearCardBalances());
    dispatch(actions.doGetAndSetCardBalances({ ...authData }));
    dispatch(actions.doGetAndSetRewardBalance({ ...authData }));
    dispatch(actions.doGetAndSetDashboardCards({ ...authData }));
  };

  const goTo = (route) => {
    history.push(route);
  };

  const handleGoalsClick = () => {
    goTo(routes.goals.path);
  };

  const handleTransactionsClick = () => {
    goTo(routes.transactions.path);
  };

  const handleRewardsClick = () => {
    goTo(routes.rewards.path);
  };

  const handleHelpClick = () => {
    goTo(routes.help.path);
  };
  const handleAtmClick = () => {
    goTo(routes.atmfinder.path);
  };
  const handleStatementClick = () => {
    goTo(routes.statements.path);
  };
  const directDepositQuickServiceClick = () => {
    goTo(`${routes.deposit.path}/direct`);
  };

  const openTnC = () => {
    contentfulAction.doGetAndSetContent(
      contentfulActions.contentfulActionTypes.registrationTnC,
      (res) => {
        openContentFulDocOnRes(res);
      }
    )
  }

  const propsTobeForward = {
    ...reducer,

    handleGoalsClick,
    handleTransactionsClick,
    handleRewardsClick,
    onClickViewVirtualCard,
    handleHelpClick,
    handleAtmClick,
    handleStatementClick,
    directDepositQuickServiceClick,
    openTnC,
    pinDialog,
    viewcardDialog,
    currentStepOfSetPinOrViewCard,
    handleSetPinPopUp,
    handleClose,
    handleViewCardDialogClose
  };

  return <Dashboard {...propsTobeForward} />;
};
