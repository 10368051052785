import { apiCall, apiRequest } from "../../../common/actions/apiCall";
import * as authActions from "../../auth/actions";
import * as toastActions from "../../../common/actions/toast";
import { TOAST_TYPES } from "../../../common/components/core";
import {
  changeEmail,
  changeMobile,
  getSuggestedAddress,
  getSupportEmail,
  getUserAddresses,
  getUserProfile,
  getUserShippingAddresses,
  saveAddress,
  saveShippingAddress,
  sendOtpCurrentEmail,
  sendOtpCurrentMobile,
  sendOtpNewEmail,
  sendOtpNewMobile,
  verifyOtpCurrentEmail,
  verifyOtpCurrentMobile,
  verifyOtpNewEmail,
  verifyOtpNewMobile,
  verifyPassword,
} from "../services";
import {
  PROFILE_SETTING_SET_USER_EMAIL,
  SET_SUPPORT_EMAIL,
  SET_USER_ADDRESSES,
  SET_USER_DATA,
  SET_USER_SHIPPING_ADDRESSES,
} from "../../../redux/actionTypes";

export const setUserData = (response) => {
  return {
    type: SET_USER_DATA,
    data: response,
  };
};

const setUserEmail = (email) => {
  return {
    type: PROFILE_SETTING_SET_USER_EMAIL,
    data: email,
  }
}

export const setUserAddresses = (response) => {
  return {
    type: SET_USER_ADDRESSES,
    data: response,
  };
};

export const setUserShippingAddresses = (response) => {
  return {
    type: SET_USER_SHIPPING_ADDRESSES,
    data: response,
  };
};

export const setSupportEmail = (email) => {
  return {
    type: SET_SUPPORT_EMAIL,
    data: email,
  };
};

export const doVerifyPassword = (data, callBackFn) => (dispatch) => {
  apiCall(dispatch, verifyPassword(data), {
    showLoader: true,
    displayError: true,
    disableHandlerUnAuthorized: true,
  }, true).then(
    (response) => {
      if (response?.data?.status == "success") {
        callBackFn && callBackFn(true);
      }
    },
    (error) => {
      const errors = error?.response?.data?.errors;
      if (errors && errors.length > 0) {
        const errorDetail = errors[0];
        if (errorDetail.code && errorDetail.code == 8205) {
          dispatch(authActions.doLogout());
          dispatch(authActions.setDisplayWrongAttemptPopup(true));
        }
      }
      callBackFn && callBackFn(false, errors);
    }
  );
};

export const doSendOtpNewMobile = (data, callBackFn) => (dispatch) => {
  apiCall(dispatch, apiRequest(dispatch, sendOtpNewMobile)(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(
          toastActions.show(
            "Check your text",
            TOAST_TYPES.SUCCESS
          )
        );
        callBackFn && callBackFn(true);
      }
    },
    (error) => {
      callBackFn && callBackFn(false);
    }
  );
};

export const doSendOtpCurrentMobile = (data, callBackFn) => async (dispatch) => {
  const response = await apiCall(dispatch, apiRequest(dispatch, sendOtpCurrentMobile)(data), {
    showLoader: true,
    displayError: true,
  })
  dispatch(
    toastActions.show(
      "Check your text",
      TOAST_TYPES.SUCCESS
    )
  );
  return response
};

export const doVerifyOtpNewMobile = (data) => (dispatch) => {
  return apiRequest(dispatch, verifyOtpNewMobile)(data)
};

export const doVerifyOtpCurrentMobile = (data, callBackFn) => async (dispatch) => {
  const response = await apiCall(dispatch, apiRequest(dispatch, verifyOtpCurrentMobile)(data), {
    showLoader: true,
    displayError: true,
  })
  return response?.data?.data




  // apiCall(dispatch, verifyOtpCurrentMobile(data), {
  //   showLoader: true,
  //   displayError: true,
  // }).then(
  //   (response) => {
  //     if (response?.data?.status == "success") {
  //       callBackFn && callBackFn(response?.data?.data);
  //     }
  //   },
  //   (error) => {
  //     callBackFn && callBackFn(false);
  //   }
  // );
};

const toastError = (error) =>{
  let message = error?.response?.data?.message
    if (!message) {
      message = "Something went wrong"
    }
  return toastActions.show(message, TOAST_TYPES.ERROR)
}

export const doSendOtpCurrentEmail = (data) => async (dispatch) => {
  try {
    const response = await apiRequest(dispatch, sendOtpCurrentEmail)(data)
    if (response?.data?.status === "success") {
      dispatch(
        toastActions.show(
          "Check your email",
          TOAST_TYPES.SUCCESS
        )
      );
    }
    return response
  } catch (error) {
    dispatch(toastError(error))
    return false
  }
};

export const doSendOtpNewEmail = (data, callBackFn) => (dispatch) => {
  apiCall(dispatch, apiRequest(dispatch, sendOtpNewEmail)(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(
          toastActions.show(
            "Check your email",
            TOAST_TYPES.SUCCESS
          )
        );
        callBackFn && callBackFn(true);
      }
    },
    (error) => {
      callBackFn && callBackFn(false);
    }
  );
};

export const doVerifyOtpCurrentEmail = (data, callBackFn) => async (dispatch) => {
    const response =  await apiRequest(dispatch, verifyOtpCurrentEmail)(data)
    return response?.data?.data
};

export const doVerifyOtpNewEmail = (data, callBackFn) => async (dispatch) => {
  const response = await apiCall(dispatch, verifyOtpNewEmail(data), { showLoader: true, displayError: true, })
  return response?.data?.data
};

export const doChangeMobile = (data) => async (dispatch) => {
  try {
    await apiCall(dispatch, changeMobile(data), {
      showLoader: true,
      displayError: true,
    })
    dispatch(toastActions.show("Profile Updated", TOAST_TYPES.SUCCESS));
    return true
  } catch (error) {
    dispatch(toastError(error))
    return false
  }
};

export const doChangeEmail = (data) => async (dispatch) => {
  try{
    const response = await changeEmail(data)
    const email = response?.data?.data?.user?.email
    if (email) {
      dispatch(toastActions.show("Profile Updated", TOAST_TYPES.SUCCESS));
      dispatch(setUserEmail(email))
      dispatch(authActions.setUsername(email))
    }
    return response?.data?.data
  }catch(error){
    dispatch(toastError(error))
    throw error
  }
};

export const doSaveAddress = (data, callBackFn) => (dispatch) => {
  apiCall(dispatch, saveAddress(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(toastActions.show("Profile Updated", TOAST_TYPES.SUCCESS));
        callBackFn && callBackFn(true);
      }
    },
    (error) => {
      callBackFn && callBackFn(false);
    }
  );
};

export const doSaveShippingAddress = (data, callBackFn) => (dispatch) => {
  apiCall(dispatch, saveShippingAddress(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(toastActions.show("Profile Updated", TOAST_TYPES.SUCCESS));
        callBackFn && callBackFn(true);
      }
    },
    (error) => {
      callBackFn && callBackFn(false);
    }
  );
};

export const doGetAndSetUserProfile = (data) => (dispatch) => {
  apiCall(dispatch, getUserProfile(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(setUserData(response?.data?.data || {}));
      }
    },
    (error) => {
      setUserData({});
    }
  );
};

export const doGetAndSetSupportEmail = (data) => (dispatch) => {
  apiCall(dispatch, getSupportEmail(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(setSupportEmail(response?.data?.data?.email || ""));
      }
    },
    (error) => {
      setUserData({});
    }
  );
};

export const doGetAndSetUserAddresses = (data) => (dispatch) => {
  apiCall(dispatch, getUserAddresses(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(setUserAddresses(response?.data?.data?.addresses || {}));
      }
    },
    (error) => {
      setUserData([]);
    }
  );
};
export const doGetAndSetUserShippingAddresses = (data) => (dispatch) => {
  apiCall(dispatch, getUserShippingAddresses(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(setUserShippingAddresses(response?.data?.data?.profile || {}));
      }
    },
    (error) => {
      setUserData([]);
    }
  );
};

export const doGetAndSetSuggestedAddress =
  (data, payload, callBackFn) => (dispatch) => {
    apiCall(dispatch, getSuggestedAddress(data, payload), {
      showLoader: true,
      displayError: false,
    }).then(
      (response) => {
        if (response?.data?.status == "success") {
          const { suggested } = response?.data?.data;
          callBackFn && callBackFn(true, suggested);
        }
      },
      (error) => {
        callBackFn && callBackFn();
      }
    );
  };


export const doSubmitAddress = ({isResidentialAddress, ...data}) => async (dispatch) => {
  const { address_line2, address_line1 } = data;
  const addressApiRequest = isResidentialAddress ? saveAddress : saveShippingAddress;
  try{
    const res = await addressApiRequest({
      address_line_1: address_line1,
      address_line_2: address_line2,
      ...data
    });
    if (res?.data?.status === "success") {
      dispatch(toastActions.show("Profile Updated", TOAST_TYPES.SUCCESS));
      const nextAction = isResidentialAddress ? doGetAndSetUserAddresses : doGetAndSetUserShippingAddresses;
      dispatch(nextAction(data));
    }else{
      dispatch(toastActions.show("Something went wrong", TOAST_TYPES.ERROR));
    }
  }catch(err){
    dispatch(toastActions.show("Something went wrong", TOAST_TYPES.ERROR));
  }
}

