import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import * as addEditRecipientActions from "../actions";
import { AddEditRecipient } from "../components/AddEditRecipient";
import { SendRecipientSuccess } from "../components/SendToRecipient/SendToRecipientSuccess";

const initialState = {

};

class SendToRecipientSuccessDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }



    handleClose = (afterSaveSuccess) => {
        const { handleClose } = this.props;
        handleClose && handleClose(afterSaveSuccess);
    };

    render() {
        let { name, amount, ting, confirmation, message } = this.props;
        const { handleClose } = this;
        const propsToForward = {
            ...this.state,
            handleClose,
            name,
            amount,
            ting,
            confirmation,
            message
        };
        return <SendRecipientSuccess {...propsToForward} />;
    }
}

const mapStateToProps = (state) => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(actions, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SendToRecipientSuccessDialog);
