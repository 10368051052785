import { Typography, Box, Paper, TableContainer, TableBody, styled, Table } from "@material-ui/core";
import React from "react";


import moment from "moment";
import StatementYearRow from "./StatementYearRow";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import useMobileScreenCollection from "../../../../hooks/userMobileScreenCollection";

const yearFromDate = (date) => {
  return moment(date).year();
}

const StatementWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
}))

const StatementPaper = styled(Paper)(({ theme, noStatement }) => ({
  minHeight: noStatement ? '500px' : 'auto',
  marginTop: theme.spacing(7),
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(2),
  height: 'calc(100vh - 220px)',
  overflowY: 'auto',
}))

const StatementHeader = () => {
  return <Box>
    <Typography variant="h2">Statements </Typography>
  </Box>
}

const NoStatementWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const StatementsTable = ({ statements }) => {
  
  const noStatements = !statements || statements.length === 0;

  if (noStatements) {
    return <NoStatementWrapper><Typography variant="subtitle1">No statements are available</Typography></NoStatementWrapper>
  }

  const statementsByYears = statements.reduce((acc, statement) => {
    const year = yearFromDate(statement.start_date);
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(statement);
    return acc;
  }, {});

  const sortedYear = Object.keys(statementsByYears) .sort((a, b) => b - a)
  
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {sortedYear.map((year) => (
            <StatementYearRow key={year} year={year} statements={statementsByYears[year]} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export const Statements = (props) => {
  let { statement, isStatementsTable } = props;
  const noStatement = !statement || statement.length === 0;
  const mobileScreenCollection = useMobileScreenCollection()
  return (
    <StatementWrapper>
      {!isStatementsTable && <StatementHeader />}
      <StatementPaper noStatement={noStatement}>
        <StatementsTable statements={statement}/>
      </StatementPaper>
      <Box sx={{ my: 1 }}>
      {!isStatementsTable && <MobileHelpScreen iconName="button" topics={mobileScreenCollection} screenName={'Statements'} anchor="top" />}
      </Box>
    </StatementWrapper>
  );
};
