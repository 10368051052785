import { Grid, Typography, Box, Paper, Chip } from "@material-ui/core";
import React, { useEffect } from "react";

import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { AppListItemCard } from "../../../../common/components/AppListItemCard";
import { SidemenuViewContent } from "../../../../common/components/SidemenuViewContent";
import { SIDEMENU_ICONS } from "../../../../constants";
import { DirectDeposit } from "./DirectDeposit";
import { CashDeposit } from "./CashDeposit";
import { Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useRequest } from "ahooks";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import useMobileScreenCollection from "../../../../hooks/userMobileScreenCollection";

export const Deposit = (props) => {
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory()
  const { pathname } = useLocation()
  const mobileScreenCollection = useMobileScreenCollection()

  const onCloseContent = () => {
    history.push(path)
  }

  const {
    bankAccountDetails,
    isGotBankAccountDetails,
    helpTopicCashDeposit,

    handleViewDirectDiposit,
  } = props;

  const { run: loadDirectDeposit, loading: directDepositDataLoading } = useRequest(handleViewDirectDiposit, {
    manual: true,
    cacheKey: 'user-direct-deposit',
    staleTime: 3 * 60 * 1000,
  });

  useEffect(() => {
    if (pathname === `${path}/direct`) {
      loadDirectDeposit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const openDirectDeposit = async () => {
    history.push(`${path}/direct`)
  };

  const openCashDeposit = () => {
    history.push(`${path}/cash`)
  }

  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div className={classes.goalHeader}>
            <Typography variant="h4" className={appClasses.text8}>
              Deposit
            </Typography>
          </div>
          <Box mt={4}></Box>

          <div>
            <AppListItemCard
              icon={SIDEMENU_ICONS.ccGreenImg}
              title={"Direct Deposit"}
              desc={"Add funds to your prepaid debit account"}
              active={pathname === `${path}/direct`}
              onClick={openDirectDeposit}
            ></AppListItemCard>
            <Box mt={2}></Box>
            <AppListItemCard
              icon={SIDEMENU_ICONS.ccGreenImg}
              title={"Cash Deposit"}
              desc={"Deposit cash onto your card at major retail chains"}
              active={pathname === `${path}/cash`}
              onClick={openCashDeposit}
            ></AppListItemCard>
            <Box mt={2}></Box>
          </div>
        </Grid>
      </Grid>
      <Switch>
        <Route path={`${path}/direct`}>
          <SidemenuViewContent
            open={!directDepositDataLoading}
            onClose={onCloseContent}
            headerComponent={
              <Box className={classes.viewVirtualCardHeader} style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
                <MobileHelpScreen topics={mobileScreenCollection} screenName="SettingsDirectDeposit" />
              </Box>
            }
          >
            <DirectDeposit
              details={bankAccountDetails}
              isGotResponse={isGotBankAccountDetails}
              isDirectDepositTable={false}
            />
          </SidemenuViewContent>
        </Route>

        <Route path={`${path}/cash`}>
          <SidemenuViewContent open
            onClose={onCloseContent}
            >
            <CashDeposit helpTopicCashDeposit={helpTopicCashDeposit} />
          </SidemenuViewContent>
        </Route>
      </Switch>

    </div>
  );
};
