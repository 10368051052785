import React from 'react';

import {
  Box,
  TableBody,
  Typography,
  styled,
} from "@material-ui/core";

import { AppTable } from "../../../../common/components/AppTable";

import TransactionRow from './TransactionRow';

const NoTransactionWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(15, 0),
  border: 0,
  textAlign: "center",
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(10, 0),
  }
}))

const NoTransaction = () => {
  return <NoTransactionWrapper>
    <Typography variant='body2'>No transactions to display</Typography>
  </NoTransactionWrapper>
}


function TransactionTable(props) {
  const { headCells, listCardHeight, pendingTransaction, successTransaction } = props;
  const pendingTitle =
    "Amounts of pending transactions may not be final and are determined by the merchants. Contact the merchant directly for any questions regarding pending transactions.";
  const susccesTitle =
    "Posted transactions have been fully processed. Amounts in posted transactions are final.";

  const isNoTransaction = (!pendingTransaction || pendingTransaction.length === 0) && (!successTransaction || successTransaction.length === 0);

  if (isNoTransaction) return <NoTransaction />

  return (
    <AppTable
      columns={headCells}
      style={{ height: listCardHeight }}
      removePagination
      tableBody={
        <TableBody>
          {pendingTransaction && pendingTransaction.length > 0 && (
            <TransactionRow
              status={'Pending'}
              transactions={pendingTransaction}
              columns={headCells}
              title={pendingTitle}
            />
          )}
          <TransactionRow status={'Posted'} transactions={Array.isArray(successTransaction) && successTransaction} columns={headCells} title={susccesTitle} />
        </TableBody>
      }
    />
  );
}

export default TransactionTable;
