import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon, handRaisedImg, warningIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";

export const ReplaceCardErrorDialog = (props) => {
  const { handleClose } = props;

  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleClose()}
          >
            <img src={closeBlackIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider></Divider>

      <DialogContent className={appClasses.dialogContent1}>
        <div className={classes.imgWrapper}>
          <img src={warningIcon} height={50} width={50} />
        </div>
        <div className={classes.imgWrapper}>

          <Typography variant="span" className={appClasses.text1}>
            We are unable to process your request at the moment. Pelase try again later.
          </Typography>

        </div>
        <Box mt={3} />
        <AppButton
          contained
          onClick={() => { handleClose() }}
          className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
          primary
        >
          Okay
        </AppButton>
        <Box mt={2} />
      </DialogContent>
    </Dialog>
  );
};
