import { API_URL, additionalApiData } from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getPurses = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
      `users/${data.user_id}/cards/${data.card_ting}/purses?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPurseTransactions = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
      `users/${data.user_id}/cards/${data.card_ting}/purse/${data.purse_ting}?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
