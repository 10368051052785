import moment from "moment";
import { API_URL, additionalApiData } from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getPrimaryCard = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/cards?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )

};

export const getPurses = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/cards/${data.card_ting}/purses?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getShippingAddress = (data) => {
  const { channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/card/${data.card_ting}/shipping-address?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSuggestedAddress = (data, payload) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  return request(
    API_URL + `users/${data.user_id}/suggest-address`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      channel: channel,
      lang_code: lang_code,
      company_id: company_id,
      country_code: country_code,
      ...payload,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const setUserShippingAddress = (data, userData) => {
  const { channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/card/${data.card_ting}/shipping-address?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      "channel": channel,
      "lang_code": lang_code,
      "company_id": company_id,
      "country_code": country_code,
      ...userData
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const reissueCard = (data, userData) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/card/${data.card_ting}/reissue-card?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&company_id=${company_id}`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      "channel": channel,
      "lang_code": lang_code,
      "country_code": country_code,
      "company_id": company_id,
      "identifier": userData.identifier,
      "password": userData.password,
      app_type, app_version,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const setCardStatus = (data, userData, cardPayload, status) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/cards/${data.card_ting}/status`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      "channel": channel,
      "lang_code": lang_code,
      "country_code": country_code,
      "company_id": company_id,
      "identifier": userData.identifier,
      "password": userData.password,
      "status": cardPayload.reason,
      "reason": cardPayload.reason,
      "report_date": moment().format("MM/DD/YYYY"),
      app_type, app_version,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSetPinCardURL = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;

  const { ting, user_id } = data;
  return request(
    API_URL + `users/${user_id}/card-set-pin-link`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      ting,
      channel,
      lang_code,
      country_code,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getViewCardURL = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;

  const { ting, user_id } = data;
  return request(
    API_URL + `users/${user_id}/card-display-link`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      ting,
      channel,
      lang_code,
      country_code,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getPurseTransactions = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/cards/${data.card_ting}/purse/${data.purse_ting}?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifyPassword = (data) => {
  const { channel, country_code, lang_code, company_id, app_version, app_type } = additionalApiData;
  const { user_id, identifier, password, bibson } = data;
  return request(
    API_URL + `users/${user_id}/write-auth`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": bibson,
    },
    {
      identifier,
      password,
      app_version,
      channel,
      lang_code,
      country_code,
      company_id,
      app_type,
    }
  )
}

export const activateCardByDetails = (data) => {
  const { channel, country_code, lang_code, company_id } = additionalApiData;
  const { last_four, expiry_month, expiry_year, user_id } =
    data;

  return request(
    API_URL + `users/${user_id}/cards/card-details/activate`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      expiry_month,
      expiry_year,
      last_four,
      channel,
      lang_code,
      country_code,
      company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
