import { LOGOUT } from "../../redux/actionTypes";
import authCheck from "../services/auth_check";

export const validateCurrentBishop = (currentLoginResponseData) => async (dispatch) => {
  const { bibson, user_id } = currentLoginResponseData;
  try {
    await authCheck({ bibson, user_id, });
  } catch (error) {
    dispatch({ type: LOGOUT, });
  }
}

