import { useSafeState } from "ahooks";
import React from "react";
import { useSelector } from "react-redux";
import { ReplaceCardContext } from "./useReplaceCard";


export default function ReplaceCardProvider({ children, step, onNextStep, isVirtualCard, closeAllSidemenu, onSuccessReplaceCard, handleClose }) {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [replaceReason, setReplaceReason] = React.useState("");
  const [shippingAddress, setShippingAddress] = useSafeState();

  const manageCardReducer = useSelector((state) => state.managecardReducer);
  const { currentActivatedCard } = manageCardReducer;
  const cardTing = currentActivatedCard?.ting

  const value = {
    isVirtualCard,
    currentPassword,
    setCurrentPassword,
    replaceReason,
    setReplaceReason,
    step,
    onNextStep,
    shippingAddress,
    setShippingAddress,
    closeAllSidemenu,
    onSuccessReplaceCard,
    cardTing,
    handleClose
  }

  return <ReplaceCardContext.Provider value={value}>
    {children}
  </ReplaceCardContext.Provider>;
}

