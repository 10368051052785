import { Box, Link, Typography, styled } from '@material-ui/core'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useATMFinderContext } from '../../store/atmhooks';

const Wrapper = styled(Box)(({ theme }) => ({
  border: 'none',
  borderBottom: '1px solid rgba(231, 231, 231, 0.9);',
  padding: theme.spacing(2, 4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 4),
    '&.no-result': {
      borderBottom: 'none',
    }
  }
}))

const ShowListLink = styled(Link)(({ theme }) => ({
  textAlign: "center",
  fontSize: '12px',
  fontWeight: 700,
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  }
}))

const ToggleShowList = ({ onClickHandler, showing, noResult }) => {
  if (noResult) return null
  return <ShowListLink component={'div'} onClick={onClickHandler}>
    {showing ? <HorizontalRuleIcon /> : "Show List"}
  </ShowListLink>
}

const ResultMessage = ({ noResult, displayListAtm }) => {
  if (!noResult && !displayListAtm) return null
  const text = noResult ? "No Results" : "ATMs Nearby"


  return <Typography variant='h6' style={{ color: '#9D9D9D' }}>{text}</Typography>
}

export default function ATMResultTitle({ noResult }) {
  const { displayListAtm, setShowList, showList } = useATMFinderContext()
  const modifyClass = (noResult || !displayListAtm) ? "no-result" : ""
  const onClickHandler = () => {
    setShowList(!showList)
  }
  return <Wrapper className={modifyClass}>
    <ToggleShowList onClickHandler={onClickHandler} showing={showList} noResult={noResult} />
    <ResultMessage noResult={noResult} displayListAtm={displayListAtm} />
  </Wrapper>
}