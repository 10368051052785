import {
  Grid,
  Typography,
  Box,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../../../App.style";

import { SetOrChangePinCardPage } from "../../../containers/SetOrChangePinCardPage";
import { useStyles } from "../style";

export const SetPin = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();

  const {
    handleCloseActivateCard
  } = props;

  return (
    <>
      <DialogTitle className={classes.header}>
        <Typography variant="h6" className={appClasses.text3}>
          Activate Physical Card
        </Typography>
        <Box mt={1} />
        <Typography variant="span" className={appClasses.text1}>
          You'll use this PIN with your card at ATMs or in stores. Make sure
          it's easy to remember, and keep it safe.
        </Typography>
      </DialogTitle>
      <Box mt={2} />
      <DialogContent className={appClasses.dialogContent}>
        <SetOrChangePinCardPage noTitle handleCloseActivateCard={handleCloseActivateCard} requirePassword={false} />
      </DialogContent>
    </>
  );
};
