import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as contentfulActions from "../../contentful/actions";
import { HelpSection } from "../components/HelpSection";

export const HelpSectionPage = (props) => {
  const dispatch = useDispatch();

  const contentfulReducer = useSelector((state) => state.contentfulReducer);
  const { helpCategoryCollection } = contentfulReducer;

  const [searchText, setSearchText] = React.useState("");
  const [searchResult, setSearchResult] = React.useState([]);

  const [openViewIndex, setOpenViewIndex] = React.useState(-1);
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const [menuData, setMenuData] = React.useState(undefined);
  const [menuDetailViewData, setMenuDetailViewData] = React.useState(undefined);

  const handleViewMenu = (data, index) => {
    closeAllSideMenu();
    if (searchText) {
      handleMenuViewDetail(data);
    } else {
      setMenuData(data);
    }
    setIsOpenMenu(true);
    setOpenViewIndex(index);
  };
  const handleCloseMenu = () => {
    if (menuDetailViewData && !searchText) {
      setMenuDetailViewData(undefined);
      return;
    }
    closeAllSideMenu();
  };

  const handleMenuViewDetail = (data) => {
    setMenuDetailViewData(data);
  };

  const closeAllSideMenu = () => {
    setIsOpenMenu(false);
    setOpenViewIndex(-1);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name == "searchText") {
      setSearchText(value);
    }
  };

  const handleClearSearchText = () => {
    setSearchText("");
  };

  useEffect(() => {
    let newSearchResult = [];
    if (searchText) {
      const helpCategoryList = helpCategoryCollection?.items || [];
      for (let i = 0; i < helpCategoryList.length; i++) {
        const items =
          helpCategoryList[i].linkedFrom?.helpTopicCollection?.items || [];
        for (let j = 0; j < items.length; j++) {
          if (
            items[j].title &&
            items[j].title.toLowerCase().includes(searchText.toLowerCase())
          ) {
            newSearchResult.push(items[j]);
          }
        }
      }
    }
    setSearchResult(newSearchResult);
  }, [searchText]);

  useEffect(() => {
    dispatch(
      contentfulActions.doGetAndSetContent(
        contentfulActions.contentfulActionTypes.helpCategoryCollection
      )
    );
  }, []);

  const propsTobeForward = {
    menuDetailViewData,

    menuData,
    handleMenuViewDetail,

    helpCategoryCollection,

    isOpenMenu,
    openViewIndex,

    handleCloseMenu,
    handleViewMenu,

    searchText,
    searchResult,

    handleClearSearchText,
    handleChange,
  };

  return <HelpSection {...propsTobeForward} />;
};
