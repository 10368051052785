import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";
import { useRequest } from "ahooks";


const AddressFormat = ({ address, label }) => {
  const { address_line2, address_line1, city, region, postal_code } = address;
  const addressLine = [address_line1, address_line2].filter(Boolean).join(", ");
  return <>
    {label} <br />
    {addressLine} <br />
    {city}, {region}   ,
    {postal_code}
  </>
}

const SuggestAddressModalBox = (props) => {
  const {
    address,
    onClose,
    suggestedAddress,
    saveAddress
  } = props;

  const appClasses = useAppStyles();

  const [selectedAddress, setSelectedAddress] = React.useState("current");


  const onRadioButtonChange = (event) => {
    setSelectedAddress(event.target.value);
  }

  const saveAddressHandler = async () => {
    const addressToSave = selectedAddress === "current" ? address : suggestedAddress;
    await saveAddress(addressToSave);
    return onClose();
  }

  const { loading, run: submitAddress } = useRequest( saveAddressHandler, {  manual: true})

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="suggested-address-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="suggested-address-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={onClose}
          >
            <img src={closeBlackIcon} alt="close icon" />
          </IconButton>
          <Typography variant="h6" className={appClasses.text1}>
            Confirm Address
          </Typography>
        </div>
      </DialogTitle>
      <Divider></Divider>
      <Box mt={1} />
      <DialogContent className={appClasses.dialogContent}>
        <RadioGroup
          name={"confirmedAddress"}
          value={selectedAddress}
          onChange={onRadioButtonChange}
        >
          <FormControlLabel
            className={appClasses.text11}
            value="current"
            control={<Radio />}
            label={<AddressFormat address={address} label="Address as entered" />}
          />
          <Box mt={2}></Box>
          <FormControlLabel
            className={appClasses.text11}
            value="suggested"
            control={<Radio />}
            label={<AddressFormat address={suggestedAddress} label="Suggested address" />}
          />
        </RadioGroup>
        <Box mt={2} />
        <AppButton
          contained
          onClick={submitAddress}
          disabled={loading}
          className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
          primary
        >
          Confirm Address
        </AppButton>
        <Box mt={2} />
        <AppButton
          variant="text"
          className={`${appClasses.textBtn} ${appClasses.width100p}`}
          onClick={onClose}
        >
          Try Another
        </AppButton>
      </DialogContent>
    </Dialog>
  );
};


export default SuggestAddressModalBox;