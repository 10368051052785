import {
  Grid,
  Typography,
  Box,
  Paper,
  Divider,
  Menu,
  Chip,
  Tabs,
  Hidden,
} from "@material-ui/core";

import React from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { TabContext } from '@material-ui/lab';

import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import CloseIcon from "@material-ui/icons/Close";
import Tab from '@material-ui/core/Tab';
import {
  helpWhiteIcon,
  infoIcon,
  filterBlackIcon,
  DATE_FORMAT,
  appColors,
  routes,
  arrowLeftIcon,
  arrowLeftIosIcon,
  arrowRightIosIcon
} from "../../../../constants";
import { AppButton } from "../../../../common/components/AppButton";
import { AppTable } from "../../../../common/components/AppTable";
import { useAppTableStyles } from "../../../../common/components/AppTable/style";
import { useState } from "react";
import { DateFilter } from "../DateFilter";
import moment from "moment";
import { Amount } from "../../../../common/components/Amount";

import { IconButton } from "../../../../common/components/core";
import history from "../../../../history";
import { ViewTransaction } from "../ViewTransaction";
import { Statements } from "../../../statements/components/Statements";
import { DirectDepositWithDescription } from "../../../deposit/components/Deposit/DirectDeposit";
import TransactionTable from '../TransactionTable/TransactionTable';
import TabPanel from './TabPanel'
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import useMobileScreenCollection from "../../../../hooks/userMobileScreenCollection";
export const Transactions = (props) => {
  const appTableClasses = useAppTableStyles();
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();
  const statementReducer = useSelector((state) => state.statementReducer);
  const transactionsIndex = 0
  const statementsIndex = 1
  const accountInfoIndex = 2
  const isStatementsTable = true;

  const [showTxFilter, setShowTxFilter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const mobileScreenCollection = useMobileScreenCollection()
  const handleClickShowTxFilter = (event) => {
    setShowTxFilter(event.currentTarget);
  };

  const handleCloseShowTxFilter = () => {
    setShowTxFilter(null);
  };

  const onHandleStartDateChange = (value) => {
    setStartDate(moment(value).format());
  };

  const onHandleEndDateChange = (value) => {
    setEndDate(moment(value).format());
  };

  const onClearDate = () => {
    setStartDate(null);
    setEndDate(null);
    props.removeDateChip();
  }

  const onHandleDateSubmit = () => {
    props.onClickApply(startDate, endDate);
    handleCloseShowTxFilter();
  };

  const propsTobeForward = {
    ...statementReducer,
    isStatementsTable
  };



  const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      canSort: false,
      render: (row) => <Moment format={DATE_FORMAT}>{row.date}</Moment>,
    },
    {
      id: "desc",
      numeric: false,
      disablePadding: false,
      label: "Details",
      render: (row) => row.desc,
    },
    {
      id: "amount",
      numeric: false,
      disablePadding: false,
      label: "Amount",
      render: (row) => (
        <span className={appListClasses.cellWithBtn}>
          <Amount>{row.amount}</Amount>
          <IconButton>
            <img onClick={() => { props.setViewTransaction(row) }} src={arrowRightIosIcon} />
          </IconButton>
        </span>
      ),
    },
  ];

  const listCardHeight = `${window.innerHeight - 280}px`;

  // Only TabPanel 0  - Transactions will have the date filter
  const isFilterAvilable = () => {
    return tabValue === transactionsIndex
  }

  // TODO: due to time contraints, I just moved the code from the old component to this one
  // TODO: I will refactor this later.
  const dateFilter = () => {
    if (!isFilterAvilable()) return null;
    return <>
      {props.isDateChipDisplay && startDate && endDate && <Chip
        deleteIcon={<CloseIcon className={classes.muiDeleteIcon} />}
        onDelete={() => { onClearDate() }}
        label={
          <>
            <Moment format={DATE_FORMAT}>{startDate}</Moment>
            {" - "}
            <Moment format={DATE_FORMAT}>{endDate}</Moment>
          </>
        }
        className={classes.dateChip}
      />}
      <AppButton
        startIcon={<img src={filterBlackIcon} alt="filter" />}
        contained
        className={`${appClasses.filterBtn} ${appClasses.filterBtnPos} ${classes.filterButton}`}
        primary
        onClick={handleClickShowTxFilter}
      >
        Filter
      </AppButton>

      <Menu
        id="user-menu"
        anchorEl={showTxFilter}
        keepMounted
        open={Boolean(showTxFilter)}
        onClose={handleCloseShowTxFilter}
        PaperProps={{
          className: `${appClasses.dropdownMenu} ${classes.dateFilterContainer}`
        }}
      >
        <DateFilter
          onHandleEndDateChange={onHandleEndDateChange}
          onHandleStartDateChange={onHandleStartDateChange}
          startDate={startDate}
          endDate={endDate}
          onClickApply={() => {
            onHandleDateSubmit();
          }}
        />
      </Menu>
    </>
  }


  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div className={classes.backBtnContainer}>
            <IconButton color="primary" aria-label="Back to login" onClick={() => { history.push(routes.dashboard.path) }} className={classes.backIconBtn}>
              <img src={arrowLeftIosIcon} alt="back"/>
            </IconButton>
            <Typography
              variant="h6"
              className={`${appClasses.text10} ${classes.backBtnText}`}
            >
              Back to Account
            </Typography>
          </div>
          <div className={classes.goalHeader}>
            <div>
              <Typography variant="h1" className={appClasses.text2}>
                <Amount removePlusSign removeSuccessColor>
                  {props.cardBalance}
                </Amount>
              </Typography>
              <Box mt={1}></Box>
              <Typography variant="h6" className={appClasses.text1}>
                Account Balance
              </Typography>
            </div>
          </div>
          <Box mt={4}></Box>
          <Paper className={`${appClasses.paperCard}`}>
            <div className={`${appListClasses.listContainer}`}>
              <Tabs
                value={tabValue}
                onChange={(event, number) => setTabValue(number)}
                aria-label="basic tabs example"
                className={appListClasses.tabs}
              >
                <Tab label="Transactions" />
                <Tab label="Statements" />
                <Tab label="Account Info" />
                <Hidden mdDown>
                  {dateFilter()}
                </Hidden>
              </Tabs>
              <Divider className={appListClasses.divider} />

              <Hidden lgUp>
                <Box sx={{ my: 1, display: 'flex', justifyContent: 'space-between' }}>
                  {dateFilter()}
                </Box>
              </Hidden>
              <TabPanel value={tabValue} index={transactionsIndex}>
                  <TransactionTable
                    headCells={headCells}
                    appClasses={appClasses}
                    appTableClasses={appTableClasses}
                    listCardHeight={listCardHeight}
                    infoIcon={infoIcon}
                    pendingTransaction={props.pendingTransaction}
                    successTransaction={props.successTransaction}
                  />
              </TabPanel>
              <TabPanel value={tabValue} index={statementsIndex}>
                <Statements {...propsTobeForward} />
              </TabPanel>

              <TabPanel value={tabValue} index={accountInfoIndex}>
                <div className={classes.accountInfoContainer}>
                  <Typography variant="body2" style={{ marginBottom: '20px', maxWidth: 700 }}>
                    Use the details below when setting up direct deposit payments for any
                    purpose, such as salaries and wages, payment of bills, taxes, and other government charges.
                  </Typography>
                  <DirectDepositWithDescription details={props.bankAccountDetails}
                    isGotResponse={props.isGotBankAccountDetails}
                    isDirectDepositTable={true}
                  />
                </div>
              </TabPanel>
            </div>
          </Paper>
          <Box mt={2}></Box>
          <div className={classes.goalFooter}>
            <MobileHelpScreen iconName="button" topics={mobileScreenCollection} screenName="transaction-history" anchor="top" />
          </div>
        </Grid>
      </Grid>

      {
        props.viewTransaction && <ViewTransaction
          data={props.selectedTransaction}
          handleClose={() => { props.handleCloseViewTransaction() }}
        />
      }
    </div>
  );
};
