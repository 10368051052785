import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  checkedB: {
    backgroundColor: `${theme.palette.other?.primary900} !important`,
  },
  checkedC: {
    color: `${theme.palette.other?.primary900} !important`,
  },
  icon: {
    width: "15px",
    height: "15px",
    backgroundColor: theme.palette.other?.black500,
    borderRadius: "30px",
  },
  container: {
    display: "flex",
    fontSize: "10px",
    color: theme.palette.other?.black500,
  },
  label : {
    fontSize : "13px",
    fontWeight : "700",
    lineHeight : "16px"
  }
}));
