import { useDispatch, useSelector } from "react-redux";
import { dismissTemporaryLockAccount } from "../common/actions/accountStatus";

export default function useAccountStatus(){
  const accountStatus = useSelector(state => state.accountStatus);
  const dispatch = useDispatch();

  const dismissTemporaryLock = () => {
    dispatch( dismissTemporaryLockAccount());
  }
  const { isTemporaryLocked, lockedCountDown, lockType } = accountStatus;
  return { isTemporaryLocked, lockedCountDown, dismissTemporaryLock, lockType }
}