import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import * as contentfulActions from "../../contentful/actions";

import { Goals } from "../components/Goals";
import { useAsyncEffect } from "ahooks";
import { doGetAndSetCardBalances } from "../../dashboard/actions";
import useAuth from "../../../hooks/useAuth";

export const GoalsPage = (props) => {
  const dispatch = useDispatch();

  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const { userCardTing } = dashboardReducer;
  const purseReducer = useSelector((state) => state.purseReducer);

  const {bibson, user_id} = useAuth()

  const [showTransferMoneyDialog, setShowTransferMoneyDialog] =
    React.useState(undefined);

  const [transferMoneyDialogData, setTransferMoneyDialogData] =
    React.useState(undefined);

  useEffect(() => {
    getData();
  }, []);

  //transfer money events
  const handleCloseTransferMoney = (success) => {
    setShowTransferMoneyDialog(false);
    success && getData();
  };
  const handleTransferMoney = (data) => {


    const transferMoneyData = {

      bibson,
      user_id,
      card_ting: userCardTing,
    };
    setTransferMoneyDialogData(transferMoneyData);
    setShowTransferMoneyDialog(true);
  };

  const getData = () => {
    if (!user_id || !bibson) {
      return;
    }

    dispatch(doGetAndSetCardBalances({user_id, bibson}))
    //getting contentful data
    dispatch(
      contentfulActions.doGetAndSetContent(
        contentfulActions.contentfulActionTypes.mobileScreenCollection
      )
    );
  };
  useAsyncEffect( async () => {
    if (!userCardTing) return ;
    const data = {
      bibson,
      user_id,
      card_ting: userCardTing,
    };
    dispatch(actions.doGetAndSetPursesAndTransactions({ ...data }));
  }, [userCardTing]);


  const propsTobeForward = {
    ...purseReducer,
    transferMoneyDialogData,

    showTransferMoneyDialog,

    handleCloseTransferMoney,
    handleTransferMoney,
  };

  return <Goals {...propsTobeForward} />;
};
