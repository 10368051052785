import {
  SET_SUPPORT_EMAIL,
  SET_USER_ADDRESSES,
  SET_USER_DATA,
  SET_USER_SHIPPING_ADDRESSES,
  PROFILE_SETTING_SET_USER_EMAIL
} from "../../../redux/actionTypes";

const initState = {
  userData: null,
  userAddresses: null,
  userShippingAddress: null,
  supportEmail: null,
};

export default function profileReducer(state = initState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return Object.assign({}, state, {
        userData: action.data,
      });
    case PROFILE_SETTING_SET_USER_EMAIL:
      const userData = state.userData;
      return {...state, userData: {...userData, email: action.data}};
    case SET_USER_ADDRESSES:
      return Object.assign({}, state, {
        userAddresses: action.data,
      });
    case SET_USER_SHIPPING_ADDRESSES:
      return Object.assign({}, state, {
        userShippingAddress: action.data,
      });
    case SET_SUPPORT_EMAIL:
      return Object.assign({}, state, {
        supportEmail: action.data,
      });

    default:
      return state;
  }
}
