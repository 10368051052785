import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import * as profileActions from "../../profile/actions";
import { SettingHome } from "../components/SettingHome";

export const SettingSidemenuTypes = {
  VERIFY_PASSWORD: "VERIFY_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
};
export const SettingHomePage = (props) => {
  const [
    isOpenConfirmEnableDisableNotifications,
    setIsOpenConfirmEnableDisableNotifications,
  ] = React.useState(false);
  const [openSidemenuType, setOpenSidemenuType] = React.useState(false);
  const [nextStep, setNextStep] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();

  const settingsReducer = useSelector((state) => state.settingsReducer);
  const { notificationSettings } = settingsReducer;

  const authReducer = useSelector((state) => state.authReducer);
  const { loginResponseData, username } = authReducer;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!loginResponseData) {
      return;
    }
    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
    };
    dispatch(actions.doGetAndSetNotificationSettings({ ...data }));
  };

  const handleCloseSidemenu = (success) => {
    if (success) {
      getData();
    }
    setOpenSidemenuType(null);
  };

  const handleOpenSidemenu = (type) => {
    setNextStep(type);
    setOpenSidemenuType(SettingSidemenuTypes.VERIFY_PASSWORD);
  };

  const passwordDialodHandleClose = () => {
    setOpenSidemenuType(null);
  };

  const passwordDialodHandleSubmit = (password) => {
    if (password) {
      dispatch(
        profileActions.doVerifyPassword(
          {
            bibson: loginResponseData?.bibson,
            user_id: loginResponseData?.user_id,
            identifier: username,
            password,
          },
          (success) => {
            if (success) {
              setPassword(password);
              setOpenSidemenuType(nextStep);
            }
          }
        )
      );
    }
  };

  const getApiToStateEnableNotification = () => {
    if (notificationSettings && notificationSettings.length > 0) {
      for (let i = 0; i < notificationSettings.length; i++) {
        if (!notificationSettings[i].enabled) {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const isEnableNotiSettings = getApiToStateEnableNotification();

  const handleConfirmEnableDisableNotifications = (confirm) => {
    setIsOpenConfirmEnableDisableNotifications(false);
    if (!confirm) {
      return;
    }

    if (notificationSettings && notificationSettings.length > 0) {
      const newNotiSettings = JSON.parse(JSON.stringify(notificationSettings));
      for (let i = 0; i < newNotiSettings.length; i++) {
        newNotiSettings[i].enabled = isEnableNotiSettings ? 0 : 1;
      }
      const reqData = {
        user_notifications_settings: newNotiSettings,
        user_id: loginResponseData?.user_id,
        bibson: loginResponseData?.bibson,
      };
      dispatch(
        actions.doSaveNotificationSettings(reqData, (success) => {
          if (success) {
            getData();
          }
        })
      );
    }
  };

  const handleEnableDisableNotification = () => {
    setIsOpenConfirmEnableDisableNotifications(true);
  };

  const userAuthData = {
    password,
  };

  const propsTobeForward = {
    openSidemenuType,
    handleCloseSidemenu,
    handleOpenSidemenu,

    isOpenConfirmEnableDisableNotifications,
    handleConfirmEnableDisableNotifications,

    isEnableNotiSettings,
    handleEnableDisableNotification,

    userAuthData,
    passwordDialodHandleClose,
    passwordDialodHandleSubmit,
  };

  return <SettingHome {...propsTobeForward} />;
};
