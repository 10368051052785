import React from "react";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, DialogTitle, Grid, Typography } from "@material-ui/core";
import { AppTextField } from "../../../../common/components/AppTextField";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";

import { EditEmailPageSteps } from "../../containers/EditEmailPage";

export const EditEmail = (props) => {
  const {
    email,
    mobileOtp,
    emailOtp,
    errors,
    step,

    handleChange,
    handleSubmit,
    handleDontReceiveCode,
  } = props;

  const appClasses = useAppStyles();

  const fillEmailStep = step === EditEmailPageSteps.fillEmail;
  const mobileOtpStep = step === EditEmailPageSteps.mobileOtp;
  const emailOtpStep = step === EditEmailPageSteps.emailOtp;

  const displayDontReceiveCode = emailOtpStep || mobileOtpStep;
  const submitBtnText = fillEmailStep ? "Send Code" : "Continue";

  const isDisabledSubmit =
    (fillEmailStep && !email) ||
    (mobileOtpStep && !mobileOtp) ||
    (emailOtpStep && !emailOtp);

  const content = () => {
    return (
      <>
        <DialogTitle id="form-dialog-title">
          <div className={appClasses.dialogTitle}>
            <Typography variant="h1" className={appClasses.text3}>
              Edit Email
            </Typography>
          </div>
          <div className={appClasses.dialogTitle}>
            {fillEmailStep && (
              <Typography variant="span" className={appClasses.text1}>
                Enter your new email address and we’ll email you a code to
                verify.
              </Typography>
            )}
            {emailOtpStep && (
              <Typography variant="span" className={appClasses.text1}>
                We sent a verification code to {email}. Please enter
                it below.
              </Typography>
            )}
            {mobileOtpStep && (
              <Typography variant="span" className={appClasses.text1}>
                We sent a verification code to the mobile number you use with
                Artist Growth Wallet account. Please enter it below.
              </Typography>
            )}
          </div>
        </DialogTitle>
        <DialogContent className={appClasses.dialogContent}>
          {fillEmailStep && (
            <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
              <AppTextField
                name={"email"}
                label="Email Address"
                value={email}
                onChange={handleChange}
                errorText={errors?.email}
                placeholder="example@email.com"
              ></AppTextField>
            </Grid>
          )}

          {emailOtpStep && (
            <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
              <AppTextField
                name={"emailOtp"}
                label="Enter the Code"
                value={emailOtp}
                onChange={handleChange}
                errorText={errors?.emailOtp}
              ></AppTextField>
            </Grid>
          )}

          {mobileOtpStep && (
            <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
              <AppTextField
                name={"mobileOtp"}
                label="Enter the Code"
                value={mobileOtp}
                onChange={handleChange}
                errorText={errors?.mobileOtp}
              ></AppTextField>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <div className={`${appClasses.dialogFooter} ${appClasses.width100p}`}>
            <AppButton
              contained
              onClick={handleSubmit}
              className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
              disabled={isDisabledSubmit}
              primary
            >
              {submitBtnText}
            </AppButton>
            {displayDontReceiveCode && (
              <>
                <Box mt={3}></Box>
                <Typography
                  variant="h4"
                  className={`${appClasses.text4} ${appClasses.textAlignCenter}`}
                >
                  <a
                    className={`${appClasses.aLink} ${appClasses.colorBlack500}`}
                    onClick={handleDontReceiveCode}
                  >
                    Didn’t receive a code?
                  </a>
                </Typography>
              </>
            )}
          </div>
        </DialogActions>
      </>
    );
  };

  return <Grid>{content()}</Grid>;
};
