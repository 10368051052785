import { Box, Paper, styled } from '@material-ui/core'
import React, { useEffect } from 'react'

import ATMResultTitle from './ATMResultTitle';
import ATMRowElement from './ATMRowElement';
import { useATMFinderContext, useAtmFilter, useAtmLocations, useDistanceDurationStorage, useElementPosition, useSelectedATM } from '../../store/atmhooks';


const ATMNearByWrapper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1, 0),
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
  }
}))

const ATmListWrapper = styled(Box)(({ theme, yposition }) => ({
  // fill remain screen but leave 80px for footer
  height: `calc(100vh - ${yposition + 80}px)`,
  overflowY: 'auto',
  transition: 'height 2s ease-in-out',
  [theme.breakpoints.down('md')]: {
    borderRadius: 0,
    '&.showList': {
      height: '200px',
      display: 'block',
    },
    '&.hideList': {
      height: '0px',
      display: 'none',
    }
  }
}))

// format distance to km or m if less than 1km
const formatDistance = (distance) => {
  const inMile = distance * 0.000621371
  return `${inMile.toFixed(1)} mi`
}
const ListATMNearBy = () => {
  const { atmLocations, currentLocation } = useAtmLocations()
  const { selectedATM, setSelectedATM } = useSelectedATM()
  const { displayListAtm, lgUp, setShowList } = useATMFinderContext()
  // const [ loadingDistanceMetrix, setLoadingDistanceMetrix ] = useState(false)
  const atmWrapperElement = React.useRef(null)

  const atmLocationsContainerPosition = useElementPosition(atmWrapperElement)

  const { distanceDurations, setDistanceDurations } = useDistanceDurationStorage()

  const { filtedLocations } = useAtmFilter()

  const onRowClickHandler = (atmLocation) => {
    setSelectedATM(atmLocation)
    if (!lgUp) {
      setShowList(false)
    }
  }

  // Calculate distance from currentLocation to the atm locations using skyway
  const calculateDirectDistance = () => {
    if (!currentLocation) return {}
    const origin = new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng)
    return filtedLocations.reduce((acc, atmLocation) => {
      const { lat, lon: lng } = atmLocation?.location || {}
      const { terminal_id } = atmLocation
      const atmLatLng = new window.google.maps.LatLng(lat, lng)
      const distanceValue = window.google.maps.geometry.spherical.computeDistanceBetween(origin, atmLatLng)
      const distanceText = formatDistance(distanceValue)
      acc[terminal_id] = { distanceValue, distanceText }
      return acc
    }, {})
  }

  // calculate and load distance to distanceDurations state
  useEffect(() => {
    if (!currentLocation) return
    const directDistance = calculateDirectDistance()
    setDistanceDurations(directDistance)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, atmLocations])

  // const loadDistanceMatrix = async (page = 1) => {
  //   console.log("loadDistanceMatrix", page)
  //   const origin = new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng)
  //   const startOffset = (page - 1) * PAGE_SIZE
  //   const processingLocation = atmLocations.slice(startOffset, page * PAGE_SIZE)
  //   const distanceDurationObj = await loadDurationAndDistance(origin, processingLocation)
  //   await awaitTimeout(SLEEP_BETWEEN_TO_MATRIX_REQUEST)
  //   // Temporary update first page to show the result to user first, then update the rest after user scroll down
  //   if (page === 1) {
  //     setDistanceDurations(distanceDurationObj)
  //   }
  //   return distanceDurationObj
  // }

  // const getAllDistanceMatrix = async () => {
  // Just an array to help us to loop through all pages
  // const pagesArray = new Array(Math.ceil(atmLocations.length / PAGE_SIZE)).fill(1)
  // // Loop through all pages and get the distance matrix for each page and merge them together
  // // We use reduce to make sure the order of the result is correct and because limit requests per second of google api,
  // // we need to wait for each request to finish before we can make the next request
  // return pagesArray.reduce((result, _, index) => {
  //   return result.then(async (distancesOjb) => {
  //     const distanceDurationObj = await loadDistanceMatrix(index + 1)
  //     return { ...distancesOjb, ...distanceDurationObj }
  //   })
  // }, Promise.resolve({}))
  // }

  // useAsyncEffect(async () => {
  // if (!currentLocation) return null
  // if (atmLocations.length <= 0) return null
  // setLoadingDistanceMetrix(true)
  // const allDistanceMatrix = await getAllDistanceMatrix()
  // setDistanceDurations(allDistanceMatrix)
  // setLoadingDistanceMetrix(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [atmLocations, currentLocation.lat, currentLocation.lng])


  // Sort the atm locations by distance to current location
  // If the distance is not available, we put it at the end of the list
  // We only begin to sort the list when the distance matrix is loaded
  let displayLocations = filtedLocations

  displayLocations = filtedLocations.sort((a, b) => {
    if (!distanceDurations[a.terminal_id]?.distanceValue) return 1
    if (!distanceDurations[b.terminal_id]?.distanceValue) return -1
    const distanceA = distanceDurations[a.terminal_id]?.distanceValue || 0
    const distanceB = distanceDurations[b.terminal_id]?.distanceValue || 0
    return distanceA - distanceB
  })
  const noResult = displayLocations.length <= 0
  const isShowListOnMObile = !noResult && displayListAtm

  return <ATMNearByWrapper elevation={1}>
    <ATMResultTitle noResult={noResult} />
    <ATmListWrapper data-test-id="atm-locations" id="atm-location-wrapper" ref={atmWrapperElement} yposition={atmLocationsContainerPosition?.y || 200} className={isShowListOnMObile ? 'showList' : 'hideList'}>
      {
        displayLocations.map((atm) => {
          const distanceDuration = distanceDurations[atm.terminal_id] || {}
          return <ATMRowElement key={atm.terminal_id} atm={atm}
            onClick={onRowClickHandler}
            selected={selectedATM?.terminal_id === atm.terminal_id}
            {...distanceDuration}
          />
        })
      }
    </ATmListWrapper>
  </ATMNearByWrapper>
}

export default ListATMNearBy