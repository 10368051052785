import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./style";

export const InputInfo = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <Typography variant="h6" component={"label"} className={classes.root}>
      {children}
    </Typography>
  );
};
