import { routes } from "../../../constants/routes";
import history from "../../../history";
import {
  SET_ACCESS_TOKEN,
  SET_USER_ID,
  LOGOUT,
  SET_USERNAME,
  SET_LOGIN_RESPONSE_DATA,
  SET_IS_LOGIN_SUCCESS,
  SET_DISPLAY_LOGOUT_WRONG_ATTEMPT_POPUP,
  SET_KYC_FORM_LINK,
  SET_LOGIN_KYC_FAILED,
  SET_CHECKING_KYC_STATUS,
  SET_IN_PROGRESSING_KYC,
} from "../../../redux/actionTypes";
import {
  changePassword,
  login,
  sendOTPForgotPassword,
  validateOTPForgotPassword,
  getSecurityQuestions,
  validateSecurityQuestions,
  createNewPassword,
  sendOTPLogin,
  validateOTPLogin,
  getkYCStatus,
  getKYCLink,
  authCheck,
} from "../services";
import * as toastActions from "../../../common/actions/toast";
import {
  ACCOUNT_LOCK_CODE,
  invalidCreds,
  KYC_STATUS_FAILED,
  KYC_STATUS_PASSED,
  newPasswordCreated,
  passwordChanged,
  REMEMBER_ME_LS_KEY,
  TEN_MIN_LOCK,
} from "../../../constants";
import { TOAST_TYPES } from "../../../common/components/core";
import { apiCall } from "../../../common/actions/apiCall";
import { removeLS, setLS } from "../../../utilities";
import { removeIdleDetector } from "../../../common/services/idleDetector";

const MaxCheckingAuthRetry = 3;
export const setAccessToken = (token) => {
  return {
    type: SET_ACCESS_TOKEN,
    data: token,
  };
};
export const setUserId = (userid) => {
  return {
    type: SET_USER_ID,
    data: userid,
  };
};
export const setUsername = (username) => {
  return {
    type: SET_USERNAME,
    data: username,
  };
};

export const setLoginResponseData = (data) => {
  return {
    type: SET_LOGIN_RESPONSE_DATA,
    data: data,
  };
};

export const setKyCLinkCopy = (data) => {
  return {
    type: SET_KYC_FORM_LINK,
    data: data,
  };
};

export const setIsLoginSuccess = (data) => {
  return {
    type: SET_IS_LOGIN_SUCCESS,
    data: data,
  };
};

export const setDisplayWrongAttemptPopup = (data) => {
  return {
    type: SET_DISPLAY_LOGOUT_WRONG_ATTEMPT_POPUP,
    data: data,
  };
};

export const setInprogressingKyc = (data) => {
  return {
    type: SET_IN_PROGRESSING_KYC,
    data
  };
}

export const setCheckingKycStatus = (data) => {
  return {
    type: SET_CHECKING_KYC_STATUS,
    data
  }
}



export const doSendOTPForgotPassword = (data, callback) => (dispatch) => {
  return apiCall(dispatch, sendOTPForgotPassword(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (sendOTPForgotPasswordResponse) => {
      if (sendOTPForgotPasswordResponse?.data?.status === "success") {
        callback({ success: true });
        return 'success';
      }
      callback({ success: false });
    },
    (e) => {
      (e?.response?.data?.errors || []).map((error) => {
        if (error.code == TEN_MIN_LOCK) {
          dispatch(setIsLoginSuccess(false));
          callback({ success: false, locked: true, lockoutTime: error.lockout_time });
        }
        else {
          const { message, code } = e?.response?.data?.errors[0];
          if (
            e?.response?.data?.errors &&
            e?.response?.data?.errors[0]
          ) {
            dispatch(toastActions.show(message, TOAST_TYPES.ERROR));
          }
          callback({ success: false, locked: false });
        }
      })
    }
  );
};

export const doValidateOTPForgotPassword = (data, callback) => (dispatch) => {
  return apiCall(dispatch, validateOTPForgotPassword(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (validateOTPForgotPasswordResponse) => {
      if (validateOTPForgotPasswordResponse?.data?.status == "success") {
        callback({
          success: true,
          data: validateOTPForgotPasswordResponse?.data?.data,
          locked: false
        });
        return;
      }
      callback({ success: false, locked: false });
    },
    (e) => {

      e?.response?.data?.errors.map((error) => {
        if (error.code == TEN_MIN_LOCK) {
          dispatch(setIsLoginSuccess(false));
          callback({ success: false, locked: true });
        }
        else {
          const { message } = e?.response?.data?.errors[0];
          callback({ success: false, locked: false });
          throw new Error(message);
        }
      })
    });
};

export const doValidateOTPForLogin = (data) => async (dispatch) => {
  const result = await apiCall(dispatch, validateOTPLogin(data), {
    showLoader: true,
    displayError: false,
  })
  const loginData = result?.data?.data
  dispatch(setLoginResponseData(loginData))
  const isKycRequired = await dispatch(doCheckKYCAndOpenKYCURL(loginData))
  if (isKycRequired) {
    throw new Error('need to perform KYC')
  }
  return result
};

export const doGetAndSetSecurityQuestions = (data, callback) => (dispatch) => {
  apiCall(dispatch, getSecurityQuestions(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (securityQuestionsResponse) => {
      if (securityQuestionsResponse?.data?.status == "success") {
        callback({
          success: true,
          data: securityQuestionsResponse?.data?.data?.security_questions || [],
        });
        return;
      }
      callback({ success: false });
    },
    (error) => {
      callback({ success: false });
    }
  );
};

export const doValidateSecurityQuestions = (data, callback) => (dispatch) => {
  apiCall(dispatch, validateSecurityQuestions(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (validateSecurityQuestionsResponse) => {
      if (validateSecurityQuestionsResponse?.data?.status == "success") {
        callback({
          success: true,
          data: validateSecurityQuestionsResponse?.data?.data,
        });
        return;
      }
      callback({ success: false, locked: false });
    },
    (e) => {
      e?.response?.data?.errors.map((error) => {
        if (error.code == TEN_MIN_LOCK) {
          dispatch(setIsLoginSuccess(false));
          callback({ success: false, locked: true });
        }
        else {
          const { message, code } = e?.response?.data?.errors[0];
          if (
            e?.response?.data?.errors &&
            e?.response?.data?.errors[0]
          ) {
            dispatch(toastActions.show(message, TOAST_TYPES.ERROR));
          }
          callback({ success: false, locked: false });

        }
      })
    }
  );
};

export const doCreateNewPassword = (data, callback) => (dispatch) => {
  apiCall(dispatch, createNewPassword(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (createNewPasswordResponse) => {
      if (createNewPasswordResponse?.data?.status == "success") {
        callback({
          success: true,
          data: createNewPasswordResponse?.data?.data,
        });
        dispatch(toastActions.show(newPasswordCreated, TOAST_TYPES.SUCCESS));
        history.push(routes.login.path);
        return;
      }
      callback({ success: false });
    },
    (error) => {
      callback({ success: false });
    }
  );
};

export const goToKyCPage = () => {
  history.push(routes.kyc.path);
}

export const doGetAndOpenKyc = (data, callback) => (dispatch) => {
  apiCall(dispatch, getKYCLink(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (kycFormLinkResponse) => {
      if (kycFormLinkResponse?.data?.status === "success") {
        dispatch(setKyCLinkCopy(kycFormLinkResponse?.data?.data?.kyc_form_url));
        history.push(routes.kyc.path);
      }
    },
    (error) => {
      history.push(routes.dashboard.path);
      dispatch(toastActions.show(invalidCreds, TOAST_TYPES.ERROR));
    }
  );
};

export const doSendOTPToPhone = () => async (dispatch, getState) => {
  const state = getState();
  const { user_id, bibson, user } = state?.authReducer?.loginResponseData || {};
  if (!user_id) {
    dispatch(toastActions.show("Requied to login first", TOAST_TYPES.ERROR));
  }
  try {
    await sendOTPLogin({ user_id, bibson, user });
    dispatch(toastActions.show("Check your text", TOAST_TYPES.SUCCESS));
    return true
  } catch (e) {
    dispatch(toastActions.show("Something went wrong", TOAST_TYPES.ERROR));
  }
  return false
}

const considerRememberIdentifier = (data) => {
  if (Boolean(data.rememberMe)) {
    setLS(REMEMBER_ME_LS_KEY, JSON.stringify({ identifier: data.identifier }));
  }
  else {
    removeLS(REMEMBER_ME_LS_KEY);
  }
}

export const doSendOTP = (loginResponse, payload, callback) => (dispatch) => {

  return apiCall(dispatch, sendOTPLogin(loginResponse?.data?.data), {
    showLoader: true,
    displayError: true,
  }).then(
    (twoFacResponse) => {
      if (loginResponse?.data?.data) {
        considerRememberIdentifier(payload)
        dispatch(setLoginResponseData(undefined));
        dispatch(setIsLoginSuccess(true));
        dispatch(setUsername(payload.identifier));
        dispatch(setLoginResponseData(loginResponse?.data?.data));
      }
      //history.push(routes.home.path);
    },
    (e) => {
      e?.response?.data?.errors.map((error) => {
        if (error.code == TEN_MIN_LOCK) {
          dispatch(setIsLoginSuccess(false));
          callback({ success: false, locked: true });
          return;
        }
      })
      callback({ success: false, locked: false });
      // history.push(routes.dashboard.path);
      // dispatch(toastActions.show(invalidCreds, TOAST_TYPES.ERROR));
    }
  );
}

export const loginFailedBecauseOfFailedKYCStatus = () => {
  return {
    type: SET_LOGIN_KYC_FAILED,
    data: true
  }
}

export const clearLoginFailedBecauseOfFailedKYCStatus = () => {
  return {
    type: SET_LOGIN_KYC_FAILED,
    data: false
  }
}

export const doCheckKycStatus = () => async (dispatch, getState) => {
  dispatch(setCheckingKycStatus(true))
  try {
    const state = getState()
    const bibson = state?.authReducer?.loginResponseData?.bibson
    const user_id = state?.authReducer?.loginResponseData?.user_id
    if (!user_id || !bibson) return false

    const { kycStatus } = await getkYCStatus({ bibson, user_id })
    dispatch(setInprogressingKyc(kycStatus !== KYC_STATUS_PASSED))
  } finally {
    dispatch(setCheckingKycStatus(false))
  }
}

const kycFailedHandler = (kycResponse) => async (dispatch) => {
  dispatch(doLogoutWihoutRedirect())
  dispatch(loginFailedBecauseOfFailedKYCStatus())
}

// Return false if user do not need to perform KYC
export const doCheckKYCAndOpenKYCURL = (loginResponse) => (dispatch) => {
  return apiCall(dispatch, getkYCStatus(loginResponse), {
    showLoader: true,
    displayError: true,
  }).then(
    ({ kycStatus, kycResponse }) => {
      if (kycStatus === KYC_STATUS_PASSED) {
        // TODO: this should be refactored since we not always need to 2FA
        dispatch(setInprogressingKyc(false))
        history.push(routes.home.path)
        return false
      }
      if (kycStatus === KYC_STATUS_FAILED) {
        dispatch(kycFailedHandler(kycResponse))
        return true
      }
      goToKyCPage()
      return true
    }
  );
}


export const doLogin = (data, callback) => (dispatch) => {

  apiCall(dispatch, login(data), { showLoader: true, displayError: false, })
    .then(
      (loginResponse) => {
        considerRememberIdentifier(data)
        if (loginResponse?.data?.data) {
          dispatch(setUsername(data.identifier));
          dispatch(setLoginResponseData(loginResponse?.data?.data));
          //Check KYC Status
          if (loginResponse?.data.data.verification_required) {
            dispatch(doSendOTP(loginResponse, data, callback));
          } else {
            dispatch(doCheckKYCAndOpenKYCURL(loginResponse?.data?.data));
          }
        }
      }
    )
    .catch((e) => {
      e?.response?.data?.errors.map((error) => {
        if (error.code == ACCOUNT_LOCK_CODE) {
          dispatch(setIsLoginSuccess(false));
          callback && callback();
        }
        else {
          const { message, code } = e?.response?.data?.errors[0];
          if (
            e?.response?.data?.errors &&
            e?.response?.data?.errors[0]
          ) {
            dispatch(toastActions.show(message, TOAST_TYPES.ERROR));
          }
        }
      })
    });
};

export const doLogoutWihoutRedirect = () => {
  removeIdleDetector()
  return {
    type: LOGOUT,
  };
}
export const doLogout = () => {
  history.push(routes.login.path);
  return doLogoutWihoutRedirect();
};

export const doChangePassword = (data, callback) => (dispatch) => {
  apiCall(dispatch, changePassword(data), {
    showLoader: true,
  }).then(
    (response) => {
      if (response?.data?.error) {
        const { message } = response?.data?.error;
        dispatch(toastActions.show(message, TOAST_TYPES.ERROR));
        return;
      }

      if (response?.data?.isPasswordUpdated) {
        //successully change password
        dispatch(toastActions.show(passwordChanged, TOAST_TYPES.SUCCESS));
        callback && callback();
      }
    },
    (error) => { }
  );
};


export const doAuthCheck = (retried = 1) => async (dispatch, getState) => {
  const state = getState();
  const { user_id, bibson } = state?.authReducer?.loginResponseData || {};
  if (!user_id) {
    return dispatch(doLogout());
  }

  try {
    return await authCheck({ user_id, bibson });
  } catch (error) {
    if (retried > MaxCheckingAuthRetry) {
      return dispatch(doLogout());
    }
    return setTimeout(() => dispatch(doAuthCheck(retried + 1)), 1000);
  }

}