import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  byPayfareImg: {
    width: "50px",
    height: "16px",
    display: "block",
  },
  mainWrapper: {
    padding: "2rem",
  },
  wrapper: {
    display: "flex",
    height: "225px",
    "& > img": {
      height: "100%",
      width: "50%",
      borderRadius: "16px",
    },
  },
  rightContent: {
    alignSelf: "center",
    margin: "auto",
    marginLeft: "2rem",
    padding: "0.5rem",
    width: "50%",
  },
}));
