import { DialogContent, DialogTitle, Divider, Drawer } from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../App.style";
import { closeBlackIcon } from "../../../constants";
import { IconButton } from "../core";
import { useStyles } from "./style";

export const SidemenuViewContent = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const { open, onClose, children, headerComponent, closeBtnImg } = props;

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.root,
      }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => onClose()}
          >
            <img src={closeBtnImg ? closeBtnImg : closeBlackIcon} />
          </IconButton>
          {headerComponent}
        </div>
      </DialogTitle>
      {/* <Divider></Divider> */}
      <DialogContent className={appClasses.dialogContent}>
        {children}
      </DialogContent>
    </Drawer>
  );
};
