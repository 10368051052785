/**
 * @typedef {Object} UseAuthRequestParam
 * @property {'get'|'post'|'put'|'delete'} method - HTTP request method
 * @property {string} endPoint - api endpoint
 * @property {Object} requestData - request data
 */

import { useRequest } from "ahooks";
import axios from "axios";
import { useSelector } from "react-redux";
import { additionalApiData } from "../../constants";

export function useAuthData() {
  const authReducer = useSelector((state) => state.authReducer);
  return authReducer && authReducer.loginResponseData;
}

function buildAxiosConfig(authData, requestData) {
  return {
    params: {
      ...additionalApiData,
      user_id: authData.user_id,
      ...requestData,
    },
    headers: {
      "x-bishop": authData.bibson,
      "Content-Type": "application/json",
    },
  }
}

async function axiosGetRequest(endpoint, axiosConfig) {
  return axios.get(endpoint, axiosConfig)
}

async function axiosRequest(endpoint, axiosConfig) {
  return axios.get(endpoint, axiosConfig.params, { headers: axiosConfig.headers })
}

const axiosRequestFactory = (method) => {
  if (method === 'get') return axiosGetRequest
  return axiosRequest
}


/**
* Authed Request hooks. This hooks is built on top of useRequest hook of ahooks libary
* @param {UseAuthRequestParam} props - hook data
* @param {Options} useRequestOptions - useRequestOptions {@link https://ahooks.js.org/hooks/use-request/index | useRequest}
*/
export function useAuthedRequest({ method, endPoint, requestData = {} }, useRequestOptions) {
  const authData = useAuthData();
  if (!authData || !authData.bibson) {
    throw new Error("unauthorized")
  }

  const axiosConfig = buildAxiosConfig(authData, requestData)
  const axiosRequestFn = axiosRequestFactory(method)

  return useRequest(() => axiosRequestFn(endPoint, axiosConfig), useRequestOptions)
}

