import {
  Grid,
  Typography,
  Box,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../../../App.style";
import { AppButton } from "../../../../../common/components/AppButton";
import { activateCardPageStaticImg } from "../../../../../constants";
import { useStyles } from "../style";

export const Intro = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const {
    handleClose,
    nextStep,
  } = props;

  return (
    <>
      <DialogTitle className={classes.header}>
        <Typography variant="h6" className={appClasses.text3}>
          Activate Your Card
        </Typography>
        <Box mt={1} />
        <Typography variant="span" className={appClasses.text1}>
          If your card already arrived, activate it now to enjoy all the
          benefits. If not, don’t worry, you can do it later.
        </Typography>
      </DialogTitle>
      <Box mt={2} />
      <DialogContent className={appClasses.dialogContent}>

        <div className={classes.imgWrapper}>
          <img src={activateCardPageStaticImg} alt="Activate Card Page" />
        </div>

        <Box my={1} >
          <AppButton
            contained
            onClick={nextStep}
            className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
            primary
          >
            Activate Card
          </AppButton>
        </Box>
        <Box my={1}>
          <AppButton
            variant="text"
            className={`${appClasses.textBtn} ${appClasses.width100p}`}
            onClick={handleClose}
          >
            Not Now
          </AppButton>
        </Box>
      </DialogContent>

    </>
  );
};
