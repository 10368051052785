import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root : {
    fontSize : "1rem",
    color : theme.palette.other?.black900,
    lineHeight: "2rem",
    fontWeight: "700",
    //margin: "auto"
  }
}));
