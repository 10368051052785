import { apiCall } from "../../../common/actions/apiCall";
import { appErrors } from "../../../constants/errors";
import * as toastActions from "../../../common/actions/toast";
import { TOAST_TYPES } from "../../../common/components/core";
import { getRecipientDetailsByTing, saveRecipientDetails, updateRecipientDetails, deleteRecipientDetails } from "../services";
import { doGetAndSetRecipients } from ".";

export const saveRecipients = (data, payload, callBackFn) => (dispatch) => {
    apiCall(dispatch, saveRecipientDetails(data, payload), {
        showLoader: true,
        displayError: false,
    }).then(
        (response) => {
            if (response?.data?.status == "success") {
                dispatch(doGetAndSetRecipients(data));
                callBackFn && callBackFn();
            }
        },
        (error) => {
            const errors = error?.response?.data?.errors;
            if (errors && errors.length > 0) {
                const errorDetail = errors[0];
                if (errorDetail.code && appErrors[errorDetail.code]) {
                    dispatch(
                        toastActions.show(
                            appErrors[errorDetail.code].message,
                            TOAST_TYPES.ERROR
                        )
                    );
                }
            }
        }
    );
};


export const deleteRecipients = (data, callBackFn) => (dispatch) => {
    apiCall(dispatch, deleteRecipientDetails(data), {
        showLoader: true,
        displayError: false,
    }).then(
        (response) => {
            if (response?.data?.status == "success") {
                dispatch(doGetAndSetRecipients(data));
                callBackFn && callBackFn();
            }
        },
        (error) => {
            const errors = error?.response?.data?.errors;
            if (errors && errors.length > 0) {
                const errorDetail = errors[0];
                if (errorDetail.code && appErrors[errorDetail.code]) {
                    dispatch(
                        toastActions.show(
                            appErrors[errorDetail.code].message,
                            TOAST_TYPES.ERROR
                        )
                    );
                }
            }
        }
    );
};

export const updateRecipients = (data, payload, callBackFn) => (dispatch) => {
    apiCall(dispatch, updateRecipientDetails(data, payload), {
        showLoader: true,
        displayError: false,
    }).then(
        (response) => {
            if (response?.data?.status == "success") {
                dispatch(doGetAndSetRecipients(data));
                callBackFn && callBackFn();
            }
        },
        (error) => {
            const errors = error?.response?.data?.errors;
            if (errors && errors.length > 0) {
                const errorDetail = errors[0];
                if (errorDetail.code && appErrors[errorDetail.code]) {
                    dispatch(
                        toastActions.show(
                            appErrors[errorDetail.code].message,
                            TOAST_TYPES.ERROR
                        )
                    );
                }
            }
        }
    );
};

export const getRecipientDetailById = (data, callBackFn) => (dispatch) => {
    apiCall(dispatch, getRecipientDetailsByTing(data), {
        showLoader: true,
        displayError: false,
    }).then(
        (response) => {
            if (response?.data?.status == "success") {
                callBackFn &&  callBackFn(response?.data?.data?.recipient)
            }
        },
        (error) => {
            const errors = error?.response?.data?.errors;
            if (errors && errors.length > 0) {
                const errorDetail = errors[0];
                if (errorDetail.code && appErrors[errorDetail.code]) {
                    dispatch(
                        toastActions.show(
                            appErrors[errorDetail.code].message,
                            TOAST_TYPES.ERROR
                        )
                    );
                }
            }
        }
    );
};
