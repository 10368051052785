import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

export const MDCheckbox = (props) => {
  const propsToForward = {
    ...props
  }

  if (!props.id && props.name) {
    propsToForward.id = props.name
  }

  const { errorText } = propsToForward
  if (errorText) {
    propsToForward.helperText = errorText
    propsToForward.error = true
  }

  // removing props which is not belongs to Material Checkbox
  delete propsToForward.errorText

  return <Checkbox {...propsToForward} />
}
