import {
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { PasswordField } from "../../../../common/components/PasswordField";
import { AppButton } from '../../../../common/components/AppButton';

const SetPinIframeCover = styled(Box)(({ theme }) => ({
  margin: "0 auto",
  maxWidth: 420,
  [theme.breakpoints.down("xs")]: {
    maxWidth: "100%",
  }
}));
const PasswordForm = ({ passwordDialogHandleSubmit }) => {
  const appClasses = useAppStyles();
  const [pass, setPass] = useState("");
  const onHandlePasswordChange = (event) => {
    setPass(event.target.value);
  };
  return (
    <div>
      <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
        <PasswordField
          name={"pass"}
          label="Your password"
          value={pass}
          onChange={onHandlePasswordChange}
        >
        </PasswordField>
      </Grid>
      <Box mt={2} />
      <AppButton
        disabled={pass ? false : true}
        contained
        onClick={() => {
          passwordDialogHandleSubmit(pass);
        }}
        className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
        primary
      >
        Yes, Continue
      </AppButton>
    </div>
  )
}

const SetPinIframe = ({ onSetupSetPinWidget, onSetPinSuccess }) => {
  const appClasses = useAppStyles();

  useEffect(() => {
    const onIframeMessage = (event) => {
      console.info("onIframeMessage", event.data)
      if (event.data.event_id === 'success') {
        onSetPinSuccess()
      }
    }
    window.addEventListener("message", onIframeMessage);
    onSetupSetPinWidget("set-pin-frame")
    return () => window.removeEventListener("message", onIframeMessage)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <Typography variant="span" className={appClasses.text1}>
      Enter a new PIN to use at ATMs or in stores. Make sure it's easy
      to remember, and keep it safe. Do not share your PIN with anyone.
    </Typography>
    <SetPinIframeCover>
      <iframe
        style={{ border: "none" }}
        title="set-pin-frame"
        src=""
        id="set-pin-frame"
        height={"370px"}
        width={"100%"}
      ></iframe>
    </SetPinIframeCover>
  </>

}


export const SetOrChangeCardPin = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const { noTitle, passwordDialogHandleSubmit, requiredValidatePassword, setPinSuccessHandler, setUpSetPinWidget } = props
  return (
    <>
      <Grid className={classes.root}>
        {!noTitle && (
          <>
            <DialogTitle className={classes.header}>
              <Typography variant="h6" className={appClasses.text3}>
                Change Pin
              </Typography>
              <Box mt={1} />

            </DialogTitle>
            <Box mt={2} />
          </>
        )}
        {requiredValidatePassword ?
          <PasswordForm passwordDialogHandleSubmit={passwordDialogHandleSubmit} /> :
          <SetPinIframe onSetupSetPinWidget={setUpSetPinWidget} onSetPinSuccess={setPinSuccessHandler} />
        }
      </Grid>
    </>
  );
};