import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  quickLinkIcon: {
    background: theme.palette.other.primary100,
  },
  goalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  goalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  noDataWrapper: {
    textAlign: "center",
  },
  backBtnContainer: {
    display: "flex"
  },
  backBtnText: {
    margin: "24px -15px"
  },
}));
