import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import { EditMobile } from "../components/EditMobile";
import { INVALID_OTP, NOT_AUTHORIZED, routes } from "../../../constants";
import history from "../../../history";
import { doLogoutWihoutRedirect } from "../../auth/actions";

import { DidNotReciveCodeDialog } from "../../../common/components/DidNotReciveCodeDialog";
import { styled } from "@material-ui/core";

const PhoneNumbeRegex = /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im

const StyledListItem = styled('li')(theme => ({
  textAlign: "left",
  padding: "5px 0px"
}));

const NotReciveCodeDialogBox = (props) => {

  return <DidNotReciveCodeDialog {...props}>
    <ul>
      <StyledListItem> Are you connected to a mobile network? </StyledListItem>
      <StyledListItem> Did you check your spam texts?</StyledListItem>
      <StyledListItem> Try restarting your device.</StyledListItem>
    </ul>
  </DidNotReciveCodeDialog>
}
export const EditMobilePageSteps = {
  fillMobile: "fillMobile",
  mobileOtp: "mobileOtp",
  emailOtp: "emailOtp",
};
const StepHelpContentMapper = {
  [EditMobilePageSteps.fillMobile]: 'SettingsEditPhone',
  [EditMobilePageSteps.mobileOtp]: 'SettingsEditPhoneOTP',
  [EditMobilePageSteps.emailOtp]: 'SettingsEditPhoneOTPEmail'
}
const initialState = {
  mobileNumber: "",
  password: "",
  email: "",
  mobileOtp: "",
  emailOtp: "",
  errors: {},
  isSubmitted: null,
  step: EditMobilePageSteps.fillMobile,
  isDidNotReceiveCodeDialogOpen: false,
};

class EditMobilePage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { data } = this.props;
    const newState = { ...initialState };
    if (data?.password) {
      newState.password = data?.password;
    }
    this.setState({ ...newState });
  }

  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state
    const { step: preStep } = prevState
    if (step !== preStep && this.props.onChangeHelpContent) {
      this.props.onChangeHelpContent(StepHelpContentMapper[step])
    }
  }

  goToStep = (step) => {
    this.setState({ step: step }, () => { });
  };

  isValid = () => {
    const { step, isSubmitted, mobileNumber, mobileOtp, emailOtp } = this.state;
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }
    if (step == EditMobilePageSteps.fillMobile) {
      if (!mobileNumber) {
        errors.mobileNumber = "Require";
      }
      if (mobileNumber && !PhoneNumbeRegex.exec(mobileNumber)) {
        errors.mobileNumber = "Invalid Phone Number";
      }
    }
    if (step == EditMobilePageSteps.mobileOtp) {
      if (!mobileOtp) {
        errors.mobileOtp = "Require";
      }
    }
    if (step == EditMobilePageSteps.emailOtp) {
      if (!emailOtp) {
        errors.emailOtp = "Require";
      }
    }

    this.setState({ errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));

    return valid;
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmitError = (error, stateWillReceiveError) => {
    const responseErrors = error?.response?.data?.errors || []
    const notAuthorized = responseErrors.some(error => error.code.toString() === NOT_AUTHORIZED)
    const invalidOTP = responseErrors.some(error => error.code.toString() === INVALID_OTP)
    if (stateWillReceiveError && invalidOTP) {
      this.setState( {errors: { ...this.state.errors, [stateWillReceiveError]: 'Invalid OTP'}})
    }
    if (notAuthorized){
      this.props.doLogoutWihoutRedirect()
      history.push(routes.login)
    }
  }

  onSubmitMobileOtp = async () => {
    const { actions, authReducer } = this.props;
    const { mobileNumber, mobileOtp } =
      this.state;
    const { bibson, user_id } = authReducer?.loginResponseData || {};
    const data = {
      bibson,
      user_id,
      phone: mobileNumber,
      twofac: mobileOtp,
    };

    try {
      const verifyMobileOtpRes = await actions.doVerifyOtpNewMobile(data)
      const email = verifyMobileOtpRes?.data?.data?.email
      await actions.doSendOtpCurrentEmail({ email, bibson, user_id })
      this.setState({ email })
      return this.goToStep(EditMobilePageSteps.emailOtp);
    } catch (error) {
      this.handleSubmitError(error, 'mobileOtp')
    }
  }

  onSubmitEmailOtp = async () => {
    const { actions, authReducer } = this.props;
    const { mobileNumber, email, emailOtp, password } =
      this.state;
    const {  username, loginResponseData } = authReducer || {};
    const { bibson, user_id } = loginResponseData || {};
    const data = {
      bibson,
      user_id,
      email: email,
      twofac: emailOtp,
    };
    try {
      const responseData = await actions.doVerifyOtpCurrentEmail(data)
      const { bibson: newBibson } = responseData
      const reqData = { phone: mobileNumber, identifier: username, password, user_id, bibson: newBibson, };
      const success = await actions.doChangeMobile(reqData)
      if (success) {
        this.handleClose(success);
      }
    } catch (error) {
      this.handleSubmitError(error, 'emailOtp')
      console.info(error)
    }
  }

  handleSubmit = () => {
    const { step, mobileNumber } =
      this.state;
    const { actions, authReducer } = this.props;
    const { goToStep } = this;
    const { loginResponseData } = authReducer;

    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        if (step == EditMobilePageSteps.fillMobile) {
          const data = {
            bibson: loginResponseData?.bibson,
            user_id: loginResponseData?.user_id,
            phone: mobileNumber,
          };
          actions.doSendOtpNewMobile(data, (success) => {
            if (success) {
              goToStep(EditMobilePageSteps.mobileOtp);
            }
          });
        }

        if (step == EditMobilePageSteps.mobileOtp) {
          this.onSubmitMobileOtp()
        }

        if (step == EditMobilePageSteps.emailOtp) {
          this.onSubmitEmailOtp()
        }
      }
    });
  };


  handleDontReceiveCode = () => {
    const { step, mobileNumber, email } = this.state;
    const { actions, authReducer } = this.props;
    const { loginResponseData } = authReducer;

    if (step == EditMobilePageSteps.mobileOtp) {
      const data = {
        bibson: loginResponseData?.bibson,
        user_id: loginResponseData?.user_id,
        phone: mobileNumber,
      };
      actions.doSendOtpNewMobile(data, (success) => { });
    }

    if (step == EditMobilePageSteps.emailOtp) {
      const data = {
        bibson: loginResponseData?.bibson,
        user_id: loginResponseData?.user_id,
        email,
      };
      actions.doSendOtpCurrentEmail(data);
    }
    this.onCloseDidNotReceiveCodeDialog()
  };

  displayDidNotReceiveCodeDialog = () => {
    this.setState({ isDidNotReceiveCodeDialogOpen: true });
  }

  onCloseDidNotReceiveCodeDialog = () => {
    this.setState({ isDidNotReceiveCodeDialogOpen: false });
  }

  handleClose = (success) => {
    const { onClose } = this.props;
    onClose && onClose(success);
  };


  renderTitleWithRemaingTime = (remainingTime) => {
    return `Editing locked for ${remainingTime} minutes`
  }

  render() {
    const { handleChange, handleSubmit, handleClose } =
      this;
    const propsToForward = {
      ...this.state,
      handleChange,
      handleSubmit,
      handleClose,
      handleDontReceiveCodeOnClickHandler: this.displayDidNotReceiveCodeDialog,
    };
    return <>
      <EditMobile {...propsToForward} />
      {this.state.isDidNotReceiveCodeDialogOpen && <NotReciveCodeDialogBox handleConfirm={this.onCloseDidNotReceiveCodeDialog} resendOTPCode={this.handleDontReceiveCode}/> }
    </>;
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    doLogout: bindActionCreators(doLogoutWihoutRedirect, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMobilePage);
