
import React from "react";
import { REPLACE_CARD_STEPS } from "../../../../constants";

import Info from "./Steps/Info";
import AskCurrentPassword from "./Steps/AskCurrentPassword";
import Confirmation from "./Steps/Confirmation";
import Reason from "./Steps/Reason";
import ReplaceCardProvider from "./store/ReplaceCardProvider";
import Address from "./Steps/Address";


const STEPS_ORDER = [
  REPLACE_CARD_STEPS.info,
  REPLACE_CARD_STEPS.askPassword,
  REPLACE_CARD_STEPS.reason,
  REPLACE_CARD_STEPS.address,
  REPLACE_CARD_STEPS.processConfirmation,
]

const STEP_COMPONENT_MAPPER = {
  [REPLACE_CARD_STEPS.askPassword]: AskCurrentPassword,
  [REPLACE_CARD_STEPS.info]: Info,
  [REPLACE_CARD_STEPS.reason]: Reason,
  [REPLACE_CARD_STEPS.address]: Address,
  [REPLACE_CARD_STEPS.processConfirmation]: Confirmation,
}

const HelpScreenNameByStep = {
  [REPLACE_CARD_STEPS.askPassword]: null,
  [REPLACE_CARD_STEPS.info]: 'manage-card-report-issue',
  [REPLACE_CARD_STEPS.reason]: 'ManageCardReplaceReason',
  [REPLACE_CARD_STEPS.address]: 'ManageCardAddress',
  [REPLACE_CARD_STEPS.processConfirmation]: 'manage-card-report-issue',
}

export const ReplaceCard = ({ isVirtualCard, closeAllSidemenu, onSuccessReplaceCard, handleClose, changeHelpScreenName }) => {
  const [step, setStep] = React.useState(STEPS_ORDER[0]);

  const nextStep = () => {
    const currentIndex = STEPS_ORDER.indexOf(step);
    const nextIndex = currentIndex + 1;
    setStep(STEPS_ORDER[nextIndex]);
    changeHelpScreenName(HelpScreenNameByStep[STEPS_ORDER[nextIndex]]);
  }

  const StepComponent = STEP_COMPONENT_MAPPER[step];

  if (!StepComponent) return null

  return <ReplaceCardProvider step={step}
    closeAllSidemenu={closeAllSidemenu}
    onSuccessReplaceCard={onSuccessReplaceCard}
    handleClose={handleClose}
    onNextStep={nextStep}
    isVirtualCard={isVirtualCard}>
    <StepComponent />
  </ReplaceCardProvider>
}
