import { useState } from "react";
import CredentialContext from "./CredentialContext";
import PasswordPopup from "../../common/components/PasswordPopup";
import useAuth from "../useAuth";
import { verifyPassword } from "../../modules/profile/services";


export default function CredentialsProvider({ children, onClose }) {
  const [password, setPassword] = useState(null);
  const { bibson, user_id, username } = useAuth();

  const onValidPassword = async (password) => {
    const response = await verifyPassword({ bibson, user_id, identifier: username, password });
    if (response.status === 200) { setPassword(password); }
    return response
  }

  return (
    <CredentialContext.Provider value={{ password }}>
      {!password && <PasswordPopup onClose={onClose} onSubmit={onValidPassword} />}
      {password && children}
    </CredentialContext.Provider>
  );
}