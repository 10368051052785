import { doGetAndSetLimit, doGetAndSetRecipient, doSendTransactions } from '../actions/sendToRecipient'
import { deleteRecipients, saveRecipients, getRecipientDetailById, updateRecipients } from '../actions/addEditRecipient'

export const doGetAndSetLimits = doGetAndSetLimit;
export const doGetAndSetRecipients = doGetAndSetRecipient;
export const doSendTransaction = doSendTransactions;

export const deleteRecipient = deleteRecipients;
export const saveRecipient = saveRecipients;
export const getRecipientById = getRecipientDetailById;
export const updateRecipient = updateRecipients;
