import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route } from "react-router-dom";
import { Router, useHistory, useLocation } from "react-router";
import HomePage from "./modules/home/containers/HomePage";
import { NOT_AUTHORIZED, routes, toastDuration } from "./constants";
import LoginPage from "./modules/auth/containers/LoginPage";
import history from "./history";
import { LinearProgress } from "@material-ui/core";
import { Toast } from "./common/components/core";
import { useDispatch, useSelector } from "react-redux";
import * as toastActions from "./common/actions/toast";
import ForgotPasswordPage from "./modules/auth/containers/ForgotPasswordPage";
import RegistrationPage from "./modules/registration/containers/RegistrationPage";
import KycFormPage from "./modules/registration/containers/KycFormPage";
import CardTnCPage from "./modules/registration/containers/CardTnCPage";
import { ProtectedRoute } from "./common/components/ProtectedRoute/ProtectedRoute";
import { useEffect } from "react";
import { validateCurrentBishop } from "./common/actions/validateCurrentBishop";
import { ResendInvite } from "./modules/auth/components/ResendInvite";
import { TemporaryAccountLockedPopup } from "./common/components/TemporaryAccountLockedPopup";
import useAccountStatus from "./hooks/useAccountStatus";
import { doCheckKycStatus } from "./modules/auth/actions";


const LoginPageWrapper = ({ invitationTokenExpired }) => {
  return <>
    <LoginPage />
    {invitationTokenExpired && <ResendInvite />}
  </>
}

function App() {
  const toastReducer = useSelector((state) => state.toastReducer);
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const loginResponseData = useSelector((state) => state.authReducer?.loginResponseData);
  const {bishop, user_id} = loginResponseData || {};
  const dispatch = useDispatch();
  const registrationReducer = useSelector((state) => state.registrationReducer);
  const  { isTemporaryLocked, dismissTemporaryLock, lockedCountDown, lockType } = useAccountStatus()

  useEffect( () => {
    dispatch(doCheckKycStatus())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bishop])

  const invitationTokenExpired = registrationReducer?.invitationTokenExpired
  const { message, type, open } = toastReducer;
  const { show } = loaderReducer;

  const handleToastClose = () => {
    dispatch(toastActions.hide());
  };

  useEffect(() => {
    if (bishop && user_id){
      const validatedTokenTask = validateCurrentBishop( {bishop, user_id});
      return () => validatedTokenTask.cancel();
    }
  }, [ bishop, user_id]);

  // TODO: Add a loader here

  return (
    <div className="App">
      {/*Material ui base css*/}
      <CssBaseline />

      {/*Application progressbar*/}
      {show && (
        <>
        <LinearProgress
          color="secondary"
          style={{ zIndex: 9999, position: "fixed", width: "100%" }}
        />
        <div className={'loader-overlay'}></div>
        </>
      )}

      {/*Application toast messages*/}
      <Toast
        handleClose={handleToastClose}
        duration={toastDuration}
        {...{ message, type, open }}
      />

      <Router history={history}>
        <Switch>
          <Route path={routes.login.path} exact>
            <LoginPageWrapper invitationTokenExpired={invitationTokenExpired}/>
          </Route>
          <Route path={routes.registration.path} component={RegistrationPage} exact />
          <Route path={routes.cardTnC.path} component={CardTnCPage} exact />
          <Route path={routes.kyc.path} exact>
            <KycFormPage />
          </Route>
          <Route path={routes.forgotPassword.path} exact>
            <ForgotPasswordPage />
          </Route>
          <Route path={routes.home.path} exact>
            <ProtectedRoute><HomePage /></ProtectedRoute>
          </Route>
        </Switch>
      </Router>
      {isTemporaryLocked && <TemporaryAccountLockedPopup handleConfirm={dismissTemporaryLock} lockoutTime={lockedCountDown} lockType={lockType}/> }

    </div>
  );
}

export default App;
