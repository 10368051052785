import Carousel from "react-material-ui-carousel"
import history from "../../../../history";
import { routes } from "../../../../constants";
import { styled } from "@material-ui/core";

const DASHBOARD_CLICK_ACTION_MAPPER = {
  drdepos: () => history.push(`${routes.deposit.path}/direct`),
  atml: () => history.push(routes.atmfinder.path),
  rs: () => history.push(routes.rewards.path),
};

const StyledCardCover = styled("div")({
  border: 0,
  minHeight: 180,
  padding: 0,
  blockSize: "fit-content",
  height: "auto",
});
const CardImage = styled("img")({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
});

const DashboardCardBig = (props) => {
  const { image, onClick } = props;

  const onClickHandler = () => {
    onClick && onClick();
  };

  return (
    <StyledCardCover elevation={0}>
      <CardImage src={image} onClick={onClickHandler} />
    </StyledCardCover>
  );
};


export default function DashboardCardCarousel({ isGotDashboardCards, dashboardCards }) {
  if (!isGotDashboardCards || !dashboardCards) return null
  return <Carousel>
    {dashboardCards.map((card, i) => (
      <DashboardCardBig
        image={card.icon}
        onClick={
          DASHBOARD_CLICK_ACTION_MAPPER[card.accept_click?.click_action]
        }
        key={card.icon}
      />
    ))}
  </Carousel>
}