import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, DialogTitle, Typography } from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon } from "../../../../constants";

export const LockCardTooltipDialog = (props) => {
  const { handleClose } = props;
  const appClasses = useAppStyles();
  const content = () => {
    return (
      <>
        <DialogTitle id="form-dialog-title">
          <div className={appClasses.dialogTitle}>
            <IconButton
              className={appClasses.dialogCloseBtn}
              onClick={() => handleClose()}
            >
              <img src={closeBlackIcon} />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent className={appClasses.dialogContent}>
          <Typography
            variant="h6"
            className={`${appClasses.text3} ${appClasses.textAlignCenter}`}
          >
            Lock Card
          </Typography>
          <Typography variant="span" className={appClasses.text1}>
            <p>
              If you think you've misplaced your card, you can lock it so that
              it cannot be used. This will help keep your money safe and prevent
              unauthorized transactions. You can unlock your card at any time.
            </p>
          </Typography>
          <Box mb={1}></Box>
          <Typography variant="span" className={appClasses.text1}>
            <p>
              If you have lost your card permanently, or if it has been stolen
              or damaged, please request a replacement card by tapping Replace
              Your Card.
            </p>
          </Typography>
        </DialogContent>

        <Box mb={2}></Box>
      </>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      {content()}
    </Dialog>
  );
};
