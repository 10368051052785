import { Grid, Typography, Box, Paper, Chip } from "@material-ui/core";
import React, { useState } from "react";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";

import {
  activePhysicalCardGreenImg,
  infoIcon,
  MANAGE_CARD_ITEM,
  pinGreenImg,
  replaceCardGreenImg,
  routes,
  viewVirtualCardGreenImg,
} from "../../../../constants";
import { AppListItemCard } from "../../../../common/components/AppListItemCard";
import { SidemenuViewContent } from "../../../../common/components/SidemenuViewContent";
import SetPinDialog from "../../../setPin/containers/SetPinDialog";
import { ReplaceCardPage } from "../../containers/ReplaceCardPage";
import { AppSwitch } from "../../../../common/components/AppSwitch";
import ActivateCardPage from "../../containers/ActivateCardPage";
import { SetOrChangePinCardPage } from "../../containers/SetOrChangePinCardPage";
import { PasswordDialog } from "../../../auth/components/PasswordDialog";
import { LockCardTooltipDialog } from "../LockCardTooltipDialog";
import history from "../../../../history";
import { LogoutOnWrongAttemptPopup } from "../../../auth/components/LogoutOnWrongAttemptPopup";
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";

import useAuth from "../../../../hooks/useAuth";
import { useSelector } from "react-redux";
import CredentialsProvider from "../../../../hooks/RequireCredentials/CrendentialProvider";
import ViewVirtualCardSlide from "../ViewVirtualCardSlide";


const findActiveVirtualCard = (cards) => {
  return cards.find((card) => card.active && card.is_virtual);
}

export const ManageCard = (props) => {
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();

  const {
    isOpenRightSidemenu,
    setIsOpenRightSidemenu,
    onCardClick,
    pinDialog,
    handleClose,
    isOpenActivateCard,
    isVirtualCard,
    isOpenChangePinCard,
    handleCloseActivateCard,
    handleViewActivateCard,
    handleChangePinCard,
    handleViewReplaceCard,
    isOpenReplaceCard,
    openViewVirtualCardSlide,

    //lock / unlock card
    startDoLockUnlockCard,
    isCardLock,
    showLockCardToolTip,
    setShowLockCardToolTip,
    authReducer,
    //password dialog
    showPwdDialog,
    handleClosePwdDialog,
    handleSubmitPwdDialog,
    handleConfirmLogoutAttemptPopup,
    mobileScreenCollection
  } = props;

  // TODO: temporary fix for the help screen can change content when switching right side menu. Should manage state better.
  const [replaceHelpScreen, setReplaceHelpScreen] = useState('manage-card-report-issue')
  const [activeCardHelpScreen, setActiveCardHelpScreen] = useState('ActivateCardFirstScreen')
  const { bibson, user_id } = useAuth()
  const {cards, currentActivatedCard } = useSelector(state => state.managecardReducer)

  const isChangePinMenuAvailable = currentActivatedCard && (!currentActivatedCard.is_virtual || currentActivatedCard.is_pin_set) && !isCardLock
  if (!currentActivatedCard) { return null}

  const initVirtualCardView = () => {
    const activeVirtualCard = findActiveVirtualCard(cards)
    let origin = window.location.origin;
    window?.PayfareMarqeta?.setGlobalCredentails({
      authToken: bibson,
      ting: activeVirtualCard.ting,
      userId: user_id,
      apiUrl: origin,
    });
    window?.PayfareMarqeta?.loadAndRenderCard();
  };

  const isOpenAnySideMenu = isOpenRightSidemenu
    || isOpenActivateCard || isOpenReplaceCard || isOpenChangePinCard;


  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>
          <div className={classes.goalHeader}>
            <Typography variant="h4" className={appClasses.text8}>
              Manage Card
            </Typography>
          </div>
          <Box mt={4}></Box>

          <div>
            {!isVirtualCard && (
              <div className={`${classes.cardStatus} `}>
                <Typography variant="span" className={appClasses.text5}>
                  Card Status
                </Typography>{" "}
                &nbsp;&nbsp;
                <Chip
                  className={
                    isCardLock ? appClasses.chipRed : appClasses.chipGreen
                  }
                  size="small"
                  label={isCardLock ? "Locked" : "Active"}
                />
              </div>
            )}
            {!isVirtualCard && (
              <Paper
                className={`${appClasses.paperCard} ${classes.lockWrapper} `}
              >
                <Typography variant="span" className={appClasses.text4}>
                  Lock Card
                  <img
                    src={infoIcon}
                    className={`${appClasses.infoIcon} ${appClasses.vAlignMiddle}`}
                    onClick={() => setShowLockCardToolTip(true)}
                    alt="info"
                  />
                </Typography>{" "}
                <AppSwitch
                  checked={isCardLock}
                  onClick={startDoLockUnlockCard}
                ></AppSwitch>
              </Paper>
            )}

            {isVirtualCard && (
              <>
                <Box mt={2}></Box>
                <AppListItemCard
                  icon={activePhysicalCardGreenImg}
                  title={"Activate Physical Card"}
                  desc={"Use your card for in-store purchases and more"}
                  active={false}
                  onClick={handleViewActivateCard}
                ></AppListItemCard>
              </>
            )}
            {isVirtualCard && (
              <>
                <Box mt={2}></Box>
                <AppListItemCard
                  icon={viewVirtualCardGreenImg}
                  title={"View Virtual Card"}
                  desc={"Use your virtual card to make online purchases"}
                  active={isOpenRightSidemenu}
                  onClick={() =>
                    onCardClick(MANAGE_CARD_ITEM.VIEW_VIRTUAL_CARD)
                  }
                ></AppListItemCard>
              </>
            )}

            <Box mt={2}></Box>
            <AppListItemCard
              icon={replaceCardGreenImg}
              title={"Replace Your Card"}
              desc={
                "Deactivate your lost, stolen, or damaged card and order a new one."
              }
              active={false}
              onClick={handleViewReplaceCard}
            ></AppListItemCard>
            <Box mt={2}></Box>

            {
              isChangePinMenuAvailable && <AppListItemCard
                icon={pinGreenImg}
                title={"Change Your PIN"}
                desc={"Change the PIN of your card"}
                active={false}
                onClick={handleChangePinCard}
              />
            }

            <Box mt={3}></Box>
            {isVirtualCard && <Typography
              variant="span"
              className={`${appClasses.text1} ${appClasses.colorBlack700}`}
            >
              <img src={infoIcon} className={classes.infoIcon} alt="info"/> Have questions
              about the virtual card?{" "}
              <span onClick={() => { history.push(routes.help.path) }} className={appClasses.aLink}>Click Here</span>
            </Typography>}
          </div>

          <Box mt={2}></Box>
          <div className={`${classes.goalFooter} ${isOpenAnySideMenu ? appClasses.openSidemenu : ""}`}>
            <MobileHelpScreen iconName="button" topics={ mobileScreenCollection } screenName={ isVirtualCard ? 'ManageCardVC' : 'manage-card-active'} anchor="top"/>
          </div>
        </Grid>
      </Grid>

      <ViewVirtualCardSlide onClose={() => setIsOpenRightSidemenu(false)} isOpen={isOpenRightSidemenu} initVirtualCardView={initVirtualCardView} mobileScreenCollection={mobileScreenCollection}/>

      {
        isOpenActivateCard && <CredentialsProvider onClose={handleCloseActivateCard}>
          <SidemenuViewContent
            open={isOpenActivateCard}
            onClose={handleCloseActivateCard}
            headerComponent={
              <Box className={classes.viewVirtualCardHeader} style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
                <MobileHelpScreen topics={mobileScreenCollection} screenName={activeCardHelpScreen} />
              </Box>
            }
          >

            <ActivateCardPage handleCloseActivateCard={handleCloseActivateCard} changeHelpScreenName={setActiveCardHelpScreen}/>

          </SidemenuViewContent>
        </CredentialsProvider>
      }

      <SidemenuViewContent
        open={isOpenReplaceCard}
        onClose={handleCloseActivateCard}
        headerComponent={
          <Box className={classes.viewVirtualCardHeader} style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            {replaceHelpScreen && <MobileHelpScreen topics={mobileScreenCollection} screenName={replaceHelpScreen} />}
          </Box>
        }
      >
        <ReplaceCardPage {...props} onViewVirtualCard={() => onCardClick(MANAGE_CARD_ITEM.VIEW_VIRTUAL_CARD)} changeHelpScreenName={setReplaceHelpScreen}/>
      </SidemenuViewContent>

      <SidemenuViewContent
        open={isOpenChangePinCard}
        onClose={() => handleCloseActivateCard(true)}
        headerComponent={
          <Box className={classes.viewVirtualCardHeader} style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'flex-end' }}>
            <MobileHelpScreen topics={mobileScreenCollection} screenName="change-pin" />
          </Box>
        }
      >
        <SetOrChangePinCardPage handleCloseActivateCard={ () => handleCloseActivateCard(true)} requirePassword />
      </SidemenuViewContent>

      {pinDialog && <CredentialsProvider onClose={handleClose}><SetPinDialog handleClose={handleClose} gotoViewVirtualCardPage={openViewVirtualCardSlide} /> </CredentialsProvider>}

      {showPwdDialog && (
        <PasswordDialog
          handleClose={handleClosePwdDialog}
          handleSubmit={handleSubmitPwdDialog}
        ></PasswordDialog>
      )}

      {showLockCardToolTip && (
        <LockCardTooltipDialog
          handleClose={() => setShowLockCardToolTip(false)}
        ></LockCardTooltipDialog>
      )}
      {authReducer.displayLogoutWrongAttemptPopup && (
        <LogoutOnWrongAttemptPopup
          handleConfirm={handleConfirmLogoutAttemptPopup}
        ></LogoutOnWrongAttemptPopup>
      )}
    </div>
  );
};
