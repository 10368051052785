import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import { EditEmail } from "../components/EditEmail";
import { NOT_AUTHORIZED, TEN_MIN_LOCK, routes } from "../../../constants";
import history from "../../../history";

export const EditEmailPageSteps = {
  fillEmail: "fillEmail",
  mobileOtp: "mobileOtp",
  emailOtp: "emailOtp",
};

const StepHelpContentMapper = {
  [EditEmailPageSteps.fillEmail]: 'SettingsEditEmail',
  [EditEmailPageSteps.mobileOtp]: 'SettingsEditEmailOTPPhone',
  [EditEmailPageSteps.emailOtp]: 'SettingsEditEmailOTP'
}

const initialState = {
  mobileNumber: "",
  password: "",
  email: "",
  mobileOtp: "",
  emailOtp: "",
  errors: {},
  isSubmitted: null,
  step: EditEmailPageSteps.fillEmail,
};

class EditEmailPage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { data } = this.props;
    const newState = { ...initialState };
    if (data?.password) {
      newState.password = data?.password;
    }
    this.setState({ ...newState });
  }

  componentDidUpdate(prevProps, prevState) {
    const {step} = this.state
    const { step: preStep} = prevState
    if (step !== preStep && this.props.onChangeHelpContent) {
      this.props.onChangeHelpContent(StepHelpContentMapper[step])
    }
  }

  goToStep = (step) => {
    this.setState({ step: step }, () => {});
  };

  isValid = () => {
    const { step, isSubmitted, email, mobileOtp, emailOtp } =
      this.state;
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }
    if (step == EditEmailPageSteps.fillEmail) {
      if (!email) {
        errors.mobileNumber = "Require";
      }
    }

    if (step == EditEmailPageSteps.emailOtp) {
      if (!emailOtp) {
        errors.emailOtp = "Require";
      }
    }

    if (step == EditEmailPageSteps.mobileOtp) {
      if (!mobileOtp) {
        errors.mobileOtp = "Require";
      }
    }

    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));

    return valid;
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmitError = (error) => {
    const responseErrors = error?.response?.data?.errors || []
    const isTemporaryLock = responseErrors.some(error => error.code.toString() === TEN_MIN_LOCK)
    const notAuthorized = responseErrors.some(error => error.code.toString() === NOT_AUTHORIZED)
    if (isTemporaryLock) {
      this.setState({ accountLock: true })
    }
    if (notAuthorized){
      this.props.doLogoutWihoutRedirect()
      history.push(routes.login)
    }
  }


  handlerSubmitEmailOtpStep = async () => {
    const { email, emailOtp } =
      this.state;
    const { actions, authReducer } = this.props;
    const { goToStep } = this;
    const { loginResponseData } = authReducer;

    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
      email: email,
      twofac: emailOtp,
    };
    try{
      const responseData = await actions.doVerifyOtpNewEmail(data)
      const sendOtpData = {
        bibson: loginResponseData?.bibson,
        user_id: loginResponseData?.user_id,
        phone: responseData?.phone,
      };
      await actions.doSendOtpCurrentMobile(sendOtpData)
      this.setState({ mobileNumber: responseData?.phone })
      goToStep(EditEmailPageSteps.mobileOtp);
    }catch(error){
      this.handleSubmitError(error)
    }
  }

  handlerSubmitMobileOtpStep = async () => {
    const { mobileNumber, password, mobileOtp, email } =
      this.state;
    const { actions, authReducer } = this.props;
    const { loginResponseData, username } = authReducer;
    const { bibson, user_id } = loginResponseData
    const data = { bibson, user_id, phone: mobileNumber, twofac: mobileOtp, };
    try {
      const response = await actions.doVerifyOtpCurrentMobile(data)
      const { bibson: newBibson, user_id: newUserId } = response
      const changeEmailResponse = await actions.doChangeEmail({ bibson: newBibson, email, identifier: username, password, user_id: newUserId, })
      this.handleClose(changeEmailResponse?.status === 'success');
    } catch (err) {
      this.handleSubmitError(err)
      this.handleClose(false);
    }
  }

  handleSubmit = () => {
    const { step, email } =
      this.state;
    const { actions, authReducer } = this.props;
    const { goToStep } = this;
    const { loginResponseData } = authReducer;

    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        if (step == EditEmailPageSteps.fillEmail) {
          const data = {
            bibson: loginResponseData?.bibson,
            user_id: loginResponseData?.user_id,
            email: email,
          };
          actions.doSendOtpNewEmail(data, (success) => {
            if (success) {
              goToStep(EditEmailPageSteps.emailOtp);
            }
          });
        }

        if (step == EditEmailPageSteps.emailOtp) {
          this.handlerSubmitEmailOtpStep()
        }

        if (step == EditEmailPageSteps.mobileOtp) {
          this.handlerSubmitMobileOtpStep()
        }
      }
    });
  };

  handleDontReceiveCode = () => {
    const { step, mobileNumber, email } = this.state;
    const { actions, authReducer } = this.props;
    const { loginResponseData } = authReducer;

    if (step == EditEmailPageSteps.emailOtp) {
      const data = {
        bibson: loginResponseData?.bibson,
        user_id: loginResponseData?.user_id,
        email: email,
      };
      actions.doSendOtpNewEmail(data, (success) => {});
    }

    if (step == EditEmailPageSteps.mobileOtp) {
      const data = {
        bibson: loginResponseData?.bibson,
        user_id: loginResponseData?.user_id,
        phone: mobileNumber,
      };
      actions.doSendOtpCurrentMobile(data, (success) => {});
    }
  };

  handleClose = (success) => {
    const { onClose } = this.props;
    onClose && onClose(success);
  };

  render() {
    const { handleChange, handleSubmit, handleClose, handleDontReceiveCode } =
      this;
    const propsToForward = {
      ...this.state,
      handleChange,
      handleSubmit,
      handleClose,
      handleDontReceiveCode
    };
    return <EditEmail {...propsToForward} />;
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmailPage);
