import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetOrChangeCardPin } from "../components/ActivateCard/SetOrChangeCardPin";
import * as dashboarActions from "../../dashboard/actions";
import * as profileActions from "../../profile/actions";
import useAuth from "../../../hooks/useAuth";
import { loadCurrentUserCards } from "../actions";
import { getPrimaryCard } from "../services";
import { useRequest } from "ahooks";

export const SetOrChangePinCardPage = (props) => {

  const dispatch = useDispatch();
  const { noTitle, handleCloseActivateCard, requirePassword } = props;
  const { user_id, bibson, username } = useAuth();

  const [requiredValidatePassword, setRequiredValidatePassword] = React.useState(requirePassword);

  const { data, error, loading  } = useRequest( () => getPrimaryCard({user_id, bibson}), { refreshDeps: [user_id, bibson]})

  const currentActivatedCard = data?.data?.data?.auto_method|| {};
  const handleSetPinSyncSuccess = useCallback(() => {
    handleCloseActivateCard && handleCloseActivateCard(true);
  }, [handleCloseActivateCard])

  const passwordDialogHandleSubmit = (password) => {
    if (password) {
      dispatch(
        profileActions.doVerifyPassword(
          {
            identifier: username,
            password,
            user_id,
            bibson
          },
          (success) => {
            if (success) {
              setRequiredValidatePassword(false)
            }
          }
        )
      );
    }
  };

  const setUpSetPinWidget = useCallback((iframeName) => {
    let origin = window.location.origin;
    window?.PayfareMarqeta?.setGlobalCredentails({
      authToken: bibson,
      ting: currentActivatedCard.ting,
      userId: user_id,
      apiUrl: origin,
    });
    window?.PayfareMarqeta?.renderSetPin(iframeName, origin + "/SetPinSuccess.html");
  }, [currentActivatedCard.ting, user_id, bibson])

  const setPinSuccessHandler = useCallback(() => {
    dispatch(dashboarActions.syncSetPinUser({
      ting: currentActivatedCard.ting,
      userId: user_id,
      bibson: bibson
    }, () => console.info("syncSetPinUser success")));
    dispatch(loadCurrentUserCards({user_id, bibson})).then(handleSetPinSyncSuccess);
  }, [currentActivatedCard.ting, dispatch, handleSetPinSyncSuccess, user_id, bibson])


  if (loading || error) return null

  const propsTobeForward = {
    noTitle,
    passwordDialogHandleSubmit,
    setUpSetPinWidget,
    setPinSuccessHandler
  };

  return <SetOrChangeCardPin {...propsTobeForward} requiredValidatePassword={requiredValidatePassword}/>;
};
