import {
  SET_SECURITY_QUESTIONS, SET_USER_PROFILE,
  INVITATION_TOKEN_EXPIRED,
  RESEND_INVITATION_SUCCESS,
  RESET_INVITATION_TOKEN_EXPIRED,
} from "../../../redux/actionTypes";
import {
  getSecurityQuestions, getUserProfile, registration,
} from "../services";
import { TOKEN_EXPIRED } from "../../../constants";
import { apiCall } from "../../../common/actions/apiCall";
import { sendResendInvite } from "../../auth/services";
import { doGetAndOpenKyc, setLoginResponseData, setUsername } from "../../auth/actions";
import * as toastActions from "../../../common/actions/toast";
import { TOAST_TYPES } from "../../../common/components/core";


export const setSecurityQuestions = (data) => {
  return {
    type: SET_SECURITY_QUESTIONS,
    data,
  };
};

export const setProfile = (data) => {
  return {
    type: SET_USER_PROFILE,
    data,
  };
};

export const doGetAndSetSecurityQuestions = (data, callback) => (dispatch) => {
  apiCall(dispatch, getSecurityQuestions(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (securityQuestionsResponse) => {
      if (securityQuestionsResponse?.data?.status == "success") {
        dispatch(setSecurityQuestions(securityQuestionsResponse?.data?.data?.security_questions))
        return;
      }
      callback({ success: false });
    },
    (error) => {
      callback({ success: false });
    }
  );
};

export const doGetAndSetUserProfile = (data) => (dispatch) => {
  apiCall(dispatch, getUserProfile(data), {
    showLoader: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(setProfile(response?.data?.data))
        return;
      }
    },
    (error) => {
      const { token } = data
      error?.response?.data?.errors.map((error) => {
        if (error.code == TOKEN_EXPIRED) {
          dispatch(tokenExpired(token));
          return;
        }
      })
    }
  );
};

export const registrationFailure = (error) => (dispatch) => {
  // TODO regitration failure
}

export const registrationSuccess = (registrationResponse, payload) => async (dispatch) => {
  const { data: responseData } = registrationResponse;
  const { bibson, session_id, user } = responseData.data
  const user_ting = user?.user_ting;
  dispatch(setUsername(payload.username));
  dispatch(setLoginResponseData({ bibson, user_ting, session_id, user_id: user.id, user }));
  dispatch(doGetAndOpenKyc({ bibson, user_ting }));
}
  export const resetInvitationTokenExpiredState = () => {
    return { type: RESET_INVITATION_TOKEN_EXPIRED }
  }

  export const doResendInvitation = ({ invitationCode }) => async (dispatch) => {
    try {
      const res = await sendResendInvite({ code: invitationCode })
      if (res?.status === 200) {
        dispatch(resendInvitationSuccess())
        dispatch(resetInvitationTokenExpiredState())
        dispatch(toastActions.show("Email sent", TOAST_TYPES.SUCCESS));
      }
    } catch (e) {
      dispatch(resentInvitationFailure('Unable to send invite. Try again later.'))
    }
  }
  export const resentInvitationFailure = (errorMessage) => {
    return toastActions.show(errorMessage, TOAST_TYPES.ERROR)
  }

  export const resendInvitationSuccess = () => {
    return { type: RESEND_INVITATION_SUCCESS }
  }

  export const tokenExpired = (token) => {
    return { type: INVITATION_TOKEN_EXPIRED, data: token }
  }

  export const doRegistration = (data, callback) => (dispatch) => {
    apiCall(dispatch, registration(data), {
      showLoader: true,
      displayError: true,
    }).then(
      (res) => {
        if (res?.data?.status == "success") {
          let payload = {
            bibson: res?.data?.data?.bibson,
            user_ting: res?.data?.data?.user?.user_ting,
          }
          let prepareLoginData = {
            "bibson": res?.data?.data?.bibson,
            "session_id": res?.data?.data?.session_id,
            "user_id": res?.data?.data?.user?.id,
            "user_ting": res?.data?.data?.user?.user_ting,
            "user": { ...res?.data?.data?.user }
          }

          dispatch(setLoginResponseData(prepareLoginData));
          return;
        }
      },
      (error) => {
      }
    );
  };

