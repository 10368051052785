import {
  SET_BANK_ACCOUNT_DETAILS,
  SET_IS_GOT_BANK_ACCOUNT_DETAILS,
} from "../../../redux/actionTypes";

const initState = {
  bankAccountDetails: {},
  isGotBankAccountDetails: false,
};

export default function depositReducer(state = initState, action) {
  switch (action.type) {
    case SET_IS_GOT_BANK_ACCOUNT_DETAILS:
      return Object.assign({}, state, {
        isGotBankAccountDetails: action.data,
      });
    case SET_BANK_ACCOUNT_DETAILS:
      return Object.assign({}, state, {
        bankAccountDetails: action.data,
      });

    default:
      return state;
  }
}
