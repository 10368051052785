
/**
 * Group n element into groups with maximum n element
 * @param {Array} elements - elements
 * @param {Number} n - maximum element in each group
 * @returns {Array<Array>} - array of group
 */
export function groupElementsByNElement(elements, n){
  return elements.reduce( (result, element, index) => {
    const groupIndex = Math.floor(index/n)
    result[groupIndex] = result[groupIndex] || []
    const elementIndex = index % n
    result[groupIndex][elementIndex] = element
    return result
  }, [])
}