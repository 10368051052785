import { Collapse, IconButton, Link, Table, TableBody, TableCell, TableRow, Typography, styled } from "@material-ui/core";
import { Fragment, useState } from "react";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Moment from "react-moment";
import moment from "moment";

const StyledRow = styled(TableRow)(({ theme }) => ({
  background: 'rgba(242, 242, 242, 0.9)',
}))

export default function StatementYearRow({ year, statements = [] }) {
  const [open, setOpen] = useState(false)

  const sortedStatementsByDate = statements.sort((a, b) => {
    return moment(b.start_date).unix() - moment(a.start_date).unix();
  })
  return <>
    <StyledRow>
      <TableCell>
        <Typography variant="h6">{year} ({statements.length})</Typography></TableCell>
      <TableCell align="right">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </TableCell>
    </StyledRow>
    <TableRow>
      <TableCell colSpan={2} style={{ padding: 0 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table>
            <TableBody>
              {sortedStatementsByDate.map((statement) => {
                return <TableRow key={statement.start_date}>
                  <TableCell>
                    <Typography variant="body2">
                      <Moment format="MMMM">{statement.start_date}</Moment>
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Link href={statement.url} target="_blank">
                      <ChevronRightIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}