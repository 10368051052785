import {
  SET_STATEMENT_DETAILS,
  SET_STATEMENT_GOT_RESPONSE,
} from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import { getStatement } from "../services";

export const setStatement = (response) => {
  return {
    type: SET_STATEMENT_DETAILS,
    data: response,
  };
};
export const setStatementGotResponse = (response) => {
  return {
    type: SET_STATEMENT_GOT_RESPONSE,
    data: response,
  };
};

export const doGetAndSetStatement = (data) => (dispatch) => {
  dispatch(setStatement([]));
  dispatch(setStatementGotResponse(false));
  apiCall(dispatch, getStatement(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      //Map error code  008005 or if the list is empty = No statements Available
      if (response?.data?.status == "success") {
        dispatch(setStatement(response?.data?.data?.statements));
      }
      dispatch(setStatementGotResponse(true));
    },
    (error) => {
      dispatch(setStatementGotResponse(true));
      dispatch(setStatement([]));
    }
  );
};
