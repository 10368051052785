import { MenuItem, styled } from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../../../App.style";
import { AppButton } from "../../../../../common/components/AppButton";
import { AppTextField } from "../../../../../common/components/AppTextField";
import { REPLACE_CARD_REASONS } from "../../../../../constants";
import { StepWrapper, SubTitle, Title } from "../AtomComponents";
import useReplaceCard from "../store/useReplaceCard";

const StyledAppTextField = styled(AppTextField)(({ theme }) => ({
  margin: theme.spacing(4, 0, 2, 0),
}));

const subTitleMessage = (isVirtualCard) => {
  if (isVirtualCard) {
    return "Let us know what happened. We'll deactivate your current cards and issue new ones immediately. Your new virtual card will become available immediately. Your new physical card will arrive in 10 business days."
  }
  return "Let us know what happened. We'll deactivate your current cards and issue new ones immediately. Your new physical card should arrive in 10 business days."
}


export default function Reason() {
  const appClasses = useAppStyles();

  const { onNextStep, setReplaceReason, replaceReason, isVirtualCard } = useReplaceCard();

  const onChangeReason = (e) => {
    setReplaceReason(e.target.value)
  }

  const isDisabled = replaceReason === "" || !replaceReason


  return <StepWrapper>
    <Title>
      Replace Your Card
    </Title>
    <SubTitle>{subTitleMessage(isVirtualCard)}</SubTitle>
    <StyledAppTextField
      name={"reason"}
      label="Reason"
      select
      value={replaceReason}
      onChange={onChangeReason}
      fullWidth
      color="secondary"
    //errorText={errors?.fromAccount}
    >
      {REPLACE_CARD_REASONS.map((reason) => (
        <MenuItem className={appClasses.dropDownMI} key={reason.reasonValue} value={reason.reasonValue}>
          {reason.reasonText}
        </MenuItem>
      ))}
    </StyledAppTextField>

    <AppButton
      disabled={isDisabled}
      variant="contained"
      fullWidth
      onClick={onNextStep}
      className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
      primary
      style={{ width: '100%' }}
    >
      Continue
    </AppButton>
  </StepWrapper>
}