import { ACCOUNT_TEMPORARY_LOCKED, DISMISS_ACCOUNT_TEMPORARY_LOCKED } from "../../redux/actionTypes";


export const temporaryLockAccount = (lockedCountDown, lockType) => {
  return {
    type: ACCOUNT_TEMPORARY_LOCKED,
    data: {
      lockedCountDown,
      lockType
    },
  };
}

export const dismissTemporaryLockAccount = () => {
  return {
    type: DISMISS_ACCOUNT_TEMPORARY_LOCKED,
  };
}