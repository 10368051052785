import React from 'react'
import styles from './styles.module.css'
import { MDButton } from './Button'
import { MDIconButton } from './IconButton'
import { MDList } from './List'
import { MDListItem } from './ListItem'
import { MDListItemIcon } from './ListItemIcon'
import { MDTextField } from './TextField'
import { MDListItemText } from './ListItemText'
import { MDToast, MD_TOAST_TYPES } from './Toast'
import { MConfirmDialog } from './ConfirmDialog'
import { MDAutocomplete } from './Autocomplete'
import { MDKeyboardDateTimePicker, MDDateTimePicker } from './DateTimePicker'
import { MDCheckbox } from './Checkbox'
import { MD_X_DesktopDatePicker } from './X_DesktopDatePicker'

export const ExampleComponent = ({ text }) => {
  return <div className={styles.test}>Example Component1 : {text}</div>
}

export const TextField = MDTextField
export const Autocomplete = MDAutocomplete
export const Button = MDButton
export const IconButton = MDIconButton
export const List = MDList
export const ListItem = MDListItem
export const ListItemIcon = MDListItemIcon
export const ListItemText = MDListItemText
export const Toast = MDToast
export const ConfirmDialog = MConfirmDialog
export const DateTimePicker = MDDateTimePicker
export const KeyboardDateTimePicker = MDKeyboardDateTimePicker
export const Checkbox = MDCheckbox
export const X_DesktopDatePicker = MD_X_DesktopDatePicker

export const TOAST_TYPES = MD_TOAST_TYPES
