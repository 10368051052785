import React from "react";
import {
    loadingBlackIcon,
  } from "../../../../constants";
  import { useAppListStyles, useAppStyles } from "../../../../App.style";

function LoadingFragment() {
    const appClasses = useAppStyles();

  return (
    <>
      <img
        src={loadingBlackIcon}
        className={`${appClasses.infoIcon} ${appClasses.vAlignMiddle}`}
      />
    </>
  );
}

export default LoadingFragment;