import { MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

const RegistrationQuestionSelectBox = ({ control, name, label, errors, questions }) => {
  const errorMessage = errors?.message
  const isError = !!errorMessage
  return <Controller
    render={
      ({ field }) => <TextField
        data-testid="question-select-box"
        select
        label={label}
        defaultValue=""
        variant='outlined'
        className='registration-field'
        error={isError} helperText={errorMessage}
        fullWidth
        {...field}
      >
        {questions.map((question) => (
          <MenuItem key={question.id} value={question.security_question}>
            {question.security_question}
          </MenuItem>
        ))}
      </TextField>
    }
    control={control}
    name={name}
    defaultValue=''
  />
}

export default RegistrationQuestionSelectBox