import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { additionalApiData } from "../../../constants";
import * as actions from "../actions";
import { EditResAddress } from "../components/EditResAddress";

export const EditResAddressPageSteps = {
  fill: "fill",
  confirmAddress: "confirmAddress",
  noSuggestedAddress: "noSuggestedAddress",
  successWithNoSuggestedAddress: "successWithNoSuggestedAddress",
};
const initialState = {
  isShipping: false,

  password: "",
  first_name: "",
  last_name: "",

  streetAddress: "",
  apartment: "",
  city: "",
  state: "",
  zipCode: "",

  suggestedAddress: null,
  confirmedAddress: null,

  errors: {},
  isSubmitted: null,
  step: EditResAddressPageSteps.fill,
};

class EditResAddressPage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { userAuthData } = this.props;
    let newState = { ...initialState };

    if (userAuthData) {
      newState = {
        ...newState,
        ...{ ...userAuthData },
      };
    }
    this.setState({ ...newState });
  }


  goToStep = (step, callback) => {
    this.setState({ step: step }, () => {
      if (callback) callback();
    });
  };

  handleClose = (success) => {
    if (success) {
      const { onClose } = this.props;
      onClose && onClose(success);
      return;
    }
    this.goToStep(EditResAddressPageSteps.fill);
  };

  // Get address depending on the type of address and format address to standard.
  editAddress = () => {
    const { isResidentialAddress, profileState } = this.props;
    const address = isResidentialAddress ? profileState?.userAddresses : profileState?.userShippingAddress;
    // Get both address_line1 and address_line_1 because shipping address has address_line_1 and residental address address has address_line1
    const { address_line1, address_line_1, address_line2, address_line_2, city, region, postal_code, first_name, last_name } = address || {};
    return {
      first_name, last_name,
      address_line1: address_line1 || address_line_1,
      address_line2: address_line2 || address_line_2,
      city,
      region,
      postal_code,
    }
  }

  render() {
    const edditAddress = this.editAddress()
    const { handleClose } = this;
    const { isResidentialAddress } = this.props;
    return <EditResAddress password={this.state.password} closeHandler={handleClose} isResidentialAddress={isResidentialAddress} address={edditAddress}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    dashboardReducer: state.dashboardReducer,
    profileState: state.profileReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditResAddressPage);
