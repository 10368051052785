import axios from "axios";

// Check if currenct bibson is valid
export default async function authCheck({ bibson, user_id, user_ting }) {
  const endPoint = `/users/${user_id}/auth-check`
  const headers = {
    "Content-Type": "application/json",
    "x-bishop": bibson,
  }
  return axios.get(endPoint, { headers })
}