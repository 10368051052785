import { Autocomplete } from '@material-ui/lab'
import React from 'react'

export const MDAutocomplete = (props) => {
  const propsToForward = {
    ...props
  }

  if (!props.id && props.name) {
    propsToForward.id = props.name
  }

  return <Autocomplete {...propsToForward} />
}
