import { Box, Grid, Hidden, Paper, Typography, styled } from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../../App.style";
import { AppButton } from "../../../../common/components/AppButton";
import { byPayfareImg, rewardBanner1, rewardBanner2, routes } from "../../../../constants";
import history from "../../../../history";
import { useStyles } from "./style";
import redBg from '../../../../assets/images/way-to-earn/red-bg.png';
import blackBg from '../../../../assets/images/way-to-earn/black-bg.png';
import earnImg from '../../../../assets/images/way-to-earn/earn.png';
import visSavingEdge from '../../../../assets/images/way-to-earn/visa-saving-edge.png';
import Reward from "./Reward/Reward";

const RewardWrapper = styled(Box)(() => ({
  width: '100%'
}))

const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 24,
}));

const CardRoot = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "16px",
  background: "#FFFFFF",
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1);",
  marginBottom: theme.spacing(2),
  ...theme.appAnimations?.animationFadeIn,
}));

const CardImage = styled('div')(({ backgroundUrl }) => ({
  background: `url(${backgroundUrl}) no-repeat center right / cover;`,
  border: 0,
  padding: '20px 40px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > img': {
    width: '100%',
    objectFit: 'contain'
  }
}));

const CardContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 2, 4, 3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
  '& > .card-title': {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    display: 'block'
  },
  '& > .by-payfare-logo': {
    width: "50px",
    height: "16px",
    marginBottom: theme.spacing(2),
  },
  '& > .more-details': {
    marginBottom: theme.spacing(3),
    color: '#555555',
  }
}));

export const WayToEarn = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const { style, onClickViewVirtualCard } = props;
  return (
    <div className={classes.mainWrapper} style={{ ...style }}>
      <RewardWrapper>
        <Reward />
      </RewardWrapper>
      <Title variant="h4">Bonus</Title>
      <CardRoot elevation={0}>
        <Grid container justifyContent="space-between" alignContent="center">
          <Hidden smDown>
            <Grid item xs={12} md={6}>
              <CardImage backgroundUrl={redBg}>
                <img src={earnImg} alt="reward banner" />
              </CardImage>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="subtitle1" className="card-title">
                Earn cash back rewards on everyday purchases
              </Typography>
              <div><img src={byPayfareImg} className='by-payfare-logo' alt="by payfare" /></div>

              <Typography variant="body2" className="more-details"              >
                You can earn cash back when you shop at hundreds of brands with your
                Artist Growth Wallet Card
              </Typography>

              <div>
                <AppButton
                  className={appClasses.primaryOutlineBtn}
                  onClick={() => {
                    props.getDoshJWTToken();
                  }} //{history.push(routes.offers.path)}
                >
                  Explore All Offers
                </AppButton>
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </CardRoot>
      <CardRoot>
        <Grid container justifyContent="space-between" alignContent="center">
          <Hidden smDown>
            <Grid md={6}>
              <CardImage backgroundUrl={blackBg}>
                <img src={visSavingEdge} alt="Visa Saving Edge" />
              </CardImage>
            </Grid>
          </Hidden>
          <Grid item md={6}>
            <CardContent>
              <Typography variant="subtitle1" className="card-title">
                Visa SavingsEdge
              </Typography>
              <Typography variant="body2" className="more-details"              >
                You can earn cash back when you shop at hundreds of brands with your
                Artist Growth Wallet Card
              </Typography>
              <div>
                <AppButton
                  className={appClasses.primaryOutlineBtn}
                  onClick={() => {
                    history.push(routes.offers.path);
                  }}
                >
                  View Merchants
                </AppButton>
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </CardRoot>
    </div>
  );
};
