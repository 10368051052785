import { useAsyncEffect } from "ahooks";
import { useDispatch, useSelector } from "react-redux";
import { contentfulActionTypes, doGetAndSetContent } from "../modules/contentful/actions";

export default function useMobileScreenCollection() {
  const dispatch = useDispatch()
  const mobileScreenCollection = useSelector((state) => state.contentfulReducer.mobileScreenCollection || [])

  useAsyncEffect(async () => {
    // Only fetch if not already fetched
    if (mobileScreenCollection.length > 0) return;
    dispatch(doGetAndSetContent(contentfulActionTypes.mobileScreenCollection));
  }, [])

  return mobileScreenCollection
}