import React from 'react'
import { TextField as MuiTextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

const TextField = ({ control, name, label, errors, disabled, ...rest }) => {
  const errorMessage = errors?.message
  const isError = !!errorMessage
  return <Controller
    control={control}
    render={({ field }) => <MuiTextField fullWidth variant="outlined" label={label} helperText={errorMessage} error={isError} {...field} disabled={disabled} {...rest}/>}
    name={name}
    defaultValue=''
  />
}

export default TextField