import SvgIcon from '@material-ui/core/SvgIcon';


export default function Bolt({ props }) {
  return <SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4831_92289)">
      <path d="M9.16667 1.83325L2.5 9.83325H8.5L7.83333 15.1666L14.5 7.16659H8.5L9.16667 1.83325Z" fill="#FF3C00" stroke="#FF3C00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_4831_92289">
        <rect width="16" height="16" fill="white" transform="translate(0.5 0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
}