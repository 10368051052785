import {
  Box,
  Grid,
  Hidden,
  Paper,
  Typography,
  styled,
} from '@material-ui/core';
import React from 'react';
import { useAppStyles } from '../../../../App.style';
import { AppButton } from '../../../../common/components/AppButton';
import { virtualCardImg } from '../../../../constants';
import { useStyles } from './style';

const VirtualCardCover = styled(Paper)(({ theme }) => ({
  display: "flex",
  borderRadius: "56px",
  "& img": {
    height: "100%",
    borderRadius: "56px",
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.spacing(2),
  }
}));
const VirtualCardCoverImage = styled('img')({
  width: '100%',
  objectFit: 'cover',
});

const StyledAppButton = styled(AppButton)(({ theme }) => ({
  width: theme.spacing(28),
}));

export const VirtualCard = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const { onClickViewVirtualCard } = props;
  return (
    <VirtualCardCover className={`${appClasses.paperCard}`}>
      <Grid container>
        <Hidden smDown>
          <Grid item xs={12} md={6} lg={4}>
            <VirtualCardCoverImage src={virtualCardImg} alt="Virtual Card" />
          </Grid>
        </Hidden>

        <Grid item xs={12} md={6} lg={8}>
          <div className={classes.rightContent}>
            <Typography variant="h6" className={appClasses.text3}>
              Start using your virtual card
            </Typography>
            <Box mt={1}></Box>
            <Typography
              variant="span"
              className={`${appClasses.text1} ${appClasses.colorBlack500}`}
            >
              Use your virtual card for online purchases until your physical
              card arrives.
            </Typography>
            <Box mt={3}></Box>
            <div>
              <StyledAppButton
                className={appClasses.primaryBtn}
                onClick={onClickViewVirtualCard}
              >
                View Virtual Card
              </StyledAppButton>
            </div>
          </div>
        </Grid>
      </Grid>
    </VirtualCardCover>
  );
};
