import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'
import DoneIcon from "@material-ui/icons/Done";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

export const MD_TOAST_TYPES = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error'
}

export const MDToast = (props) => {
  const position = {
    vertical: 'top',
    horizontal: 'center'
  }
  const ALERT_SERVIRITY_COLOR_MAPPER = {
    "success": "#018787"
  }
  const ALERT_SERVIRITY_ICON_MAPPER = {
    "success": <DoneIcon />
  }
  const { vertical, horizontal } = position
  const { open, type, message, duration, handleClose } = props
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={duration}
      onClose={() => handleClose()}
    >
      <Alert
        style={{ backgroundColor: ALERT_SERVIRITY_COLOR_MAPPER[type] }}
        icon={ALERT_SERVIRITY_ICON_MAPPER[type]}
        onClose={() => handleClose()}
        severity={type || MD_TOAST_TYPES.INFO}
      >
        {message || 'No message'}
      </Alert>
    </Snackbar>
  )
}
