import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { passwordRegex } from "../../../constants";
import * as actions from "../actions";
import { ChangePassword } from "../components/ChangePassword";

const initialState = {
  currentPassword: "",
  password: "",
  confirmPassword: "",
  errors: {},
  isSubmitted: null,
};

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { data } = this.props;
    const newState = { ...initialState };
    if (data?.password) {
      newState.currentPassword = data?.password;
    }
    this.setState({ ...newState });
  }

  goToStep = (step) => {
    this.setState({ step: step }, () => {});
  };

  isValid = () => {
    const { isSubmitted, password, confirmPassword } = this.state;
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }

    if (!password) {
      errors.password = "Require";
    }
    if (!confirmPassword) {
      errors.confirmPassword = "Require";
    }

    if (password && confirmPassword) {
      if (password != confirmPassword) {
        errors.confirmPassword =
          "New password and confirm password must be same";
      }
      if (password.length < 8) {
        errors.password = "Minimum 8 characters require";
      }
      if (!password.match(passwordRegex)) {
        errors.password = "Your password must include: minimum of 8 characters, 1 number or symbol, 1 upper and 1 lower case letter";
      }
    }

    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));

    return valid;
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmit = () => {
    const { password, currentPassword } = this.state;
    const { actions, authReducer } = this.props;
    const { loginResponseData, username } = authReducer;

    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const reqData = {
          identifier: username,
          password: currentPassword,
          current_password: currentPassword,
          new_password: password,
          user_id: loginResponseData?.user_id,
          bibson: loginResponseData?.bibson,
        };
        actions.doChangePassword(reqData, (success) => {
          if (success) {
            this.handleClose(success);
          }
        });
      }
    });
  };

  handleClose = (success) => {
    const { onClose } = this.props;
    onClose && onClose(success);
  };

  render() {
    const { handleChange, handleSubmit, handleClose } = this;
    const propsToForward = {
      ...this.state,
      handleChange,
      handleSubmit,
      handleClose,
    };
    return <ChangePassword {...propsToForward} />;
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { actions: bindActionCreators(actions, dispatch) };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
