
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import styled from '@emotion/styled';

import React from 'react';
import RewardDetails from './RewardDetails';

const styles = makeStyles((theme) => ({
  dialogContent: {
    padding: 0,
    "&:first-child": {
      paddingTop: 0,
    },
    "&::-webkit-scrollbar": {
      width: "5px",
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#aaa",
      borderRadius: "5px",
    },
    width: "750px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default function RewardDialogPlaceHolder({ closeDetailsHandler, reward }) {
  return (
    <Dialog
      onClose={closeDetailsHandler}
      aria-labelledby="reward-detail-diaglogbox"
      open={!!reward}
      maxWidth="md"
    >
      <DialogContent className={`${styles.dialogContent}`}>
        {reward && (
          <RewardDetails onClose={closeDetailsHandler} reward={reward} />
        )}
      </DialogContent>
    </Dialog>
  );
}