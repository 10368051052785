import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import {
  logoImage,
  loginLeftImg,
  arrowLeftIcon
} from "../../../../constants";
import { useStyles } from "./style";
import { AppTextField } from "../../../../common/components/AppTextField";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";
import { InputInfo } from "../../../../common/components/InputInfo";

import { LogoutOnWrongAttemptPopup } from "../LogoutOnWrongAttemptPopup";
import { PasswordField } from "../../../../common/components/PasswordField";
import { AppCheckboxWithLabel } from "../../../../common/components/AppCheckboxWithLabel";
import { AccountLockedPopup } from "../AccountLockPopup";
import { twoFaFormatPhone } from "../../../../utilities";
import { IconButton } from "../../../../common/components/core";
import { ResendOTPPopUp } from "../ResendOTPPopUp"
import { LoginKycFailed } from "../LoginKycFailed";

export const Login = (props) => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const {
    isAccountLockedPopupShown,
    authData,
    username,
    password,
    rememberMe,
    errors,
    handleSubmit,
    handleChange,
    handleForgotPassowrd,
    filledOtp,
    handleConfirmLogoutAttemptPopup,
    handleClose,
    resendOTP,
    resetLoginSuccess,
    handleResendOTPDialog,
    openResendOTPDialog,
    onCloseLoginFailedKyc,
    onValidateOTP
  } = props;
  return (
    <Grid container={true} className={classes.root}>
      <Grid
        xs={12}
        sm={5}
        md={4}
        xl={4}
        item={true}
        className={classes.leftSection}
      >
        <img src={loginLeftImg} className={classes.leftImg} alt="Login Left" />
      </Grid>
      <Grid xs={12} sm={7} md={8} xl={8} item={true} className={classes.rightSectionWrapper}>
        {authData.isLoginSuccess && <div>
          <IconButton color="primary" aria-label="Back to login" className={classes.backIconBtn} onClick={() => { resetLoginSuccess() }}>
            <img src={arrowLeftIcon} alt="arrow left" />
          </IconButton>
        </div>}
        <div className={`${classes.rightSection} ${classes.rightSectionWd}`}>
          <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
            <img src={logoImage} className={classes.logoImg} alt="Logo" />
          </Grid>

          {authData.isLoginSuccess ? (
            <>
              <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>

                <Typography
                  variant="h6"
                  component={"h6"}
                  className={`${appClasses.colorBlack900} ${appClasses.text5}`}
                >
                  Enter Your Code
                </Typography>
                <Box mt={1} mb={3}>
                  <InputInfo>
                    We sent a verification code to {twoFaFormatPhone(authData?.loginResponseData?.user?.phone, true)}. Please enter it below.
                  </InputInfo>
                </Box>

                <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                  <AppTextField
                    name={"filledOtp"}
                    label={"Enter the Code"}
                    inputProps={{
                      maxLength: 6,
                      autocomplete: "off",
                    }}
                    color="secondary"
                    value={filledOtp}
                    onChange={handleChange}
                    errorText={errors?.filledOtp}
                  />
                </Grid>
              </Grid>
              <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={onValidateOTP}
                  className={appClasses.primaryButton}
                  disabled={filledOtp ? false : true}
                >
                  Continue
                </Button>
              </Grid>
              <span className={`${appClasses.text9} ${classes.cursor_pointer}`} onClick={() => { handleResendOTPDialog() }}>Didn't receive a code?</span>
            </>
          ) : (
            <>
              <br />
              <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                <Typography
                  variant="h6"
                  component={"h6"}
                  className={`${appClasses.colorBlack700} ${appClasses.text8}`}
                >
                  Sign In
                </Typography>
              </Grid>
              <br />

              <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                <AppTextField
                  name={"username"}
                  label={"Email"}
                  inputProps={{
                    maxLength: 100,
                  }}
                  color="secondary"
                  value={username}
                  onChange={handleChange}
                  errorText={errors?.username}
                />
              </Grid>
              <Grid
                className={`${appClasses.textFieldWrapper} ${classes.pwdWrapper}`}
                xs={12}
                item={true}
              >
                <AppButton
                  link
                  secondary
                  aria-label="forgot-password"
                  onClick={() => handleForgotPassowrd()}
                >
                  <span>Forgot Password</span>
                </AppButton>
                <div>
                  <PasswordField
                    name={"password"}
                    label={"Password"}
                    value={password}
                    onChange={handleChange}
                    color="secondary"
                    errors={{message: errors?.password}}
                  />
                </div>
              </Grid>
              <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                <AppCheckboxWithLabel
                  name={"rememberMe"}
                  label={"Remember Me"}
                  inputProps={{
                    maxLength: 100,
                  }}
                  checked={rememberMe}
                  onChange={handleChange}
                />
              </Grid>
              <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                  className={appClasses.primaryButton}
                  disabled={(username && password) ? false : true}
                >
                  Sign In
                </Button>
              </Grid>
            </>
          )}
        </div>
      </Grid>

      {authData.displayLogoutWrongAttemptPopup && (<LogoutOnWrongAttemptPopup handleConfirm={handleConfirmLogoutAttemptPopup} />)}
      {authData.loginFailedDueToKYCFailed && (<LoginKycFailed onClose={onCloseLoginFailedKyc} />)}
      {isAccountLockedPopupShown && (<AccountLockedPopup handleConfirm={handleClose} handleForgotPassowrd={handleForgotPassowrd} />)}
      {openResendOTPDialog && <ResendOTPPopUp redirectViaLogin={true} handleConfirm={handleResendOTPDialog} resendCode={resendOTP} resendOTPCode={props.resendPhoneOTP}/>}

    </Grid>
  );
};

Login.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
};
