import {
  API_URL,
  additionalApiData,
} from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export  const GetGoogleMapKeys = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, currency, company_id } =
    additionalApiData;
  return request(
    API_URL + `third-party/credentials?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&currency=${currency}&app_version=${app_version}&app_type=${app_type}&company_id=${company_id}`,
    "Get",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {

    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetGoogleMapData = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, currency, company_id } =
    additionalApiData;
  return request(
    API_URL + `users/${data.user_id}/atm-locations?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&currency=${currency}&app_version=${app_version}&app_type=${app_type}&lat=${data.lat}&lng=${data.lng}&company_id=${company_id}`,
    "Get",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {

    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};