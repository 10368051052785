import { apiCall } from "../../../common/actions/apiCall";
import * as toastActions from "../../../common/actions/toast";
import { TOAST_TYPES } from "../../../common/components/core";
import {
  changePassword,
  getNotificationSettings,
  saveNotificationSettings,
} from "../services";
import { SET_NOTIFICATION_SETTINGS_DATA } from "../../../redux/actionTypes";

export const setNotificationSettings = (response) => {
  return {
    type: SET_NOTIFICATION_SETTINGS_DATA,
    data: response,
  };
};

export const doChangePassword = (data, callBackFn) => (dispatch) => {
  apiCall(dispatch, changePassword(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(toastActions.show("Profile Updated", TOAST_TYPES.SUCCESS));
        callBackFn && callBackFn(true);
      }
    },
    (error) => {
      callBackFn && callBackFn(false);
    }
  );
};

export const doGetAndSetNotificationSettings = (data) => (dispatch) => {
  apiCall(dispatch, getNotificationSettings(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        dispatch(
          setNotificationSettings(
            response?.data?.data?.user_notifications_settings || []
          )
        );
      }
    },
    (error) => {
      setNotificationSettings(null);
    }
  );
};

export const doSaveNotificationSettings = (data, callBackFn) => (dispatch) => {
  apiCall(dispatch, saveNotificationSettings(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      if (response?.data?.status == "success") {
        callBackFn && callBackFn(true);
      }
    },
    (error) => {
      callBackFn && callBackFn(false);
    }
  );
};
