import styled from "@emotion/styled";
import { Box, Typography } from "@material-ui/core";

const AddressBoxWrapper = styled(Box)`
  text-align: center;
`

const StyledSubTitle = styled(Typography)`
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  p {
    padding-bottom: 10px;
  }
`

const RESIDENTAIL_TEXT_CONTENT = {
  title: 'Edit Residential Address',
  bodyPragraphs: [
    'You are required to have a valid residential address on file at all times by our regulatory standards.',
    'You cannot enter a P.O. Box or mailbox as your home address.'
  ]
}

const SHIPPING_ADDRESS_TEXT_CONTENT = {
  title: 'Edit Shipping Address',
  bodyPragraphs: [
    'Your shipping address is where we will mail your card. This doesn\'t have to be the same as your residential address.',
    'It can be a P.O. Box, mailing box, or a different address altogether. Just make sure you can receive mail here.'
  ]
}


export default function AddressTitle({ isResidentialAddress }) {
  const textContent = isResidentialAddress ? RESIDENTAIL_TEXT_CONTENT : SHIPPING_ADDRESS_TEXT_CONTENT
  return <AddressBoxWrapper>
    <div>
      <Typography variant="h2" style={{textAlign: "center"}}>
        {textContent.title}
      </Typography>
    </div>
    <div>
      <StyledSubTitle variant="subtitle1">
        {textContent.bodyPragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
      </StyledSubTitle>
    </div>
  </AddressBoxWrapper>
}