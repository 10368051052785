import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Box, DialogTitle, Grid, Typography } from "@material-ui/core";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon } from "../../../../constants";
import { PasswordField } from "../../../../common/components/PasswordField";

export const PasswordDialog = (props) => {
  const { handleClose, handleSubmit } = props;
  const appClasses = useAppStyles();
  const [password, setPassword] = React.useState("");

  const handleChange = ({ target: { name, value } }) => {
    setPassword(value);
  };

  const content = () => {
    return (
      <>
        <DialogTitle id="form-dialog-title" style={{padding: '32px 24px'}}>
          <div className={appClasses.dialogTitle}>
            <IconButton
              className={appClasses.dialogCloseBtn}
              onClick={() => handleClose()}
            >
              <img src={closeBlackIcon} alt="close" />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent className={appClasses.dialogContent1}>
          <Typography
            variant="h6"
            className={`${appClasses.text1} ${appClasses.colorBlack700} ${appClasses.textAlignCenter}`}
          >
            For your security, please <br/> enter your password
          </Typography>
          <Box mt={4}></Box>
          <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
            <PasswordField
              id={"password"}
              name={"password"}
              label="Password"
              value={password}
              onChange={handleChange}
              // errorText={errors?.password}
            />
          </Grid>
        </DialogContent>
        <DialogActions className={`${appClasses.dialogContent1}`}>
          <Grid xs={12} item={true}>
            <AppButton
              contained
              onClick={() => handleSubmit(password)}
              className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
              primary
              disabled={!password}
            >
              Continue
            </AppButton>
            <Box mt={2} />
            <AppButton
              variant="text"
              className={`${appClasses.textBtn} ${appClasses.width100p}`}
              onClick={() => handleClose()}
            >
              Cancel
            </AppButton>
          </Grid>
        </DialogActions>
        <Box mb={2}></Box>
      </>
    );
  };

  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      {content()}
    </Dialog>
  );
};
