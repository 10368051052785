import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  kycRightSection: {
    width: "100%",
    margin: "auto",
    height: "100%",
    ...theme.appAnimations?.animationFadeIn,
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: 0,
  }
}));


const KycForm = ({ kycLink }) => {
  const classes = useStyles();
  return (
    <Grid container={true} className={classes.root}>
      <div className={classes.kycRightSection}>
        <iframe frameBorder={0} className={classes.iframe} src={kycLink} title="Kyc Form" data-testid="Kyc form"></iframe>
      </div>
    </Grid>
  );
};

export default KycForm;

