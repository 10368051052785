import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import {loadCurrentUserCards} from "../../manageCard/actions";
import { ProfileHome } from "../components/ProfileHome";
import useAuth from "../../../hooks/useAuth";

export const ProfileScreenSidemenuTypes = {
  VERIFY_PASSWORD: "VERIFY_PASSWORD",
  MOBILE: "MOBILE",
  EMAIL: "EMAIL",
  RES_ADDRESS: "RES_ADDRESS",
  SHIP_ADDRESS: "SHIP_ADDRESS",
};
export const ProfileHomePage = (props) => {
  const [openSidemenuType, setOpenSidemenuType] = React.useState(false);
  const [nextStep, setNextStep] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();

  const profileReducer = useSelector((state) => state.profileReducer);
  const { userData, userAddresses, userShippingAddress } = profileReducer;
  const { user_id, bibson, username } = useAuth()
  const { currentActivatedCard } = useSelector((state) => state.managecardReducer);
  const cardTing = currentActivatedCard?.ting;
  useEffect(() => {
    dispatch(loadCurrentUserCards({bibson, user_id}));
    if (cardTing){
      getData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bibson, cardTing, dispatch, user_id]);

  const getData = () => {
    const data = {
      bibson: bibson,
      user_id: user_id,
      card_ting: cardTing
    };
    dispatch(actions.doGetAndSetUserProfile({ ...data }));
    dispatch(actions.doGetAndSetUserAddresses({ ...data }));
    dispatch(actions.doGetAndSetUserShippingAddresses({ ...data }));
  };

  const handleCloseSidemenu = (success) => {
    if (success) {
      getData();
    }
    setOpenSidemenuType(null);
  };

  const handleOpenSidemenu = (type) => {
    setNextStep(type);
    setOpenSidemenuType(ProfileScreenSidemenuTypes.VERIFY_PASSWORD);
  };

  const passwordDialodHandleClose = () => {
    setOpenSidemenuType(null);
  };

  const passwordDialodHandleSubmit = (password) => {
    if (password) {
      dispatch(
        actions.doVerifyPassword(
          {
            bibson,
            user_id,
            identifier: username,
            password,
          },
          (success) => {
            if (success) {
              setPassword(password);
              setOpenSidemenuType(nextStep);
            }
          }
        )
      );
    }
  };

  const { first_name, last_name } = userData || {};
  const userAuthData = {
    password,
    first_name,
    last_name,
  };

  const propsTobeForward = {
    userData,
    userAddresses,
    userShippingAddress,

    openSidemenuType,
    handleCloseSidemenu,
    handleOpenSidemenu,

    userAuthData,
    passwordDialodHandleClose,
    passwordDialodHandleSubmit,
  };

  return <ProfileHome {...propsTobeForward} />;
};
