import {
  API_URL,
  additionalApiData,
} from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getStatement = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  const { user_id } = data;

  return request(
    API_URL +
    `users/${user_id}/account-statements?country_code=${country_code}&lang_code=${lang_code}&channel=${channel}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
