import React from "react";
import { Grid, Typography, Box, Divider } from "@material-ui/core";
import { useStyles } from "./style";
import { useAppStyles } from "../../../../App.style";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "../../../../common/components/core";
import { arrowRightIosIcon } from "../../../../constants";
import { AppButton } from "../../../../common/components/AppButton";
import { ContactSupportDialog } from "./ContactSupportDialog";

export const HelpSectionView = (props) => {
  const { data, detail, handleViewDetail } = props;
  const appClasses = useAppStyles();
  const classes = useStyles();

  const [isDisplayContactSupport, setIsDisplayContactSupport] =
    React.useState("");

  const { items } = data?.linkedFrom?.helpTopicCollection || [];

  const handleMoreHelp = () => {
    setIsDisplayContactSupport(true);
  };

  const handleCloseMoreHelp = () => {
    setIsDisplayContactSupport(false);
  };
  const handlContactSupportExit = () => {
    handleCloseMoreHelp();
  };
  const handlClickContactSupport = () => {
    handleCloseMoreHelp();
  };

  return (
    <>
      <Grid xs={12} item className={classes.root}>
        <Typography variant="h6" className={appClasses.text3}>
          {detail ? detail.title : data?.title}
        </Typography>
        <Box mt={3} />
        {!detail && (
          <List>
            {items &&
              items.map((item) => (
                <>
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={
                        <Typography variant="h4" className={appClasses.text1}>
                          &nbsp; {item.title}
                        </Typography>
                      }
                    />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        handleViewDetail(item);
                      }}
                    >
                      <img src={arrowRightIosIcon} />
                    </IconButton>
                  </ListItem>
                  <Divider component="li" />
                </>
              ))}
          </List>
        )}

        {detail?.content && (
          <Typography
            variant="h6"
            className={appClasses.text4}
            dangerouslySetInnerHTML={{
              __html: detail?.content,
            }}
          ></Typography>
        )}

        {detail && (
          <>
            <Box mt={5} />
            <AppButton
              onClick={handleMoreHelp}
              variant="text"
              className={`${appClasses.textBtn} 
              ${appClasses.width100p} 
              `}
            >
              Need more help?
            </AppButton>
          </>
        )}
      </Grid>

      {/*contact support dialog*/}
      {isDisplayContactSupport && (
        <ContactSupportDialog
          onExit={handlContactSupportExit}
          handleContactSupport={handlClickContactSupport}
        ></ContactSupportDialog>
      )}
    </>
  );
};
