import {
  CLEAR_CARD_BALANCES,
  SET_CARD_BALANCES,
  SET_DASHOBOARD_CARDS,
  SET_IS_GOT_CARD_BALANCES,
  SET_IS_GOT_DASHBOARD_CARDS,
  SET_IS_GOT_REWARD_BALANCE,
  SET_TING_MATCHED_CARD,
  SET_USER_CARD_TING,
  SET_USER_CARDS,
} from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import {
  getBalances,
  getDashboardCards,
  getPrimaryCard,
  getRewardBalance,
  SyncSetPin,
} from "../services";
import * as ToastAction from "../../../common/actions/toast";
import { toastTypes } from "../../../constants";

export const setIsGotCardBalances = (response) => {
  return {
    type: SET_IS_GOT_CARD_BALANCES,
    data: response,
  };
};

export const setUserCards = (data) => {
  return {
    type: SET_USER_CARDS,
    data,
  };
}

export const setIsGotRewardBalance = (response) => {
  return {
    type: SET_IS_GOT_REWARD_BALANCE,
    data: response,
  };
};

export const clearCardBalances = () => {
  return {
    type: CLEAR_CARD_BALANCES,
  };
};

export const setCardBalances = (response) => {
  return {
    type: SET_CARD_BALANCES,
    data: response,
  };
};

export const setUserCardTing = (data) => {
  return {
    type: SET_USER_CARD_TING,
    data,
  };
};

export const setDashboardCards = (response) => {
  return {
    type: SET_DASHOBOARD_CARDS,
    data: response,
  };
};

export const setIsGotDashboardCards = (response) => {
  return {
    type: SET_IS_GOT_DASHBOARD_CARDS,
    data: response,
  };
};

export const setTingMatchedCard = (response) => {
  return {
    type: SET_TING_MATCHED_CARD,
    data: response,
  };
};

export const doGetAndSetCardBalances = (data, isHideLoader) => (dispatch) => {
  dispatch(setIsGotCardBalances(false));
  return apiCall(dispatch, getPrimaryCard(data), {
    showLoader: !isHideLoader,
    displayError: true,
  }).then(
    (primaryCardResponse) => {
      if (primaryCardResponse?.data?.status == "success") {
        dispatch(setUserCards( primaryCardResponse?.data?.data));
        const { cards, auto_method } = primaryCardResponse?.data?.data;
        if (auto_method && cards && cards.length > 0) {
          for (let card in cards) {
            if (cards[card].ting == auto_method.ting) {
              dispatch(setTingMatchedCard(cards[card]));
              data.ting = auto_method.ting;
              break;
            }
          }
        }
      }

      if (data.ting) {
        //setting selected card ting for future use
        dispatch(setUserCardTing(data.ting));
        apiCall(dispatch, getBalances(data), {
          showLoader: !isHideLoader,
          displayError: true,
        }).then(
          (balancesResponse) => {
            dispatch(setIsGotCardBalances(true));
            if (balancesResponse?.data?.status == "success") {
              if (balancesResponse?.data?.data?.balances) {
                dispatch(
                  setCardBalances({ ...balancesResponse?.data?.data?.balances })
                );
              }
            }
          },
          (error) => {}
        );
      }
    },
    (error) => {
      dispatch(setIsGotCardBalances(true));
    }
  );
};

export const doGetAndSetRewardBalance = (data) => (dispatch) => {
  dispatch(setIsGotRewardBalance(false));
  apiCall(dispatch, getRewardBalance(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (rewardBalanceResponse) => {
      if (rewardBalanceResponse?.data?.status == "success") {
        const { balance } = rewardBalanceResponse?.data?.data;
        dispatch(setCardBalances({ rewards: balance }));
      }
      dispatch(setIsGotRewardBalance(true));
    },
    (error) => {
      dispatch(setIsGotRewardBalance(true));
    }
  );
};

export const syncSetPinUser = (data, callback) => (dispatch) => {
  apiCall(dispatch, SyncSetPin(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      callback && callback();
      dispatch(ToastAction.show("Pin set successfully", toastTypes.SUCCESS))
    },
    (error) => {
    }
  );
};

export const doGetAndSetDashboardCards = (data) => (dispatch) => {
  dispatch(setIsGotDashboardCards(false));
  apiCall(dispatch, getDashboardCards(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (dashboardCardsResponse) => {
      if (dashboardCardsResponse?.data?.status == "success") {
        const { mobile_cards } = dashboardCardsResponse?.data?.data;
        dispatch(setDashboardCards(mobile_cards));
      }
      dispatch(setIsGotDashboardCards(true));
    },
    (error) => {
      dispatch(setIsGotDashboardCards(true));
    }
  );
};
