import { Box, Button, Grid } from "@material-ui/core";
import { InputInfo } from "../../../../common/components/InputInfo";
import { AppTextField } from "../../../../common/components/AppTextField";
import { useAppStyles } from "../../../../App.style";

export default function EnterEmail({ forgotPasswordEmail, handleChange, errors, handleSubmit, isDisableSubmit }) {
  const appClasses = useAppStyles();
  return <>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <InputInfo>
        Please enter the email address associated with your Artist
        Growth Wallet account.
      </InputInfo>
      <Box mt={7}></Box>
      <AppTextField
        name={"forgotPasswordEmail"}
        label={"Email"}
        inputProps={{
          maxLength: 100,
        }}
        value={forgotPasswordEmail}
        onChange={handleChange}
        errorText={errors?.forgotPasswordEmail}
      />
    </Grid>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        className={`${appClasses.primaryBtn}`}
        disabled={isDisableSubmit}
      >
        Reset Password
      </Button>
    </Grid>
  </>
}