import React, { useEffect } from 'react'
import CredentialsProvider from '../../../../hooks/RequireCredentials/CrendentialProvider'
import useCredentials from '../../../../hooks/RequireCredentials/useCrendentials'
import useAuth from '../../../../hooks/useAuth'
import { USER_CARD_STATUSES } from '../../../../constants'
import { useDispatch } from 'react-redux'
import { doActiveDeactiveCard } from '../../actions'
import { useRequest } from 'ahooks'


// TODO: refactor to remove getData and use redux
const LockCardTrigger = ({ cardTing, isLocked, getData, onSuccess }) => {
  const { password } = useCredentials()
  const { user_id, bibson, username } = useAuth()
  const dispatch = useDispatch()

  const deactivateCard = async () => {
    const data = {
      bibson, user_id, card_ting: cardTing
    }
    const userData = { identifier: username, password }
    const reason = isLocked ?  USER_CARD_STATUSES.active : USER_CARD_STATUSES.frozen
    const cardPayload = { reason }
    await dispatch(doActiveDeactiveCard(data, userData, cardPayload))
    getData && getData()
  }

  const { loading, error, data } = useRequest(deactivateCard, { refreshDeps: [cardTing] })

  useEffect(() => {
    if (!loading) onSuccess && onSuccess(data, error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return null
}


const LockCard = (props) => {
  const {onClose, ...rest} = props
  return (
    <CredentialsProvider onClose={onClose}>
      <LockCardTrigger {...rest} />
    </CredentialsProvider>
  )
}

export default LockCard