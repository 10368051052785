import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  root: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    "&.Mui-focused": {
      color: "tomato",
      fontWeight: "bold"
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    }
  },
  logoImg: {
    //margin: "auto",
  },
  leftSection: {
    height: "100vh",
    background: theme.palette.other.black700,
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "30vh",
    }
  },
  spanCenter: {
    display: "block",
    textAlign: "center",
  },
  getStartedText: {
    width: "100%",
  },
  leftImg: {
    objectFit: "contain",
    width: "100%",
    ...theme.appAnimations?.animationFadeIn,
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "auto",
    }
  },
  rightSection: {
    margin: "auto",
    padding: "0.5rem",
    [theme.breakpoints.up("lg")]: {
      padding: "1.5rem 10rem",
    },
    ...theme.appAnimations?.animationFadeIn,
  },
  rightSectionWd: {
    width: "80%"
  },
  pwdWrapper: {
    position: "relative",
    "& button": {
      position: "absolute",
      right: 0,
    },
    "& > div": {
      marginTop: "2rem",
      width: "100%",
    },
  },
  cursor_pointer: {
    cursor: "pointer",
    textDecoration : "underline"
  },
  backIconBtn: {
    margin: "1rem",
    background: theme.palette.other.primary100,
  },
  rightSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
  },
}));
