import React from 'react'
import { Box, Grid, MenuItem, Typography } from "@material-ui/core";
import { logoImage, loginLeftImg, stepsImg } from "../../../../constants";
import { useStyles } from "./style";
import { useAppStyles } from "../../../../App.style";

const CardTnC = () => {

    const classes = useStyles();
    const appClasses = useAppStyles();

    return (
        <Grid container={true} className={classes.root}>
            <Grid
                xs={12}
                sm={5}
                md={4}
                xl={4}
                item={true}
                className={classes.leftSection}
            >
                <img src={loginLeftImg} className={classes.leftImg} />
            </Grid>
            <Grid
                className={classes.rightSectionContainer}
                xs={12}
                sm={7}
                md={8}
                xl={8}
                item={true}
            >
                <div className={classes.rightSection}>
                    
                </div>
            </Grid>
        </Grid>
    )
}

export default CardTnC;