import { Box, Button, styled } from "@material-ui/core";
import React from "react";
import { AppButton } from "../../../../../common/components/AppButton";
import { useAppStyles } from "../../../../../App.style";
import { activateCardPageStaticImg } from "../../../../../constants";
import { StepWrapper, SubTitle, Title } from "../AtomComponents";
import useReplaceCard from "../store/useReplaceCard";
import { useStyles } from "../style";


const StyledLockYourCardButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 700,
  lineHeight: 1.43,
  letterSpacing: '0.25px',
  textTransform: 'none',
  marginTop: '20px',
}));

const SubTitleForPhysicalCard = () => {
  return <>
    <SubTitle>
      Ordering a new card will permanently close your current card.  A $5.00 replacement fee applies and you cannot reactivate your current card.
    </SubTitle>
    <SubTitle>
      You can temporarily lock your current card if you think it may be misplaced - you can lock and unlock your card at any time.
    </SubTitle>
  </>
}
const SubTitleForVirtualCard = () => {
  return <SubTitle>
    Ordering a new card will permanently close your current card. A $5.00 replacement fee applies and you cannot reactivate your current card.
  </SubTitle>
}

const SubTitleContent = ({ isVirtualCard }) => {
  return isVirtualCard ? <SubTitleForVirtualCard /> : <SubTitleForPhysicalCard />
}

export default function Info() {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const { onNextStep, isVirtualCard, closeAllSidemenu } = useReplaceCard();

  return <StepWrapper>
    <Title> Replace Your Card</Title>
    <SubTitleContent isVirtualCard={isVirtualCard} />
    <Box sx={{width: '100%'}}>
      <div className={classes.imgWrapper}>  <img src={activateCardPageStaticImg} alt="Active Card" /></div>
      <AppButton
        contained
        onClick={onNextStep}
        style={{ width: '100%' }}
        className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
        primary
      >
        Continue
      </AppButton>
      {
        !isVirtualCard && <StyledLockYourCardButton variant="text" fullWidth className={`${appClasses.text4} ${classes.lockCardText}`} onClick={closeAllSidemenu}>Lock Your Card Instead</StyledLockYourCardButton>
      }
    </Box>
  </StepWrapper>
}