import {
  API_URL,
  additionalApiData,
} from "../../../constants/app.constants";
import { request } from "../../../utilities/request";

export const getPrimaryCard = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  return request(
    API_URL +
    `users/${data.user_id}/cards?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getBalances = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  const { user_id, ting } = data;

  return request(
    API_URL +
    `users/${user_id}/cards/${ting}/account-balances?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getRewardBalance = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  const { user_id } = data;

  return request(
    API_URL +
    `users/${user_id}/rewards/balance?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&app_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const SyncSetPin = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  const { userId, ting } = data;

  return request(
    API_URL +
    `users/${userId}/cards/${ting}/pin/status`,
    "POST",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    },
    {
      "channel": channel,
      "country_code": country_code,
      "lang_code": lang_code,
      "company_id" : company_id
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


export const getDashboardCards = (data) => {
  const { app_type, app_version, channel, country_code, lang_code, company_id } =
    additionalApiData;
  const { user_id } = data;

  return request(
    API_URL +
    `users/${user_id}/mobile-cards?channel=${channel}&country_code=${country_code}&lang_code=${lang_code}&device_type=${app_type}&app_version=${app_version}&company_id=${company_id}`,
    "GET",
    {
      "Content-Type": "application/json",
      "x-bishop": data.bibson,
    }
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};


