import { Button, Grid } from "@material-ui/core";
import { InputInfo } from "../../../../common/components/InputInfo";
import { AppTextField } from "../../../../common/components/AppTextField";
import { useStyles } from "./style";
import { useAppStyles } from "../../../../App.style";

export default function EnterOTP({ forgotPasswordEmail, handleChange, errors, filledOtp, handleSubmit, isDisableSubmit, handleResendOTPDialog, validateOTP }) {
  const classes = useStyles();
  const appClasses = useAppStyles();
  return <>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true} container spacing={2} direction="column">
      <Grid item>
        <InputInfo>
          We sent a verification code to {forgotPasswordEmail}. Please
          enter it below.
        </InputInfo>
      </Grid>
      <Grid
        item
      >
        <AppTextField
          name={"filledOtp"}
          label={"Enter the Code"}
          fullWidth
          inputProps={{
            maxLength: 6,
          }}
          color="secondary"
          value={filledOtp}
          onChange={handleChange}
          errorText={errors?.filledOtp}
        />
      </Grid>
    </Grid>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={validateOTP}
        className={`${appClasses.primaryBtn}`}
        disabled={isDisableSubmit}
      >

        Continue
      </Button>
    </Grid>
    <span
      className={`${appClasses.text9} ${classes.cursor_pointer}`}
      onClick={() => {
        handleResendOTPDialog();
      }}
    >
      <u>Didn't receive a code?</u>
    </span>
  </>
}