import React from "react";
import { Checkbox, FormControl, FormHelperText } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Controller } from "react-hook-form";

const RegistrationCheckBox = ({ control, name, label, errors, showError }) => {
  const errorMessage = errors?.message
  const isError = !!errorMessage
  return <Controller
    control={control}
    render={({ field }) => (
      <FormControl error={isError}>
        <FormControlLabel label={label}
          control={
            <>
              <Checkbox
                {...field}
                value={true}
                checkedIcon={<CheckBoxIcon style={{ color: '#018787' }} />}
                icon={<CheckBoxOutlineBlankIcon style={{ color: '#555555' }} />}
              />
            </>
          } />
        {showError && isError && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>)
    }
    name={name}
    defaultValue={false}
  />;
};

export default RegistrationCheckBox;