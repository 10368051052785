import React from 'react'
import TextField from '@material-ui/core/TextField'

export const MDTextField = (props) => {
  const propsToForward = {
    ...props
  }

  if (!props.id && props.name) {
    propsToForward.id = props.name
  }

  const { errorText } = propsToForward
  if (errorText) {
    propsToForward.helperText = errorText
    propsToForward.error = true
  }

  // removing props which is not belongs to Material TextField
  delete propsToForward.errorText

  return <TextField {...propsToForward} />
}
