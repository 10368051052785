import React from "react";
import { useAppStyles } from "../../../App.style";
import { currencySymbol } from "../../../constants";
import { to2Decimal } from "../../../utilities";

export const Amount = (props) => {
  const appClasses = useAppStyles();
  const { children, removePlusSign, removeSuccessColor } = props;
  let amount = children;
  let className = "";
  if (isNaN(children)) {
    return "";
  }
  amount = to2Decimal(Math.abs(amount));

  if (!removeSuccessColor && parseFloat(children) > 0) {
    className = appClasses.colorSuccess500;
  }

  return (
    <span className={className}>
      {parseFloat(children) > 0
        ? !removePlusSign
          ? "+"
          : ""
        : parseFloat(children) === 0
        ? ""
        : "-"}
      {currencySymbol}
      {amount}
    </span>
  );
};
