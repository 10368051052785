
import React from 'react'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { eyeIcon, eyeOffIcon } from '../../../../constants'


const PasswordEndAdornment = ({ showPassword, handleClickShowPassword }) => {
  return <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
    >
      <img src={showPassword ? eyeIcon : eyeOffIcon} alt="eye" />
    </IconButton>
  </InputAdornment>
}


const RegistrationPasswordField = ({ control, name, label, errors, disabled }) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const errorMessage = errors?.message
  const isError = !!errorMessage
  return <Controller
    control={control}
    render={({ field }) => <TextField fullWidth variant="outlined"
      type={showPassword ? 'text' : 'password'}
      className='registration-field'
      InputProps={{
        endAdornment: <PasswordEndAdornment showPassword={showPassword} handleClickShowPassword={handleClickShowPassword} />,
      }}
      label={label} helperText={errorMessage} error={isError}
      disabled={disabled}
      {...field} />}
    name={name}
    defaultValue=""
  />

}

export default RegistrationPasswordField