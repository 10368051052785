import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  goalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  goalFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  infoIcon: {
    width: "24px",
    height: "24px",
    verticalAlign: "bottom",
  },
  viewVirtualCardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  lockWrapper: {
    display: "flex",
    padding: "1.5rem",
    alignItems: "center",
    width: "472px",
    height: "80px",
    border: `1px solid ${theme.palette.other.white}`,
    cursor: "pointer",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    }
  },
  cardStatus: {
    padding: "1.5rem",
    paddingLeft: "0px",
    height: "80px",
    border: `1px solid ${theme.palette.other.white}`,
  },
}));
