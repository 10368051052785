import {
  Grid,
  Typography,
  Box,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import { useAppStyles } from "../../../../../App.style";
import { AppButton } from "../../../../../common/components/AppButton";
import { useStyles } from "../style";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import TextField from "../../../../../common/components/form-hook-fields/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import useAuth from "../../../../../hooks/useAuth";
import moment from "moment";
import { doActivateCardByDetails } from "../../../actions";

const ExpirationDateRegx = /^(0[1-9]|1[0-2])\/([0-9]{4}|[0-9]{2})$/;
const Last4DigitsRegx = /^\d{4}$/;
const CardActivateFormSchema = Yup.object().shape({
  cardNumber: Yup.string().required("Required").matches(Last4DigitsRegx, "Invalid Input"),
  expirationDate: Yup.string().required("Required").matches(ExpirationDateRegx, "Invalid Input"),
});


export const CardInfoForm = ({ refreshDashboardCardData, nextStep }) => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const dispatch = useDispatch()
  const {bibson, user_id} = useAuth()


  const { control, handleSubmit, formState: { errors, isDirty, isSubmitting, isValidating, isValid } } = useForm({
    mode: "onChange",
    resolver: yupResolver(CardActivateFormSchema),
    defaultValues: {}
  });

  const onSubmitCard = async (submitData) => {
    try{
      const { cardNumber, expirationDate } = submitData
      const expirationMonthYear = expirationDate.split("/");
      let currentYear = moment().format('YYYY');
      let data = {
        masked_card_number: cardNumber,
        last_four: cardNumber,
        expiry_month: expirationMonthYear[0],
        expiry_year: currentYear?.substring(0, 2) + expirationMonthYear[1],
        bibson,
        user_id,
      }
      const cardActivateResponse = await dispatch(doActivateCardByDetails({ ...data }));
      refreshDashboardCardData(false);
      nextStep()
      return cardActivateResponse;
    }catch(e){
      console.error(e);
    }
  }

  const isDisabled = !isDirty || isSubmitting || isValidating || !isValid;

  return (
    <>
      <DialogTitle className={classes.header}>
        <Typography variant="h6" className={appClasses.text3}>
          Activate Physical Card
        </Typography>
        <Box mt={1} >
          <Typography variant="span" className={appClasses.text1}>
            Please enter the card details found on the back of your card
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={appClasses.dialogContent}>
        <p className={`${appClasses.text10} ${classes.mb5} ${appClasses.colorBlack500}`}>Card Number (required)*</p>
        <p className={`${appClasses.text10} ${classes.mb5} ${appClasses.colorBlack500}`}>Enter Last 4 Digits of Card</p>

        <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
          <TextField name="cardNumber" control={control} label="Last 4 Digits of Card"
            errors={errors?.cardNumber} inputProps={{ maxLength: 4 }} />
        </Grid>

        <p className={`${appClasses.text10} ${classes.mb5} ${appClasses.colorBlack500}`}>Expiration Date(required)*</p>
        <p className={`${appClasses.text10} ${classes.mb5} ${appClasses.colorBlack500}`}>Enter expiration date (MM/YY)</p>
        <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
          <TextField name="expirationDate" control={control} label="Expiration Date (MM/YY)"
            errors={errors?.expirationDate} inputProps={{ maxLength: 5 }} />
        </Grid>
        <Box mt={2} >
          <AppButton
            contained
            onClick={handleSubmit(onSubmitCard)}
            className={`${appClasses.cardActiveCapsuleBtn} ${appClasses.width100p}`}
            primary
            disabled={isDisabled}
          >
            Activate Card
          </AppButton>
        </Box>
      </DialogContent>
    </>
  );
};
