import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 200px);
`;

const Spinner = styled.div`
  display: inline-block;
  width: 64px;
  height: 64px;
  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #7c7c7c;
    border-color: #7c7c7c transparent #7c7c7c transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

const Text = styled.p`
  font-size: 18px;
  color: #7c7c7c;
  margin-top: 16px;
`;

const Loading = ({ text = 'Loading...' }) => {
  return (
    <Container>
      <Spinner />
      <Text>{text}</Text>
    </Container>
  );
};

export default Loading;