import React, { useEffect } from 'react'
import { Grid, FormControl, RadioGroup, FormControlLabel, Typography, styled } from "@material-ui/core";
import { useStyles } from "./style";
import { infoIcon } from '../../../constants';

import { AppTooltip } from '../../../common/components/AppTooltip';
import { useAppStyles } from '../../../App.style';
import { AppRadio } from '../../../common/components/AppRadio';
import ListATMNearBy from './ListATMNearBy';
import MapView from './ATMMap/MapView';
import { useAtmLocations, useElementPosition } from '../store/atmhooks';
import { MobileHelpScreen } from '../../../common/components/MobileHelpScreen';
import useMobileScreenCollection from '../../../hooks/userMobileScreenCollection';
import ATMFilter from './ATMFilter';

const ATMFinderHeader = styled(Typography)(({ theme }) => ({
    color: '#555555',
    [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        padding: '0 16px',
    }
}))
const AtmContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        position: 'relative',
    }
}))

const AtmListContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    }
}))
const ATMFinder = (props) => {
    const classes = useStyles();
    const appClasses = useAppStyles();

    const { loadNearbyATMs, currentLocation, } = useAtmLocations()
    const mobileScreenCollection = useMobileScreenCollection()

    useEffect(() => {
        if (currentLocation?.lat && currentLocation?.lng) {
            loadNearbyATMs(currentLocation)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation?.lat, currentLocation?.lng])

    return (
        <>
            <ATMFinderHeader variant="h4" >
                ATM Finder
            </ATMFinderHeader>
            <ATMFilter />
            <AtmContainer container spacing={2} direction='row-reverse'>
                <Grid item xs={12} lg={7} className={classes.mapContainer}>
                    <FormControl className={classes.atmFilter}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={props.onAtmTypeChange}
                            value={props.selectedType}
                        >
                            <FormControlLabel className={classes.mapFilterRadio} value="free" control={<AppRadio label={"Free ATM"} disabled
                                style={{ cursor: "default" }} />}
                                label={<><AppTooltip title={"A fee may be charged and promptly returned for applicable free ATM locations."}>
                                    <img src={infoIcon} className={`${appClasses.infoIcon} ${appClasses.vAlignTxtBtm}`} alt="Free ATM" />
                                </AppTooltip></>}
                            />
                            <FormControlLabel className={classes.mapFilterRadio} value="paid" control={<AppRadio label="Low Fee ATM" disabled
                                style={{ cursor: "default" }} />} />
                        </RadioGroup>
                    </FormControl>
                    <MapView />
                </Grid>
                <AtmListContainer item xs={12} lg={5}>
                    <ListATMNearBy />
                </AtmListContainer>
            </AtmContainer>
            <div style={{ marginTop: '10px' }}>
                <MobileHelpScreen iconName="button" topics={mobileScreenCollection} screenName={'showATMDetail'} anchor="top" />
            </div>
        </>
    )
}

export default ATMFinder;