import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import { closeBlackIcon, warningIcon } from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";
import { VISA_SAVING_EDGE_FAQ_URL } from "../../../../constants";

export const DoshHelpPopup = (props) => {
  const { handleConfirm } = props;

  const appClasses = useAppStyles();
  const classes = useStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleConfirm(false)}
          >
            <img src={closeBlackIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider></Divider>
      <Box mt={3} />
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <Typography variant="span" className={appClasses.text3}>
          Need more help?
        </Typography>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text1}>
          {"See "}
          <a
            onClick={() => {
              window.open(VISA_SAVING_EDGE_FAQ_URL);
            }}
            className={appClasses.aLinkBlack}
          >
            Frequently Asked Questions
          </a>
          {" about Visa SavingsEdge rewards."}
        </Typography>
        <Box mt={1}></Box>
        <Typography variant="span" className={appClasses.text1}>
          Still need help? Contact Visa Global Customer Care Services :
          1-888-466-9227
        </Typography>
        <Box mt={5}></Box>
      </DialogContent>
    </Dialog>
  );
};
