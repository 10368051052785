import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "626px",
  },
  leftList: {
    maxWidth: "472px",
    padding: "2rem 1rem",
  },
  listItem: {
    paddingLeft: "0px",
    paddingRight: "0px",

    "& img ": {
      width: "24px",
      height: "24px",
    },
  },
  listItemActive : {
    background: theme.palette.other?.primary100
  },
  list: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden"
  },
  imgWrapper : {
    textAlign: "center",
  }
}));
