import { ADD_PURSE_TRANSACTIONS } from "../../../redux/actionTypes";
import { transferMoneyPurse } from "../services";
import { apiCall } from "../../../common/actions/apiCall";

export const addPurseTransactions = (transaction) => {
  return {
    type: ADD_PURSE_TRANSACTIONS,
    data: transaction,
  };
}
export const doTransferMoneyPurse = (data, callback) => (dispatch) => {
  apiCall(dispatch, transferMoneyPurse(data), {
    showLoader: true,
    displayError: false,
  }).then(
    (transferMoneyPurseResponse) => {
      if (transferMoneyPurseResponse?.data?.status == "success") {
        const { result } = transferMoneyPurseResponse?.data?.data;
        dispatch(addPurseTransactions(result));
        callback(result);
        return;
      }
      callback(null);
    },
    (error) => {
      callback(null);
    }
  );
};
