import { SET_CONTENTFUL_CONTENT } from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import {
  helpCategoryCollection,
  helpTopicCashDeposit,
  helpTopicCollection,
  mobileScreenCollection,
  cardHolderAgreementPDFUrl,
  registrationTnC,
  getRewardsImageList,
  privacyPolicy
} from "../services";

export const contentfulActionTypes = {
  helpTopicCollection: {
    service: helpTopicCollection,
    key: "helpTopicCollection",
    responseKey: "helpTopicCollection",
  },
  cashDeposit: {
    service: helpTopicCashDeposit,
    key: "helpTopicCashDeposit",
    responseKey: "helpTopic",
  },
  helpCategoryCollection: {
    service: helpCategoryCollection,
    key: "helpCategoryCollection",
    responseKey: "helpCategoryCollection",
  },
  mobileScreenCollection: {
    service: mobileScreenCollection,
    key: "mobileScreenCollection",
    responseKey: "mobileScreenCollection",
  },
  legal: {
    service: cardHolderAgreementPDFUrl,
    key: "cardHolderAgreement",
    responseKey: "legal",
  },
  registrationTnC: {
    service: registrationTnC,
    key: "termsAndConditions",
    responseKey: "legal",
  },
  privacyPolicy: {
    service: privacyPolicy,
    key: "legal",
    responseKey: "legal",
  },
  rewards: {
    service: getRewardsImageList,
    key: "imageList",
    responseKey: "imageList",
  }
};

const setContentfulContent = (key, data) => {
  return {
    type: SET_CONTENTFUL_CONTENT,
    data: { key, data },
  };
};

export const doGetAndSetContent = (contentfulActionType, callBackFn) => (dispatch) => {
  const { key, responseKey, service } = contentfulActionType;
  apiCall(dispatch, service(), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      const responseData = response
      if (responseData && responseData[responseKey]) {
        dispatch(setContentfulContent(key, responseData[responseKey]));
        callBackFn && callBackFn(responseData && responseData[responseKey]);
      } else {
        dispatch(setContentfulContent(key, null));
      }
    },
    (error) => {
      dispatch(setContentfulContent(key, null));
    }
  );
};
