import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: "#262626",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17px",
    padding: theme.spacing(2),
    borderRadius: "8px",
  },
  arrow: {
    color: theme.palette.other?.black700,
  },
}));
