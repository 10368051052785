import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import styled from "@emotion/styled";
import { Box, makeStyles } from "@material-ui/core";
import { questionBlackIcon, helpWhiteIcon } from "../../../constants";
import { getHelpTopicCollectionFromScreenName } from "../../../modules/contentful/services";
import HelpContent from "./HelpContent";
import { AppButton } from "../AppButton";

const userStyles = makeStyles((theme) => ({
  capsuleBtn: {
    borderRadius: "24px",
    background: theme.palette.other?.black500,
    width: "100px",
    textTransform: "none",
  },
}));

const HelpWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const HelpIcon = ({ onClickIcon, id }) => {
  return <img src={questionBlackIcon} alt="question icon" onClick={onClickIcon} aria-describedby={id} />
}

const HelpButton = ({ onClickIcon, id }) => {
  const styles = userStyles()
  return <AppButton
    inputProps={{ 'aria-describedby': id }}
    onClick={onClickIcon}
    startIcon={<img src={helpWhiteIcon} alt="help icon" />} contained className={styles.capsuleBtn}
    primary>
    Help
  </AppButton>
}

const HelpIconsMapper = {
  icon: HelpIcon,
  button: HelpButton
}

export const MobileHelpScreen = ({ topics, screenName, iconName = 'icon', anchor = 'bottom' }) => {
  const [helpAnchorEl, setHelpAnchorEl] = React.useState(null);

  const onClose = () => {
    setHelpAnchorEl(null)
  }

  const onClickIcon = (event) => {
    setHelpAnchorEl(event.currentTarget)
  }

  const selectedTopics = useMemo(() => {
    return getHelpTopicCollectionFromScreenName(topics, screenName) || {}
  }, [topics, screenName])

  const id = `help-screen-${screenName}`;
  const open = Boolean(helpAnchorEl);
  const HelpButtonComponent = HelpIconsMapper[iconName]
  return (
    <HelpWrapper data-testid="help-icon-with-content">
      <HelpButtonComponent onClickIcon={onClickIcon} id={id} />
      <HelpContent topics={selectedTopics?.helpTopicsCollection?.items} helpAnchorEl={helpAnchorEl} onClose={onClose} id={id} open={open} anchor={anchor} screenName={screenName}/>
    </HelpWrapper>
  );

}

MobileHelpScreen.propTypes = {
  iconName: PropTypes.oneOf(['icon', 'button']),
  screenName: PropTypes.string.isRequired,
  topics: PropTypes.object.isRequired
}