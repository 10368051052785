import styled from '@emotion/styled';
import { Box, Chip, Button } from '@material-ui/core';
import Bolt from '../../../../common/icons/bolt';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { truncateContentfulDocument } from '../../../../utilities/contentful';
import { useReward } from '../../../../common/components/Reward/useReward';
import { useCallback } from 'react';

const RewardItemWrapper = styled(Box)(() => ({
  width: `100%`,
  minWidth: '220px',
  height: '100%',
  border: '1px solid #E7E7E7',
  borderRadius: '8px',
  padding: '70px 15px 10px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

const ExpiredCountDownChip = styled(Chip)(() => ({
  position: 'absolute',
  top: '25px', right: '8px',
  padding: '11px 22px',
  background: 'rgba(255, 60, 0, 0.1)',
  '& > svg': {
    fontSize: '1.2em'
  },
  '& > .MuiChip-label': {
    fontWeight: 700,
    fontSize: '12px',
    paddingLeft: 5,
    paddingRight: 0,
    color: '#FF3C00',
  }
}))

const StyledBrandLogo = styled.div`
  width: 71px;
  height: 71px;
  padding: 6px;
  border: 0.5px solid #E5E5E5;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url(${props => props.url});
`

const StyledInstoreOrOnlineMessage = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #018787;
  padding: 0;
  margin: 10px 0 0;
`

const CastBackHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #262626;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  line=height: 22px;
  max-height: 66px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; 
  & > p {
    margin: 0;
  }
`

const CashbackDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #555555;
  padding: 4px 0;
  margin: 0;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  overflow-y: hidden;
  height: 60px;
  & > p {
    margin: 0;
  }
`

const ButtonContainer = styled.div`
  margin-top: 10px;
  align-self: flex-start;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
`

const PrimaryButton = styled(Button)`
  padding: 8px 24px;
  background: #1AD6D6;
  border-radius: 200px;
  font-weight: 700;
  font-size: 13px;
  color: #262626;
`

const RewardItem = ({ rewardData }) => {
  const { badge_text: badgeText, contentful: { icon, disclaimer, title, button_text: buttonText, subheading } } = rewardData
  const [_remaning, processedHeading] = truncateContentfulDocument(subheading, 50)
  const { openRewardDetail } = useReward()
  const viewDetailHandler = useCallback(() => { openRewardDetail(rewardData) }, [openRewardDetail, rewardData])
  const description = documentToReactComponents(processedHeading)
  return (
    <RewardItemWrapper>
      {badgeText && (
        <ExpiredCountDownChip
          icon={<Bolt css={{ fontSize: "1.2em" }} />}
          label={badgeText}
        />
      )}
      <StyledBrandLogo url={icon}></StyledBrandLogo>
      <StyledInstoreOrOnlineMessage>{disclaimer}</StyledInstoreOrOnlineMessage>
      <CastBackHeader>
        <p>{title}</p>
      </CastBackHeader>
      <CashbackDescription variant="body1">{description}</CashbackDescription>
      <ButtonContainer>
        <PrimaryButton
          color="primary"
          style={{ textTransform: "none" }}
          onClick={viewDetailHandler}
        >
          {buttonText}
        </PrimaryButton>
      </ButtonContainer>
    </RewardItemWrapper>
  );
};

export default RewardItem