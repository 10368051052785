import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as transactionActions from "../actions";
import * as dashboardActions from "../../dashboard/actions";
import * as contentfulActions from "../../contentful/actions";
import { Transactions } from "../components/Transactions";
import moment from "moment";
import { DATE_PARSE_FORMAT } from "../../../constants";
import { getHelpTopicCollectionFromScreenName } from "../../contentful/services";
import { useAsyncEffect } from "ahooks";

import * as statementActions from "../../statements/actions";
import * as depositActions from "../../deposit/actions";
import useAuth from "../../../hooks/useAuth";
import Loading from "../../../common/components/Loading/Loading";

export const TransactionsPage = (props) => {
  const dispatch = useDispatch();
  const depositReducer = useSelector((state) => state.depositReducer);
  const dashBoardAction = bindActionCreators(dashboardActions, dispatch);
  const transactionAction = bindActionCreators(transactionActions, dispatch);

  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const transactionReducer = useSelector((state) => state.transactionReducer);

  const {user_id, bibson} = useAuth()
  const contentfulReducer = useSelector((state) => state.contentfulReducer);
  const { mobileScreenCollection } = contentfulReducer;

  const [isDateChipDisplay, setIsDateChipDisplay] = React.useState(false);
  const [viewTransaction, setIsViewTransaction] = React.useState(false);
  const [selectedTransaction, setSelectedTransaction] = React.useState(undefined);

  const [showTransferMoneyDialog, setShowTransferMoneyDialog] =
    React.useState(undefined);

  const [isLoaddingData, setIsLoaddingData] = React.useState(false);
  useAsyncEffect(async () => {
    setIsLoaddingData(true);
    await getData();
    setIsLoaddingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const removeDateChip = () => {
    setIsDateChipDisplay(false);
    getTransactionData();
  }

  const setViewTransaction = (tx) => {
    setIsViewTransaction(true);
    setSelectedTransaction(tx);
  }

  const handleCloseViewTransaction = () => {
    setIsViewTransaction(false);
    setSelectedTransaction(undefined);
  };

  //transfer money events
  const handleCloseTransferMoney = (success) => {
    setShowTransferMoneyDialog(false);
    // success && getData();
  };
  const handleTransferMoney = (data) => {
    setShowTransferMoneyDialog(true);
  };

  const getData = async () => {
    const getTransactionPayload = {
      bibson,
      user_id,
    };

    await dashBoardAction.doGetAndSetCardBalances({ ...getTransactionPayload });
    dispatch(depositActions.doGetAndSetBankAccountDetails({ ...getTransactionPayload }));
    dispatch(statementActions.doGetAndSetStatement({ ...getTransactionPayload }));
    //getting contentful data
    dispatch(
      contentfulActions.doGetAndSetContent(
        contentfulActions.contentfulActionTypes.mobileScreenCollection
      )
    );
  };

  useAsyncEffect(async () => {
    if (!dashboardReducer.userCardTing) return ;
    getTransactionData();
  }, [
    dashboardReducer.userCardTing,
  ]);

  const getTransactionData = (startDate, endDate) => {
    const getTransactionPayload = {
      bibson,
      user_id,
      userCardTing: dashboardReducer.userCardTing,
      fromDate: startDate ? moment(startDate).format(DATE_PARSE_FORMAT) : moment().subtract(30, 'days').format(DATE_PARSE_FORMAT),
      toDate: endDate ? moment(endDate).format(DATE_PARSE_FORMAT) : moment().format(DATE_PARSE_FORMAT),
    };

    return transactionAction.doGetAndSetTransactionList({...getTransactionPayload});
  }

  const onClickApply = (startDate, endDate) => {
    setIsDateChipDisplay(true);
    getTransactionData(startDate, endDate);
  }

  const transactionHistoryHelpTopicCollection = getHelpTopicCollectionFromScreenName(
    mobileScreenCollection,
    "transaction-history"
  );

  if (isLoaddingData) return <Loading>Loadding data ...</Loading>

  const propsTobeForward = {
    ...dashboardReducer,
    ...depositReducer,
    transactionHistoryHelpTopicCollection,
    cardBalance: dashboardReducer?.cardBalances?.card,
    showTransferMoneyDialog,
    successTransaction: transactionReducer.transactions,
    pendingTransaction: transactionReducer.pendingTransactions,
    handleCloseTransferMoney,
    handleTransferMoney,
    onClickApply,
    isDateChipDisplay,
    removeDateChip,
    handleCloseViewTransaction,
    setViewTransaction,
    viewTransaction,
    selectedTransaction,
  };

  return <Transactions {...propsTobeForward} />;
};
