// Commont authenticate
export const ACCOUNT_LOCKED = "ACCOUNT_LOCKED";
export const ACCOUNT_TEMPORARY_LOCKED = "ACCOUNT_TEMPORARY_LOCKED";
export const DISMISS_ACCOUNT_TEMPORARY_LOCKED = "DISMISS_ACCOUNT_TEMPORARY_LOCKED";
//auth
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USERNAME = "SET_USERNAME";
export const SET_LOGIN_KYC_FAILED = "SET_LOGIN_KYC_FAILED";

//Registration
export const SET_SECURITY_QUESTIONS = "SET_SECURITY_QUESTIONS";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const INVITATION_TOKEN_EXPIRED = 'INVITATION_TOKEN_EXPIRED'
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS'
export const RESET_INVITATION_TOKEN_EXPIRED='RESET_INVITATION_TOKEN_EXPIRED'
export const SET_IN_PROGRESSING_KYC = 'SET_IN_PROGRESSING_KYC'
export const SET_CHECKING_KYC_STATUS = 'SET_CHECKING_KYC_STATUS'
//dashboard
export const SET_IS_GOT_CARD_BALANCES = "SET_IS_GOT_CARD_BALANCES";
export const SET_IS_GOT_REWARD_BALANCE = "SET_IS_GOT_REWARD_BALANCE";
export const CLEAR_CARD_BALANCES = "CLEAR_CARD_BALANCES";
export const SET_CARD_BALANCES = "SET_CARD_BALANCES";
export const SET_USER_CARD_TING = "SET_USER_CARD_TING";
export const SET_DASHOBOARD_CARDS = "SET_DASHOBOARD_CARDS";
export const SET_IS_GOT_DASHBOARD_CARDS = "SET_IS_GOT_DASHBOARD_CARDS";
export const SET_TING_MATCHED_CARD = "SET_TING_MATCHED_CARD";

export const SET_DISPLAY_LOGOUT_WRONG_ATTEMPT_POPUP =
  "SET_DISPLAY_LOGOUT_WRONG_ATTEMPT_POPUP";
export const SET_LOGIN_RESPONSE_DATA = "SET_LOGIN_RESPONSE_DATA";
export const SET_KYC_FORM_LINK = "SET_KYC_FORM_LINK";
export const SET_IS_LOGIN_SUCCESS = "SET_IS_LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

//purses
export const SET_IS_GOT_PURSES = "SET_IS_GOT_PURSES";
export const SET_PURSES = "SET_PURSES";
export const SET_PURSE = "SET_PURSE";
export const SET_IS_GOT_PURSE_TRANSACTIONS = "SET_IS_GOT_PURSE_TRANSACTIONS";
export const SET_PURSE_TRANSACTIONS = "SET_PURSE_TRANSACTIONS";
export const ADD_PURSE_TRANSACTIONS = "ADD_PURSE_TRANSACTIONS";
//rewards
export const SET_IS_GOT_REWARDS_TRANSACTIONS =
  "SET_IS_GOT_REWARDS_TRANSACTIONS";
export const SET_REWARDS_TRANSACTIONS = "SET_REWARDS_TRANSACTIONS";
export const SET_REDEEM_REWARDS_STATUS = "SET_REDEEM_REWARDS_STATUS";
export const SET_IS_GOT_REWARDS_SUMMARY = "SET_IS_GOT_REWARDS_SUMMARY";
export const SET_REWARDS_SUMMARY = "SET_REWARDS_SUMMARY";

//deposit
export const SET_IS_GOT_BANK_ACCOUNT_DETAILS =
  "SET_IS_GOT_BANK_ACCOUNT_DETAILS";
export const SET_BANK_ACCOUNT_DETAILS = "SET_BANK_ACCOUNT_DETAILS";

//users
export const SET_USERS_SEARCH_RESULT = "SET_USERS_SEARCH_RESULT";

//messageSenders
export const SET_MESSAGE_SENDERS_SEARCH_RESULT =
  "SET_MESSAGE_SENDERS_SEARCH_RESULT";

//view backgound events
export const SET_BACKGROUND_EVENTS = "SET_BACKGROUND_EVENTS";
export const SET_BACKGROUND_EVENT = "SET_BACKGROUND_EVENT";
export const SET_BACKGROUND_EVENTS_LOADING = "SET_BACKGROUND_EVENTS_LOADING";

//Toast actions
export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

//loader actions
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

//merchants
export const SET_MERCHANT = "SET_MERCHANT";
export const SET_MERCHANTS = "SET_MERCHANTS";
export const SET_MERCHANTS_LOADING = "SET_MERCHANTS_LOADING";
export const SET_MERCHANTS_STANDALONE = "SET_MERCHANTS_STANDALONE"; //like use in select options

//KIM
export const SET_KEYWORDS = "SET_KEYWORDS";
export const SET_KEYWORD = "SET_KEYWORD";
export const SET_KEYWORD_LOADING = "SET_KEYWORD_LOADING";

//categories
export const SET_TRANSACTION_CATEGORIES = "SET_TRANSACTION_CATEGORIES";
export const SET_TRANSACTION_CATEGORY_GROUPS =
  "SET_TRANSACTION_CATEGORY_GROUPS";

//uncategorised merchant texts
export const SET_UNCATEGORISED_MERCHANT_TEXTS =
  "SET_UNCATEGORISED_MERCHANT_TEXTS";
export const SET_UNCATEGORISED_MERCHANT_TEXTS_LOADING =
  "SET_UNCATEGORISED_MERCHANT_TEXTS_LOADING";

//charts
export const SET_APP_CHART_DATA = "SET_APP_CHART_DATA";

//Transactions
export const SET_TRANSACTIONS = "SET_TRANSACTIONS";
export const SET_PENDING_TRANSACTIONS = "SET_PENDING_TRANSACTIONS";

// ATM Finder
export const SET_GOOGLE_MAP_KEYS = "SET_GOOGLE_MAP_KEYS";
export const SET_ALL_GOOGLE_MAP_DATA = "SET_ALL_GOOGLE_MAP_DATA";
export const SET_FREE_ATM_DATA = "SET_FREE_ATM_DATA";
export const SET_PAID_ATM_DATA = "SET_PAID_ATM_DATA";
export const CHANGE_CURRENT_LOCATION = "CHANGE_CURRENT_LOCATION";

//Move Money
export const SET_RECIPIENTS = "SET_RECIPIENTS";
export const SET_LIMIT = "SET_LIMIT";

//Replace Card
export const SET_SHIPPING_DETAILS = "SET_SHIPPING_DETAILS";
export const SET_SUGGESTED_ADDRESS = "SET_SUGGESTED_ADDRESS";
export const ACTIVATE_CARD_ERROR_DETAILS = "ACTIVATE_CARD_ERROR_DETAILS";
export const CLOSE_ACTIVATE_CARD_ERROR_POPUP = "CLOSE_ACTIVATE_CARD_ERROR_POPUP";
export const SET_USER_CARDS = "SET_USER_CARDS";
export const LOADING_CARDS_FAILED = "LOADING_CARDS_FAILED";
export const ACTIVATE_CARD_SHOW_CONGRATULATION_POPUP = "ACTIVATE_CARD_SHOW_CONGRATULATION_POPUP";

//Statement
export const SET_STATEMENT_DETAILS = "SET_STATEMENT_DETAILS";
export const SET_STATEMENT_GOT_RESPONSE = "SET_STATEMENT_GOT_RESPONSE";

//profile
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_ADDRESSES = "SET_USER_ADDRESSES";
export const SET_USER_SHIPPING_ADDRESSES = "SET_USER_SHIPPING_ADDRESSES";
export const SET_NOTIFICATION_SETTINGS_DATA = "SET_NOTIFICATION_SETTINGS_DATA";
export const SET_SUPPORT_EMAIL = "SET_SUPPORT_EMAIL";
export const PROFILE_SETTING_SET_USER_EMAIL = "PROFILE_SETTING_SET_USER_EMAIL";
//contentful
export const SET_CONTENTFUL_CONTENT = "SET_CONTENTFUL_CONTENT";
