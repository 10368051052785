import { Box, Button, Grid, Typography } from "@material-ui/core";
import { InputInfo } from "../../../../common/components/InputInfo";

import { useAppStyles } from "../../../../App.style";

import { PasswordField } from "../../../../common/components/PasswordField";

export default function EnterNewPassword({ handleChange, errors, newPassword, confirmPassword, handleSubmit, isDisableSubmit }) {
  const appClasses = useAppStyles();
  return <>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <InputInfo>
        Create a strong password, keep it safe, and do not share it
        with anyone.
      </InputInfo>
      <Box mt={7}></Box>
      <PasswordField
        name={"newPassword"}
        label={"New Password"}
        inputProps={{
          maxLength: 100,
        }}
        value={newPassword}
        onChange={handleChange}
        errors={{message: errors?.newPassword}}
      />
    </Grid>
    <Typography variant="h6" className={appClasses.text9}>
      Your password must include:
    </Typography>
    <ul>
      <li>minimum of 8 characters</li>
      <li>1 number or symbol</li>
      <li>1 upper and 1 lower case letter</li>
    </ul>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <PasswordField
        name={"confirmPassword"}
        label={"Confirm Password"}
        inputProps={{
          maxLength: 100,
        }}
        value={confirmPassword}
        onChange={handleChange}
        errors={{message: errors?.confirmPassword}}
      />
    </Grid>
    <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        className={`${appClasses.primaryBtn}`}
        disabled={isDisableSubmit}
      >

        Save New Password
      </Button>
    </Grid>
  </>
}