import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../../../hooks/useAuth";
import { doGetAndSetshippingAddress } from "../../../actions";
import AddressForm from "../../AddressForm/AddressForm";
import { StepWrapper, Title, SubTitle } from "../AtomComponents";
import useReplaceCard from "../store/useReplaceCard";


const standardizeAddress = (address) => {
  if (!address) return {}
  const { address_line1, address_line_1, address_line2, address_line_2, city, region, postal_code } = address
  return {
    address_line1: address_line1 || address_line_1,
    address_line2: address_line2 || address_line_2,
    city,
    region,
    postal_code
  }
}

const addressToShippingAddress = (address) => {
  return {
    ...address,
    address_line_1: address.address_line1,
    address_line_2: address.address_line2,
  }
}

export default function Address() {
  const dispatch = useDispatch()
  const { user_id, bibson } = useAuth()
  const { onNextStep, shippingAddress, setShippingAddress, cardTing } = useReplaceCard()
  const [readyToMoveNextStep, setReadyToMoveNextStep] = React.useState(false)
  const manageCardReducer = useSelector((state) => state.managecardReducer);
  const { shippingDetails } = manageCardReducer;

  const address = shippingAddress || standardizeAddress(shippingDetails)

  useEffect(() => {
    if (readyToMoveNextStep) onNextStep()
    return () => setReadyToMoveNextStep(false)
  }, [readyToMoveNextStep, onNextStep])

  const onSaveAddress = (data) => {
    const formattedShippingAddress = addressToShippingAddress(data);
    setShippingAddress({ ...shippingDetails, ...formattedShippingAddress });
    setReadyToMoveNextStep(true)
  }

  useEffect(() => {
    if (!cardTing) return
    dispatch(doGetAndSetshippingAddress({ user_id, bibson, card_ting: cardTing }))
  }, [cardTing, user_id, bibson, dispatch])

  return <StepWrapper>
    <Title>
      Replace Your Card
    </Title>
    <SubTitle>
      Confirm your shipping address, so we can send you a new card.
    </SubTitle>
    <Box sx={{ width: '100%' }}>
      <AddressForm address={address} onSaveAddress={onSaveAddress} />
    </Box>
  </StepWrapper>
}