import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  txDelCol: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "700",
    "& span": {
      color: theme.palette.other.black700,
    },
    "& label": {
      color: theme.palette.other.black300New,
    },
  },
  txContainer: {
    padding: "0px 48px",
    textAlign: "center",
    minHeight: "400px",
  },
  txTable: {
    padding: "24px 48px",
  },
  dialogContent: {
    margin: "10px 25%"
  }
}));
