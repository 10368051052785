import { Box, Grid, Hidden, Typography, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import quearyString from 'query-string';
import * as contentfulActions from "../../contentful/actions";
import * as actions from "../actions";

import { loginLeftImg, logoImage, stepsImg } from "../../../constants";
import { openContentFulDocOnRes } from "../../../utilities";
import {  Redirect, Route } from "react-router-dom";

import { BannerImg, LeftSection, RightSectionContainer, SectionSubtitle, SectionTitle } from "../components/Registration/AtomComponents";
import RegistrationForm from "../components/Registration/RegistrationForm";

const useToken = () => {
  const location = useLocation()
  const URL = quearyString.parse(location.search)
  return URL.token
}

const JoinArtistGrowthWallet = () => {
  const theme = useTheme()
  return <Box sx={{ py: 2 }}>
    <Box>
      <img src={logoImage} alt="logo" />
    </Box>
    <Typography style={{ padding: theme.spacing(2, 0), fontWeight: 400 }} variant="h2">Join Artist Growth Wallet today</Typography>
    <Box>
      <img src={stepsImg} alt="step" />
    </Box>
  </Box>
}

const RegistrationPage = function () {
  const dispatch = useDispatch();
  const token = useToken()
  const registrationReducer = useSelector((state) => state.registrationReducer);

  useEffect(() => {
    dispatch(actions.doGetAndSetUserProfile({ token }));
    dispatch(actions.doGetAndSetSecurityQuestions());
  }, [dispatch, token])


  const { securityQuestions = [], userProfile } = registrationReducer
  const paidappUser = userProfile?.paidapp_user;
  const onRegistrationSuccess = (responseData, payload) => {
    dispatch(actions.registrationSuccess(responseData, payload));
  }

  const contentfulState = useSelector((state) => state.contentfulReducer);

  const openContentfulPdf = (documentType) => {
    const { key } = documentType;
    if (contentfulState[key]) {
      contentfulState[key] && openContentFulDocOnRes(contentfulState[key]);
      return
    }
    dispatch(contentfulActions.doGetAndSetContent(documentType, (res) => {
      openContentFulDocOnRes(res);
    }))
  }

  const openTnC = () => {
    openContentfulPdf(contentfulActions.contentfulActionTypes.registrationTnC)
  }

  const openCardHolderAgreement = () => {
    openContentfulPdf(contentfulActions.contentfulActionTypes.legal)
  }

  const invitationTokenExpired = registrationReducer?.invitationTokenExpired
  if (invitationTokenExpired) {
  return <Redirect to={{ pathname: "/login", state: { tokenExpired: true } }} />
  }
  return <Grid container>
    <Hidden only={['xs', 'sm']}>
      <Grid item md={4} container>
        <LeftSection
          justifyContent="center"
          alignItems="center"
          item>
          <BannerImg src={loginLeftImg} alt="logo left" />
        </LeftSection>
      </Grid>
    </Hidden>

    <Grid item xs={12} md={8} style={{ height: '100vh', padding: "0 0 20px 0", overflow: 'scroll', boxSizing: 'border-box' }}>
      <RightSectionContainer>
        <JoinArtistGrowthWallet />
        <SectionTitle>Account Info</SectionTitle>
        <SectionSubtitle>
          Please provide your information below to sign up for a Artist Growth
          Wallet account.
        </SectionSubtitle>
        <RegistrationForm
          paidappUser={paidappUser}
          onboardToken={token}
          securityQuestions={securityQuestions}
          openTnC={openTnC}
          openCardHolderAgreement={openCardHolderAgreement}
          onRegistrationSuccess={onRegistrationSuccess}
        />
      </RightSectionContainer>
    </Grid>

  </Grid>
}

export default RegistrationPage;