import { Box, IconButton, Popover, Typography, Link } from "@material-ui/core";
import React from "react";
import styled from '@emotion/styled'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useUpdateEffect } from "ahooks";

const AnchorMapper = {
  top: {
    anchorOrigin:{
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin:{
      vertical: 'bottom',
      horizontal: 'right',
    }
  },
  bottom: {
    anchorOrigin:{
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin:{
      vertical: 'top',
      horizontal: 'right',
    }
  }
}
const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    background: ${({ details }) => details ? '#fff' : '#262626'};
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    color: ${({ details }) => details ? '#262626' : '#FFFFFF'};
    width: 375px;
    padding: ${({ details }) => details ? '0 0 8px' : '35px 0'};
    box-sizing: border-box;
  }
`

const Wrapper = styled(Box)`
  & > .help-title-wrapper{
    position: relative;
    align-items: center;
    padding: 18px;
    background-color: #262626;
    & > .back-button{
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      display: ${({ details }) => details ? 'block' : 'none'};
    }
    & > h4 {
      display: block;
      flex: 1 1 auto;
      text-align: center;
      font-weight: 700;
      font-size: ${({ details }) => details ? '18px' : '24px'};
      text-align: ${({ details }) => details ? 'center' : 'left'};
      color: #E7E7E7;
    }
  }
  & > .help-list {
    display: ${({ details }) => details ? 'none' : 'block'};
    padding: 16px 24px;
  }
  & > .topic-detail {
    padding: 24px;
    display: ${({ details }) => details ? 'block' : 'none'};
  }
`


const HelpTopic = styled(Typography)`
  color: #E7E7E7;
  margin: 16px 0;
`

const StyledSubTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

const HelpItemTitle = styled(Typography)`
  color: #262626;
`

const HelpItemContent = styled(Typography)`
  margin-top: 24px;
`

const NeedMoreHelpButton = styled(Link)`
  display: block;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #018787;
  padding: 16px 0;
`

const HelpItem = ({ title, content }) => {
  const [open, setOpen] = React.useState(false)
  const toggleOpen = () => {
    setOpen(!open)
  }
  return <>
    <HelpItemTitle variant="h6" onClick={toggleOpen}>{title}</HelpItemTitle>
    <HelpItemContent variant="body1">{content}</HelpItemContent>
    <Box sx={{ mt: 3 }}>
      <NeedMoreHelpButton href="mailto:agwallet-support@payfare.com">Need more help?</NeedMoreHelpButton>
    </Box>
  </>
}
export default function HelpContent({ topics, helpAnchorEl, onClose, id, open, anchor, screenName }) {
  const [topic, setTopic] = React.useState(null)

  const onClickHandler = (topic) => {
    setTopic(topic)
  }

  const onBackHandler = () => {
    setTopic(null)
  }

  useUpdateEffect( () => {
    setTopic(null)
  }, [screenName])

  const details = !!topic
  const title = details ? 'Help' : 'Need more help?'

  const anchorConfig = AnchorMapper[anchor] || AnchorMapper.bottom

  return <StyledPopover id={id}
    details={details}
    open={open}
    anchorEl={helpAnchorEl}
    onClose={onClose}
    anchorOrigin={anchorConfig.anchorOrigin}
    transformOrigin={anchorConfig.transformOrigin}
  >
    <Wrapper details={details}>
      <Box className="help-title-wrapper">
        <IconButton onClick={onBackHandler} className="back-button">
          <ArrowBackIosIcon style={{ color: '#FFFFFF', fontSize: '18px' }} />
        </IconButton>
        <Typography variant="h4">{title}</Typography>
      </Box>

      <Box className="help-list">
        <StyledSubTitle variant="subtitle1">Try these help articles:</StyledSubTitle>
        {(topics || []).map(topic => <HelpTopic variant="h6" key={topic.title} onClick={() => onClickHandler(topic)}>{topic.title}</HelpTopic>)}
      </Box>
      <Box className="topic-detail">
        <HelpItem title={topic?.title} content={topic?.content} />
      </Box>
    </Wrapper>
  </StyledPopover>
}
