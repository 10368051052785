import { TOAST_TYPES } from "../components/core";
import * as loaderActions from "../../common/actions/loader";
import * as toastActions from "../../common/actions/toast";
import * as authActions from "../../modules/auth/actions";
import { commonError } from "../../constants";
import { NOT_AUTHORIZED, TEN_MIN_LOCK } from "../../constants/app.constants";
import { temporaryLockAccount } from "./accountStatus";


/*
* This function handles some errors that are common to all api calls
* It returns true if the error was handled, false otherwise
*/
const handleSubmitError = (error) => async (dispatch) => {
  if (!error.isAxiosError)  return false

  const responseErrors = error?.response?.data?.errors || []
  const temporaryLockError = responseErrors.find(error => error.code.toString() === TEN_MIN_LOCK)
  const notAuthorizedError = responseErrors.find(error => error.code.toString() === NOT_AUTHORIZED)
  if (temporaryLockError) {
    const config = error?.config
    dispatch(temporaryLockAccount(temporaryLockError.lockout_time, config.headers?.['x-client-2fa-reason']))
    return true
  }
  if (notAuthorizedError) {
    dispatch(authActions.doLogoutWihoutRedirect())
    return true
  }

  return false
}

// this is a decorator function that wraps the api call to handler some errors
export const apiRequest = (dispatch, fn) => {
  return async function (...args) {
    try {
      const response = await fn(...args);
      return response;
    } catch (error) {
      dispatch(handleSubmitError(error))
      throw error;
    }
  }
}

export const apiCall = async (dispatch, service, config = {}, logOutUserOnExcessLoginAttempts) => {
  let { showLoader, displayError, disableHandlerUnAuthorized } = config;
  showLoader && dispatch(loaderActions.show());

  try {
    const response = await service;

    showLoader && dispatch(loaderActions.hide());
    if (
      displayError &&
      response?.data?.errors &&
      response?.data?.errors[0]
    ) {
      const { message } = response?.data?.errors[0];
      dispatch(toastActions.show(message, TOAST_TYPES.ERROR));
      //rejecting in error block of caller
      return Promise.reject(response);
    }
    return response;

  } catch (errors) {
    showLoader && dispatch(loaderActions.hide());
    let errorHandled = false
    if (!disableHandlerUnAuthorized) {
      errorHandled = await dispatch(handleSubmitError(errors))
    }
    if (errorHandled) return;
    const { message, code } = errors?.response?.data?.errors[0];
    //check for unauthorise request to redirect to login
    if (!logOutUserOnExcessLoginAttempts && code === 401) {
      dispatch(authActions.doLogout());
      return Promise.reject(errors);
    }

    else if (logOutUserOnExcessLoginAttempts && code === 8205) {
      displayError = false;
      dispatch(authActions.doLogout());
      dispatch(authActions.setDisplayWrongAttemptPopup(true));
      return Promise.reject(errors);
    }

    if (
      displayError &&
      errors?.response?.data?.errors &&
      errors?.response?.data?.errors[0]
    ) {
      dispatch(toastActions.show(message, TOAST_TYPES.ERROR));
      return Promise.reject(errors);
    }

    displayError &&
      dispatch(toastActions.show(commonError, TOAST_TYPES.ERROR));
    return Promise.reject(errors);
  }
};
