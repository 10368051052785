import React from "react";

import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  styled
} from "@material-ui/core";

import { useAppStyles } from "../../../App.style";
import { IconButton } from "../core";
import { closeBlackIcon } from "../../../constants";
import { AppButton } from "../AppButton";


const StyledResendBtnCtrWrapper = styled('div')(theme => ({
  padding: "0rem 2rem"
}));

export const DidNotReciveCodeDialog = (props) => {
  const { handleConfirm } = props;

  const appClasses = useAppStyles();
  return (
    <Dialog
      open={true}
      onClose={() => handleConfirm(false)}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleConfirm(false)}
          >
            <img src={closeBlackIcon} alt="back icon"/>
          </IconButton>
          <Typography variant="span" className={appClasses.text5}>
            Didn't receive a code?
          </Typography>
        </div>
      </DialogTitle>
      {/* <Divider></Divider> */}
      <Box mt={1} />
      <DialogContent
        className={`${appClasses.dialogContent1} ${appClasses.textAlignCenter}`}
      >
        <Typography variant="subtitle1" component='p' style={{textAlign: 'left'}}>
          Please wait at least 60 seconds for the code to arrive to your phone. If you didn't receive the verification code, please check the following:
        </Typography>
        {
          props.children
        }

        <Box mt={5} mb={5}>
          <StyledResendBtnCtrWrapper>
            <AppButton
              contained
              onClick={props.resendOTPCode}
              className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
              primary
            >
              Resend Code
            </AppButton>
          </StyledResendBtnCtrWrapper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
