import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core";
import { appAnimations } from "./App.style";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import { appColors } from "./constants";

const theme = createTheme({
  typography: {
    fontFamily: ["SF pro", "sans-serif"].join(","),
    h2: {
      color: appColors.black700,
      fontSize: "24px",
      lineHeight: "29px",
      fontWeight: "700",
    },
    h6: {
      color: appColors.black700,
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: "700",
    },
    body1: {
      fontSize: '14px',
      lineHeight: '17px'
    },
    body2: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    subtitle1: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: "400",
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: appColors.black700,
      },
    },
    MuiTextField: {
      root: {
        '&.registration-field': {
          '& fieldset': {
            border: 0,
            borderBottom: '1px solid #000000',
            borderRadius: 0,
          }
        },
        '& .Mui-disabled fieldset': {
          border: 0
        }
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: appColors.black300,
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiLink: {
      root: {
        lineHeight: "normal",
        padding: 0,
      }
    }
  },
  palette: {
    primary: {
      main: "#0C2232",
      dark: "#040509"
    },
    secondary: {
      main: appColors.secondary,
    },
    other: {
      primary100: appColors.primary100,
      primary300: appColors.primary300,
      primary500: appColors.primary500,
      primary900: appColors.primary900,
      primaryButtonBg: '#1AD6D6',
      disableButtonBg: '#9D9D9D',
      grey: appColors.grey,
      grey100: appColors.grey100,
      white: appColors.white,
      highlight: appColors.highlight,
      red: appColors.red,
      navyBlue300: appColors.navyBlue300,
      navyBlue700: appColors.navyBlue700,
      green900: appColors.green900,
      black100: appColors.black100,
      black300: appColors.black300,
      black300New: appColors.black300New,
      black900: appColors.black900,
      black700: appColors.black700,
      black500: appColors.black500,
      orange500: appColors.orange500,
      black: appColors.black,
      success500: appColors.success500,
      success100: appColors.success100,
      error100: appColors.error100,
      error500: appColors.error500,
    },
    action: {
      disabledBackground: appColors.black300New,
      disabled: appColors.white
    }
  },
  breakpoints:{
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    }
  },
  appAnimations,
});

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerWrapper />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
