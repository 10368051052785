import React, { useEffect } from "react";
import { useRequest } from "ahooks";
import { getKYCLink } from "../../auth/services";
import useAuth from "../../../hooks/useAuth";
import Loading from "../../../common/components/Loading/Loading";
import KYC from "../components/KYC";
import { useDispatch } from "react-redux";
import { setInprogressingKyc } from "../../auth/actions";

const KycFormPage = () => {
  const { user_ting, bibson } = useAuth()
  const dispatch = useDispatch();

  useEffect( () => {
    dispatch(setInprogressingKyc(true))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSuccessfulKycHandler = () => {
    dispatch(setInprogressingKyc(false))
  }

  const { loading, data, error } = useRequest(() => getKYCLink({ user_ting, bibson }));

  const kycLink = data?.data?.data?.kyc_form_url;

  if (loading) return <Loading text="Loadding KYC...." />;
  if (error) console.error("Error: ", error)

  return <KYC kycLink={kycLink} onSuccessKyc={onSuccessfulKycHandler} />;
}

export default KycFormPage;