import React from "react";
import { Button } from "../core";
import { useStyles } from "./style";

export const AppButton = (props) => {
  const classes = useStyles();
  const { primary, secondary, contained, link } = props;
  let variant;
  if (contained) {
    variant = "contained";
  }
  return (
    <Button
      variant={variant}
      color={primary ? "primary" : secondary ? "secondary" : ""}
      classes={{
        label: link ? classes.buttonLinkLabel : classes.buttonLabel,
        root: link ? classes.buttonLink : classes.button,
      }}
      {...props}
    ></Button>
  );
};
