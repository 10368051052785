import {
  ACTIVATE_CARD_ERROR_DETAILS,
  LOADING_CARDS_FAILED,
  SET_USER_CARDS,
  SET_SHIPPING_DETAILS,
  SET_SUGGESTED_ADDRESS,
  CLOSE_ACTIVATE_CARD_ERROR_POPUP,
  ACTIVATE_CARD_SHOW_CONGRATULATION_POPUP
} from "../../../redux/actionTypes";

const initState = {
  shippingDetails: undefined,
  suggestedAddress: undefined,
  isPhysicalCardActivated: false,
  currentActivatedCard: undefined,
  showActivateCardSuccessPopup: false,
  cards: []
};

const pickCurrentActiveCard = (cards, cardTing) => {
  const defaultCard = cards.find((card) => card.ting === cardTing && card.method_type === "card" );
  if (defaultCard) return defaultCard;

  const activatedCards = cards.filter((card) => card.active);
  const physicalCard = activatedCards.find((card) => !card.is_virtual);
  const virtualCard = activatedCards.find((card) => card.is_virtual);
  return physicalCard || virtualCard;
};

export default function managecardReducer(state = initState, action) {
  switch (action.type) {

    case SET_SHIPPING_DETAILS:
      return Object.assign({}, state, {
        shippingDetails: action.data,
      });
    case SET_SUGGESTED_ADDRESS:
      return Object.assign({}, state, {
        suggestedAddress: action.data,
      });
    case ACTIVATE_CARD_ERROR_DETAILS:
      return {...state, activateCardErrorDetails: action.data}
    case CLOSE_ACTIVATE_CARD_ERROR_POPUP:
      return {...state, activateCardErrorDetails: undefined}
    case SET_USER_CARDS:
      const auto_method = action.data?.auto_method || {};
      const  cards = action.data?.cards || [];
      const currentActivatedCard = pickCurrentActiveCard(cards, auto_method?.ting);
      const isPhysicalCardActivated = currentActivatedCard && !currentActivatedCard.is_virtual;
      return {...state, isPhysicalCardActivated, currentActivatedCard, cards: cards}
    case LOADING_CARDS_FAILED:
      // TODO: handle error when loadding card failed
      return {...state}
    case ACTIVATE_CARD_SHOW_CONGRATULATION_POPUP:
      return {...state, showActivateCardSuccessPopup: action.data}
    default:
      return state;
  }
}
