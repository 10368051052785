import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Login } from "../components/Login";
import * as authActions from "../actions";
import {
  REMEMBER_ME_LS_KEY,
  routes,
} from "../../../constants";
import history from "../../../history";
import { getLS } from "../../../utilities";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccountLockedPopupShown: false,
      isAccountTempLockedPopupShown: false,
      lockoutTime: 10,
      username: "",
      password: "",
      filledOtp: "",
      rememberMe: "",
      openResendOTPDialog: false,
      errors: {
        filledOtp: "",
        username: "",
        password: "",
      },
      isSubmitted: null,
      isFormValid: false,
    };
  }

  componentDidMount() {
    //Check if user has selected remember me option
    const lsData = JSON.parse(getLS(REMEMBER_ME_LS_KEY));
    if (lsData?.identifier) {
      this.setState({
        rememberMe: true,
        username: lsData?.identifier
      })
    }

    this.props.authActions.doLogout();
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value, type, checked } = event.target;
    if (type == "checkbox")
      this.setState({ [name]: checked }, () => this.isValid());
    else
      this.setState({ [name]: value }, () => this.isValid());
  };

  handleForgotPassowrd = () => {
    history.push(routes.forgotPassword.path);
  };

  handleClose = () => {
    this.setState({
      isAccountLockedPopupShown: false,
      isAccountTempLockedPopupShown: false,
    }, () => {
      history.push(routes.login.path);
    })
  }

  handleLoginError = (data) => {
    if (data?.locked) {
      !this.state.isAccountLockedPopupShown && this.setState({
        isAccountTempLockedPopupShown: true,
      })
    }
    else {
      !this.state.isAccountTempLockedPopupShown && this.setState({
        isAccountLockedPopupShown: true,
      })
    }
  }

  resendOTP = () => {
    this.props.authActions.doSendOTPForgotPassword(
      {
        email: this.props.authData.authReducer && this.props.authData.authReducer.loginResponseData.user.email,
      },
      (result) => {
        if (result?.locked) {
          this.setState({
            isAccountLocked: true
          })
        }
      }
    );
  }

  resendPhoneOTP = async () => {
    const success = await this.props.authActions.doSendOTPToPhone()
    if (success) this.setState({openResendOTPDialog: false})
  }

  handleResendOTPDialog = (doResendOTP) => {
    this.setState({
      openResendOTPDialog: !this.state.openResendOTPDialog
    }, () => {
      doResendOTP && this.resendOTP();
    })
  }

  onCloseLoginFailedKyc = () => {
    this.props.authActions.clearLoginFailedBecauseOfFailedKYCStatus()
  }

  onValidateOTP = async () => {
    try {
      const otpResponse = await this.props.authActions.doValidateOTPForLogin({
        user_id: this.props?.authData?.authReducer?.loginResponseData?.user?.id,
        bibson: this.props?.authData?.authReducer?.loginResponseData?.bibson,
        twofac: this.state?.filledOtp,
        phone: this.props?.authData?.authReducer?.loginResponseData?.user?.phone,
      })
      if (otpResponse?.data?.status === "success") {
        authActions.setIsLoginSuccess(false)
        history.push(routes.home.path)
      }
    } catch (e) {
      const errorsData = e?.response?.data?.errors
      // if the opt is invalide, error code is 8018
      if (errorsData && errorsData[0]?.code === 8018) {
        this.setState({ errors: { filledOtp: "The code you've entered is incorrect. Please try again." } })
      }
      // if the opt wrong and account is locked, error code is 8204
      if (errorsData && errorsData[0]?.code === 8204) {
        authActions.setIsLoginSuccess(false)
        this.setState({
          isAccountTempLockedPopupShown: true,
          lockoutTime: errorsData[0]?.lockout_time
        })
      }
    }
  }

  handleSubmit = () => {
    this.setState({ isSubmitted: true, filledOtp: null }, () => {
      if (this.isValid()) {
        const { handleLoginError } = this;
        const { username, password, rememberMe } = this.state;
        this.props.authActions.doLogin({
          identifier: username,
          password,
          rememberMe
        }, handleLoginError);
      }
    });
  };

  resetLoginSuccess = () => {
    this.props.authActions.setIsLoginSuccess(false);
  }

  isValid = (forceIsSubmittedTrue) => {
    const { username, password, isSubmitted, filledOtp } = this.state;
    const errors = {};
    let valid = true;

    if (!forceIsSubmittedTrue && !isSubmitted) {
      return valid;
    }

    if (
      this.props.authData.authReducer &&
      this.props.authData.authReducer.isLoginSuccess
    ) {
      if (!filledOtp) {
        errors.filledOtp = "Require";
      }
    } else {
      if (!username) {
        errors.username = "Required";
      }

      if (!password) {
        errors.password = "Required";
      }
    }

    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    this.setState({ isFormValid: valid });
    return valid;
  };

  handleChangeOtp = (result) => {
    const { isSuccess, finalOtp } = result;
    this.setState({ filledOtp: isSuccess ? finalOtp : "" }, () =>
      this.isValid(true)
    );
  };

  handleConfirmLogoutAttemptPopup = () => {
    this.props.authActions.setDisplayWrongAttemptPopup(false);
  };

  render() {
    const {
      handleChange,
      handleSubmit,
      handleForgotPassowrd,
      handleChangeOtp,
      handleConfirmLogoutAttemptPopup,
      handleClose,
      resendOTP,
      resetLoginSuccess,
      handleResendOTPDialog
    } = this;


    const props = {
      authData: this.props.authData.authReducer,
      ...this.state,
      handleChange,
      handleChangeOtp,
      handleSubmit,
      handleForgotPassowrd,
      handleConfirmLogoutAttemptPopup,
      handleClose,
      resendOTP,
      resetLoginSuccess,
      handleResendOTPDialog,
      onCloseLoginFailedKyc: this.onCloseLoginFailedKyc,
      onValidateOTP: this.onValidateOTP,
      resendPhoneOTP: this.resendPhoneOTP
    };
    return <Login {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
