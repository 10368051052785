import React, { Component } from "react";

import { ActivateCard } from "../components/ActivateCard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import * as dashboardActions from "../../dashboard/actions";
import WarningDialog from "../../../common/components/Dialogs/WarningDialog";

export const ACTIVATE_CARD_STEPS = {
  init: "init",
  filling: "filling",
  initSetPin: "initSetPin",
  setPin: "setPin",
  success: "success",
};


const ERROR_TITLE_BY_CODE = {
  "002001": "Wrong Card Credentials",
  "default": "Something went wrong",
}
const ERROR_MESSAGE_BY_CODE = {
  "002001": "The card number or expiry date you have entered is wrong",
  default: "Something went wrong. Please try again later."
}


const ActivateErrorPopUp = ({ onClose, error }) => {
  if (!error) return null
  const { code, message } = error || {};
  const content = ERROR_MESSAGE_BY_CODE[code] || message || ERROR_MESSAGE_BY_CODE.default;
  const title = ERROR_TITLE_BY_CODE[code] || ERROR_TITLE_BY_CODE.default;
  return (
    <WarningDialog open={true} title={title}
      content={content}
      buttonText="Try Again" closeHanlder={onClose}
    />
  );
}


class ActivateCardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  refreshDashboardCardData = (isLoaderHide) => {
    const { loginResponseData } = this.props.authReducer;
    const { userCardTing } = this.props.dashboardReducer;
    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
      card_ting: userCardTing,
    };
    this.props.dashboardActions.doGetAndSetCardBalances({ ...data }, isLoaderHide)
    this.props.actions.loadCurrentUserCards(data);
  }

  handleClose = (afterSaveSuccess) => {
    const { handleCloseActivateCard } = this.props;
    handleCloseActivateCard();
  };

  render() {
    const {
      handleClose,
    } = this;

    const {
      handleCloseActivateCard,
      activeCardError,
    } = this.props;

    const propsToForward = {
      handleCloseActivateCard,
      handleClose,
      refreshDashboardCardData: this.refreshDashboardCardData,
      changeHelpScreenName: this.props.changeHelpScreenName,
    };

    const activateHasError = !!activeCardError;

    return <>
      <ActivateCard {...propsToForward} />
      {activateHasError && <ActivateErrorPopUp onClose={this.props.actions?.closeActiveCardErrorPopup} error={activeCardError} />}
    </>;
  }
}

const mapStateToProps = (state) => {
  return {
    authReducer: state.authReducer,
    dashboardReducer: state.dashboardReducer,
    activeCardError: state.managecardReducer.activateCardErrorDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateCardPage);
