import { Grid } from "@material-ui/core"

const CarouselGroupRewardsItem = ({ rewards, groupSize, rewardItemComp: RewardItemCom }) => {
  const colSize = Math.round(12 / groupSize)

  return <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
    {
      rewards.map((el) => (
        <Grid item key={el.reward_ting} xs={colSize}>
          <RewardItemCom rewardData={el} />
        </Grid>
      ))
    }
  </Grid>
}

export default CarouselGroupRewardsItem