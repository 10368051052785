import { Grid, Typography, Box, Paper, styled, Link } from "@material-ui/core";
import React from "react";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useAppListStyles, useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import TransferMoneyDialog from "../../../transferMoney/containers/TransferMoneyDialog";

import { heartIcon, DATE_FORMAT, routes } from "../../../../constants";
import { IconButton } from "../../../../common/components/core";
import Moment from "react-moment";
import { AppTable } from "../../../../common/components/AppTable";
import { Amount } from "../../../../common/components/Amount";

import { PrimaryButton } from "../../../../common/components/core/Button/Buttons";
import { ReactComponent as BackIcon } from '../../../../assets/icons/arrow-back.svg';
import { MobileHelpScreen } from "../../../../common/components/MobileHelpScreen";
import useMobileScreenCollection from "../../../../hooks/userMobileScreenCollection";
import moment from "moment";


const StyledBreadcrumb = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "10px",
  marginBottom: theme.spacing(8),
}));
const GoalHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const Goals = (props) => {
  const appListClasses = useAppListStyles();
  const appClasses = useAppStyles();
  const classes = useStyles();
  const mobileScreenCollection = useMobileScreenCollection();
  const {
    isGotPurseTransactions,
    purse,
    purseTransactions,
    //transfer money
    transferMoneyDialogData,
    showTransferMoneyDialog,
    handleCloseTransferMoney,
    handleTransferMoney,
  } = props;

  const columns = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Date",
      canSort: false,
      render: (row) => <Moment format={DATE_FORMAT}>{row.trans_date}</Moment>,
    },
    {
      id: "desc",
      numeric: false,
      disablePadding: false,
      label: "Details",
      render: (row) => row.description,
    },
    {
      id: "amount",
      numeric: true,
      disablePadding: false,
      label: "Amount",
      render: (row) => <Amount>{row.amount}</Amount>,
    },
  ];

  const listCardHeight = `${window.innerHeight - 200}px`;

  const sortedPurseTransactions = purseTransactions?.sort((a, b) => {
    const {trans_date: transDateA} = a;
    const {trans_date: transDateB} = b;
    return moment(transDateB || 0).unix() - moment(transDateA || 0).unix();
  });

  return (
    <div className={appListClasses.root}>
      <Grid container className={appListClasses.root} spacing={4}>
        <Grid xs={12} item>

          <StyledBreadcrumb>
            <Link aria-label="Back to login" href={routes.dashboard.path}>
              <BackIcon />
            </Link>
            <Link aria-label="Back to login" href={routes.dashboard.path} style={{textDecoration: "none"}}>
              <Typography variant="body2" style={{color: "#555555"}}>Back to Account</Typography>
            </Link>

          </StyledBreadcrumb>
          <GoalHeaderWrapper>
            <Typography variant="h1" className={appClasses.text2}>
              <Amount removePlusSign removeSuccessColor>
                {purse?.balance || 0.0}
              </Amount>
            </Typography>
            <PrimaryButton
              startIcon={<AddCircleOutlineIcon />}
              contained
              fullWidth={false}
              onClick={handleTransferMoney}
              className={appClasses.primaryBtn}
              primary
            >
              Add Funds
            </PrimaryButton>
          </GoalHeaderWrapper>
          <Box mt={4}></Box>
          <Paper className={`${appClasses.paperCard}`}>
            {isGotPurseTransactions &&
              purseTransactions &&
              purseTransactions.length === 0 && (
                <div
                  className={`${appListClasses.listContainer} ${appClasses.centerHvWrapper}`}
                  style={{ height: listCardHeight }}
                >
                  <div className={`${classes.noDataWrapper}`}>
                    <IconButton className={classes.quickLinkIcon}>
                      <img src={heartIcon} alt="Heart" />
                    </IconButton>
                    <Box mt={2}></Box>
                    <Typography variant="h4" className={appClasses.text3}>
                      Your goal account is empty
                    </Typography>
                    <Box mt={2}></Box>
                    <Typography variant="span" className={appClasses.text4}>
                      Add funds today to start saving for all of your <br />
                      short-term goals and long-term dreams.
                    </Typography>
                  </div>
                </div>
              )}

            {isGotPurseTransactions &&
              purseTransactions &&
              purseTransactions.length > 0 && (
                <div className={`${appListClasses.listContainer}`}>
                  <AppTable
                    columns={columns}
                    removePagination
                    data={sortedPurseTransactions}
                    style={{ height: listCardHeight }}
                  ></AppTable>
                </div>
              )}
          </Paper>
          <Box mt={2}></Box>
          <div className={classes.goalFooter}>
          <MobileHelpScreen iconName="button" topics={ mobileScreenCollection } screenName="transfer-money" anchor="top"/>
          </div>
        </Grid>
      </Grid>

      {/*Transfer Money*/}
      {showTransferMoneyDialog && (
        <TransferMoneyDialog
          data={transferMoneyDialogData}
          handleClose={handleCloseTransferMoney}
        ></TransferMoneyDialog>
      )}
    </div>
  );
};
