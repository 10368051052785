import { Box } from "@material-ui/core";
import { useRequest } from "ahooks";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppStyles } from "../../../../../App.style";
import { AppButton } from "../../../../../common/components/AppButton";
import useAuth from "../../../../../hooks/useAuth";
import { loadCurrentUserCards, setShippingAddress } from "../../../actions";
import { reissueCard, setCardStatus, setUserShippingAddress } from "../../../services";
import { StepWrapper, SubTitle, Title } from "../AtomComponents";
import useReplaceCard from "../store/useReplaceCard";


export default function Confirmation() {
  const dispatch = useDispatch()

  const appClasses = useAppStyles();
  const { user_id, bibson, username } = useAuth();

  const { shippingAddress, replaceReason, currentPassword, cardTing, onSuccessReplaceCard, handleClose } = useReplaceCard();

  const processReissueCard = async () => {
    const crendentialsData = { user_id, bibson, card_ting: cardTing }
    const userData = { identifier: username, password: currentPassword };
    const updateShippingAddressResponse = await setUserShippingAddress(crendentialsData, { ...shippingAddress, ...userData });

    if (updateShippingAddressResponse?.data?.status === "success") {
      const { profile } = updateShippingAddressResponse?.data?.data;
      dispatch(setShippingAddress(profile));
    }

    await setCardStatus(crendentialsData, userData, { reason: replaceReason });
    await reissueCard(crendentialsData, userData)
  }

  const onProcessingReissueCardSuccess = () => {
    dispatch(loadCurrentUserCards({ user_id, bibson }))
    onSuccessReplaceCard(true)
  }

  const { run: onClickContinueHandler, loading } = useRequest(processReissueCard, {
    manual: true, debounceWait: 500,
    onSuccess: onProcessingReissueCardSuccess,
    onError: (err) => {
      onSuccessReplaceCard(false)
    }
  })

  return <StepWrapper>
    <Title>$5 Card Replacement Fee</Title>
    <SubTitle>By continuing, you accept that your account will be charged a $5.00 card replacement fee.</SubTitle>
    <Box sx={{ width: '100%' }}>
      <AppButton
        contained
        disabled={loading}
        onClick={onClickContinueHandler}
        className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
        primary
      >
        Yes, Continue
      </AppButton>
      <Box mt={2} />
      <AppButton
        variant="text"
        className={`${appClasses.textBtn} ${appClasses.width100p}`}
        onClick={handleClose}
      >
        Cancel
      </AppButton>
    </Box>
  </StepWrapper>
}