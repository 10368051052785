import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as contentfulActions from "../../contentful/actions";
import { DoshOffers } from "../components/Dosh";

export const DoshOfferPage = (props) => {
  const dispatch = useDispatch();

  const contentfulReducer = useSelector((state) => state.contentfulReducer);

  const [isShowHelpPopup, setIsShowHelpPopup] = useState(false);

  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const rewardsReducer = useSelector((state) => state.rewardsReducer);

  useEffect(() => {
    dispatch(
      contentfulActions.doGetAndSetContent(
        contentfulActions.contentfulActionTypes.rewards
      )
    );
  }, [])

  const handleHelpPopup = () =>  {
    setIsShowHelpPopup(!isShowHelpPopup);
  }

  const propsTobeForward = {
    ...dashboardReducer,
    ...rewardsReducer,
    handleHelpPopup,
    isShowHelpPopup,
    items: contentfulReducer?.imageList?.imageCollection?.items
  };

  return <DoshOffers {...propsTobeForward} />;
};
