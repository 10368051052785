/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Grid, Typography, styled } from '@material-ui/core'
import { atmFullAddress } from '../../utils'

const StyledAtmFee = styled(Typography)(({ theme, free }) => ({
  color: free ? '#018787' : '#555555',
}))

const ElementRoot = styled(Grid)(({ theme, selected }) => ({
  padding: theme.spacing(3, 4),
  borderBottom: '1px solid rgba(231, 231, 231, 0.9);',
  backgroundColor: selected ? '#E7E7E7' : 'white',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 4),
  }
}))

const smallText = css`
  font-size: 11px;
  line-height: 13px;
`

const defaultTextColor = css`
  color: #555555;
`
const textBold = css`
  font-weight: 700;
  color: #262626;
`

const textRight = css`
  text-align: right;
`

const spacing = css`
  padding-bottom: 8px;
`

export default function ATMRowElement({ atm, selected, distanceText, durationText, onClick }) {
  const { fee_amt, loc_name, postal_code } = atm
  const fullAddress = atmFullAddress(atm)
  const isFreeAtm = fee_amt <= 0
  const feeText = isFreeAtm ? `Free ATM` : `Low Fee - $${fee_amt} ATM Fee`
  const distance = distanceText
  const movingTime = durationText

  const onClickHandler = () => {
    onClick && onClick(atm)
  }

  return (
    <ElementRoot flexDirection="column" container selected={selected} onClick={onClickHandler}>
      <Grid item container alignContent='space-between' alignItems='baseline'>
        <Grid item xs={10}>
          <Typography variant='h6' css={[textBold]}>{loc_name}</Typography>
        </Grid>
        <Grid item xs={2} css={spacing}>
          <Typography variant='body1' css={[textBold, smallText, textRight]}>{movingTime}</Typography>
        </Grid>
      </Grid>
      <Grid item css={spacing}>
        <StyledAtmFee free={isFreeAtm}>{feeText}</StyledAtmFee>
      </Grid>
      <Grid item container justifyContent='space-between'>
        <Grid item xs={10}>
          <Typography variant='body1' css={defaultTextColor}>{fullAddress}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='body1' css={[smallText, textRight]}>{distance}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='body1' css={defaultTextColor} >{postal_code}</Typography>
      </Grid>
    </ElementRoot>
  )
}