import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { IconButton } from "../../../../common/components/core";
import {
  closeBlackIcon,
} from "../../../../constants/images";
import ViewCardTemplete from "./viewCardTemplete";
import useAuth from "../../../../hooks/useAuth";


export const ViewCard = (props) => {
  const {
    handleClose,
    card
  } = props;

  const { user_id, bibson } = useAuth()

  const initVirtualCardView = () => {
    let origin = window.location.origin;
    window?.PayfareMarqeta?.setGlobalCredentails({
      authToken: bibson,
      ting: card.ting,
      userId: user_id,
      apiUrl: origin,
    });
    window?.PayfareMarqeta?.loadAndRenderCard();
  };

  const appClasses = useAppStyles();

  return (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleClose()}
          >
            <img src={closeBlackIcon} alt="close icon" />
          </IconButton>
          <Typography variant="h1" className={appClasses.dialogText}>
            View Virtual Card
          </Typography>
        </div>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent className={appClasses.dialogContent}>
        <ViewCardTemplete onInit={initVirtualCardView} />
      </DialogContent>
    </Dialog>
  );
};
