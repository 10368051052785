import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Sidemenu } from '../../../../common/components/Sidemenu';
import { useStyles } from './style';
import { routes } from '../../../../constants/routes';
import { DashboardPage } from '../../../dashboard/containers/DashboardPage';
import { GoalsPage } from '../../../goals/containers/GoalsPage';
import { TransactionsPage } from '../../../transactions/containers/TransactionsPage';
import ATMFinderPage from '../../../atmFinder/container';
import { ManageCardPage } from '../../../manageCard/containers/ManageCardPage';
import { DepositPage } from '../../../deposit/containers/DepositPage';
import { RewardsPage } from '../../../rewards/containers/RewardsPage';
import { MoveMoneyPage } from '../../../moveMoney/containers/MoveMoneyPage';
import { Statementpage } from '../../../statements/containers/StatementPage';
import { ProfileHomePage } from '../../../profile/containers/ProfileHomePage';
import { SettingHomePage } from '../../../settings/containers/SettingHomePage';
import { HelpSectionPage } from '../../../helpSection/containers/HelpSectionPage';
import { DoshOfferPage } from '../../../rewards/containers/DoshOfferPage';
import { SessionExpiredPopup } from '../../../auth/components/SessionExpiredPopup';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as contentfulActions from '../../../contentful/actions';
import { openContentFulDocOnRes } from '../../../../utilities';
import FullLayout from '../../../../common/layouts/FullLayout';

export const Home = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const dispatch = useDispatch();
  const contentfulAction = bindActionCreators(contentfulActions, dispatch);

  const { handleLogout, isSessionTimeOut } = props;

  const getTnCUrl = () => {
    contentfulAction.doGetAndSetContent(
      contentfulActions.contentfulActionTypes.registrationTnC,
      (res) => {
        openContentFulDocOnRes(res);
      }
    );
  };

  const getCardHolderAgreement = () => {
    contentfulAction.doGetAndSetContent(
      contentfulActions.contentfulActionTypes.legal,
      (res) => {
        openContentFulDocOnRes(res);
      }
    );
  };

  const getPrivacyPolicy = () => {
    contentfulAction.doGetAndSetContent(
      contentfulActions.contentfulActionTypes.privacyPolicy,
      (res) => {
        openContentFulDocOnRes(res);
      }
    );
  };

  return (
    <FullLayout sideMenuContent={
      <Sidemenu {...{ handleDrawerToggle, mobileOpen, handleLogout, getTnCUrl, getCardHolderAgreement, getPrivacyPolicy }} />
    }>
      {/* <AppHeader
        {...{ authData, handleDrawerToggle, handleLogout, handleChangePassword, mobileOpen }}
      /> */}
      {/* <Sidemenu {...{ handleDrawerToggle, mobileOpen, handleLogout, getTnCUrl, getCardHolderAgreement, getPrivacyPolicy }} /> */}

      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        <Switch>
          <Route path={routes.dashboard.path} exact>
            <DashboardPage />
          </Route>
          <Route path={routes.goals.path} exact>
            <GoalsPage />
          </Route>
          <Route path={routes.transactions.path} exact>
            <TransactionsPage />
          </Route>
          <Route path={routes.atmfinder.path} exact>
            <ATMFinderPage />
          </Route>
          <Route path={routes.manageCard.path} exact>
            <ManageCardPage />
          </Route>
          <Route path={routes.deposit.path}>
            <DepositPage />
          </Route>
          <Route path={routes.rewards.path} exact>
            <RewardsPage />
          </Route>
          <Route path={routes.moveMoney.path} exact>
            <MoveMoneyPage />
          </Route>
          <Route path={routes.statements.path} exact>
            <Statementpage />
          </Route>
          <Route path={routes.profile.path} exact>
            <ProfileHomePage />
          </Route>
          <Route path={routes.offers.path} exact>
            <DoshOfferPage />
          </Route>
          <Route path={routes.setting.path} exact>
            <SettingHomePage />
          </Route>
          <Route path={routes.help.path} exact>
            <HelpSectionPage />
          </Route>
          <Route
            exact
            path={routes.home.path}
            render={() => {
              return <Redirect to={routes.dashboard.path} />;
            }}
          />
        </Switch>

        {/*Change Password*/}
        {/* {showChangePasswordDialog && (
          <ChangePasswordDialog
            handleClose={handleCloseChangePassword}
          ></ChangePasswordDialog>
        )} */}
        {isSessionTimeOut && (
          <SessionExpiredPopup handleLogout={handleLogout} />
        )}
      </main>
    </FullLayout>
  );
};
