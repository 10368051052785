import React from "react";
import { Checkbox } from "../core";
import { useStyles } from "./style";
import { FormControlLabel } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
export const AppCheckboxWithLabel = (props) => {
  const classes = useStyles();
  const { primary, label } = props;
  return (
    <FormControlLabel
      control={<Checkbox
              checkedIcon={<CheckCircleIcon style={{color: '#1AD6D6'}}/>}
              icon={<RadioButtonUncheckedOutlinedIcon/>}
              color={primary ? "primary" : ""} {...props} />}
      label={label}
      InputProps={{
        classes: {
          input: classes.textFieldInput,
        },
      }}
      InputLabelProps={{
        classes: {
          root: classes.textFieldLabel,
        },
      }}
      className={classes.textField}
    />
  );
};
