import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../actions";
import * as dashboardActions from "../../dashboard/actions";
import { Rewards } from "../components/Rewards";
import { ACTION_STATUS, DATE_FORMAT, routes } from "../../../constants";
import history from "../../../history";
import moment from "moment";
import { bindActionCreators } from "redux";

import * as transactionActions from "../actions";
import { DATE_PARSE_FORMAT } from "../../../constants";

export const RewardsPage = (props) => {
  const dispatch = useDispatch();

  const authReducer = useSelector((state) => state.authReducer);
  const { loginResponseData } = authReducer;
  const transactionAction = bindActionCreators(transactionActions, dispatch);

  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const rewardsReducer = useSelector((state) => state.rewardsReducer);

  const [dateChipLabel, setDateChipLabel] = React.useState();
  
  const [selectedTransaction, setSelectedTransaction] = useState(undefined);
  const [isOpenViewTransaction, setIsOpenViewTransaction] = useState(false);

  const handleCloseViewTransaction = () => {
    setIsOpenViewTransaction(false);
    setSelectedTransaction(undefined);
  };

  const onClickApply = (startDate, endDate) => {
    setDateChipLabel(`${moment(startDate).format(DATE_FORMAT)} - ${moment(endDate).format(DATE_FORMAT)}`);
    getTransactionData(startDate, endDate);
  }
  const removeDateChip = () => {
    setDateChipLabel();
    getData()
  }
  const handleViewTransaction = (tx) => {
    setIsOpenViewTransaction(true);
    setSelectedTransaction(tx);
  };

  const getAuthData = () => {
    if (!loginResponseData) {
      return;
    }
    return {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
    };
  };

  const getDoshJWTToken = () => {
    let payload = {
      bibson: loginResponseData.bibson,
      userTing: loginResponseData.user_ting,
    };

    dispatch(
      actions.doGetDoshJWTToken({ ...payload }, (res) => {
        const base64encode = btoa;
        const payloadString = JSON.stringify({
          jwt: res.token,
          callbackUrls: {
            example: window.origin + "/#/rewards",
          },
          contentOverrides: {
            example: "Rewards page",
          },
        });

        window.open(
          `https://poweredby.dosh.com/partners/payfare/setup/${base64encode(
            payloadString
          )}`
        );
      })
    );
  };

  const handleRedeem = () => {
    const authData = getAuthData();
    if (!authData) return;
    dispatch(actions.doRedeemRewards({ ...authData }));
  };

  useEffect(() => {
    if (rewardsReducer?.redeemStatus == ACTION_STATUS.SUCCESS) {
      getRewardCardData();
    }
  }, [rewardsReducer?.redeemStatus]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const authData = getAuthData();
    if (!authData) return;
    getRewardCardData();
    dispatch(actions.doGetAndSetRewardsTransactions({ ...authData }));
    dispatch(actions.doGetAndSetRewardsSummary({ ...authData }));
  };
  const getTransactionData = (startDate, endDate) => {
    const getTransactionPayload = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
      userCardTing: dashboardReducer.userCardTing,
      fromDate: startDate ? moment(startDate).format(DATE_PARSE_FORMAT) : moment().startOf('month').format(DATE_PARSE_FORMAT),
      toDate: endDate ? moment(endDate).format(DATE_PARSE_FORMAT) : moment().endOf('month').format(DATE_PARSE_FORMAT),
    };

    transactionAction.doGetAndSetRewardsTransactions({...getTransactionPayload});
  }

  const getRewardCardData = () => {
    const authData = getAuthData();
    if (!authData) return;
    dispatch(dashboardActions.doGetAndSetRewardBalance({ ...authData }));
  };

  const navigateToHelp = () => {
    history.push(routes.help.path);
  };

  const propsTobeForward = {
    ...dashboardReducer,
    ...rewardsReducer,

    selectedTransaction,
    isOpenViewTransaction,
    getDoshJWTToken,
    handleCloseViewTransaction,
    handleViewTransaction,
    handleRedeem,
    navigateToHelp,
    onClickApply,
    dateChipLabel,
    removeDateChip
  };

  return <Rewards {...propsTobeForward} />;
};
