import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  txContainer: {
    padding: "0px 70px",
    minHeight: "400px",
  },
  txDetCol: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
