import React from "react";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogTitle, Grid, Typography } from "@material-ui/core";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { PasswordField } from "../../../../common/components/PasswordField";

export const ChangePassword = (props) => {
  const {
    password,
    confirmPassword,
    errors,
    isSubmitted,
    step,

    handleChange,
    handleClose,
    handleSubmit,
  } = props;

  const appClasses = useAppStyles();
  const classes = useStyles();

  const submitBtnText = "Continue";

  const isDisabledSubmit = !password || !confirmPassword;

  const content = () => {
    return (
      <>
        <DialogTitle id="form-dialog-title">
          <div className={appClasses.dialogTitle}>
            <Typography variant="h1" className={appClasses.text3}>
              Change Password
            </Typography>
          </div>
          <div className={appClasses.dialogTitle}>
            <Typography variant="span" className={appClasses.text1}>
              Minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1
              number or symbol.
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent className={appClasses.dialogContent}>
          <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
            <PasswordField
              name={"password"}
              label="New Password"
              value={password}
              onChange={handleChange}
              errors={{message: errors?.password}}
            />
          </Grid>
          <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
            <PasswordField
              name={"confirmPassword"}
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleChange}
              errors={{message: errors?.confirmPassword}}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={`${appClasses.dialogFooter} ${appClasses.width100p}`}>
            <AppButton
              contained
              onClick={handleSubmit}
              className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
              disabled={isDisabledSubmit}
              primary
            >
              {submitBtnText}
            </AppButton>
          </div>
        </DialogActions>
      </>
    );
  };

  return <Grid>{content()}</Grid>;
};
