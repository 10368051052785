import { useContext } from "react";
import CredentialContext from "./CredentialContext";

export default function useCredentials() {
  const credentials = useContext(CredentialContext)

  if (!credentials) {
    throw new Error("useCredentials must be used within a CredentialsProvider");
  }
  return credentials;
}