import { Redirect } from "react-router-dom";
import { routes } from "../../../constants";
import useAuth from "../../../hooks/useAuth";
import { useEffect } from "react";
import { doLogout } from "../../../modules/auth/actions";
import { useDispatch } from "react-redux";

export const ProtectedRoute = ({ children }) => {
  const { bibson, inProgressingKyc, checkingKycStatus } = useAuth();
  const dispatch = useDispatch()
  useEffect( () => {
    if (bibson && inProgressingKyc){
      dispatch(doLogout())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inProgressingKyc, bibson])

  if (checkingKycStatus) return <></>
  if (!bibson || inProgressingKyc) {
    // user is not authenticated
    return <Redirect to={routes.login.path} />;
  }

  return children;
};