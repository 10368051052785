import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    borderRadius: "56px",
    "& img": {
      height: "100%",
      borderRadius: "56px",
    },
  },
  rightContent: {
    alignSelf: "center",
    margin: "auto",
    marginLeft: "2rem",
    padding: "20px"

  },
}));
