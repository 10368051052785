import * as yup from "yup";

const poRegex = /\bP(ost|ostal)?([ .]*O(ffice)?)?([ .]*Box)?\b/i;

const isNotPOboxAdrress = (value) => value && !poRegex.exec(value);

const AddressValidationSchema = yup.object({
  address_line1: yup.string().required('is required').when("isResidentialAddress", {
    is: true,
    then: (schema) => schema.test(
      "non-po-box-address",
      "Street address can not be a P.O. Box",
      isNotPOboxAdrress,
    )
  }),
  address_line2: yup.string().optional().nullable(),
  city: yup.string().required("is required"),
  region: yup.string().required("is required"),
  postal_code: yup
    .string()
    .max(5)
    .matches(/\d{5}/, "Invalid Postal code")
    .required("is required"),
});


export default AddressValidationSchema;
