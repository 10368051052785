import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Box,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Moment from "react-moment";
import { AppTextField } from "../../../../common/components/AppTextField";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import {
  closeBlackIcon,
  checkGreenIcon,
  loadingBlackIcon,
  errorRedIcon,
} from "../../../../constants/images";
import {
  currencySymbol,
  DATE_FORMAT,
  TRANSFER_MONEY_STEPS,
} from "../../../../constants";
import { Amount } from "../../../../common/components/Amount";


const nameNotEmptyOnly = (accounts) => {
  return accounts.filter((account) => account.name !== "");
}
export const TransferMoney = (props) => {
  const {
    fromAccounts,
    toAccounts,
    fromAccount,
    toAccount,
    amount,
    successResult,

    transferMoneyStep,
    errors,
    handleChange,
    handleClose,
    handleSubmit,

    noDialog,
  } = props;
  const appClasses = useAppStyles();
  const classes = useStyles();


  const content = () => {
    return (
      <>
        {transferMoneyStep &&
          (transferMoneyStep == TRANSFER_MONEY_STEPS.filling ||
            transferMoneyStep == TRANSFER_MONEY_STEPS.success && noDialog) && (
            <>
              <DialogTitle id="form-dialog-title">
                <div className={appClasses.dialogTitle}>
                  {!noDialog && (
                    <IconButton
                      className={appClasses.dialogCloseBtn}
                      onClick={() => handleClose()}
                    >
                      <img src={closeBlackIcon} alt="close"/>
                    </IconButton>
                  )}
                  <Typography variant="h1" className={appClasses.dialogText}>
                    {noDialog ? "Transfer Between Accounts" : "Transfer Money"}
                  </Typography>
                </div>
              </DialogTitle>
              {!noDialog && <Divider></Divider>}
              <DialogContent
                className={`${!noDialog ? appClasses.dialogContent : ""}`}
              >
                <Grid
                  className={appClasses.textFieldWrapper}
                  xs={12}
                  item={true}
                >
                  <AppTextField
                    name={"fromAccount"}
                    label="From"
                    select
                    value={fromAccount}
                    onChange={handleChange}
                    color={"secondary"}
                    errorText={errors?.fromAccount}
                    SelectProps={{
                      MenuProps: {
                       classes: {
                        'paper': classes.dropdownBackground
                       }
                      }
                    }}
                  >
                    {nameNotEmptyOnly(fromAccounts).map((account) => (
                      <MenuItem
                        className={appClasses.dropDownMI}
                        key={account.ting}
                        value={account.ting}
                      >
                        {account.name}
                      </MenuItem>
                    ))}
                  </AppTextField>
                </Grid>
                <Grid
                  className={appClasses.textFieldWrapper}
                  xs={12}
                  item={true}
                >
                  <AppTextField
                    name={"toAccount"}
                    label="To"
                    select
                    value={toAccount}
                    onChange={handleChange}
                    color={"secondary"}
                    errorText={errors?.toAccount}
                    SelectProps={{
                      MenuProps: {
                       classes: {
                        'paper': classes.dropdownBackground
                       }
                      }
                    }}
                  >
                    {nameNotEmptyOnly(toAccounts).map((account) => (
                      <MenuItem
                        className={appClasses.dropDownMI}
                        key={account.ting}
                        value={account.ting}
                      >
                        {account.name}
                      </MenuItem>
                    ))}
                  </AppTextField>
                </Grid>
                <Grid
                  className={appClasses.textFieldWrapper}
                  xs={12}
                  item={true}
                >
                  <AppTextField
                    id={"amount"}
                    name={"amount"}
                    type={"number"}
                    label="Amount"
                    value={amount}
                    onChange={handleChange}
                    errorText={errors?.amount}
                    InputProps={{
                      startAdornment: <>{currencySymbol}</>,
                    }}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid
                  className={`${
                    noDialog
                      ? appClasses.dialogFooter1
                      : appClasses.dialogFooter
                  }`}
                  xs={12}
                  item={true}
                >
                  <AppButton
                    contained
                    onClick={handleSubmit}
                    className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
                    primary
                    disabled={!fromAccount || !toAccount || !amount}
                  >
                    Transfer Now
                  </AppButton>
                </Grid>
              </DialogActions>
            </>
          )}
        {transferMoneyStep &&
          transferMoneyStep === TRANSFER_MONEY_STEPS.success &&
          successResult && (
            <>
              {noDialog ? <Dialog
                open={true}
                onClose={() => handleClose()}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth
                PaperProps={{ className: appClasses.paperCard }}
              >
                {" "}
                {<SuccessBlock></SuccessBlock>}
              </Dialog> : <SuccessBlock></SuccessBlock>}
            </>
          )}
        {transferMoneyStep &&
          transferMoneyStep === TRANSFER_MONEY_STEPS.process && (
            <>
              <DialogTitle id="form-dialog-title">
                {!noDialog && (
                  <div className={appClasses.dialogTitle}>
                    <IconButton
                      className={appClasses.dialogCloseBtn}
                      onClick={() => handleClose()}
                    >
                      <img src={closeBlackIcon} />
                    </IconButton>
                  </div>
                )}
              </DialogTitle>
              <div className={`${appClasses.centerHvWrapper}`}>
                <div className={`${classes.txContainer}`}>
                  <IconButton className={appClasses.dialogCloseBtn}>
                    <img src={loadingBlackIcon} />
                  </IconButton>
                  <Box mt={2}></Box>
                  <Typography variant="span" className={appClasses.text1}>
                    Processing...
                  </Typography>
                </div>
              </div>
            </>
          )}
        {transferMoneyStep &&
          transferMoneyStep == TRANSFER_MONEY_STEPS.error && (
            <>
              <DialogTitle id="form-dialog-title">
                {!noDialog && (
                  <div className={appClasses.dialogTitle}>
                    <IconButton
                      className={appClasses.dialogCloseBtn}
                      onClick={() => handleClose()}
                    >
                      <img src={closeBlackIcon} />
                    </IconButton>
                  </div>
                )}
              </DialogTitle>
              <div className={`${appClasses.centerHvWrapper}`}>
                <div className={`${classes.txContainer}`}>
                  <IconButton className={appClasses.dialogCloseBtn}>
                    <img src={errorRedIcon} />
                  </IconButton>
                  <Box mt={2}></Box>
                  <Typography variant="span" className={appClasses.text1}>
                    Your money transfer request could not be processed due to
                    insufficient funds
                  </Typography>
                </div>
              </div>
            </>
          )}
      </>
    );
  };

  const SuccessBlock = () => (
    <>
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton
            className={appClasses.dialogCloseBtn}
            onClick={() => handleClose(true)}
          >
            <img src={closeBlackIcon} alt="close" />
          </IconButton>
        </div>
      </DialogTitle>
      <div className={`${appClasses.centerHvWrapper}`}>
        <div className={`${classes.txContainer}`}>
          <IconButton className={appClasses.dialogCloseBtn}>
            <img src={checkGreenIcon} alt="success"/>
          </IconButton>
          <Box mt={2}></Box>
          <Typography variant="span" className={appClasses.text1}>
            Your money transfer request is in progress and should be completed
            shortly
          </Typography>
          <Box mt={2}></Box>
          <div className={`${classes.txTable}`}>
            <div className={classes.txDelCol}>
              <label>Amount</label>
              <span>
                <Amount removePlusSign removeSuccessColor>
                  {Math.abs(successResult.amount)}
                </Amount>
              </span>
            </div>
            <div className={classes.txDelCol}>
              <label>Date</label>
              <span>
                <Moment format={DATE_FORMAT}>{successResult.trans_date}</Moment>
              </span>
            </div>
            <div className={classes.txDelCol}>
              <label>Confirmation</label>
              <span className="confirmation-number" style={{lineBreak: 'auto'}}>{successResult.confirmation}</span>
            </div>
            <div className={classes.txDelCol}>
              <label>From</label>
              <span className={appClasses.ttCapital}>
                {successResult.fromAccountType
                  ? successResult.fromAccountType.toLowerCase()
                  : ""}
              </span>
            </div>
            <div className={classes.txDelCol}>
              <label>To</label>
              <span className={appClasses.ttCapital}>
                {successResult.toAccountType
                  ? successResult.toAccountType.toLowerCase()
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return noDialog ? (
    <Grid>{content()}</Grid>
  ) : (
    <Dialog
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      {content()}
    </Dialog>
  );
};
