import React from 'react'
import TextField from '@material-ui/core/TextField';
import { Box, styled } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search-icon.svg';
import { Autocomplete } from '@react-google-maps/api';
import { useAtmLocations } from '../../store/atmhooks';



const SearchWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flex: '1 1 100%',
  marginRight: 0,
  marginLeft: 0,
  padding: theme.spacing(0),
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  '& .place-autocomplete-cover': {
    width: '100%',
  },

  '& .MuiInputBase-input': {
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },

  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,
    width: 'auto',
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #9D9D9D',
  }
}))

const SearchIconCover = styled('div')(({ theme }) => ({
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  left: '1em',
}))

const SearchTextField = styled(TextField)(({ theme }) => ({
  borderColor: '#9D9D9D',
}))

const SearchField = ({ onChange }) => {
  const autoCompleteInstance = React.useRef(null)
  const { changeLocation } = useAtmLocations()

  const onLoadHandler = (autocomplete) => {
    autoCompleteInstance.current = autocomplete
  }

  const onPlaceChangeHandler = () => {
    if (autoCompleteInstance.current) {
      const selectedPlace = autoCompleteInstance.current.getPlace()
      const { geometry: { location } } = selectedPlace
      changeLocation({ lat: location.lat(), lng: location.lng() })
    }
  }

  return (
    <SearchWrapper>
      <Autocomplete data-testid="google-map-autocomplete-container"
        restrictions={{ country: "us" }}
        onLoad={onLoadHandler}
        onPlaceChanged={onPlaceChangeHandler}
        className='place-autocomplete-cover'>
        <SearchTextField
          variant="outlined"
          fullWidth
          InputProps={{
            type: 'search',
            placeholder: 'Search...' }}
        />
      </Autocomplete>
      <SearchIconCover>
        <SearchIcon />
      </SearchIconCover>
    </SearchWrapper>

  )
}

export default SearchField