import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  txDelCol: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "700",
    marginBottom: "22px",
    "& span": {
      color: theme.palette.other.black700,
      paddingLeft: "30px",
      textAlign: "right",
    },
    "& label": {
      color: theme.palette.other.black300New,
    },
  },
  txContainer: {
    padding: "0px 48px",
    textAlign: "center",
    minHeight: "400px",
  },
  txTable: {
    padding: "24px 48px",
  },

  dropdownBackground: {
    backgroundColor: '#EDF5F5',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px'
  }
}));
