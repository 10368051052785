import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReplaceCard } from "../components/ReplaceCard";
import * as dashboarActions from "../../dashboard/actions";
import { ReplaceCardSuccessDialog } from "../components/ReplaceCard/ReplaceCardSuccessDialog";
import { ReplaceCardErrorDialog } from "../components/ReplaceCard/ReplaceCardErrorDialog";

export const ReplaceCardPage = (props) => {
  const {
    closeAllSidemenu,
    onViewVirtualCard,
    changeHelpScreenName
  } = props;

  const dispatch = useDispatch();

  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = React.useState(false);
  const [isOpenErrorDialog, setIsOpenErrorDialog] = React.useState(false);
  const authReducer = useSelector((state) => state.authReducer);
  const manageCardReducer = useSelector((state) => state.managecardReducer);

  const { loginResponseData } = authReducer;

  const { currentActivatedCard } = manageCardReducer;
  const { ting: userCardTing } = currentActivatedCard

  const isVirtualCard = currentActivatedCard?.is_virtual;

  const onClickViewVirtualCard = () => {
    handleClose();
    onViewVirtualCard()
  };

  const onSuccessReplaceCard = (isTransactionCompleted) => {
    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
      card_ting: userCardTing,
    };
    dispatch(dashboarActions.doGetAndSetCardBalances({ ...data }));
    if (!isTransactionCompleted) {
      setIsOpenErrorDialog(true);
    } else {
      setIsOpenSuccessDialog(true);
    }
  }


  const handleClose = () => {
    setIsOpenErrorDialog(false);
    setIsOpenSuccessDialog(false);
    closeAllSidemenu();
  }

  const propsTobeForward = { isVirtualCard, closeAllSidemenu, onSuccessReplaceCard, handleClose, changeHelpScreenName };

  return <>
    <ReplaceCard {...propsTobeForward} />
    {isOpenSuccessDialog && <ReplaceCardSuccessDialog {...{ onClickViewVirtualCard, handleClose }} />}
    {isOpenErrorDialog && <ReplaceCardErrorDialog handleClose={handleClose} />}
  </>
};
