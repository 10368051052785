import { Drawer, styled, useMediaQuery, useTheme } from '@material-ui/core';
import { drawerWidth } from '../../../constants';
import useLayout from './layoutProvider/useLayout';

const NavigationMenuDrawer = styled(Drawer)(({ theme }) => ({
  border: '0',
}));
export default function SideNav({ DrawerContent }) {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const theme = useTheme();
  const { sidebarOpen, toggleSidebar } = useLayout();
  return (
    <NavigationMenuDrawer
      anchor="left"
      open={lgUp || sidebarOpen}
      variant={lgUp ? 'persistent' : 'temporary'}
      onClose={toggleSidebar}
      elevation={0}
      PaperProps={{
        elevation: 0,
        style: {
          width: drawerWidth,
          background: theme.palette.other.black,
          ...theme.appAnimations?.animationFadeIn,
        },
      }}
    >
      {DrawerContent}
    </NavigationMenuDrawer>
  );
}
