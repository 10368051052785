import {
  SET_IS_GOT_PURSES,
  SET_IS_GOT_PURSE_TRANSACTIONS,
  SET_PURSE,
  SET_PURSES,
  SET_PURSE_TRANSACTIONS,
  ADD_PURSE_TRANSACTIONS
} from "../../../redux/actionTypes";

const initState = {
  purses: [],
  purse: undefined,
  purseTransactions: [],
  isGotPurses: false,
  isGotPurseTransactions: false,
};

export default function purseReducer(state = initState, action) {
  switch (action.type) {
    case SET_IS_GOT_PURSES:
      return Object.assign({}, state, {
        isGotPurses: action.data,
      });
    case SET_IS_GOT_PURSE_TRANSACTIONS:
      return Object.assign({}, state, {
        isGotPurseTransactions: action.data,
      });
    case SET_PURSES:
      return Object.assign({}, state, {
        purses: action.data,
      });
    case SET_PURSE:
      return Object.assign({}, state, {
        purse: action.data,
      });
    case SET_PURSE_TRANSACTIONS:
      return Object.assign({}, state, {
        purseTransactions: action.data,
      });
    case ADD_PURSE_TRANSACTIONS:
      return { ...state, purseTransactions: [ action.data, ...state.purseTransactions]}
    default:
      return state;
  }
}
