import React from "react";
import { Grid, InputAdornment, MenuItem, Paper } from "@material-ui/core";
import { AppTextField } from "../../../../common/components/AppTextField";
import { App_X_DesktopDatePicker } from "../../../../common/components/App_X_DesktopDatePicker";
import { AppButton } from "../../../../common/components/AppButton";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";

import {
  appColors,
  DATE_PICKER_FORMAT,
} from "../../../../constants";
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

export const DateFilter = (props) => {
  const {
    errors,
    startDate,
    endDate,
    onClickApply,
    onHandleStartDateChange,
    onHandleEndDateChange,
    minDate,
    maxDate,
    disableFuture,
  } = props;

  const [fromDatePickerOpen, setFromDatePickerOpen] = React.useState(false);
  const [toDatePickerOpen, setToDatePickerOpen] = React.useState(false);
  const toRef = React.useRef();

  const appClasses = useAppStyles();
  const classes = useStyles();

  return (
    <div>
      <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
        <App_X_DesktopDatePicker
          label="From"
          inputFormat={DATE_PICKER_FORMAT}
          value={startDate}
          minDate={minDate}
          maxDate={endDate}
          disableFuture={disableFuture}
          onChange={onHandleStartDateChange}
          components={{
            OpenPickerIcon: CalendarTodayOutlinedIcon,
          }}
          onOpen={() => {
            setFromDatePickerOpen(true);
          }}
          onClose={() => {
            setFromDatePickerOpen(false);
          }}
          OpenPickerButtonProps={
            fromDatePickerOpen && { style: { color: appColors.primary900 } }
          }
          renderInput={(params) => (
            <AppTextField {...params} color={"secondary"} />
          )}
        />
      </Grid>
      <Grid className={appClasses.textFieldWrapper} xs={12} item={true}>
        <App_X_DesktopDatePicker
          label="To"
          inputFormat={DATE_PICKER_FORMAT}
          value={endDate || startDate}
          minDate={startDate}
          maxDate={maxDate}
          disableFuture={disableFuture}
          components={{
            OpenPickerIcon: CalendarTodayOutlinedIcon,
          }}
          onOpen={() => {
            setToDatePickerOpen(true);
          }}
          onClose={() => {
            setToDatePickerOpen(false);
          }}
          onChange={onHandleEndDateChange}
          OpenPickerButtonProps={
            toDatePickerOpen && { style: { color: appColors.primary900 } }
          }
          renderInput={(params) => (
            <AppTextField {...params} color="secondary" />
          )}
        />
      </Grid>
      {!!errors?.length && (
        <Grid className={appClasses.textFieldError} xs={12} item={true}>
          {errors.map((error) => (
            <div>{error}</div>
          ))}
        </Grid>
      )}
      <Grid
        className={`${appClasses.dialogFooter} ${classes.applyBtnWrapper}`}
        xs={12}
        item={true}
      >
        <AppButton
          contained
          onClick={onClickApply}
          className={`${appClasses.primaryBtn} ${classes.applyBtn}`}
          primary
          disabled={!startDate || !endDate}
        >
          Apply
        </AppButton>
      </Grid>
    </div>
  );
};
