import { Grid } from '@material-ui/core';
import { DashboardCard } from '../DashboardCard';
import { SectionTitle } from '../common';
import { heartIcon, starIcon } from '../../../../constants';

export default function AccountCard({
  handleTransactionsClick,
  handleRewardsClick,
  handleGoalsClick,
  cardBalances,
}) {
  const cardGridProps = {
    xs: 12,
    sm: 6,
    md: 4,
  };
  return (<>
    <SectionTitle>Account</SectionTitle>
    <Grid container spacing={2} alignItems="stretch">
      <Grid {...cardGridProps} item onClick={handleTransactionsClick}>
        <DashboardCard
          amount={cardBalances?.card}
          title={'Account Balance'}
        />
      </Grid>
      <Grid {...cardGridProps} item onClick={handleRewardsClick}>
        <DashboardCard
          amount={cardBalances?.rewards}
          title={'Rewards Balance'}
          white
          icon={starIcon}
        />
      </Grid>
      <Grid {...cardGridProps} item onClick={handleGoalsClick}>
        <DashboardCard
          amount={cardBalances?.savings}
          title={'Goals Account'}
          white
          icon={heartIcon}
        />
      </Grid>
    </Grid>
  </>
  );
}
