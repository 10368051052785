import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Link, styled, Typography } from '@material-ui/core'

import React from 'react'
import { useForm } from 'react-hook-form'
import { useAppStyles } from '../../../../App.style'
import { registration } from '../../services'
import { SectionSubtitle, SectionTitle } from './AtomComponents'
import RegistrationQuestionSelectBox from './QuestionSelectBox'
import RegistrationPasswordField from './RegistartionPasswordField'
import RegistrationCheckBox from './RegistrationCheckbox'
import RegistrationDataSchema from './RegistrationDataSchema'
import RegistrationTextField from './RegistrationTextField'

const FieldCover = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  '& .field-helper': {
    padding: theme.spacing(1, 0, 0, 2),
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px'
  }
}))

const OnAgreeTNCAndCardHolderAgreementText = ({ onTnCClick, onCardHolderAgreementClick }) => {
  const clickToOpenDoc = (openDocFn) => (event) => {
    event.preventDefault()
    openDocFn()
    return false
  }
  return <>
    <Typography variant='body1' component='span'>I agree to the </Typography>
    <Link href='#' onClick={clickToOpenDoc(onTnCClick)} color="secondary" underline="always">Terms & Conditions</Link>
    <Typography variant='body1' component='span'> and </Typography>
    <Link href='#' onClick={clickToOpenDoc(onCardHolderAgreementClick)} color="secondary" underline="always">Cardholder Agreement.</Link>
  </>
}

export default function RegistrationForm({ paidappUser, securityQuestions, onboardToken, onRegistrationSuccess, openCardHolderAgreement, openTnC }) {
  const appClasses = useAppStyles();

  const { control, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    values: paidappUser,
    resolver: yupResolver(RegistrationDataSchema)
  })

  const { errors, isSubmitting, isValid, isValidating } = formState

  const isDisabled = isSubmitting || isValidating || !isValid

  const onSubmit = async (data) => {
    const { first_name, last_name, phone, password, email, security_questions } = data
    const payload = {
      first_name, last_name, phone, email, username: email, password, security_questions, onboard_token: onboardToken
    }
    const res = await registration(payload)
    const status = res?.data?.status
    if (status === 'success') {
      onRegistrationSuccess(res, payload)
    }
  }

  return <>
    <FieldCover>
      <RegistrationTextField control={control} name="first_name" label="Legal First Name" errors={errors?.first_name} disabled />
    </FieldCover>
    <FieldCover>
      <RegistrationTextField control={control} name="last_name" label="Legal Last Name" errors={errors.last_name} disabled />
    </FieldCover>
    <FieldCover>
      <RegistrationTextField control={control} name="email" label="Email" errors={errors.email} disabled />
    </FieldCover>
    <FieldCover>
      <RegistrationTextField control={control} name="phone" label="Phone Number" errors={errors.phone} />
      <Typography variant='body2' className='field-helper'>
        Example: +1 888-888-9999
      </Typography>
    </FieldCover>
    <FieldCover>
      <RegistrationPasswordField control={control} name="password" label="Password" errors={errors.password} />
      <div className='field-helper'>
        <Typography variant='body2'>
          Your password must include:
        </Typography>
        <ul>
          <li>minimum of 8 characters</li>
          <li>1 number or symbol</li>
          <li>1 upper and 1 lower case letter</li>
        </ul>
      </div>
    </FieldCover>
    <FieldCover>
      <RegistrationPasswordField control={control} name="password_confirmation" label="Confirm Password" errors={errors.password_confirmation} />
    </FieldCover>

    <Box data-testid="security-question-section" sx={{ maxWidth: '450px' }}>
      <SectionTitle>Security Info</SectionTitle>
      <SectionSubtitle>
        To help ensure the security of your account, choose three
        questions and provide your answers below.
      </SectionSubtitle>
      {
        new Array(3).fill(0).map((_, index) => {
          const selectedQuestions = new Array(3).fill(0).map( (_, i) => index !== i && getValues(`security_questions.${i}.security_question`)).filter(Boolean)
          const filteredQuestions = securityQuestions.filter( q => !selectedQuestions.includes(q.security_question))
          return <div key={index}>
            <FieldCover>
              <RegistrationQuestionSelectBox control={control} name={`security_questions[${index}].security_question`} label={`Security Question ${index + 1}`} errors={errors?.security_questions?.[index]?.security_question} questions={filteredQuestions} />
            </FieldCover>
            <FieldCover>
              <RegistrationTextField control={control} name={`security_questions[${index}].security_answer`} label='Answer' errors={errors?.security_questions?.[index]?.security_answer} />
            </FieldCover>
          </div>
        })
      }
    </Box>
    <FieldCover>
      <RegistrationCheckBox
        name="agreeTNC"
        label={<OnAgreeTNCAndCardHolderAgreementText onCardHolderAgreementClick={openCardHolderAgreement} onTnCClick={openTnC} />}
        errors={errors.agreeTNC}
        control={control}
      />
    </FieldCover>
    <Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit(onSubmit)}
        disabled={isDisabled}
        className={`${appClasses.primaryButton} ${appClasses.registrationButton}`}
      >
        Continue
      </Button>
    </Box>

  </>
}