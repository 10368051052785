import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../actions";
import * as toastActions from "../../../common/actions/toast";
import { TOAST_TYPES } from "../../../common/components/core";
import { emailRegex, FORGOT_PASSWORD_STEPS, passwordRegex, routes } from "../../../constants";
import { ForgotPassword } from "../components/ForgotPassword";
import history from "../../../history";

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccountLocked: false,
      screenSteps: undefined,
      forgotPasswordEmail: "",
      filledOtp: "",
      lockoutTime: 10,
      validateOTPForgotPasswordResponse: undefined,
      validateSecurityQuestionsResponse: undefined,
      securityQuestions: [],
      selectedSecurityQuestion: null,
      securityAnswer: "",
      newPassword: "",
      confirmPassword: "",
      openResendOTPDialog: false,
      errors: {
        forgotPasswordEmail: "",
        filledOtp: "",
        selectedSecurityQuestion: "",
        securityAnswer: "",
        newPassword: "",
        confirmPassword: "",
      },
      isSubmitted: null,
    };
  }

  componentDidMount() {
    // this.props.authActions.doLogout();
    this.goToStep(FORGOT_PASSWORD_STEPS.email);
  }

  handleClose = () => {
    this.setState({
      isAccountLocked: false
    }, () => {
      history.push(routes.login.path);
    })
  }

  goToStep = (step) => {
    const { validateOTPForgotPasswordResponse } = this.state;
    this.setState({ screenSteps: { [step]: true }, isSubmitted: false }, () => {
      if (step == FORGOT_PASSWORD_STEPS.questions) {
        if (
          validateOTPForgotPasswordResponse &&
          validateOTPForgotPasswordResponse.user_id
        ) {
          this.props.authActions.doGetAndSetSecurityQuestions(
            { ...validateOTPForgotPasswordResponse },
            (result) => {
              if (result.success) {
                this.setState({ securityQuestions: result?.data || [] });
              }
            }
          );
        }
      }
    });
  };

  handleChange = (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleChangeOtp = (result) => {
    const { isSuccess, finalOtp } = result;
    this.setState({ filledOtp: isSuccess ? finalOtp : "" }, () =>
      this.isValid(true)
    );
  };

  handleBack = (event) => {
    history.push(routes.login.path);
  };

  resendOTP = async () => {
    const {
      forgotPasswordEmail,
    } = this.state;

    return this.props.authActions.doSendOTPForgotPassword(
      {
        email: forgotPasswordEmail,
      },
      (result) => {
        if (result?.locked) {
          this.setState({
            isAccountLocked: true,
            lockoutTime: result?.lockoutTime,
          })
        }
      }
    );
  }

  resendEmailOTP = async () => {
    const result = await this.resendOTP()
    if (result === 'success') {
      this.setState({ openResendOTPDialog: false })
      this.props.showToast('Check your email', TOAST_TYPES.SUCCESS)
    }
  }


  handleResendOTPDialog = (doResendOTP) => {
    this.setState({
      openResendOTPDialog: !this.state.openResendOTPDialog
    }, () => {
      doResendOTP && this.resendOTP();
    })
  }

  validateOTP = async () => {
    const { filledOtp, forgotPasswordEmail } = this.state;
    if (!filledOtp) {
      return this.setState({ errors: { filledOtp: 'Require' } })
    }
    try {
      await this.props.authActions.doValidateOTPForgotPassword(
        {
          twofac: filledOtp,
          email: forgotPasswordEmail,
        },
        (result) => {
          if (result?.success) {
            this.setState({
              validateOTPForgotPasswordResponse: result?.data,
            });
            this.goToStep(FORGOT_PASSWORD_STEPS.questions);
          }
          else {
            if (result?.locked) {
              this.setState({
                isAccountLocked: true
              })
            }
          }
        }
      )
    } catch (e) {
      return this.setState({ errors: { filledOtp: 'The code you\'ve entered is incorrect. Please try again.' } })
    }
  }

  // TODO: refactor all validation to use react-hook-form
  handleSubmit = () => {
    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const {
          forgotPasswordEmail,
          filledOtp,
          screenSteps,
          selectedSecurityQuestion,
          securityAnswer,
          validateOTPForgotPasswordResponse,
          validateSecurityQuestionsResponse,
          newPassword,
        } = this.state;
        if (screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.email]) {
          this.props.authActions.doSendOTPForgotPassword(
            {
              email: forgotPasswordEmail,
            },
            (result) => {
              if (result?.success) {
                this.goToStep(FORGOT_PASSWORD_STEPS.otp);
              }
              else {
                if (result?.locked) {
                  this.setState({
                    isAccountLocked: true
                  })
                }
              }
            }
          );
        }

        if (screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.otp]) {
          this.props.authActions.doValidateOTPForgotPassword(
            {
              twofac: filledOtp,
              email: forgotPasswordEmail,
            },
            (result) => {
              if (result?.success) {
                this.setState({
                  validateOTPForgotPasswordResponse: result?.data,
                });
                this.goToStep(FORGOT_PASSWORD_STEPS.questions);
              }
              else {
                if (result?.locked) {
                  this.setState({
                    isAccountLocked: true
                  })
                }
              }
            }
          );
        }

        if (
          screenSteps &&
          screenSteps[FORGOT_PASSWORD_STEPS.questions] &&
          validateOTPForgotPasswordResponse
        ) {
          this.props.authActions.doValidateSecurityQuestions(
            {
              ...validateOTPForgotPasswordResponse,
              security_question: selectedSecurityQuestion?.security_question,
              security_answer: securityAnswer,
            },
            (result) => {
              if (result?.success) {
                this.setState({
                  validateSecurityQuestionsResponse: result?.data,
                });
                this.goToStep(FORGOT_PASSWORD_STEPS.newPassword);
              }
              else {
                if (result?.locked) {
                  this.setState({
                    isAccountLocked: true
                  })
                }else{
                  this.setState({
                    errors: {
                      securityAnswer: 'The answer you\'ve entered is incorrect. Please try again.'
                    }
                  })
                }
              }
            }
          );
        }

        if (
          screenSteps &&
          screenSteps[FORGOT_PASSWORD_STEPS.newPassword] &&
          validateSecurityQuestionsResponse
        ) {
          this.props.authActions.doCreateNewPassword(
            {
              ...validateSecurityQuestionsResponse,
              new_password: newPassword,
            },
            (result) => { }
          );
        }
      }
    });
  };

  isValid = (forceIsSubmittedTrue) => {
    const {
      forgotPasswordEmail,
      isSubmitted,
      screenSteps,
      filledOtp,
      selectedSecurityQuestion,
      securityAnswer,
      newPassword,
      confirmPassword,
    } = this.state;
    const errors = {};
    let valid = true;

    if (!forceIsSubmittedTrue && !isSubmitted) {
      return valid;
    }

    if (screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.email]) {
      if (!forgotPasswordEmail) {
        errors.forgotPasswordEmail = "Require";
      }

      if (forgotPasswordEmail && !forgotPasswordEmail.match(emailRegex)) {
        errors.forgotPasswordEmail = "Invalid";
      }
    }

    if (screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.otp]) {
      if (!filledOtp) {
        errors.filledOtp = "Require";
      }
    }

    if (screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.questions]) {
      if (!selectedSecurityQuestion) {
        errors.selectedSecurityQuestion = "Require";
      }
      if (!securityAnswer) {
        errors.securityAnswer = "Require";
      }
    }

    if (screenSteps && screenSteps[FORGOT_PASSWORD_STEPS.newPassword]) {
      if (!newPassword) {
        errors.newPassword = "Require";
      }
      if (newPassword.length < 8) {
        errors.newPassword = "Minimum 8 characters require";
      }
      if (!newPassword.match(passwordRegex)) {
        errors.newPassword = "Password does not match criteria";
      }
      if (!confirmPassword) {
        errors.confirmPassword = "Require";
      }
      if (newPassword && confirmPassword && newPassword != confirmPassword) {
        errors.confirmPassword =
          "New password and confirm password must be same";
      }
    }

    this.setState({ errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  render() {
    const { handleChange, handleSubmit, handleChangeOtp, handleBack, handleClose, resendOTP, handleResendOTPDialog } = this;

    const props = {
      authData: this.props.authData,
      ...this.state,
      handleChangeOtp,
      handleChange,
      handleSubmit,
      handleBack,
      handleClose,
      resendOTP,
      handleResendOTPDialog,
      validateOTP: this.validateOTP,
      resendEmailOTP: this.resendEmailOTP,
    };
    return <ForgotPassword {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    authData: state.authReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
    showToast: bindActionCreators(toastActions.show, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
