import {
  LOADING_CARDS_FAILED,
  SET_SHIPPING_DETAILS,
  SET_SUGGESTED_ADDRESS,
  ACTIVATE_CARD_ERROR_DETAILS,
  CLOSE_ACTIVATE_CARD_ERROR_POPUP,
  SET_USER_CARDS,
  ACTIVATE_CARD_SHOW_CONGRATULATION_POPUP
} from "../../../redux/actionTypes";

import { apiCall } from "../../../common/actions/apiCall";
import {
  activateCardByDetails,
  getSetPinCardURL,
  getViewCardURL,
  getShippingAddress,
  getSuggestedAddress,
  setUserShippingAddress,
  setCardStatus,
  reissueCard,
} from "../services";
import { getPrimaryCard } from "../../dashboard/services";
import { appErrors } from "../../../constants/errors";
import { TOAST_TYPES } from "../../../common/components/core";
import * as toastActions from "../../../common/actions/toast";

export const setShippingAddress = (response) => {
  return {
    type: SET_SHIPPING_DETAILS,
    data: response,
  };
};

export const setSuggestedAddress = (response) => {
  return {
    type: SET_SUGGESTED_ADDRESS,
    data: response,
  };
};

export const setActivateCardCongratulationPopup = (isShow) => {
  return {
    type: ACTIVATE_CARD_SHOW_CONGRATULATION_POPUP,
    data: isShow,
  };
}


export const loadCurrentUserCards = ({ user_id, bibson }) => async (dispatch) => {
  try {
    const { data } = await getPrimaryCard({ user_id, bibson });
    if (data?.status === "success") {
      return dispatch({
        type: SET_USER_CARDS,
        data: data?.data
      })
    }
  } catch (e) {
    console.log("error", e);
    return dispatch({
      type: LOADING_CARDS_FAILED,
      data: e.message,
    });
  }
}

export const doGetAndSetshippingAddress = (data) => async (dispatch) => {
  const response = await getShippingAddress(data);
  if (response?.data?.status === "success") {
    const { profile } = response?.data?.data;
    dispatch(setShippingAddress(profile));
  } else {
    dispatch(setShippingAddress(undefined));
  }
};


// Check if typed address is same as suggested address
const areAddressesEqual = (address, suggestedAddress) => {
  const compareAddressKeys = ["address_line1", "city", "region", "postal_code"];
  return compareAddressKeys.every((key) => {
    const suggestedValue = suggestedAddress[key] || '';
    const addressValue = address[key] || '';
    return suggestedValue.trim().toUpperCase() === addressValue.trim().toUpperCase()
  });
}

/*
* Check and give a suggested address
* @parmam data: data to be sent to api including user id, auto token, etc
* @param payload: address payload to be sent to api
* @return suggested address if there is any, otherwise undefined
*/
const checkAndGiveSuggestedAddress = async (data, payload) => {
  const suggestedAddressResponse = await getSuggestedAddress(data, payload);
  const { suggested: suggestedAddress } = suggestedAddressResponse?.data?.data;
  if (!suggestedAddress || areAddressesEqual(payload, suggestedAddress)) {
    return undefined
  }
  return suggestedAddress;
}

export const validateAddress = (data, payload, callBackFn) => async (dispatch) => {
  try {
    const suggestedAddress = await checkAndGiveSuggestedAddress(data, payload);
    dispatch(setSuggestedAddress(suggestedAddress));
    // if there no suggested address, then call callback function (go to next step)
    if (!suggestedAddress) {
      return callBackFn()
    }
  } catch (error) {
    dispatch(setSuggestedAddress(undefined));
    dispatch(toastActions.show(appErrors.somethingWentWrong, TOAST_TYPES.ERROR));
  }
}

export const closeSuggestedAddressDialog = () => ({
  type: SET_SUGGESTED_ADDRESS,
  data: undefined,
})


export const doGetAndSetSuggestedAddress =
  (data, payload, callBackFn) => (dispatch) => {
    dispatch(setSuggestedAddress(undefined));
    apiCall(dispatch, getSuggestedAddress(data, payload), {
      showLoader: true,
      displayError: true,
    }).then(
      (response) => {
        if (response?.data?.status === "success") {
          const { suggested } = response?.data?.data;
          dispatch(setSuggestedAddress(suggested));
          callBackFn && callBackFn();
        }
      },
      (error) => {
        dispatch(setSuggestedAddress(undefined));
        callBackFn && callBackFn();
      }
    );
  };

export const doGetAndSetReplaceCard =
  (data, userData, cardPayload, callBackFn) => async (dispatch) => {
    apiCall(dispatch, setUserShippingAddress(data, userData), {
      showLoader: true,
      displayError: true,
    }).then(
      (response) => {
        if (response?.data?.status == "success") {
          apiCall(
            dispatch,
            setCardStatus(
              data,
              userData,
              cardPayload,
              response?.data?.data?.profile?.status
            ),
            {}
          ).then(
            (response) => {
              if (response?.data?.status == "success") {
                apiCall(dispatch, reissueCard(data, userData), {
                  showLoader: true,
                  displayError: true,
                }).then(
                  (response) => {
                    if (response?.data?.status == "success") {
                      dispatch(loadCurrentUserCards(data));
                      callBackFn && callBackFn(true);
                    }
                  },
                  (error) => {
                    callBackFn && callBackFn(false);
                  }
                );
              }
            },
            (error) => {
              callBackFn && callBackFn(false);
            }
          );
        }
      },
      (error) => {
        dispatch(setSuggestedAddress(undefined));
        callBackFn && callBackFn(false);
      }
    );
  };

export const doActiveDeactiveCard =
  (data, userData, cardPayload, callBackFn) => async (dispatch) => {
    //Here we have to logout user after3 attempts.
    //we are passing true for not logout user on 401 status code
    try {
      const result = await apiCall(dispatch, setCardStatus(data, userData, cardPayload), {
        showLoader: true,
        displayError: true,
      }, true)
      const isSuccess = result?.data?.status === "success"
      callBackFn && callBackFn(isSuccess);
      if (isSuccess) {
        dispatch(loadCurrentUserCards(data));
      }
      return result

    } catch (e) {
      console.info("doActiveDeactiveCard error", e)
      callBackFn && callBackFn(false);
    }
  };

export const getSetPinUrlAndOpenPopup = (data, callBack) => (dispatch) => {
  apiCall(dispatch, getSetPinCardURL(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      //let setPinwindow = window.open(response?.data?.data?.url,"set_pin_window","height=500,width=520")
      //window.popUpWindowObjectCopy = setPinwindow;
      let url = new URL(response?.data?.data?.url);
      let otc = url.searchParams.get("otc");
      callBack && callBack(otc);
    },
    (error) => {
      return null;
    }
  );
};

export const getViewCardURLAndOpenModel = (data, callBack) => (dispatch) => {
  apiCall(dispatch, getViewCardURL(data), {
    showLoader: true,
    displayError: true,
  }).then(
    (response) => {
      window.open(
        response?.data?.data?.url,
        "view_card_window",
        "height=370,width=280"
      );
      callBack && callBack();
    },
    (error) => {
      return null;
    }
  );
};



const setCardActivationError = function (response) {
  const errors = response?.data?.errors;
  // pick the first error and show it
  const errorDetail = errors[0];
  return {
    type: ACTIVATE_CARD_ERROR_DETAILS,
    data: errorDetail
  }
}


export const doActivateCardByDetails = (data) => async (dispatch) => {
  try{
    const response = await  apiCall(dispatch, activateCardByDetails(data), {
      showLoader: true,
      displayError: false,
    })
    if (response?.data?.status != "success") {
      dispatch(setCardActivationError(response))
    }
  }catch(error){
    if (error?.response){
      dispatch(setCardActivationError(error.response));
    }
    throw error;
  }
};

export const closeActiveCardErrorPopup = () => {
  return ({
    type: CLOSE_ACTIVATE_CARD_ERROR_POPUP,
  })
}