import { Box, Paper, Typography, styled } from "@material-ui/core";
import React from "react";
import { useAppListStyles } from "../../../../App.style";
import { useStyles } from "./style";


const CardWrapper = styled(Paper)(({ theme, white }) => ({
  padding: theme.spacing(4, 2),
  backgroundColor: white ? '#FFFFFF' : '#018787',
  boxShadow: white ? '1px 10px 10px rgba(0, 0, 0, 0.1)' : '1px 10px 10px rgba(0, 0, 0, 0.1)',
  color: white ? '#262626' : '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.spacing(2),
  height: '100%',
}));

const StyledAmount = styled(Typography)(({ theme, white }) => ({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '29px',
  color: white ? '#262626' : '#FFFFFF',
  textAlign: 'center',
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.up('lg')]: {
    fontSize: '32px',
  }
}));

const TitleWrapepr = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  spacing: theme.spacing(1),
  minHeight: '30px'
}));

const StyledTitle = styled(Typography)(({ theme, white }) => ({
  fontWeight: 400,
  fontSize: '18px',
  textAlign: 'center',
  color: white ? '#262626' : '#FFFFFF',
  whiteSpace: 'nowrap',
}));

const IntlNumberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD',  maximumFractionDigits: 2, })
const formatCurrency = (amount) => {
  return IntlNumberFormat.format(amount);
}

export const DashboardCard = (props) => {
  const classes = useStyles();
  const { title, amount, white, icon } = props;
  return (

    <CardWrapper white={white}>
      <StyledAmount variant="body1" white={white}>
        {formatCurrency(amount)}
      </StyledAmount>
      <TitleWrapepr>
        {icon && <img src={icon} className={classes.iconImg} alt="account icon" />}
        <StyledTitle white={white}>{title}</StyledTitle>
      </TitleWrapepr>
    </CardWrapper>

  );
};
