import { AppBar, Box, IconButton, Toolbar, styled } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as ArtistGrowthLogo } from '../../../assets/images/artistgrowth-wallet-logo.svg';
import useLayout from './layoutProvider/useLayout';

const StyleAppBar = styled(AppBar)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const ToolBarSpace = styled(Box)(({ theme }) => ({
  height: theme.mixins.toolbar.minHeight,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const ArtistGrowthLogoWrapper = styled(Box)(({ theme }) => ({
  flex: 'none',
  margin: '0 auto',
}));

const HamburgerIcon = ({ onClick }) => {
  const onClickHandler = () => {
    onClick && onClick();
  };
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={onClickHandler}
      size="large"
      sx={{
        display: {
          lg: 'flex',
          xs: 'none',
        },
      }}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default function Header() {
  const { toggleSidebar } = useLayout();
  return (
    <>
      <StyleAppBar position="fixed" elevation={0} color="transparent">
        <Toolbar disableGutters={false}>
          <HamburgerIcon onClick={toggleSidebar} />
          <ArtistGrowthLogoWrapper>
            <ArtistGrowthLogo />
          </ArtistGrowthLogoWrapper>
        </Toolbar>
      </StyleAppBar>
      <ToolBarSpace />
    </>
  );
}
