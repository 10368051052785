
export function truncateContentfulDocument(contentfulDocument, limitCharacters) {
  const { content } = contentfulDocument
  if (limitCharacters < 0) {
    return [-1, undefined]
  }
  if (!content) {
    const { value: contentValue } = contentfulDocument
    let value = contentValue.slice(0, limitCharacters)
    value = contentValue.length > limitCharacters ? `${value} ...` : value
    return [limitCharacters - value.length, { ...contentfulDocument, value }]
  }

  let totalCharacters = limitCharacters
  const contents = []
  for (let i = 0; i < content.length; i++) {
    const [remainingChar, contentData] = truncateContentfulDocument(content[i], totalCharacters)
    totalCharacters = remainingChar
    if (contentData) {
      contents[i] = contentData
    }
  }

  return [totalCharacters, { ...contentfulDocument, content: contents }]
}