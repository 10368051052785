import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TRANSFER_MONEY_STEPS } from "../../../constants";
import * as actions from "../actions";
import { SendToRecipient } from "../components/SendToRecipient";

const initialState = {
  amount: "",
  optionalMessage: "",
  recipientId: "",
  errors: {},
  isSubmitted: null,
  transferMoneyStep: TRANSFER_MONEY_STEPS.filling,

  isAddRecipientDialogOpen: false,
  isTransactionSuccessDialogOpen: false,
  confirmation: undefined,
  ting: undefined
};

class SendToRecipientPage extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const { bibson, user_id } = this.props.state?.authReducer?.loginResponseData;
    let payload = {
      user_id,
      bibson
    }

    const newState = { ...initialState };

    this.setState({ ...newState });

    //Get recipients on page load
    this.props.actions.doGetAndSetRecipients(payload);
    this.props.actions.doGetAndSetLimits(payload);
  }

  goToStep = (step) => {
    this.setState({ transferMoneyStep: step }, () => { });
  };

  validateAmount(limit24Hours, amount) {  
    if(!limit24Hours) return ""
    if (amount > limit24Hours.available) {
      return `Available to send is $${limit24Hours.available}`;
    }
    if (amount > limit24Hours.limit_amount) {
      return "Exceeded maximum transaction amount";
    }
    return ""
  }

  isValid = () => {
    const { amount, isSubmitted } = this.state;
    const limitData  = this.props.limitSendingAmount
    const DailyPeriod = 24 * 60 * 60
    const limit24Hours  =  limitData.find(element => element.limit_period === DailyPeriod);
    const errors = {};
    let valid = true;

    if (!isSubmitted) {
      return valid;
    }

    if (!amount) {
      errors.amount = "Require";
    }
    if (amount) {
        errors.amount = this.validateAmount(limit24Hours, amount);  
      if (isNaN(amount)) {
        errors.amount = "Invalid";
      } else if (amount <= 0) {
        errors.amount = "Must be greater than zero";
      }
    }

    this.setState({ errors, errors });
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));

    return valid;
  };


  handleSuccessDialog = (data) => {
    //const {getRecipientByTing} = this;
    let { confirmation, ting } = data?.["ach-payment"];
    this.setState({
      confirmation,
      ting,
      //selectedRecipients: getRecipientByTing(this.state.recipientId),
      isTransactionSuccessDialogOpen: true
    })
  }

  handleSuccessDialogClose = () => {
    const { handleCloseSendToRecipient } = this.props;
    this.setState({
      isTransactionSuccessDialogOpen: false
    }, () => {
      handleCloseSendToRecipient();
    })
  }

  onAddEditRecipientHandle = () => {
    this.setState({
      isAddRecipientDialogOpen: true
    })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.isValid());
  };

  handleSubmit = () => {
    this.setState({ isSubmitted: true }, () => {
      if (this.isValid()) {
        const { handleSuccessDialog } = this;
        let { recipientId, amount, optionalMessage } = this.state;
        const { bibson, user_id } = this.props.state?.authReducer?.loginResponseData;

        amount = parseFloat(amount);
        let data = {
          bibson,
          user_id,
          achTing: recipientId
        }

        let payload = {
          amount,
          message: optionalMessage
        }

        this.props.actions.doSendTransaction(data, payload, handleSuccessDialog);
      }
    });
  };

  handleClose = () => {
    this.setState({
      isAddRecipientDialogOpen: false
    })
  };

  render() {
    const limitData =  this.props.limitSendingAmount;
    const recipients = this.props.recipients;
    const { handleChange, handleSubmit, handleClose, onAddEditRecipientHandle, handleSuccessDialogClose } = this;
    const propsToForward = {
      ...this.state,
      recipient: recipients || [],
      limits: limitData || [],
      recipientId: this.state.recipientId,
      toAccounts: [],
      handleChange,
      handleSubmit,
      handleClose,
      onAddEditRecipientHandle,
      handleSuccessDialogClose,
      isAddRecipientDialogOpen: this.state.isAddRecipientDialogOpen
    };
    return <SendToRecipient {...propsToForward} />;
  }
}

const mapStateToProps = (state) => {
  return {
    state,
    limitSendingAmount: state.moveMoneyReducer.limitData,
    recipients: state.moveMoneyReducer.recipients
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendToRecipientPage);
