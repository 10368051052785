import React from "react";
import { useSelector } from "react-redux";
import TransferMoneyDialog from "../../transferMoney/containers/TransferMoneyDialog";

export const TransferBetweenAcPage = (props) => {
  const authReducer = useSelector((state) => state.authReducer);
  const purseReducer = useSelector((state) => state.purseReducer);
  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const { userCardTing } = dashboardReducer;

  const { loginResponseData } = authReducer;

  const { purse } = purseReducer;

  const fromAccounts = [
    {
      name: "Main Account",
      ting: userCardTing,
      transferType: "card",
    },
    {
      name: "Goal Account",
      ting: purse?.ting,
      transferType: "purse",
    },
  ];

  const toAccounts = [
    {
      name: "Main Account",
      ting: userCardTing,
      transferType: "card",
    },
    {
      name: "Goal Account",
      ting: purse?.ting,
      transferType: "purse",
    },
  ];

  const transferMoneyDialogData = {
    fromAccountIndex: 0,
    toAccountIndex: 0,

    fromAccounts: fromAccounts,
    toAccounts: toAccounts,

    bibson: loginResponseData?.bibson,
    user_id: loginResponseData?.user_id,
    card_ting: userCardTing,
  };

  return (
    <TransferMoneyDialog
      noDialog
      data={transferMoneyDialogData}
      handleClose={props.handleClose}
    />
  );
};
