const REFRESH_TOKEN_TIME = 10 * 60 * 1000 // 10 minutes
const COUNT_DOWN_TIME = 15 * 60 * 1000 // 15 minutes

const EVENT_TO_DECTECT_IDLE = [
  'mousemove', 'mousedown', 'keypress', 'touchmove', 'scroll', 'click', 'touchstart', 'touchend', 'touchcancel', 'wheel', 'mousewheel', 'blur'
]

let eventIsRegistered = false
let idleTimer = null
let idleEventHandlers = []
let refreshTokenPid = null
let refeshTokenAt = new Date().getTime()
let refeshTokenActions = []
export const registerIdleEvent = (handler) => {
  const existedHandler = idleEventHandlers.find(h => h === handler)
  if (!existedHandler) {
    idleEventHandlers.push(handler)
  }
}

export const unRegisterIdleEvent = (handler) => {
  idleEventHandlers = idleEventHandlers.filter(h => h !== handler)
}

export const updateRefreshTokenTime = () => {
  refeshTokenAt = new Date().getTime()
}

export const getNewLoginToken = () => {
  refeshTokenActions.forEach(action => action())
  updateRefreshTokenTime()
}

const scheduleToGetNewLoginToken = () => {
  const now = new Date().getTime()
  const timeLeft = refeshTokenAt + REFRESH_TOKEN_TIME - now
  console.info("Time left to refresh token: ", timeLeft)
  if (timeLeft > 0) {
    window.clearTimeout(refreshTokenPid)
    refreshTokenPid = setTimeout(() => {
      getNewLoginToken()
    }, timeLeft - 3000)
  }
}

const scheduleToRefreshTokenAndResetIdleTimer = () => {
  scheduleToGetNewLoginToken()
  console.info("reset idle timer")
  window.clearTimeout(idleTimer)
  idleTimer = setTimeout(() => {
    console.info("Idle time out", idleEventHandlers)
    idleEventHandlers.forEach(handler => handler())
  }, COUNT_DOWN_TIME)
}

export const initIdleDetector = (onRefreshTokenHandler) => {
  // Add the action to get a new login token
  if (onRefreshTokenHandler) refeshTokenActions.push(onRefreshTokenHandler)
  updateRefreshTokenTime()
  // Register the event to detect idle
  if (eventIsRegistered) return
  EVENT_TO_DECTECT_IDLE.forEach(event => {
    window.addEventListener(event, scheduleToRefreshTokenAndResetIdleTimer)
  })
  eventIsRegistered = true
}

export const removeIdleDetector = () => {
  console.info("remove idle detector")
  EVENT_TO_DECTECT_IDLE.forEach(event => {
    window.removeEventListener(event, scheduleToRefreshTokenAndResetIdleTimer)
  })
  refeshTokenActions = []
  eventIsRegistered = false
}

