import React, { useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import { Box, DialogContent, DialogTitle, Divider } from "@material-ui/core";
import { useAppStyles } from "../../../../App.style";
import { useStyles } from "./style";
import { IconButton } from "../../../../common/components/core";
import {
  closeBlackIcon,
  logoWithByPayfareImg,
} from "../../../../constants/images";
import { AppButton } from "../../../../common/components/AppButton";
import * as profileActions from "../../../profile/actions";
import { useDispatch, useSelector } from "react-redux";

export const ContactSupportDialog = (props) => {
  const { onExit, handleContactSupport } = props;
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const { loginResponseData } = authReducer;
  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const { userCardTing } = dashboardReducer;
  const profileReducer = useSelector((state) => state.profileReducer);
  const { supportEmail } = profileReducer;

  const appClasses = useAppStyles();
  const classes = useStyles();

  useEffect(() => {
    if (!loginResponseData || !userCardTing) {
      return;
    }

    const data = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
      card_ting: userCardTing,
    };
    dispatch(profileActions.doGetAndSetSupportEmail(data));
  }, []);

  return (
    <Dialog
      open={true}
      onClose={onExit}
      aria-labelledby="form-dialog-title"
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ className: appClasses.paperCard }}
    >
      <DialogTitle id="form-dialog-title">
        <div className={appClasses.dialogTitle}>
          <IconButton className={appClasses.dialogCloseBtn} onClick={onExit}>
            <img src={closeBlackIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider></Divider>

      <DialogContent className={appClasses.dialogContent1}>
        <div className={classes.imgWrapper}>
          <img src={logoWithByPayfareImg} height={100} width={200} />
        </div>

        <Box mt={10} />

        <AppButton
          contained
          onClick={handleContactSupport}
          className={`${appClasses.primaryBtn} ${appClasses.width100p}`}
          primary
          disabled={!supportEmail}
          href={`mailto:${supportEmail}`}
        >
          Contact Support
        </AppButton>
        <Box mt={2} />
      </DialogContent>
    </Dialog>
  );
};
