import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "626px",
  },
  btnDelete: {
    textAlign: "center",
    cursor: "pointer",
    padding: "5px 0px",
    marginTop: "15px"
  },
  mb10: {
    marginBottom: "10px"
  },
  mtb10: {
    margin: "10px 0px 10px 0px"
  }
}));
