import { makeStyles } from "@material-ui/core";
import { closeBlackIcon } from "../../../../constants";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "626px",
  },
  header: {
    textAlign: "center",
  },
  imgWrapper: {
    textAlign: "center",
  },
  addressRadio: {
    width: "50%",
    margin: "0px 130px"
  },
  lockCardText: {
    textAlign: "center",
    marginTop: "10px",
    cursor: "pointer"
  }
}));
