import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useStyles } from "./style";
import { useAppStyles } from "../../../../App.style";

export const CashDeposit = (props) => {
  const appClasses = useAppStyles();
  const classes = useStyles();

  const { helpTopicCashDeposit } = props;

  return (
    <>
      <Grid xs={12} item className={classes.root}>
        <Typography variant="h6" className={appClasses.text3}>
          Cash Deposit
        </Typography>
        <Box mt={1} />
        <div
          dangerouslySetInnerHTML={{
            __html: helpTopicCashDeposit?.content,
          }}
        ></div>
      </Grid>
    </>
  );
};
