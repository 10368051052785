import { Radio } from "@material-ui/core";
import React from "react";
import { useStyles } from "./style";

export const AppRadio = (props) => {
  const classes = useStyles();
  const { label } = props;

  const Rcom = (props) => {
    const { checked } = props;
    return (
      <div className={`${classes.container}`}>
        <div
          className={`${classes.icon} ${checked ? classes.checkedB : ""}`}
        ></div>
        &nbsp;
        <div className={`${classes.label} ${checked ? classes.checkedC : ""}`}>
          {" "}
          {label}
        </div>
      </div>
    );
  };

  return (
    <Radio
      {...props}
      size={"small"}
      checkedIcon={<Rcom checked></Rcom>}
      icon={<Rcom></Rcom>}
      color={"primary"}
    />
  );
};
