import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../actions";
import moment from "moment";

import { Statements } from "../components/Statements";

export const Statementpage = (props) => {
  const dispatch = useDispatch();

  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const authReducer = useSelector((state) => state.authReducer);
  const statementReducer = useSelector((state) => state.statementReducer);
  const isStatementsTable =  false;
  const { loginResponseData } = authReducer;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (!loginResponseData) {
      return;
    }

    const payload = {
      bibson: loginResponseData?.bibson,
      user_id: loginResponseData?.user_id,
    };

    dispatch(actions.doGetAndSetStatement({ ...payload }));
  };

  const propsTobeForward = {
    ...statementReducer,
    isStatementsTable,
  };

  return <Statements {...propsTobeForward} />;
};
